import React from 'react';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { createDefaultItem } from './fieldInputFactory';
import FontAwesome from 'react-fontawesome';
import { FieldInputShape, fieldInputPropTypes } from '../../models';
import FieldInputDecorator from '../FieldInputDecorator'

/**
 * Input field for a list-type datasource parameter
 */

function getFieldInputItemProps(input, meta, itemProps, elemType) {
    // replace the field name of the array (from the config) with the field name of the item,
    // so the binders bind correctly.
    const fieldConfig = itemProps.fieldConfig
        .set('name', input.name)
        .set('type', elemType);

    return {
        ...itemProps,
        fieldConfig,
        input,
        meta
    }
}

function FieldInputElementComponent(props) {
    const { elemType, removeFn, meta, input, itemProps } = props;
    const inputItemProps = getFieldInputItemProps(input, meta, itemProps, elemType);

    return <div className="field-list-item">
            <div className="field-list-item-inner">
                <FieldInputDecorator {...inputItemProps}/>
            </div>
            <FontAwesome className="field-input-icon-btn" name="times" onClick={removeFn}/>
        </div>
}

FieldInputElementComponent.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    idx: PropTypes.number.isRequired,
    removeFn: PropTypes.func.isRequired,
    elemType: PropTypes.string.isRequired,
    itemProps: FieldInputShape.isRequired
}

function FieldInputListComponent(props) {
    const elemType = props.elemType;
    const fields = props.fields.map((fieldName, idx) => {
        return <Field
            key={fieldName}
            name={fieldName}
            removeFn={props.fields.remove.bind(null, idx)}
            elemType={elemType}
            itemProps={props.itemProps}
            component={FieldInputElementComponent}/>
    });

    return <div>
        { fields }
        <button onClick={() => props.fields.push(createDefaultItem(elemType))}>+</button>
    </div>
}

FieldInputListComponent.propTypes = {
    elemType: PropTypes.string.isRequired,
    itemProps: FieldInputShape.isRequired,
    fields: PropTypes.object.isRequired
}

function getElemType(fieldConfig) {
    const type = fieldConfig.get('type');
    return type.substring(0, type.length - 2);
}

function FieldInputList(props) {
    const name = props.input.name;
    const elemType = getElemType(props.fieldConfig);
    return <FieldArray
        name={`${name}.values`}
        elemType={elemType}
        itemProps={props}
        component={FieldInputListComponent}/>
}

FieldInputList.propTypes = fieldInputPropTypes;

export default FieldInputList;
