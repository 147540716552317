import { compile as compileTeeCup, evaluate as evaluateTeeCup } from 'tee-cup';
import percentExpression from './expressions/percentExpression';

export function compileStatic(v) {
    return [v];
}

export function compile(key, input) {
    if (key === 'drilldown') {
        const params = compileTeeCup(input.get('params'));
        return input.set('params', params);
    }
    if (typeof input === 'string' && input.includes('$')) {
        const compiled = compileTeeCup(input.startsWith('=') ? input: '=' + input);
        return compiled;
    }
    return compileStatic(input);
}

export function compileCondition(key, input) {
    return compileTeeCup(input.startsWith('=') ? input: '=' + input);
}

export function evaluate(expression, context) {
    const key = context.key;
    if (key === 'drilldown') {
        const params = evaluateTeeCup(expression.get('params'), context);
        return expression.set('params', params);
    }

    return evaluateTeeCup(expression, context.template);
}

export function evaluateCondition(expression, context) {
    const e = evaluate(expression, context);
    return e;
}

export function compileAndEvaluate(input, context) {
    const expr = compileTeeCup(input);
    return evaluate(expr, context);
}

export function isDynamicExpression(expression) {
    return expression.some((part) => typeof part === 'object' && part !== null);
}

export function valueFromStaticExpression(expression) {
    return expression.length > 0 ? expression[0] : '';
}

export function isCompileable(key, expression) {
    if (key === 'drilldown') {
        return true;
    }

    return typeof expression === 'string' ||
        typeof expression === 'number' ||
        typeof expression === 'boolean';
}
