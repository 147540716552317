import { DataView, DataViewObserver } from "./viewTypes";
import { Row } from "../../components/types";

export class SourceView implements DataView {
    private readonly observers: DataViewObserver[] = [];
    private data: Row[];
    private lookupById: Map<string, number>;

    constructor(data: Row[]) {
        this.data = data;
        this.lookupById = this.computeLookupById(data);
    }

    setData(data: Row[]) {
        if (data !== this.data) {
            this.data = data;
            this.lookupById = this.computeLookupById(data);
            for (let observer of this.observers) {
                observer('set-data');
            }
        }
    }

    getRecordById(id: string): Row {
        const index = this.lookupById.get(id);
        if (index != null) {
            return this.data[index];
        } else {
            throw new Error(`No record exists with id "${id}"`);
        }
    }

    getData(start?: number, end?: number): Row[] {
        if (!start && !end) return this.data;
        if (!start) start = 0;
        if (!end) end = this.data.length;
        return this.data.slice(start, end);
    }

    recordCount(): number {
        return this.data.length;
    }

    addObserver(observer: DataViewObserver) {
        this.observers.push(observer);
    }

    private computeLookupById(rows: Row[]) {
        const lookup = new Map<string, number>();
        for (let i = 0, n = rows.length; i < n; i++) {
            lookup.set(rows[i].id, i);
        }
        return lookup;
    }
}

