import { PluginDefinition } from "./types/pluginTypes";
import { Cache } from "./utils/Cache";
import { PluginDefinitionLoader } from "./PluginDefinitionLoader";

export type PluginCache<C, R> = {
    resources: Cache<R>;
    definition: PluginDefinitionLoader<C, R>;
}

export class PluginsManager {
    private readonly plugins = new Map<string, PluginCache<unknown, unknown>>();

    /**
     * Add a new plugin source
     * @param id - id of the plugin
     * @param source - plugin source location
     */
    addPlugin<C, R>(id: string, source: string): void {
        const cache: PluginCache<C, R> = {
            resources: new Cache<R>(),
            definition: new PluginDefinitionLoader(id, source)
        };
        this.plugins.set(id, cache as PluginCache<unknown, unknown>);
    }
    
    /**
     * Retrieves the plugin cache
     * @param id - id of the plugin
     */
    getPlugin<C, R>(id: string): PluginCache<C, R> | undefined {
        return this.plugins.get(id) as PluginCache<C, R> | undefined;
    }

    /**
     * Called by the plugin script after it is loaded
     * @param id - id of the plugin
     * @param definition - plugin definition
     */
    onDefinitionLoaded<C, R>(id: string, definition: PluginDefinition<C, R>): void {
        const plugin = this.plugins.get(id) as PluginCache<C, R> | undefined;
        if (plugin) {
            plugin.definition.onLoaded(definition);
        }
    }
}
