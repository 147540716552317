import { RequestBuilder } from "@pearlchain/component-lib-common";
import { RealmResponse } from "./responseTypes";

export const REQUEST_ID = {
    AVAILABLE_REALMS: 'AVAILABLE_REALMS'
}

const URL = {
    AVAILABLE_REALMS: 'sec/api/realm/available'
}

export function listAvailableRealms() {
    return new RequestBuilder()
        .get(URL.AVAILABLE_REALMS)
        .toJS<RealmResponse[]>();
}
