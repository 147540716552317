import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { isFetchingAnyDesign, getDrilldownBreadcrumbs, getCurrentDesignId } from '../../helper/selector/selectors';
import { drilldownFromBreadcrumb } from '../../actions/drilldownActions';
import { popView } from '../../actions/DesignerActions';
import FontAwesome from 'react-fontawesome';
import { getViewStack } from '../../helper/selector/canvasFigureViewSelectors';

function BreadcrumbTrail(props, context) {
    if(!showDrilldownBreadcrumbs(props.drilldowns) && !showViewBreadcrumbs(props.viewStack))
        return false;

    function renderBreadcrumbItem(name, idx, fetching, callback) {
        return (
            <div className="bread-crumb"
                key={idx}
                onClick={callback}>
                { idx > 0 && <div className="triangle"/> }
                { idx > 0 && <div className="triangle-overlay"/> }
                { fetching
                    ? <FontAwesome className="loading-spinner-small" spin name="circle-o-notch"/>
                    : <span>{ name }</span> }
            </div>)
    }

    function renderDrilldownBreadcrumb(breadcrumb, idx) {
        const isLast = idx === props.drilldowns.size - 1;
        const designId = breadcrumb.get('designId');
        const params = breadcrumb.get('params');
        const name = breadcrumb.get('name');
        return renderBreadcrumbItem(name, idx, isLast && props.fetching,
            !isLast && (() => props.drilldownFromBreadcrumb(designId, params, idx)));
    }

    function renderViewBreadcrumb(view, idx) {
        const designId = props.designId;
        const name = view.get('name');
        const viewId = view.get('viewId');
        return renderBreadcrumbItem(name, idx, false, () => props.popView(designId, viewId))
    }

    return <div className="bread-crumb-trail">
        { showDrilldownBreadcrumbs(props.drilldowns) && props.drilldowns.map(renderDrilldownBreadcrumb) }
        { showViewBreadcrumbs(props.viewStack) && props.viewStack.map(renderViewBreadcrumb)}
    </div>
}

function showDrilldownBreadcrumbs(drilldowns) {
    return drilldowns ? drilldowns.size > 1 : false;
}

function showViewBreadcrumbs(viewStack) {
    return viewStack ? viewStack.size : false;
}

BreadcrumbTrail.contextTypes = {
    widgetUuid: PropTypes.string
}

BreadcrumbTrail.propTypes = {
    fetching: PropTypes.bool.isRequired,
    designId: PropTypes.string.isRequired,
    viewStack: ImmutablePropTypes.list,
    drilldowns: ImmutablePropTypes.list.isRequired,
    drilldownFromBreadcrumb: PropTypes.func.isRequired,
    popView: PropTypes.func.isRequired
}

export default connect(
    (state) => {
        const designId = getCurrentDesignId(state);
        return {
            designId,
            viewStack: getViewStack(state, designId),
            drilldowns: getDrilldownBreadcrumbs(state),
            fetching: isFetchingAnyDesign(state)
        }
    },
    { drilldownFromBreadcrumb, popView }
)(BreadcrumbTrail);
