import { List, Map } from 'immutable';
import { createTimer } from '../../perfMonitor';

const timer = createTimer('compute-parents-mapping');

/**
 * Updates and maintains a mapping, figure id -> parent-heirarchy.
 * 
 * This is used to build the path of the figure in the store,
 * as well as to determine relationships between the folders and the current selection.
 * 
 * For performance reasons, if the figure paths didn't change, the returned instance returned will not change. 
 */

export function createFigureParentsMapping() {
    let prev = Map();
    return (figureChildren) => {
        if(figureChildren == null) return Map();
        const timerInstance = timer.start();

        prev = prev.withMutations((m) => {

            // remove keys not in the figure-children
            const keysToRemove = new Set(m.keySeq());

            const queue = figureChildren.get('root')
                .map((figId, idx) => [List(), idx, figId])
                .toArray();

            while(queue.length > 0) {
                const [ parentPath, index, figId ] = queue.pop();
    
                keysToRemove.delete(figId);
    
                const path = parentPath.push(Map({ index, id: figId }));
                const prevPath = prev.get(figId);
                if(!arePathsEquivalent(prevPath, path)) {
                    m.set(figId, path);
                }
    
                const children = figureChildren.get(figId);
                if(children) {
                    let idx = 0;
                    for(let child of children)
                        queue.push([ path, idx++, child ]);
                }
            }

            // remove remaining keys
            for(let k of keysToRemove) m.delete(k);
        })

        timerInstance.stop();
        return prev;
    }
}

function arePathsEquivalent(prev, curr) {
    if(prev === curr) {
        return true;
    } else if(prev == null || curr == null || prev.size !== curr.size) {
        return false;
    }

    const n = curr.size;
    for(let i = 0; i < n; i++) {
        const pIdx = prev.get(i).get('index');
        const idx = curr.get(i).get('index');
        if(pIdx !== idx) return false;
    }

    return true;
}

/**
 * Convert a list of parents to a relative path to lookup in the store 
 */

export function parentsToRelativePath(parents) {
    const path = [];
    parents.forEach((parent, i) => {
        if(i > 0) {
            path.push('children');
        }
        path.push(parent.get('index'));
    });
    return List(path);
}
