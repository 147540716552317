import React from 'react';
import { Provider } from 'react-redux';
import store from './store-lib';
import DesignerViewerComponent from './DesignerViewerComponent';

export default function DesignerViewerContainer(props) {
    return (
        <Provider store={store}>
            <DesignerViewerComponent {...props}/>
        </Provider>
    )
}
