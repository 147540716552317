import {
    formatDecimal,
    formatTime,
    formatDate,
    formatDateTime,
    formatDateTimeWithSeconds,
    formatTimeWithSeconds,
    formatYearMonth,
    formatPrice
} from "./formatters";
import { Formatter } from "./formatterTypes";

export enum FormatType {
    DECIMAL = 'decimal',
    PRICE = 'price',
    TIME = 'time',
    TIME_SEC = 'time-seconds',
    DATE = 'date',
    DATE_TIME = 'datetime',
    DATE_TIME_SEC = 'datetime-seconds',
    YEAR_MONTH = 'year-month'
}

export const standardFormatters: { [K in FormatType]: Formatter } = {
    [FormatType.DECIMAL]: formatDecimal,
    [FormatType.PRICE]: formatPrice,
    [FormatType.TIME]: formatTime,
    [FormatType.DATE]: formatDate,
    [FormatType.DATE_TIME]: formatDateTime,
    [FormatType.DATE_TIME_SEC]: formatDateTimeWithSeconds,
    [FormatType.TIME_SEC]: formatTimeWithSeconds,
    [FormatType.YEAR_MONTH]: formatYearMonth
};
