import createUndoReducers from './undoReducers';

export default createUndoReducers([
    {
        groupId: 'figures',
        limit: 10,
        select: selectFiguresChanges,
        apply: applyFiguresChanges,
        getSubgroupId: getFiguresSubgroupId
    }
]);

function selectFiguresChanges(designer, designId) {
    return designer.getIn(['designs', designId, 'figures-config']);
}

function applyFiguresChanges(designer, changes, designId) {
    return designer.setIn(['designs', designId, 'figures-config'], changes);
}

function getFiguresSubgroupId(designer) {
    return designer.getIn(['selected-design', 'uuid']);
}
