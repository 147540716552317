import { Record } from 'immutable';

import { LocationState } from './components/location/types';
import { TranslationState } from './components/translation/types';
import { RequestsState } from './components/fetcher/types';

export enum Permission {
    CREATE_DASHBOARD
}

/**
 * The redux store-state for the entire module
 */
export interface StoreStateCommon {
    location: LocationState,
    requests: RequestsState,
    translation: Record<TranslationState>
}
