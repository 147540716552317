import React, { useState, useEffect, useMemo } from 'react';
import { Input } from 'reactstrap';

import { BaseBinderProps } from './types';

/**
 * Text-Binder that calls "onChange" when the input loses focus, or the enter key is pressed
 */

type Props = {
    formatter?: (value: string) => string,
    type?: "text" | "textarea"
} & BaseBinderProps;

export default function TextBinder({ disabled, datacy, formatter, input, type }: Props) {

    // apply the formatter to the input
    const valueFormatted = useMemo(() =>  formatter ? formatter(input.value) : input.value, [formatter, input.value]);

    // keep track of the current input state
    const [value, setValue] = useState(valueFormatted);

    // reset the state when a new input value was passed in
    useEffect(() => {
        if (value !== valueFormatted) setValue(valueFormatted);
    }, [valueFormatted]);

    function handleBlur() {
        input.onChange(value);
        input.onBlur(value);
    }

    function handleChange(e: any) {
        setValue(e.target.value);
    }

    function handleKeyPress(e: React.KeyboardEvent<any>) {
        if(e.key === 'Enter') input.onChange(value);
    }

    return <Input data-cy={datacy}
                type={type ? type : "text"}
                disabled={!!disabled}
                {...input}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                value={value}/>
}
