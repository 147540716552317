import React from 'react';
import { RequestBuilder, CachedFetcher } from '@pearlchain/component-lib-common';

import SelectBinder, { Option } from './SelectBinder';
import { BaseBinderProps } from './types';

/**
 * Fetches available companies codes, and lists the options in a select field
 */

type Props = {
    multi?: boolean,
    disabled?: boolean,
    clearable?: boolean
} & BaseBinderProps;

export interface Company {
    id: string,
    code: string,
    name: string
};

const URL = 'sec/api/masterdata/companies';

function mapDataToOptions(data: Company[] | undefined): Option<string>[] {
    return data ? data.map((d) => ({ value: d.code, label: d.name })) : [];
}

function updater() {
    return new RequestBuilder().post(URL).toJS<Company[]>();
}

export default function CompanyBinder(props: Props) {
    return (
        <CachedFetcher
            requestId='companies'
            updater={updater}
            render={
            (companies) =>
                <SelectBinder
                    options={mapDataToOptions(companies.data)}
                    multi={props.multi}
                    disabled={props.disabled}
                    input={props.input}
                    meta={props.meta}
                    clearable={props.clearable}
                    datacy={props.datacy}/>
            }
        />);
}
