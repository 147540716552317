import { List, Map } from 'immutable';
import { createTimer } from '../../perfMonitor';

const timer = createTimer('compute-children-mapping');

/**
 * Builds a map figureId -> childId.
 * 
 * If the figure ids didn't change, then the returned instance will not change,
 * so it's safe to use with memoized selectors.
 * 
 */

export function createFigureChildrenSelector(getIdFn) {
    let prev = Map();
    return (figures) => {
        if(figures == null) return figures;
        
        const timerInstance = timer.start();
        prev = prev.withMutations((m) => {
            const keysToRemove = new Set(m.keySeq());
            keysToRemove.delete('root');

            const rootFigureIds = figures.map(getIdFn);
            if(areFigureIdsDifferent(prev.get('root'), rootFigureIds)) {
                m.set('root', rootFigureIds);
            }
    
            const queue = figures.toArray();
            while(queue.length > 0) {
                const figure = queue.pop();
                const children = figure.get('children');
    
                const figureId = getIdFn(figure);
                
                const prevFigureIds = prev.get(figureId);
                const nextFigureIds = children ? children.map(getIdFn) : List();
                if(areFigureIdsDifferent(prevFigureIds, nextFigureIds)) {
                    m.set(figureId, nextFigureIds);
                }
                
                keysToRemove.delete(figureId);

                if(children != null) {
                    for(let child of children) queue.push(child);
                }
            }

            // remove remaining keys
            for(let k of keysToRemove) m.delete(k);
        });

        timerInstance.stop();
        return prev;
    }
}

function areFigureIdsDifferent(prevFigureIds, nextFigureIds) {
    if(prevFigureIds === nextFigureIds) return false;
    if(prevFigureIds == null
        || nextFigureIds == null
        || prevFigureIds.size != nextFigureIds.size)
        return true;

    for(let i = 0; i < prevFigureIds.size; ++i) {
        const prevId = prevFigureIds.get(i);
        const nextId = nextFigureIds.get(i);
        if(prevId !== nextId) return true;
    }
    return false;
}
