export function createFigureIdentifier(figureType, designId, figureId) {
    return figureId && { figureType, designId, figureId };
}

const url = {
    figurePath: (id) => 'api/resource/' + id
};

/**
 * @param {string} basePath 
 */
export function resourceIdToPath(resourceId) {
    return url.figurePath(resourceId);
}
