import $ from 'jquery';
import { IDatasource, ValueChangedListener, ValidateCallback, ValidationResult } from './datasourceTypes';
import { Row } from '../components/types';
import { SortedView } from './views/SortedView';
import { BufferedView } from './views/BufferedView';
import { SourceView } from './views/SourceView';

export type DataChangedFn = (id: string, key: number, value: any) => void;

export class Datasource implements IDatasource {
    private readonly sortedView: SortedView;
    private readonly bufferedView: BufferedView;
    private readonly sourceView: SourceView;

    private valueChangedListener: ValueChangedListener | undefined;
    private validator: ValidateCallback | undefined;
    private data: Row[];

    constructor(data: Row[]) {
        // setup the view stack
        this.sourceView = new SourceView(data);
        this.bufferedView = new BufferedView(this.sourceView);
        this.sortedView = new SortedView(this.bufferedView);

        this.data = this.sortedView.getData();
        this.sortedView.addObserver(this.handleDataViewChanged.bind(this));
    }

    getBufferedChanges(): { prev: Row, next: Row }[] {
        return this.bufferedView.getChanges();
    }

    clearChanges() {
        this.bufferedView.reset();
    }

    update(data: Row[]) {
        this.sourceView.setData(data);
    }

    recordCount() {
        return this.sortedView.recordCount();
    }

    getData(start?: number, end?: number): Row[] {
        return this.sortedView.getData(start, end);
    }

    setValue(id: string, colKey: number, value: unknown) {
        this.bufferedView.setValue(id, colKey, value);
        
        if (this.valueChangedListener) {
            this.valueChangedListener(id, colKey, value);
        }

        $(this).trigger('valuechanged', [{
            id,
            key: colKey,
            value
        }]);
    }

    getRecordById(id: string): Row {
        // FIX, bcs JQuery always convert strings to numbers if it's possible
        id = '' + id;
        return this.sortedView.getRecordById(id);
    }

    sort(comparator: (a: Row, b: Row) => number) {
        this.sortedView.sort(comparator);
    }  

    isReady() {
        return true;
    }

    assertReady() {
        if(!this.isReady()) {
            throw new Error('Datasource not ready yet');
        }
    }

    validate(record: Row, column: { key: number }, el: HTMLElement): ValidationResult[] | undefined {
        return this.validator && this.validator(record, column, el);   
    }

    setValueChangedListener?(listener: ValueChangedListener | undefined): void {
        this.valueChangedListener = listener;
    }

    setValidator(validator: ValidateCallback | undefined) {
        this.validator = validator;
    }

    private handleDataViewChanged(source?: string) {
        const oldData = this.data;
        const data = this.sortedView.getData();
        this.data = data;
        
        if (oldData !== data) {
            const values = this.computeChangedValues(oldData, data);
            if (!values.length && !this.hasIdChanges(oldData, data)) {
                // the provided data is identical. No grid changes are required
                return;
            }

            // trigger the grid to update
            if (source !== 'set-value') {
                $(this).trigger('datachanged', {
                    data,
                    oldData,
                    values
                });
            }

            // trigger the validation extension
            if (values.length) {
                if (this.validator) {
                    $(this).trigger('validationresultchanged', { values });
                }
            }
        }
    }

    private computeChangedValues(oldData: Row[], data: Row[]): { id: string, key: number }[] {
        const values: { id: string, key: number }[] = [];
        const oldDataById = new Map<string, Row>();
        for (let datum of oldData) {
            oldDataById.set(datum.id, datum);
        }

        for (let row of data) {
            const rowId = row.id;
            if (oldDataById.has(rowId)) {
                const prevRow = oldDataById.get(rowId)!;
                for (let i = 0, n = row.length; i < n; i++) {
                    if (row[i] !== prevRow[i]) {
                        values.push({ id: row.id, key: i });
                    }
                }
            }
        }
    
        return values;
    }

    private hasIdChanges(oldData: Row[], data: Row[]) {
        if (oldData.length !== data.length) {
            return true;
        }
        for (let i = 0, n = oldData.length; i < n; i++) {
            if (oldData[i].id !== data[i].id) {
                return true;
            }
        }
        return false;
    }
}
