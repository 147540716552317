import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

import { getFigureId } from '../figureConfigHelper';
import { getDesign } from './selectors';
import { createFigureParentsMapping, parentsToRelativePath } from './util/createFigureParentsSelector';
import { createFigureChildrenSelector } from './util/createFigureChildrenSelector';

export function getCanvasFiguresBasePath(designId) {
    return List(['designs', designId, 'figures-config']);
}

export const getFiguresConfig = createSelector(getDesign,
    (design) => design && design.get('figures-config'));

export function getFigureConfigs(state, designId) {
    const design = getDesign(state, designId)
    return design ? design.get('figures-config') : List();
}

export function getFigureFilterText(state) {
    return state.designer.get('filterText');
}

export const getChildMapping = createSelector(
    (canvasFigures) => canvasFigures,
    createFigureChildrenSelector(getFigureId));

export const getParentsMapping = createSelector(
    getChildMapping,
    createFigureParentsMapping(getFigureId));

export const childMappingSelector = createSelector(getFiguresConfig, getChildMapping);

export const parentMappingSelector = createSelector(getFiguresConfig, getParentsMapping);

export const canvasFigureSelector = createSelector(
        getFiguresConfig,
        parentMappingSelector,
        (figures, parentsMapping) => (figureId) => {
            const parents = parentsMapping.get(figureId);
            const relativePath = parentsToRelativePath(parents);
            return figures.getIn(relativePath);
        });

export function getComputedValues(state, designId, figureId) {
    return state.designer.getIn(['computed', designId, figureId], Map());
}

export function getAllComputedValues(state, designId) {
    return state.designer.getIn(['computed', designId], Map());
}

export function getCanvasFigureParentId(parentsMapping, figureId) {
    const parents = parentsMapping.get(figureId);
    if(parents && parents.size >= 2) {
        const parent = parents.get(parents.size - 2);
        return parent.get('id');
    }
}
