import { Map } from 'immutable';
import { Store } from 'redux';
import { getCommonState } from '../../selectors';

let store!: Store;
let cache = Map();

// Wraps the translation function with a cache to memoize it
export default function translateWithCache(code: string, defaultTranslation: string) {
  const cachedTranslation = cache.getIn([code, defaultTranslation]);
  if(cachedTranslation) {
    return cachedTranslation;
  } else {
    const translation = translate(code, defaultTranslation);
    cache = cache.setIn([code, defaultTranslation], translation);
    return translation;
  }
}

export function translate(code: string, defaultTranslation: string) {
  // The code is the default in case no default is provided
  if(!defaultTranslation) {
    defaultTranslation = code;
  }

  const commonState = getCommonState(store.getState());
  const translations = commonState.translation.get('translations');

  if(translations) {
    const translated = translations.get(code);
    if(translated) {
      return translated;
    }
  }

  return defaultTranslation;
}

export function setStore(s: Store) {
  store = s;
}

export function invalidateCache() {
  cache = Map();
}
