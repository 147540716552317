/**
 * An edit policy to show the bounds of the current view.
 */

/* global draw2d */
export const ShowViewBoundsEditPolicy = draw2d.policy.canvas.DecorationPolicy.extend({

    COLOR: '#c1c1c1',

    init(viewBounds) {
        this.color = new draw2d.util.Color(this.COLOR);
        this.svg = null;
        this.zoom = 1;
        this.viewBounds = viewBounds;
        this._super();
    },

    onInstall(canvas) {
        this._super(canvas);
        this.zoom = canvas.getZoom();
        this.setViewBounds(this.viewBounds);
    },

    onUninstall(canvas) {
        this._super(canvas);
        if(this.svg != null) {
            this.svg.remove();
            this.svg = null;
        }
    },

    setViewBounds(viewBounds) {
        this.viewBounds = viewBounds;
        if(this.canvas == null) return;

        if(this.svg != null) {
            this.svg.remove();
        }

        const paper = this.canvas.paper;
        const x = viewBounds.x;
        const y = viewBounds.y;
        const w = viewBounds.width;
        const h = viewBounds.height;
        
        const props = { stroke: this.color.hash() };
        paper.setStart();
        paper.rect(x, y, w, h).attr(props);
        paper.path(`M${x},${y}L${w},${h}M${x},${y + h}L${w},${y}`).attr(props);
        this.svg = paper.setFinish();
        this.svg.toBack();
    }
});
