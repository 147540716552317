import { Record } from 'immutable';
import { Action } from 'redux';

import { TranslationState } from './types';
import { createTranslationState } from './records';
import { SET_TRANSLATIONS, SetTranslationsAction } from './translation-actions';

function setTranslations(state: Record<TranslationState>, action: SetTranslationsAction) {
  return state.set('translations', action.translations);
}

export default function(state: Record<TranslationState> = createTranslationState(), action: Action): Record<TranslationState> {
  switch(action.type) {
    case SET_TRANSLATIONS: return setTranslations(state, action as SetTranslationsAction)
    default: return state;
  }
}
