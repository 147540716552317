import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import XHR from 'i18next-xhr-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

import RequestBuilder from '../../utils/RequestBuilder';
import { languageParam } from '../auth/authUtils';

export default function() {
    return i18next
        .use(initReactI18next)
        .use(Backend)
        .init({
            lng: languageParam.get() || undefined,
            keySeparator: '|',
            debug: false,
            ns: ['prl'],
            defaultNS: 'prl',
            backend: {
                backends: [
                    LocalStorageBackend, // primary
                    XHR // fallback
                ],
                backendOptions: [
                    {
                        /* Local storage backend */
                        prefix: 'i18next_res_'
                    },
                    {
                        /* XHR Backend */
                        loadPath: 'sec/api/translation/locales/{{lng}}/translations.json',
                        crossDomain: true,
                        ajax: fetchTranslations
                    }
                ]
            }
        }, () => {
            const lang = languageParam.get();
            if (lang && i18next.language !== lang) {
                i18next.changeLanguage(lang);
            }
        });
    }

function fetchTranslations(url: string, body: any, callback: any) {
    return new RequestBuilder()
        .post(url)
        .fetch()
        .then((response) =>  {
            if (response.ok) {
                return response.text();

            } else {
                throw {
                    status: response.status,
                    message: response.statusText
                };
            }
        })
        .then((response) => callback(response, { status: (response as any).status }))
        .catch(error => callback(undefined, error));
}
