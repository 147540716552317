export class LocalParamAccessor {
    private readonly storageKey: string;
    
    constructor(storageKey: string) {
        this.storageKey = storageKey;
        this.watchLocation();
        this.watchStorage();
    }

    set(value: string): void {
        window.localStorage.setItem(this.storageKey, value);
        window.dispatchEvent(new CustomEvent(this.eventKey));
    }

    clear(): void {
        window.localStorage.removeItem(this.storageKey);
        window.dispatchEvent(new CustomEvent(this.eventKey));
    }

    get(): string | null {
        return window.localStorage.getItem(this.storageKey);
    }

    addChangedListener(callback: () => void): () => void {
        window.addEventListener(this.eventKey, callback);
        return () => {
            window.removeEventListener(this.eventKey, callback);
        }
    }

    get eventKey() {
        return this.storageKey + '-changed';
    }

    private watchLocation(): () => void {
        const handler = () => {
            const searchParams = new URLSearchParams(window.location.hash);
            if (searchParams.has(this.storageKey)) {
                const value = searchParams.get(this.storageKey)!;
                if (value !== this.get()) {
                    this.set(value);
                }
            }
        }

        handler();
        window.addEventListener('hashchange', handler);
        return () => {
            window.removeEventListener('hashchange', handler);
        }
    }

    private watchStorage(): () => void {
        const handler = (event: StorageEvent) => {
            if (event.key !== this.storageKey) return;
            window.dispatchEvent(new CustomEvent(this.eventKey));
        }

        window.addEventListener('storage', handler);
        return () => {
            window.removeEventListener('storage', handler);
        }
    }
}
