import React from 'react';
import ReactDOM from 'react-dom';

/* global draw2d Raphael*/

/**
 * An adapter to render a group of SVG elements, managed by React
 */

function ReactShape(node, svg, rootComponent) {

    // Wrap the node in a Raphael element
    Raphael.el.constructor.call(this, node, svg);

    // initial render
    this.rootComponent = rootComponent;
    this.props = { node };
    ReactDOM.render(React.createElement(rootComponent, this.props), node);
}

ReactShape.prototype = Object.create(Raphael.el);
ReactShape.prototype.constructor = ReactShape;

ReactShape.prototype.remove = function() {
    // unmount the react component
    ReactDOM.unmountComponentAtNode(this.node);

    // remove the dom element
    Raphael.el.remove.call(this);
}

ReactShape.prototype.attr = function(attributes) {

    let updateTranslation = false;
    for(let attrName in attributes) {
        if(attrName === 'x' || attrName === 'y') {
            updateTranslation = true;
        }
        
        // copy attributes -> props
        const attrValue = attributes[attrName];
        this.props[attrName] = attrValue;
    }

    if(updateTranslation) {
        // the root translation
        this.node.setAttributeNS(null, 'transform', `translate(${this.props.x}, ${this.props.y})`);
    }

    // re-render the react component
    ReactDOM.render(React.createElement(this.rootComponent, this.props), this.node);    
}

export default draw2d.Figure.extend({
    NAME: 'custom.ReactFigure',

    createShapeElement() {
        // the SVG "group" node acts as the root SVG element
        const groupNode = document.createElementNS('http://www.w3.org/2000/svg', 'g');

        // add the element to the DOM
        const svgCanvas = this.canvas.paper.canvas;
        svgCanvas.append(groupNode);

        const component = this.getReactComponent();
        return new ReactShape(groupNode, this.canvas.paper, component);
    },

    getReactComponent() {
        // override //
    },

    repaint(attributes) {
        if(this.repaintBlocked === true
            || this.shape == null) return;

        attributes = Object.assign({}, {
            x: this.getAbsoluteX(),
            y: this.getAbsoluteY(),
            width: this.getWidth(),
            height: this.getHeight(),
            canvas: this.canvas
        }, attributes);

        this._super(attributes);
        return this;
    },

    layoutPorts() {
        // TODO
    }
});
