import { Action } from 'redux';
import { List, Record } from 'immutable';
import { Realm } from '../types/types';

enum ActionTypes {
    LOGIN= 'component-lib-ui/login/LOGIN',
    PICK_REALM= 'component-lib-ui/login/PICK_REALM',
    SET_REALMS= 'component-lib-ui/login/SET_REALMS',
    AUTH_ERROR= 'component-lib-ui/login/AUTH_ERROR',
    LOGIN_SUCCESS= 'component-lib-ui/login/LOGIN_SUCCESS',
    RESET= 'component-lib-ui/login/RESET'
};

export default ActionTypes;

export function login(username: string, password: string): LoginAction {
    return {
        type: ActionTypes.LOGIN,
        username,
        password
    };
}

export function setRealms(jwt: string, realms: List<Record<Realm>>): SetRealmsAction {
    return {
        type: ActionTypes.SET_REALMS,
        realms,
        jwt
    };
}

export function pickRealm(jwt: string, realm: string): PickRealmAction {
    return {
        type: ActionTypes.PICK_REALM,
        realm,
        jwt
    };
}

export function loginSuccess() {
    return {
        type: ActionTypes.LOGIN_SUCCESS
    };
}

export function authError(message: string): AuthErrorAction {
    return {
        type: ActionTypes.AUTH_ERROR,
        message
    };
}

export function reset() {
    return {
        type: ActionTypes.RESET
    }
}

export interface LoginAction extends Action {
    username: string,
    password: string
}

export interface SetRealmsAction extends Action {
    realms: List<Record<Realm>>,
    jwt: string
}

export interface PickRealmAction extends Action {
    realm: string,
    jwt: string
}

export interface AuthErrorAction extends Action {
    message: string
}
