import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';

const DEFAULT_ROOT_ID = 'modal-root';

/**
 * Uses a portal to render a component at a different root element of the page.
 * Useful for wrapping components that need to "break-out" of their container - e.g. modals.
 * See: https://reactjs.org/docs/portals.html
 */

type Props = {
    rootId?: string,
    children: React.ReactNode
}

export default function ModalContainer(props: Props) {
    const rootId = props.rootId || DEFAULT_ROOT_ID;
    const el = useMemo(() => document.getElementById(rootId)!, [rootId]);
    return createPortal(props.children, el);
}
