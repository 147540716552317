import { Action } from 'redux';
import { createLocationState, LocationState, createInitialState } from "./types";
import * as Acts from './actions';
import { ActionType } from './actions';

export default function reducer(state = createLocationState(), action: Action): LocationState {
    switch (action.type) {
        case ActionType.INIT_BREADCRUMBS:
            return handleInitBreadcrumbs(state, action as Acts.InitBreadcrumbsAction);
        case ActionType.RESET_BREADCRUMBS:
            return handleResetBreadcrumbs(state)
        case ActionType.SET_BREADCRUMBS:
            return handleSetBreadcrumbs(state, action as Acts.SetBreadcrumbsAction);
        default: return state;
    }
}

function handleInitBreadcrumbs(state: LocationState, action: Acts.InitBreadcrumbsAction) {
    return state
        .set('initial', createInitialState({
            breadcrumbs: action.items,
            params: action.params
        }))
        .set('breadcrumbs', action.items)
        .set('params', action.params);
}

function handleResetBreadcrumbs(state: LocationState) {
    return state
        .set('breadcrumbs', state.initial.breadcrumbs)
        .set('params', state.initial.params)
}

function handleSetBreadcrumbs(state: LocationState, action: Acts.SetBreadcrumbsAction) {
    return state
        .set('breadcrumbs', action.items)
        .set('params', action.params);
}
