import { Set } from 'immutable';
import { put, cancel, call, fork, all, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import ActionTypes from '../actions/DesignerActions';
import { updateUndoHistory } from '../actions/undoActions';

/**
 * Throttles pushes to the undo stack, so that groups of updates executed
 * in quick succession are bundled together.
 */

export default function() {
    return all([
        takeEvery(undoableActionsFilter, debounce(handleUpdateUndoHistory))
    ])
}

const undoableActions = Set([
    ActionTypes.CREATE_FIGURE_GROUP,
    ActionTypes.UNGROUP_FIGURE_GROUP,
    ActionTypes.COPY_FIGURE,
    ActionTypes.MOVE_FIGURE_Z_UP,
    ActionTypes.MOVE_FIGURE_Z_DOWN,
    ActionTypes.CREATE_FIGURE_ON_CANVAS,
    ActionTypes.DELETE_FIGURES,
    ActionTypes.CHANGE_FIGURES,
    ActionTypes.SET_FIGURE_NAME,
    ActionTypes.UPDATE_SELECTED_DATA_FIELD_VALUE
])

function undoableActionsFilter({ type }) {
    return undoableActions.contains(type);
}

function debounce(applyFn) {
    let ongoing;
    return function *(action) {
        if(ongoing != null) {
            yield cancel(ongoing);
        }

        ongoing = yield fork(function *() {
            // debounce 500 millis
            yield call(delay, 500);

            yield* applyFn(action);
        })
    }
}

function *handleUpdateUndoHistory() {
    yield put(updateUndoHistory('figures'));
}
