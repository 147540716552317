import { List, fromJS } from 'immutable'
import { baseFigureDescriptors } from './baseFigureDescriptors';

const design1 = fromJS({
  'selected-figure': null,
  'selector-configs': [],
  'datasource-results': {
    ds1: {
      quantity: 70,
      maxQuantity: 75
    }
  },
  defaults: {
    'draw2d.Connection': {
      router: 'draw2d.layout.connection.InteractiveManhattanConnectionRouter',
      radius: 15
    },
    'draw2d.shape.basic.Rectangle': {
      bgColor: '#FF0000',
      radius: 5
    },
    'draw2d.shape.node.Start': {
      radius: 2
    },
    'draw2d.shape.node.End': {
      radius: 2
    }
  },
  'figures-config': [
    {
      mainConfig: {
        data: {
          width: 50,
          height: 50,
          x: 107,
          y: 141,
          radius: 2,
          bgColor: '#bad276',
          type: 'draw2d.shape.node.Start',
          id: '5eef94d0-b5f7-5d5f-536d-0f5ff4fc9c53',
          onDblClickAction: 'Start stuff!'
        }
      },
      altConfigs: [{
        data: {
          condition: '50 == ${ds1.quantity}',
          bgColor: '#FFAA88'
        }
      },
      {
        data: {
          condition: '1 == 2',
          bgColor: '#AAFF88'
        }
      }]
    },
    {
      mainConfig: {
        data: {
          width: 50,
          height: 50,
          x: 597,
          y: 353,
          radius: 2,
          type: 'draw2d.shape.node.End',
          id: '066af27c-4bf6-96f8-8b31-e81d0613dcb4'
        }
      }
    },
    {
      mainConfig: {
        data: {
          type: 'draw2d.shape.basic.Label',
          text: 'Add text ...\nNewLine?',
          'text-template': 'The quantity: ${ds1.quantity}',
          fontColor: '#080808',
          fontSize: 12,
          x: 590,
          y: 413,
          id: 'd25388ab-f13c-2c34-eefa-6e0796884a56'
        }
      }
    },
    {
      mainConfig: {
        data: {
          vertex: [
            {
              x: 157,
              y: 88
            },
            {
              x: 267,
              y: 166
            },
            {
              x: 267,
              y: 222
            },
            {
              x: 422,
              y: 222
            },
            {
              x: 422,
              y: 378
            },
            {
              x: 597,
              y: 378
            }
          ],
          radius: 15,
          id: '22a50ada-9434-441f-5228-bf8f94972490',
          type: 'draw2d.Connection',
          router: 'draw2d.layout.connection.InteractiveManhattanConnectionRouter',
          source: {
            node: '5eef94d0-b5f7-5d5f-536d-0f5ff4fc9c53',
            port: 'output0'
          },
          target: {
            node: '066af27c-4bf6-96f8-8b31-e81d0613dcb4',
            port: 'input0'
          },
          routingMetaData: {
            routedByUserInteraction: true,
            fromDir: 1,
            toDir: 3
          }
        }
      }
    },
    {
      mainConfig: {
        data: {
          'width': 100,
          'height': 200,
          'radius': 5,
          'x': 200,
          'y': 300,
          'type': 'draw2d.shape.basic.Rectangle',
          'id': 'a99e7541-6430-035e-eebd-ba69566e6daa',
          'bgColor': '#EEEEEE'
        }
      }
    },
    {
      mainConfig: {
        data: {
          'width': 90,
          'height': 50,
          'radius': 5,
          'x': '205',
          'y': '450',
          'y=': '500 - (200*${ds1.quantity}/${ds1.maxQuantity})',
          'height=': '200*${ds1.quantity}/${ds1.maxQuantity}',
          'type': 'draw2d.shape.basic.Rectangle',
          'id': 'b7313c92-7d61-f28e-483e-74306e92490f',
          'bgColor': '#FFFFBB'
        }
      }
    },
    {
      mainConfig: {
        data: {
          'type': 'draw2d.shape.icon.GitHub',
          'width': 74,
          'height': 120,
          'id': 'b390b5c5-2217-e2e1-ffb4-17c34e273cd2',
          'x': 215,
          'y': 377,
          'bgColor': '#FF2222',
          'visible=': '${ds1.quantity}/${ds1.maxQuantity} > 0.9'
        }
      }
    }
  ],
  'figure-descriptors': [
    {
      label: 'ShapeA',
      id: 'a',
      'figure-props': {
        mainConfig: {
          data: {
            width: 50,
            height: 50,
            type: 'draw2d.shape.icon.SVGPathShape',
            svgPathData: 'M16,1.466C7.973,1.466,1.466,7.973,1.466,16c0,8.027,6.507,14.534,14.534,14.534c8.027,0,14.534-6.507,14.534-14.534C30.534,7.973,24.027,1.466,16,1.466z M17.328,24.371h-2.707v-2.596h2.707V24.371zM17.328,19.003v0.858h-2.707v-1.057c0-3.19,3.63-3.696,3.63-5.963c0-1.034-0.924-1.826-2.134-1.826c-1.254,0-2.354,0.924-2.354,0.924l-1.541-1.915c0,0,1.519-1.584,4.137-1.584c2.487,0,4.796,1.54,4.796,4.136C21.156,16.208,17.328,16.627,17.328,19.003z'
          }
        }
      }
    },
    {
      label: 'ShapeB',
      id: 'b',
      'figure-props': {
        mainConfig: {
          data: {
            width: 50,
            height: 50,
            type: 'draw2d.shape.icon.SVGPathShape',
            svgPathData: 'M14.605,13.11c0.913-2.851,2.029-4.698,3.313-6.038c0.959-1,1.453-1.316,0.891-0.216c0.25-0.199,0.606-0.464,0.885-0.605c1.555-0.733,1.442-0.119,0.373,0.54c2.923-1.045,2.82,0.286-0.271,0.949c2.527,0.047,5.214,1.656,5.987,5.077c0.105,0.474-0.021,0.428,0.464,0.514c1.047,0.186,2.03,0.174,2.991-0.13c-0.104,0.708-1.039,1.167-2.497,1.471c-0.541,0.112-0.651,0.083-0.005,0.229c0.799,0.179,1.69,0.226,2.634,0.182c-0.734,0.846-1.905,1.278-3.354,1.296c-0.904,3.309-2.976,5.678-5.596,7.164c-6.152,3.492-15.108,2.984-19.599-3.359c2.947,2.312,7.312,2.821,10.555-0.401c-2.125,0-2.674-1.591-0.99-2.449c-1.595-0.017-2.608-0.521-3.203-1.434c-0.226-0.347-0.229-0.374,0.14-0.64c0.405-0.293,0.958-0.423,1.528-0.467c-1.651-0.473-2.66-1.335-3.009-2.491c-0.116-0.382-0.134-0.363,0.256-0.462c0.38-0.097,0.87-0.148,1.309-0.17C6.11,10.88,5.336,9.917,5.139,8.852c-0.186-1.006,0.005-0.748,0.758-0.46C9.263,9.68,12.619,11.062,14.605,13.11L14.605,13.11z'
          }
        }
      }
    },
    {
      label: 'Rectangle shaped thing',
      id: 1,
      'figure-props': {
        mainConfig: {
          data: {
            type: 'draw2d.shape.basic.Rectangle',
            width: 80,
            height: 40,
            keepAspectRatio: true
          }
        }
      }
    },
    {
      label: 'Oval shaped thing',
      id: 2,
      'figure-props': {
        mainConfig: {
          data: {
            type: 'draw2d.shape.basic.Oval',
            cssClass: 'draw2d_shape_basic_Oval',
            width: 150,
            height: 100
          }
        }
      }
    },
    {
      label: 'Github rocks',
      id: 3,
      'figure-props': {
        mainConfig: {
          data: {
            type: 'draw2d.shape.icon.GitHub',
            width: 150,
            height: 100
          }
        }
      }
    },
    {
      label: 'Start',
      id: 4,
      'figure-props': {
        mainConfig: {
          data: {
            type: 'draw2d.shape.node.Start',
            width: 50,
            height: 50
          }
        }
      }
    },
    {
      label: 'End',
      id: 5,
      'figure-props': {
        mainConfig: {
          data: {
            type: 'draw2d.shape.node.End',
            width: 50,
            height: 50
          }
        }
      }
    },
    {
      label: 'Text',
      id: 6,
      'figure-props': {
        mainConfig: {
          data: {
            type: 'draw2d.shape.basic.Label',
            text: 'Add text ...\nNewLine?',
            'text-template': 'Add text ...\nNewLine?',
            fontColor: '#080808',
            fontSize: 12
          }
        }
      }
    },
    {
      label: 'Composite',
      id: 7,
      'figure-props': {
        mainConfig: {
          data: {
            type: 'draw2d.shape.composite.Group'
          }
        }
      },
      children: [
        {
          label: 'Text Thing',
          id: 8,
          'figure-props': {
            mainConfig: {
              data: {
                type: 'draw2d.shape.basic.Label',
                text: 'Composite Text Placeholder',
                fontSize: 12,
                groupId: 7
              }
            }
          }
        },
        {
          label: 'Oval Shaped Thing',
          id: 9,
          'figure-props': {
            mainConfig: {
              data: {
                type: 'draw2d.shape.basic.Oval',
                cssClass: 'draw2d_shape_basic_Oval',
                width: 150,
                height: 100,
                groupId: 7
              }
            }
          }
        }
      ]
    }
  ]
})

//b7313c92-7d61-f28e-483e-74306e92490f
let design2 = design1;
let template = design1.get('figures-config').find(
  (i) => i.getIn(['mainConfig', 'data']).get('id') === 'b7313c92-7d61-f28e-483e-74306e92490f').first()
                      .set('width', 15)
let i, j
for (i = 0; i < 40; i++) {
  for(j = 1; j <= 6; j++) {
    let id = 't' + i + '-' + j
    let qtyY = 'quantity-y' + j
    let figConfig = template.set('id', id)
            .set('x', 10+ i*20)
            .set('y=', (j*110) + ' - ('+ (100 - i) + '*${ds1.' + qtyY + '}/${ds1.maxQuantity})')
            .set('height=', (100 - i) + '*${ds1.' + qtyY + '}/${ds1.maxQuantity}')
    design2 = design2.update('figures-config', (l) => l.push(List().push(figConfig)))
    design2 = design2.setIn(['datasource-results', 'ds1', qtyY], j*10)
  }
}

const designer = fromJS({
  'design-base': design1.set('figures-config', List()).delete('datasource-results'),
  'base-figure-descriptors': baseFigureDescriptors,
  designs: {
    'test-design': design1,
    design1,
    design2
  }
})

/**
{
  type:'core.designer.SET_DATASOURCE_RESULT',
  designId: 'test-design',
  datasourceKey:'ds1',
  datasourceResult: {
    quantity: 20,
    'quantity-y1': 10,
    'quantity-y2': 30,
    'quantity-y3': 40,
    'quantity-y4': 50,
    'quantity-y5': 60,
    'quantity-y6': 70,
    maxQuantity: 100
  }
}
*/

export default designer;
