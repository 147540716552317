import { all } from 'redux-saga/effects';
import { sagas } from '@pearlchain/stackbase-common';

import datarequests from '../core/designer/datarequest/sagas';
import designListSagas from '../core/designer/designlist/sagas'
import undoSagas from '../core/designer/sagas/undoSagas';
import reportSagas from '../core/designer/sagas/reportSagas';
import drilldownSagas from '../core/designer/sagas/drilldownSagas';

export default function* () {
    yield all([
        sagas(),
        datarequests(),
        designListSagas(),
        undoSagas(),
        reportSagas(),
        drilldownSagas()
    ]);
}
