import { List, Map } from 'immutable';

/**
 * Built-in datasource data
 */

const report = Map({
    valuePath: List(['data']),
    fields: Map([
        ['pageOffset', Map({
            hidden: true,
            defaultValue: 0
        })],
        ['count', Map({
            hidden: true,
            defaultValue: -1
        })],
        ['transforms', Map({
            formStateBinding: List([
                Map({
                    from: 'reportName',
                    as: 'reportName'
                })
            ])
        })]
    ])
});

export const datasourcesData = Map([
    ['REPORT', report]
]);

export function getField(datasourceId, fieldName) {
    return datasourcesData.getIn([datasourceId, 'fields', fieldName]); 
}

export function isFieldHidden(datasourceId, fieldName) {
    const field = getField(datasourceId, fieldName);
    return field && field.get('hidden', false);
}

export function getFieldDefaultValue(datasourceId, fieldName) {
    const field = getField(datasourceId, fieldName);
    return field && field.get('defaultValue', '');
}

export function getFormStateBinding(datasourceId, fieldName) {
    const field = getField(datasourceId, fieldName);
    return field && field.get('formStateBinding', List()); 
}

export function extractMetaAndValuesFromResult(allResults, datasourceData) {
    let valuePath = datasourceData && datasourceData.get('valuePath');
    const result = allResults.get(0);
    if(valuePath == null) {
        return { meta: null, values: result };
    }

    function extract(path, value) {
        const valueKey = path.first();
        let m = Map();
        for(let [key, value] of value.entrySeq()) {
            if(key !== valueKey) {
                m = m.set(key, value);
            }
        }

        let v = value.get(valueKey);
        if(path.size > 1) {
            const { meta, values } = extract(path.shift(), v);
            m = m.set(valueKey, meta);
            v = values; 
        }

        return { meta: m, values: v }
    }

    return extract(valuePath, result);
}
