import { fromJS } from 'immutable';
import { put, takeEvery, all } from 'redux-saga/effects';
import { runRemoteCall } from '../../../util/remoteSagaHelper';
import ActionTypes, { receiveReportDefinition, fetchReportDefinitionError } from '../actions/reportActions';

const remoteUrl = {
    FIND_REPORT_DEFINITION: 'sec/api/report-definition/by-name/'
}

export default function() {
    return all([
        takeEvery(ActionTypes.FIND_REPORT_DEFINITION_BY_NAME, handleFindReportDefinitionByName)
    ]);
}

function *handleFindReportDefinitionByName(action) {
    const reportName = action.reportName;
    const url = remoteUrl.FIND_REPORT_DEFINITION + reportName;
    const [ result, error ] = yield* runRemoteCall(url, undefined, 'REPORT_DEFINITION');
    if(result) {
        const resultImm = fromJS(result);
        yield put(receiveReportDefinition(reportName, resultImm));
    } else {
        yield put(fetchReportDefinitionError(reportName, error));
    }
}
