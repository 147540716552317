import React, { useCallback, useEffect, useRef } from "react";
import { popLocation, initBreadcrumbs, getBreadcrumbs, LocationItem } from '@pearlchain/component-lib-common';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { List } from "immutable";

function isTranslateTitle(translate: boolean | undefined): boolean {
    return translate == null ? true : translate;
}

type Props = {
    initialLocation?: List<LocationItem>;
}

export default function Breadcrumbs(props: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const breadcrumbs = useSelector(getBreadcrumbs);

    const isMounted = useRef(false);
    useEffect(() => {
        // sets the initial location in the redux store. Only ever trigger once, when the page is first loaded.
        if (!isMounted.current && props.initialLocation && props.initialLocation.size) {
            isMounted.current = true;
            dispatch(initBreadcrumbs(props.initialLocation));
        }
    }, [props.initialLocation]);

    const size = breadcrumbs.size;
    const onLinkClicked = useCallback((index: number) => {
        const n = size - index - 1;
        dispatch(popLocation(n));
    }, [size, dispatch]);

    return (
        <Breadcrumb listClassName="mb-0 nav-breadcrumb prl-navbar-breadcrumbs">
            { breadcrumbs.map((item, index) => (
                <BreadcrumbItem 
                    onClick={index < size - 1 ? onLinkClicked.bind(null, index) : undefined}
                    key={index}>
                    { isTranslateTitle(item.translateTitle) ? t(item.title) : item.title }
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
}
