import { put, takeEvery, all } from 'redux-saga/effects';

import ActionTypes from '../actions/drilldownActions';
import { setSelectedDesign } from '../actions/DesignerActions';

export default function() {
    return all([
        takeEvery(ActionTypes.DRILLDOWN, handleDrilldown),
        takeEvery(ActionTypes.DRILLDOWN_FROM_BREADCRUMB, handleDrilldown)
    ]);
}

function *handleDrilldown(action) {
    const { designId, params } = action;
    yield put(setSelectedDesign(designId, params));
}
