import { List, Map } from 'immutable';

/* global draw2d */

export const canvasFiguresPath = (designId) => List(['designs', designId, 'figures-config']);

export function getFigurePropMainConfig(figure, key, defaultVal) {
    return figure && figure.getIn(['mainConfig', 'data', key], defaultVal);
}

export function isGroupFigure(figure) {
    return figure ? figure.getIn(['mainConfig', 'data', 'type']) === 'draw2d.shape.composite.Group' : false;
}

export function getFigureId(figure) {
    return getFigurePropMainConfig(figure, 'id');
} 

export function getFigureType(figure) {
    return getFigurePropMainConfig(figure, 'type');
}

export function getFigureX(figure) {
    return getFigurePropMainConfig(figure, 'x');
}

export function getFigureY(figure) {
    return getFigurePropMainConfig(figure, 'y');
}

export function getFigureName(figure) {
    return figure && figure.get('name');
}

export function isFigureReadonly(figure) {
    return figure && figure.get('readOnly');
}

export function findFigureById(design, id) {
    const figuresConfig = design.get('figures-config');
    return figuresConfig && figuresConfig.find((figure) => getFigureId(figure) === id);
}

export function getFigureChildren(figure) {
    const children = figure && figure.get('children');
    return children || List();
}

export function canvasFigureToConfig(label, canvasFigure) {

    // transform children recursively
    const children = canvasFigure.get('children', List())
        .map((childFigure) => canvasFigureToConfig(label + '_child', childFigure));

    return Map({
        label,
        id: draw2d.util.UUID.create(),
        children,
        'figure-props': canvasFigure
            .set('readOnly', false)
            .delete('children')
    });
}

/**
 * Convert figure config to a list of new figures to add to the canvas
 */
export function createFigureFromDescriptor(defaults, figureDescriptor, hasParentGroup) {

    // convert children
    const children = figureDescriptor.get('children', List())
        .map((childConfig) => createFigureFromDescriptor(defaults, childConfig, true));

    return figureDescriptor.get('figure-props')
        .set('children', children)
        .set('readOnly', false)
        .set('name', figureDescriptor.get('label'))
        .updateIn(['mainConfig', 'data'], (mainConfigData) => {
            const figureType = mainConfigData.get('type');
            const defaultsForType = defaults.get(figureType, Map());

            mainConfigData = defaultsForType.entrySeq().reduce(
                (data, [k, v]) => {
                    return data.has(k) ? data
                        : data.set(k, v);
                }, mainConfigData);
            
            // override x and y properties if not a group member
            const x = hasParentGroup && mainConfigData.get('x') || 10;
            const y = hasParentGroup && mainConfigData.get('y') || 10;

            return mainConfigData.merge({
                id: draw2d.util.UUID.create(),
                x, y
            });
        });
}


