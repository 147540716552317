import Imm from 'immutable';

import ActionTypes from '../actions/computedPropsActions';
import { getFigureId } from '../helper/figureConfigHelper';
import { getParentsMapping, getChildMapping, getCanvasFigureParentId } from '../helper/selector/canvasFigureConfigSelectors';
import { createTimer } from '../helper/perfMonitor';
import { updateExpressions } from './utils/computedExpressionsUpdater';
import { updateComputedFigure} from './utils/computedFiguresUpdater';
import { getViewId, getViewStackFiguresView, getViewWidth, getViewHeight } from '../helper/selector/canvasFigureViewSelectors';
import { getCanvasConfig, getCanvasWidth, getCanvasHeight } from '../helper/selector/canvasConfigSelectors';
import { getEvalContext } from '../helper/selector/datasourceSelectors';

const timerUpdateComputedProps = createTimer('update-computed-props');
const timerRecomputeComputedProps = createTimer('recompute-computed-props');

/**
 * Updating computed properties: 
 * 
 * 1. compile new computed properties => "expressions" 
 * 2. calculate figure contexts => "contexts"
 * 3. For each figure context:
 *   a) merge alt configs using conditions
 *   b) evaluate the merged config => "figures"
 */

export default function(state, action) {
    switch(action.type) {
        case ActionTypes.RECOMPUTE_FIGURE_PROPS:
            return handleRecomputeAllFigureProps(state, action);
        case ActionTypes.BULK_REMOVE_UPDATE_COMPUTED_PROPS:
            return handleBulkClearUpdateComputedProps(state, action);
        case ActionTypes.BULK_UPDATE_COMPUTED_PROPS:
            return handleBulkUpdateComputedProps(state, action);
        case ActionTypes.BULK_CLEAR_COMPUTED_PROPS:
            return handleBulkClearComputedProps(state, action);
    }
    return state;
}

function handleBulkClearUpdateComputedProps(state, action) {
    const { designId, removedIds, diffs } = action;
    state = handleBulkClearComputedProps(state, { designId, figureIds: removedIds });
    return handleBulkUpdateComputedProps(state, { designId, diffs });
}

function handleBulkClearComputedProps(state, action) {
    const { designId, figureIds } = action;

    return state.updateIn(['computed', designId], Imm.Map(), 
        (compVals) => compVals.withMutations((computedState) => {

            const updateParams = createUpdateParams(compVals, state, designId);
            const figureIdsFiltered = withoutChildren(state, figureIds, updateParams);
            const queue = new Array(figureIdsFiltered.size);
            for(let figureId of figureIdsFiltered) queue.push(figureId);

            let figureId;
            while((figureId = queue.pop()) != null) {
                computedState.delete(figureId);
                const children = updateParams.childMapping.get(figureId);
                if(children != null) {
                    for(let childId of children) queue.push(childId);
                }
            }
        }
    ));
}

function handleRecomputeAllFigureProps(state, action) {
    const { designId } = action;
    return state.updateIn(['computed', designId], Imm.Map(),
        (compVals) => {
            const timer = timerRecomputeComputedProps.start();
            const updateParams = createUpdateParams(compVals, state, designId);

            const allFigureIds = updateParams.figuresConfig.map(getFigureId);
            for(let figureConfigId of allFigureIds) {
                compVals = updateComputedFigure(compVals, figureConfigId, updateParams);
            }

            timer.stop();
            return compVals;
        });
}

function handleBulkUpdateComputedProps(state, action) {
    const { designId, diffs } = action;
    return state.updateIn(['computed', designId], Imm.Map(),
        (compVals) => {
            const timer = timerUpdateComputedProps.start();

            // apply diffs, update computed expressions
            let { computedValues, figureIds } = updateExpressions(compVals, diffs);

            // filter out children where the parent is also updated.
            // The children will be updated during parent calculation.
            const updateParams = createUpdateParams(computedValues, state, designId);
            const figureIdsFiltered = withoutChildren(state, figureIds, updateParams);
            
            // recalculate computed figures
            for(let figureConfigId of figureIdsFiltered) {
                computedValues = updateComputedFigure(computedValues, figureConfigId, updateParams);
            }

            timer.stop();
            return computedValues;
        });
}

function createUpdateParams(computedValues, state, designId) {
    const figuresConfig = getFiguresConfig(state, designId);
    const childMapping = getChildMapping(figuresConfig);
    const parentsMapping = getParentsMapping(figuresConfig);
    const viewId = getViewId({ designer: state }, designId);
    const evalContext = getEvalContext(state, designId);

    const view = getViewStackFiguresView(state.getIn(['viewStack', designId]));
    const canvasConfig = getCanvasConfig(state.getIn(['designs', designId]));

    const baseWidth = view ? getViewWidth(view) : getCanvasWidth(canvasConfig);
    const baseHeight = view ? getViewHeight(view) : getCanvasHeight(canvasConfig);
    
    return {
        figuresConfig,
        computedValues,
        childMapping,
        parentsMapping,
        viewId,
        evalContext,
        baseWidth,
        baseHeight
    }
}

function withoutChildren(state, figureIds, params) {
    const remaining = new Set(figureIds);
    for(let figureId of figureIds) {
        let parentId = figureId;
        while((parentId = getCanvasFigureParentId(params.parentsMapping, parentId)) != null) {
            if(remaining.has(parentId)) {
                remaining.delete(figureId);
                break;
            }
        }
    }
    return remaining;
}

function getFiguresConfig(state, designId) {
    return state.getIn(['designs', designId, 'figures-config']);
}
