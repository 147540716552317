import { PgExts } from "../types";
import { GridCreationContext } from "./extensionTypes";

export function createSelectionExtension(exts: PgExts, ctx: GridCreationContext) {
    const callbacks = ctx.callbacks;
    exts.selection = {
        onrowselected: function(grid: any, id: string | number) {
            const cb = callbacks.current;
            cb.onRowSelected && cb.onRowSelected('' + id);
        }
    }
}
