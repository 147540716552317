import { all, call } from 'redux-saga/effects';

/**
 * Creates an action creator that can create a single or bulk action
 * @param {string} actionType 
 * @param {any} argNames 
 */
export function createBulkActions(actionType, ...argNames) {
    return {
        invokeSingle(value, ...args) {
            const values = [value];
            const result = { type: actionType, values };
            for(let i = 0; i < args.length; i++) {
                result[argNames[i]] = args[i];
            }
            return result;
        },

        invokeBulk(values, ...args) {
            const result = { type: actionType, values };
            for(let i = 0; i < args.length; i++) {
                result[argNames[i]] = args[i];
            }
            return result;
        }
    }
}

export function mapSagaOverBulkValues(fn, values, ...rest) {
    if(!values.length && !values.size) {
        const value = values[0];
        if(!value) return [];

    } else {
        return all(values.reduce((acc, value) => {
            acc.push(call(fn, value, ...rest));
            return acc;
        }, []));
    }
}

/**
 * Creates a reducer that can handle a single or bulk action 
 * @param {string} actionType 
 * @param {(state: any, id: string, value: any) => any} fn 
 */
export function createBulkReducer(fn) {
    return (state, action) => {
        for(let value of action.values) {
            state = fn(state, value, action);
        }
        return state;
    }
}
