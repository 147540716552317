import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveProperties, saveAppSwitcherProperties } from '../actions/actions';
import { selectProperties, selectAppSwitcherProperties } from '../selectors/selectors';

const PROPERTIES_LOCATION = 'api/urlProperties';
const APP_SWITCHER_PROPERTIES_LOCATION = '/api/switcherProperties';


export function useProperties(){
    const properties = useSelector(selectProperties);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!properties) {
            fetch(PROPERTIES_LOCATION)
                .then(r => r.json())
                .then((properties) => {
                    dispatch(saveProperties(properties))
                });
        }
    }, [properties, saveProperties]);

    return properties;
}

export function useAppSwitcherProperties(){
    const properties = useSelector(selectAppSwitcherProperties);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!properties) {
            fetch(APP_SWITCHER_PROPERTIES_LOCATION)
                .then(r => r.json())
                .then((properties) => {
                    dispatch(saveAppSwitcherProperties(properties))
                });
        }
    }, [properties, saveAppSwitcherProperties]);

    return properties;
}
