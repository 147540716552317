import React from 'react'
import PropTypes from 'prop-types'
import {List, Map} from 'immutable'
import {formValueSelector, reduxForm, Field} from 'redux-form'
import { connect } from 'react-redux'
import FieldInputDecorator from './FieldInputDecorator'

function Column(props, fieldConfigs) {
  const rowsDom = fieldConfigs.map((fieldConfig, idx) => {
    const name = fieldConfig.get('name');
    const label = fieldConfig.get('label', name);
    return <div className="form-column-row" key={'row-' + idx}>
        <div>
          <label className="form-column-label ellipsis">{label}</label>
        </div>
        <div className="form-column-input">
          <Field
            className="field-full-width"
            name={name}
            key={name}
            fieldConfig={fieldConfig}
            formId={props.formId}
            datasourceDefinition={props.datasourceDefinition}
            formValuesSelector={props.formValuesSelector}
            component={FieldInputDecorator}/>
        </div>
      </div>
  });

  return rowsDom;
}

function FieldsContainerFn(props) {
  if (props.fieldsConfig.isEmpty()) {
    return <div></div>
  }

  const columnsAmount = props.layout.get('columns')
  const columns = props.fieldsConfig.filter((fc) => fc.get('visible', true))
                                    .groupBy((field, idx) => idx % columnsAmount)
  const colsDom = columns.valueSeq().map((colFields, idx) => Column(props, colFields, idx))
  return <div>{colsDom}</div>
}

FieldsContainerFn.propTypes = {
  layout: PropTypes.instanceOf(Map).isRequired,
  fieldsConfig: PropTypes.instanceOf(List).isRequired,
  configuration: PropTypes.instanceOf(Map).isRequired
}

function mapStateToProps(props) {
  const initialState = Map(props)
    .delete('fields')
    .set('configuration', Map(props))
    .set('fieldsConfig', props.fields)
    .set('form', props.formId)
    .set('formValuesSelector', formValueSelector(props.formId))

  return (state, { initialValues }) => {
    return initialState
      .set('datasource', state.datasource)
      .set('initialValues', initialValues ? initialValues.toJS() : {})
      .toObject()
  }
}

export function createForm(props) {
  const { formId } = props

  const reduxFormConfig = {
    form: formId,
    enableReinitialize: true
  }

  const state2props = mapStateToProps(props)
  return connect(state2props)(reduxForm(reduxFormConfig)(FieldsContainerFn));
}
