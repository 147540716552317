import { useEffect } from 'react';
import { authTokenHolder } from './authUtils';
import { useAuthToken } from './useAuthToken';
import { AuthToken } from './authTypes';

const URL_KEEP_ALIVE = 'sec/api/keep-alive';
const INTERVAL_CHECK_EXPIRED = 1000;

type Opts = {
    refreshInterval?: number
}

function getTimeToExpiry(token: AuthToken) {
    return token.payload.expiry * 1000 - new Date().getTime();
}

function getRefreshInterval(token: AuthToken, opts?: Opts): number {
    if (opts && opts.refreshInterval) {
        return opts.refreshInterval;
    } else {
        return getTimeToExpiry(token) / 2;
    }
}

/**
 * Refreshes the auth-token with an interval
 */

export function useAuthTokenRefresher(opts?: Opts) {
    let token = useAuthToken();

    // validate the token not already expired
    let startTime = new Date().getTime();
    const expiresIn = token ? getTimeToExpiry(token) : -1;
    if (expiresIn < 0) {
        token = null;
    }

    useEffect(() => {
        if (!token) {
            return;
        }
        
        // Use window.setInterval instead of setTimeout, because setTimeout is not an accurate
        // measure of time passed. In particular, the timer will be paused while the computer is asleep.
        // See: https://stackoverflow.com/questions/6346849/what-happens-to-settimeout-when-the-computer-goes-to-sleep
        const refreshInterval = getRefreshInterval(token, opts);
        const handle = window.setInterval(() => {
            const now = new Date().getTime();
            
            if (now - startTime > refreshInterval) {
                if (now - startTime > expiresIn) {
                    // If a lot of time has passed, immediately invalidate the token.
                    authTokenHolder.setToken(null);
                    return;
                }

                window.clearInterval(handle);

                // refresh the token
                fetch(URL_KEEP_ALIVE, {
                    headers: {
                        'Authorization': 'Bearing ' + token!.jwt
                    }
                })
                .then(res => {
                    if (!res.ok) {
                        // Something went wrong. Assume the cause was an invalid token.
                        // Returning null will cause the localStorage variable to be cleared.
                        console.error('Keep-alive endpoint returned status-code: ' + res.status);
                        return Promise.resolve(null);
                    }
                    return res.text()
                })
                .then(token => {
                    authTokenHolder.setJwt(token);
                    //Reset startTime as this new JWT is valid for another X amount of time
                    startTime = new Date().getTime();
                });
            }
        }, INTERVAL_CHECK_EXPIRED);

        return () => {
            window.clearInterval(handle);
        }
    }, [token]);

    return token;
}
