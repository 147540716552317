import React from 'react';
import FieldInputList from './FieldInputList';
import FieldInputMap from './FieldInputMap';
import FieldInputReportTransform from './FieldInputReportTransform';
import FieldInputText from './FieldInputText';
import FieldInputCheckbox from './FieldInputCheckbox';

/**
 * Datasource input field factory
 */

export default function createInputComponent(type, fieldProps) {
    if(type != null) {
        if(type.endsWith('[]')) {
            // lists
            return React.createElement(FieldInputList, fieldProps);

        } else if(type === 'REPORT_TRANSFORM') {
            // report transform
            return React.createElement(FieldInputReportTransform, fieldProps);
            
        } else if(type === 'MAP') {
            // maps
            return React.createElement(FieldInputMap, fieldProps);

        } else if(type === 'BOOLEAN') {
            // boolean
            return React.createElement(FieldInputCheckbox, fieldProps);
        }
    }

    // unrecognised field type, or text. Show a text input field
    return React.createElement(FieldInputText, fieldProps);
}

/**
 * Initializes a new element by type
 */
export function createDefaultItem(type) {
    if(type != null) {
        if(type.endsWith('[]')) {
            return [];
        } else if(type === 'REPORT_TRANSFORM') {
            return {};
        } else if(type === 'MAP') {
            return {};
        }
    }
    return '';
}

