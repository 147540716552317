import React from 'react'
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { List } from 'immutable'
import DatasourceChooser from './DatasourceChooser';
import DesignerSelectorConfig from './DesignerSelectorConfig';
import { getCurrentDesignId, isViewMode } from '../../helper/selector/selectors';
import { getSelectorConfigs, getSelectorConfigMergeFormValues } from '../../helper/selector/datasourceSelectors';
import { setDesignDatasourceConfig } from '../../datarequest/actions';

class DesignerDataSources extends React.Component {

  componentWillUnmount() {
    // persist the datasource selector values
    // one last time before unmounting
    this.props.setDesignDatasourceConfig(
      this.props.designId,
      this.props.selectorConfigs);
  }

  render() {
    const selectors = this.props.selectors ? this.props.selectors.map((selectorConfig, idx) => {
        const selectorId = selectorConfig.get('id');
        return <DesignerSelectorConfig
          key={selectorId}
          selectorIdx={idx}
          selectorId={selectorId}
          datasourceConfig={selectorConfig}/>
      }) : List();

    return <div className="designer-pane-content">
      {selectors}

      {!this.props.viewMode
          && <DatasourceChooser/> }
    </div> 
  }

}

DesignerDataSources.propTypes = {
  designId: PropTypes.string.isRequired,
  viewMode: PropTypes.bool.isRequired,
  selectors: ImmutablePropTypes.list,
  selectorConfigs: ImmutablePropTypes.list,
  setDesignDatasourceConfig: PropTypes.func.isRequired
}

export default connect(
  (state) => {
    const designId = getCurrentDesignId(state);
    return {
      designId,
      viewMode: isViewMode(state),
      selectors: getSelectorConfigs(state, designId),
      selectorConfigs: getSelectorConfigMergeFormValues(state, designId)
    }
  },
  { setDesignDatasourceConfig }
)(DesignerDataSources);
