import { RequestHandler, RequestBuilderCreator } from "./types";
import RequestBuilder from "../../utils/RequestBuilder";

let requestBuilderCreator: RequestBuilderCreator = () => () => new RequestBuilder();
const requestHandlers: { [key: string]: RequestHandler } = { }

/**
 * @deprecated removed in version 4.7.0
 */ 
export function registerRequestHandlers(handlers: { [key: string]: RequestHandler }) {
    Object.assign(requestHandlers, handlers);
}

/**
 * @deprecated removed in version 4.7.0
 */ 
export function registerRequestHandler(key: string, handler: RequestHandler) {
    requestHandlers[key] = handler;
}

/**
 * @deprecated removed in version 4.7.0
 */ 
export function getRequestHandler(key: string): RequestHandler | undefined {
    return requestHandlers[key];
}

/**
 * @deprecated removed in version 4.7.0
 */ 
export function getRequestBuilderCreator() {
    return requestBuilderCreator;
}

/**
 * @deprecated removed in version 4.7.0
 */ 
export function setRequestBuilderCreator(creator: RequestBuilderCreator) {
    requestBuilderCreator = creator;
}