import { Action } from "redux";

import { RequestsState, createRequests, RequestState, createRequest } from "./types";
import { ActionType, MountUnmountCachedFetcherAction, SetCachedFetching, ReceiveCachedDataAction, ReceiveCachedErrorAction, ClearCachedDataAction } from "./actions";

export default function reducer(state: RequestsState = createRequests(), action: Action): RequestsState {
    switch (action.type) {
        case ActionType.MOUNT_CACHED_FETCHER:
            return handleMountCachedFetcher(state, action as MountUnmountCachedFetcherAction);

        case ActionType.UNMOUNT_CACHED_FETCHER:
            return handleUnmountCachedFetcher(state, action as MountUnmountCachedFetcherAction);

        case ActionType.SET_CACHED_FETCHING:
            return handleSetCachedFetching(state, action as SetCachedFetching);

        case ActionType.RECEIVE_CACHED_DATA:
            return handleReceiveCachedData(state, action as ReceiveCachedDataAction);

        case ActionType.RECEIVE_CACHED_ERROR:
            return handleReceiveCachedError(state, action as ReceiveCachedErrorAction);

        case ActionType.CLEAR_CACHED_DATA:
            return handleClearCachedData(state, action as ClearCachedDataAction);

        default:
            return state;
    }
}

function handleMountCachedFetcher(state: RequestsState, { cacheKey, fetcherId }: MountUnmountCachedFetcherAction) {
    return updateCachedRequest(state, cacheKey, req =>
        req.update('fetchers', fs => fs.push(fetcherId)));
}

function handleUnmountCachedFetcher(state: RequestsState, { cacheKey, fetcherId }: MountUnmountCachedFetcherAction) {
    return updateCachedRequest(state, cacheKey, req =>
        req.update('fetchers', fs => fs.filter(f => f !== fetcherId)))
}

function handleSetCachedFetching(state: RequestsState, { cacheKey, isFetching }: SetCachedFetching) {
    return updateCachedRequest(state, cacheKey, req => {
        return req.set('isFetching', isFetching);
    });
}

function handleReceiveCachedData(state: RequestsState, { cacheKey, data }: ReceiveCachedDataAction) {
    return updateCachedRequest(state, cacheKey, req => {
        return req
            .set('data', data)
            .set('isFetching', false);
    });
}

function handleReceiveCachedError(state: RequestsState, { cacheKey, error }: ReceiveCachedErrorAction) {
    return updateCachedRequest(state, cacheKey, req => {
        return req
            .set('error', error)
            .set('isFetching', false);
    });
}

function handleClearCachedData(state: RequestsState, { cacheKey }: ClearCachedDataAction) {
    return updateCachedRequest(state, cacheKey, req => req.delete('data'));
}

function updateCachedRequest(state: RequestsState, cacheKey: string | string[], fn: (req: RequestState) => RequestState): RequestsState {
    return state.update('requests', (requests) => {
        if (typeof cacheKey === 'string') {
            return requests.update(cacheKey,
                req => fn(req || createRequest()));

        } else {
            if (!cacheKey.length) return requests;
            return requests.update(cacheKey[0],
                req => updateCachedRequestByPath(req || createRequest(), cacheKey, 1, fn));
        }
    });
}

function updateCachedRequestByPath(req: RequestState, path: string[], i: number, fn: (req: RequestState) => RequestState): RequestState {
    if (path.length <= i) {
        return fn(req);
    }

    const requests = req.get('requests');
    const p = path[i];

    let nextRequest = requests.get(p);
    if (nextRequest == null)
        nextRequest = createRequest();

    nextRequest = updateCachedRequestByPath(nextRequest, path, i + 1, fn);
    return req.set('requests', requests.set(p, nextRequest));
}
