import ServerDataActionTypes from './server-data-actions';
import { Map, fromJS } from 'immutable'

export default function reducer(state = {}, action) {
    switch (action.type) {
        case ServerDataActionTypes.FETCH_SERVER_DATA_SUCCESS:
            return handleFetchServerDataSuccess(state, action);
    }
    return state
}

function handleFetchServerDataSuccess(state, {results, requests}) {
    const resultsWithPath = results.map((result, i) => ({path: requests[i].path, result}))
    resultsWithPath.forEach(({path, result}) => {
        const immResult = fromJS(result)
        if (Map.isMap(state)) {
            state = state.mergeIn(path, immResult)
        } else {
            const [head, ...tail] = path;
            let slice = state[head]
            if (!slice) {
                slice = Map()
            }
            const updated = {}
            updated[head] = slice.mergeIn(tail, immResult)
            state = Object.assign({}, state, updated)
        }
    })
    return state
}
