import { fromJS, Map } from 'immutable';
import queryString from 'query-string';
import { drilldown } from '../../actions/drilldownActions';
import { resourceIdToPath } from '../../helper/figurePathHelper';

// saves the value in the userData property of the figure
function saveToUserData(k, applyFn) {
    return {
        valueFn: (f) => f.getUserData().get(k),
        applyFn: (f, v, context) => {
            if(v == null) {
                f.setUserData(f.getUserData().delete(k));
            } else {
                f.setUserData(f.getUserData().set(k, v));
            }

            applyFn && applyFn(f, v, context);
        }
    }
}

export const PROPERTY_FUNCTIONS = Map()
    .set('visible', { valueFn: (f) => f.visible,
                    applyFn: (f, v) => f.setVisible(v)})
    .set('keepAspectRatio', { valueFn: (f) => f.keepAspectRatio,
                            applyFn: (f, v) => f.setKeepAspectRatio(v)})
    .set('text', { valueFn: (f) => f.text,
                applyFn: (f, v) => f.setText(v)})
    .set('color', { valueFn: (f) => f.getColor().hex(),
                    applyFn: (f, v) => f.setColor(v)})
    .set('bgColor', { valueFn: (f) => f.getBackgroundColor().hex(),
                    applyFn: (f, v) => f.setBackgroundColor(v)})
    .set('path', saveToUserData('path', (f, v) => f.path = resourceIdToPath(v)))
    .set('x', saveToUserData('x'))
    .set('y', saveToUserData('y'))
    .set('onDblClickAction', saveToUserData('onDblClickAction'))
    .set('width', {
        valueFn: (f) => f.width,
        applyFn: (f, v) => f.setWidth(v)
    })
    .set('height', {
        valueFn: (f) => f.height,
        applyFn: (f, v) => f.setHeight(v)
    })
    .set('page', {
        valueFn: (f) => f.page,
        applyFn: (f, v) => f.setPage(v)
    })
    .set('url', {
        valueFn: (f) => f.url,
        applyFn: (f, v) => f.setUrl(v)
    })
    .set('fontColor', {
        valueFn: (f) => f.fontColor,
        applyFn: (f, v) => f.setFontColor(v)
    })
    .set('fontSize', {
        valueFn: (f) => f.fontSize,
        applyFn: (f, v) => f.setFontSize(v)
    })
    .set('bold', {
        valueFn: (f) => f.bold,
        applyFn: (f, v) => f.setBold(v)
    })
    .set('dash', {
        valueFn: (f) => f.dasharray,
        applyFn: (f, v) => f.setDashArray(v)
    })
    .set('resizeable', {
        valueFn: (f) => f.isResizeable(),
        applyFn: (f, v) => f.setResizeable(v)
    })
    .set('parent-name', {
        // returning and applying nothing so that the parent-name is not considered 
        // as one of the persistent properties of a draw2dFigure
        valueFn: (f) => null, 
        applyFn: (f, v) => null
    })
    .set('anchor-x', saveToUserData('anchor-x'))
    .set('anchor-y', saveToUserData('anchor-y'))
    .set('drilldown', saveToUserData('drilldown', (f, v, { store }) => {
        f.setUserData(f.getUserData().set('onDblClickAction', () => {
            // params are input as a query-string. e.g: param1=b&param2=c
            // convert to an object before saving in the store.
            const params = fromJS(queryString.parse(v.get('params')));
            store.dispatch(drilldown(v.get('designId'), params));
        }));
    }));