import { Map, Record, RecordOf, List } from 'immutable';

type RequestStateProps = {
    data: unknown,
    error: string | undefined,
    isFetching: boolean,
    fetchers: List<string>,
    requests: Map<string, RequestState>
}

export type RequestState = RecordOf<RequestStateProps>;

type RequestsStateProps = {
    requests: Map<string, RequestState>;
}

export type RequestsState = RecordOf<RequestsStateProps>;

export const createRequest = Record<RequestStateProps>({
    data: undefined,
    error: undefined,
    isFetching: false,
    fetchers: List<string>(),
    requests: Map<string, RequestState>()
});

export const createRequests = Record<RequestsStateProps>({
    requests: Map<string, RequestState>()
});
