import PropTypes from 'prop-types';
import toHex from 'colornames';

const messages = {
  NOT_A_NUMBER: 'Not a number',
  NOT_A_BOOLEAN: 'Not a boolean (yes/no) value',
  INVALID: 'Field invalid',
  REQUIRED: 'Field required'
}

function messageGreaterThan(max) {
  return `Greater than ${max}`;
}

function messageLessThan(min) {
  return `Less than ${min}`;
}

function messageTooLong(max) {
  return `Longer than ${max} chars`; 
}

function messageTooShort(min) {
  return `Fewer than ${min} chars`;
}

export const ValidatorType = {
  color: 'COLOR',
  string: 'STRING',
  bool: 'BOOL',
  number: 'NUMBER',
  image: 'IMAGE',
  drilldown: 'DRILL_DOWN',
  pdf: 'PDF',
  any: 'ANY'
}

export class NumberValidator {
  constructor(opts = {}) {
    this.opts = opts;
  }

  validate(value) {
    const { min = null, max = null } = this.opts;

    if(!isFinite(value) || isNaN(value = parseFloat(value))) {
     throw messages.NOT_A_NUMBER;
    }

    if(min !== null && value < min) 
      throw messageLessThan(min);
    
    if(max !== null && value > max)
      throw messageGreaterThan(max);

    return value;
  }

  get defaultValue() {
    return valueOr(this.opts.defaultValue, 0);
  }

  get type() {
    return ValidatorType.number;
  }
}

export class BoolValidator {
  constructor(opts={}) {
    this.opts = opts;
  }

  validate(value) {
    switch(typeof value) {
      case 'boolean': return value;
      case 'number': 
        if(value === 1) return true;
        if(value === 0) return false;
        break;
      case 'string':
        if(BoolValidator.truthyStrings.indexOf(value) > -1) {
          return true;
        }

        if(BoolValidator.falseyStrings.indexOf(value) > -1) {
          return false;
        }
    }
    throw messages.NOT_A_BOOLEAN;
  }

  get defaultValue() {
    return valueOr(this.opts.defaultValue, false);
  }

  get type() {
    return ValidatorType.bool;
  }
}

BoolValidator.truthyStrings = ['1', 'true', 'y', 'yes'];
BoolValidator.falseyStrings = ['0', 'false', 'n', 'no'];

export class StringValidator {

  constructor(opts = {}) {
    this.opts = opts;
  }

  validate(value) {
    const { minLength = null, maxLength = null } = this.opts;
  
    if(value == null) throw messages.REQUIRED

    value = value.toString();
    if(minLength != null && value.length < minLength)
      throw messageTooLong(minLength);

    if(maxLength != null && value.length > maxLength)
      throw messageTooShort(maxLength);

    return value;
  }

  get defaultValue() {
    return valueOr(this.opts.defaultValue, '');
  }

  get type() {
    return ValidatorType.string;
  }
}

export class ColorValidator extends StringValidator {
  constructor(opts = {}) {
    super({
      minLength: 1,
      ...opts
    })
  }

  validate(value) {
    // apply string validation
    super.validate(value);

    // convert color name to hex-code
    return toHex(value) || value;
  }

  get defaultValue() {
    return valueOr(this.opts.defaultValue, '#FFFFFF');
  }

  get type() {
    return ValidatorType.color;
  }
}

export class ImagePathValidator extends StringValidator {
  constructor(opts = {}) {
    super({
      minLength: 1,
      ...opts
    })
  }

  get type() {
    return ValidatorType.image;
  }
}


export class AnyValidator {
  constructor(opts = {}) {
    this.opts = opts;
  }

  validate(value) {
    if(this.opts.required && value == null) {
      throw messages.INVALID;
    }
    return value;
  }

  get defaultValue() {
    return valueOr(this.opts.defaultValue, '');
  }

  get type() {
    return ValidatorType.any;
  }
}

export class DrilldownValidator extends AnyValidator {
  constructor() {
    super({ required: true })
  }

  get type() {
    return ValidatorType.drilldown
  }
}

export class PdfUrlValidator extends StringValidator {
  constructor() {
    super({
      minLength: 1
    });
  }

  get type() {
    return ValidatorType.pdf;
  }
}


function valueOr(value, defaultVal) {
  return value != null ? value : defaultVal;
}

export const ValidatorShape = PropTypes.oneOfType([
  PropTypes.instanceOf(NumberValidator),
  PropTypes.instanceOf(BoolValidator),
  PropTypes.instanceOf(StringValidator),
  PropTypes.instanceOf(ColorValidator),
  PropTypes.instanceOf(ImagePathValidator)]); 
