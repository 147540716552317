// @ts-check
///<reference path='../../../../node_modules/immutable/dist/immutable.d.ts'/>
import { List } from 'immutable';

/**
 * Maps all children within the tree against a function, and returns the result in a flat list
 */
export function flatMapDeep(xs, getChildrenFn, mapFn) {
  const queue = [];
  let result = [];
  queue.push([xs, null]);
  while(queue.length > 0) {
    const [items, parent] = queue.pop();
    for(let item of items) {
      const itemMapped = mapFn ? mapFn(item, parent) : item;
      result.push(itemMapped);

      const children = getChildrenFn(item, parent);
      if(children && children.size > 0) {
        queue.push([ children, item ]);
      }
    }
  }
  return List(result);
}

/**
 * Apply a function against all the nodes within the tree
 */
export function mapDeep(xs, getChildrenFn, mapFn) {
  const lookup = new Map();
  const result = [];
  const queue = [];
  for(let x of xs)
    queue.push([x, null]);

  while(queue.length > 0) {
    const [item, parent] = queue.pop();
    const childrenMapped = lookup.get(item);
    if(childrenMapped != null) {
      const itemMapped = mapFn(item, List(childrenMapped), parent);

      if(parent != null) {
        lookup.get(parent).unshift(itemMapped);
      } else {
        result.unshift(itemMapped);
      }

    } else {
      const children = getChildrenFn(item);
      lookup.set(item, []);

      queue.push([ item, parent ]);
      if(children != null) {
        for(let child of children) {
          queue.push([child, item]);
        }
      }
    }
  }

  return List(result);
}

/**
 * Iterate over pairs of items in the tree (identified by a function). The callback function receives "null"
 * for items which exist on one side but not the other.
 * Useful for deep difference calculation.
 * @template T
 * @param {Iterable<T>} xs
 * @param {Iterable<T>} ys
 * @param {(node: T) => Iterable<T>} getChildrenFn
 * @param {(node: T) => string} idFn
 * @param {(x: T=, y: T=, xParent: T=, yParent: T=) => any} applyFn
 */
export function pairwiseDeep(xs, ys, getChildrenFn, idFn, applyFn) {
  /** @type {[Iterable<T>=, Iterable<T>=, T=, T=][]} */
  const queue = [];
  queue.push([xs, ys, null, null]);

  while(queue.length > 0) {
    const [xChildren, yChildren, xParent, yParent] = queue.pop();
    const lookupX = new Map();

    if(xChildren != null) {
      for(let x of xChildren) {
        lookupX.set(idFn(x), x);
      }
    }

    if(yChildren != null) {
      for(let y of yChildren) {
        const id = idFn(y);
        const x = lookupX.get(id);
        lookupX.delete(id);

        applyFn(x, y, xParent, yParent);
        if(x != null) {
          queue.push([getChildrenFn(x), getChildrenFn(y), x, y]);
        } else {
          queue.push([null, getChildrenFn(y), null, y]);
        }
      }
    }

    for(let x of lookupX.values()) {
      applyFn(x, null, xParent, yParent);
      queue.push([getChildrenFn(x), null, x, null]);
    }
  }

}

/**
 * Enumerate all nodes within the tree
 */
export function forEachDeep(xs, getChildrenFn, foreachFn) {
  const queue = [];
  
  // add items to the queue in reverse order,
  // so callback is called in the correct order
  for(let i = xs.size - 1; i >= 0; i--) {
    queue.push([xs.get(i), null, i]);
  }

  while(queue.length > 0) {
    const [item, parent, idx] = queue.pop();
    if(foreachFn != null) {
      const cont = foreachFn(item, parent, idx);
      if(cont === false) return; 
    }
    
    const children = getChildrenFn(item, parent);
    if(children) {
      for(let i = children.size - 1; i >= 0; i--) {
        queue.push([children.get(i), item, i]);
      }
    } 
  }
}

/**
 * Utility function for building a tree.
 * Builds depth-first, starting with leaves and ending with the root.
 * @template T, S
 * @param {function(T=): Iterable<T>} childrenFn - select the children of the current element
 * @param {function(T, T[]): S} combineFn - combine the element and children
 * @returns {S}
 */
export function createTree(root, childrenFn, combineFn) {
  const stack = [{ parent: null, item: root }];
  const resolvedMap = new Map();

  while(stack.length > 0) {
    const { parent, item } = stack[stack.length - 1];

    let isResolved = true;
    if(!resolvedMap.has(item)) {
      const xs = childrenFn(item);

      if(xs) {
        isResolved = false;
        resolvedMap.set(item, []);
        
        for(let x of xs) {
          stack.push({ parent: item, item: x })
        }
      }
    }

    if(isResolved) {
      const branch = combineFn(item, resolvedMap.get(item));

      if(parent) {
        resolvedMap.get(parent).push(branch);
        stack.pop();

      } else {
        return branch;
      }
    }
  }
}
