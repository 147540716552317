import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { List } from 'immutable';
import { useFetcher, setLocation, useAuthToken, AuthToken, LocationItem, authTokenHolder } from '@pearlchain/component-lib-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faThList } from '@fortawesome/free-solid-svg-icons';

import { LocationMenuItem } from './types';
import brandUrl from '../../img/pearlchain_thumb.png';

import {
    Collapse,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    NavbarBrand,
    NavbarToggler,
    UncontrolledDropdown
} from 'reactstrap';

import { RealmResponse } from '../responseTypes';
import { listAvailableRealms } from '../requests';
import NavDropdownItem from './NavDropdownItem';
import LanguageChooser from './LanguageChooser';
import Breadcrumbs from './Breadcrumbs';

library.add(faThList);

interface Props {
    initialLocation?: List<LocationItem>,
    prefixLocation?: List<LocationItem>,
    menuItems: LocationMenuItem[],
    loginPath: string,
    onRefreshTranslations?: () => void,
    children?: React.ReactNode,
    languageCodes?: string[];
}

function getRealmDescription(realmNo: string, realms: RealmResponse[]) {
    const realm = realms.find(rlm => rlm.no === realmNo);
    if (realm) {
        return realm.description;
    } else {
        return realmNo;
    }
}

function getUserRealmText(realms: RealmResponse[], token: AuthToken | null) {
    if (token) {
        const realmNo = token.payload.realm;
        const realmDescription = getRealmDescription(realmNo, realms);
        return token.payload.loginName + '(' + realmDescription + ')';
    } else {
        return '';
    }
}

export default function Navigation(props: Props) {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const dispatch = useDispatch();

    // fetch all available realms, so we can display the correct description
    const { data: realms } = useFetcher(listAvailableRealms);

    const onToggleNavbar = useCallback(() => {
        setIsNavbarOpen(!isNavbarOpen);
    }, [isNavbarOpen]);

    const token: AuthToken | null = useAuthToken();
    const userRealmText = useMemo(() => {
        return getUserRealmText(realms || [], token);
    }, [realms, token]);

    // callback when the current page was changed
    const onLocationChanged = useCallback((location: LocationItem) => {
        const prefix = props.prefixLocation || List<LocationItem>();
        const newLocations = prefix.push(location);
        dispatch(setLocation(newLocations));
    }, [dispatch, props.prefixLocation]);

    return (
        <Navbar color="light" light expand="md" className="prl-navbar">
            <UncontrolledDropdown className="prl-nav-menu-dropdown">
                <DropdownToggle nav>
                    <FontAwesomeIcon icon={['fas', 'th-list']}/>
                </DropdownToggle>
                <DropdownMenu>
                    { props.menuItems.map((page, idx) => (
                        <NavDropdownItem key={idx} page={page} onLocationChanged={onLocationChanged}/>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            <Breadcrumbs initialLocation={props.initialLocation}/>
            <NavbarToggler onClick={onToggleNavbar}/>
            <Collapse isOpen={isNavbarOpen} navbar>
                <NavbarBrand href="#" className="ml-auto">
                    <img width="30" height="30" src={brandUrl}/>
                </NavbarBrand>
                <Nav navbar>
                    { props.children }
                    <LanguageChooser languageCodes={props.languageCodes}/>
                    <NavItem>
                        <NavLink href="#">{ userRealmText }</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="link-decorated" href={props.loginPath} onClick={() => {
                            authTokenHolder.clear();
                        }}>Sign Out</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
}
