
export enum ActionType {
    MOUNT_CACHED_FETCHER = 'complib-common.fetcher.MOUNT_CACHED_FETCHER',
    UNMOUNT_CACHED_FETCHER = 'complib-common.fetcher.UNMOUNT_CACHED_FETCHER',
    SET_CACHED_FETCHING = 'complib-common.fetcher.SET_CACHED_FETCHING',
    RECEIVE_CACHED_DATA = 'complib-common.fetcher.RECEIVE_CACHED_DATA',
    RECEIVE_CACHED_ERROR = 'complib-common.fetcher.RECEIVE_CACHED_ERROR',
    CLEAR_CACHED_DATA = 'complib-common.fetcher.CLEAR_CACHED_DATA'
}

export function mountCachedFetcher(cacheKey: string | string[], fetcherId: string): MountUnmountCachedFetcherAction {
    return {
        type: ActionType.MOUNT_CACHED_FETCHER, cacheKey, fetcherId
    };
}

export function unmountCachedFetcher(cacheKey: string | string[], fetcherId: string): MountUnmountCachedFetcherAction {
    return {
        type: ActionType.UNMOUNT_CACHED_FETCHER, cacheKey, fetcherId
    };
}

export function setCachedFetching(cacheKey: string | string[], isFetching: boolean): SetCachedFetching {
    return {
        type: ActionType.SET_CACHED_FETCHING, cacheKey, isFetching
    };
}

export function receiveCachedData(cacheKey: string | string[], data: unknown): ReceiveCachedDataAction {
    return {
        type: ActionType.RECEIVE_CACHED_DATA, cacheKey, data
    };
}

export function receiveCachedError(cacheKey: string | string[], error: string): ReceiveCachedErrorAction {
    return {
        type: ActionType.RECEIVE_CACHED_ERROR, cacheKey, error
    };
}

export function clearCachedData(cacheKey: string | string[]): ClearCachedDataAction {
    return {
        type: ActionType.CLEAR_CACHED_DATA, cacheKey
    };
}

export interface FetcherAction {
    cacheKey: string | string[];
}

export interface ClearCachedDataAction extends FetcherAction {
    type: ActionType.CLEAR_CACHED_DATA;
}

export interface MountUnmountCachedFetcherAction extends FetcherAction {
    type: ActionType.MOUNT_CACHED_FETCHER | ActionType.UNMOUNT_CACHED_FETCHER;
    fetcherId: string;
}

export interface ReceiveCachedDataAction extends FetcherAction {
    type: ActionType.RECEIVE_CACHED_DATA;
    data: unknown;
}

export interface ReceiveCachedErrorAction extends FetcherAction {
    type: ActionType.RECEIVE_CACHED_ERROR;
    error: string;
}

export interface SetCachedFetching extends FetcherAction {
    type: ActionType.SET_CACHED_FETCHING;
    isFetching: boolean;
}
