import { useRef } from "react";

/**
 * Compares the previous value against the next, and returns the previous instance if nothing changed.
 * Useful with react components / hooks that rely on referential equality.
 * 
 * @param value 
 * @param compareFn 
 */

export function useMemoCompare<T>(value: T, compareFn: (prev: T, next: T) => boolean): T {
    const prev = useRef(value);
    if (value !== prev.current && (!compareFn(prev.current, value))) {
        prev.current = value;
    }
    return prev.current;
}
