import { call, select } from "redux-saga/effects";
import { getRequestBuilderCreator, getRequestHandler } from "./globals";

/**
 * @deprecated removed in version 4.7.0
 * 
 * Called from sagas to invoke an API request and then use its response.
 * 
 * Usage:
 * 
 * const [result, error] = yield* callRequestHandler(requestId, params);
 * ...
 */
export function *callRequestHandler(
    requestId: string,
    ...params: unknown[]
): IterableIterator<any> {
    const handler = getRequestHandler(requestId);
    let result, error;
    try {
        if (handler == null) throw new Error('No request handler exists with id: ' + requestId);
        const reqBuilderFactory = yield select(getRequestBuilderCreator())
        const safeParams = params ? params : [];
        result = yield call(handler, (reqBuilderFactory as any)(), ...safeParams);

    } catch (e) {
        error = e;
        console.error(error);
    }
    return [result, error];
}
