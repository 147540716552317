import React, { CSSProperties, useRef, useLayoutEffect } from 'react';
import $ from 'jquery';

import { EventHandler, PowergridOpts } from './types';

export type Props = {
    style?: CSSProperties,
    className?: string,
    events?: EventHandler[],
    onCreated?: (grid: any) => void,
    opts: PowergridOpts
}

export default function Grid(props: Props) {
    const elRef = useRef<HTMLDivElement>(null);
    const gridRef = useRef<any>(null);

    useLayoutEffect(() => {
        const el = elRef.current;
        if (el == null) return;

        // mount the Powergrid
        const gridOpts = Object.assign({ settingsId: props.opts.gridId }, props.opts);
        gridRef.current = ($(el) as any).PowerGrid(gridOpts);

        // supply the newly created grid to the callback
        if (props.onCreated)
            props.onCreated(gridRef.current);
    }, []);

    useLayoutEffect(() => {
        const events = props.events;
        if (events == null) return;

        const grid = gridRef.current;
        if (grid == null) return;

        // attach event listeners
        for (let event of events) {
            grid.target.on(event.key, event.action);
        }

        return () => {
            // detach old event listeners
            for (let event of events) {
                grid.target.off(event.key, event.action);
            }
        }
    }, [props.events]);

    return (
        <div
            id={props.opts.gridId}
            style={props.style}
            className={props.className}
            ref={elRef}/>
    );
}
