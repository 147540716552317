import { useRef, useCallback } from "react";

/**
 * Returns an idempotent function that calls the most recent callback provided.
 * 
 * This can be used as a transparent alternative to useRef.
 */
export function useLatestCallback<P extends any[], R>(fn: (...params: P) => R): (...params: P) => R {
    const ref = useRef(fn);
    ref.current = fn;
    return useCallback((...params: P) => ref.current.apply(null, params), []);
}
