import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { fieldInputPropTypes } from '../../models';

/**
 * Input field for a map-type datasource parameter
 */

function FieldEntry(props) {
    const name = props.input.name;
    return <div className="field-input-map-entry">
            <Field name={`${name}.key`} component="input" placeholder="key" type="text"/>
            <Field name={`${name}.value`} component="input" placeholder="value" type="text"/>
            <button onClick={props.remove}>-</button>
        </div>
}

FieldEntry.propTypes = {
    input: PropTypes.object.isRequired,
    remove: PropTypes.func.isRequired
}

function FieldInputMap(props) {
    return <div className="field-input-map">
            { props.fields.map((field, idx) => 
                <Field 
                    key={idx}
                    name={field} 
                    remove={() => props.fields.remove(idx)}
                    component={FieldEntry}
                    />
            )}
            <button onClick={() => props.fields.push({ })}>+</button>
        </div>
}

FieldInputMap.propTypes = {
    fields: PropTypes.object.isRequired
}

export default function FieldInputMapArray(props) {
    const name = props.input.name;
    return <FieldArray name={`${name}.values`} component={FieldInputMap}/>
}

FieldInputMapArray.propTypes = fieldInputPropTypes;
