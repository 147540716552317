import { Column, Row } from "./types";

export function dataToRows<T>(data: Iterable<T>, idFn: (item: T, index: number) => string, columns: Column[]): Row[] {
    const rows: Row[] = [];

    let i = 0;
    for (let item of data) {
        const row: Row = columns.map((column) => (item as any)[column._key]) as any;
        row.id = idFn(item, i++);
        rows.push(row);
    }

    return rows;
}

export function translateRows(t: (value: string) => string, columns: Column[], rows: Row[]) {
    return rows.map((row) => {
        const r: Row = Object.assign([], row);

        for (let i = 0, n = columns.length; i < n; i++) {
            let value = row[i];
            if (columns[i].translateValue && typeof value === 'string') {
                value = t(value);
            }

            r[i] = value;
        }
        
        return r;
    });
}

export function translateColumns(t: (key: string) => string, columns: Column[]): Column[] {
    return columns.map((column) => {
        if (column.translateHeader !== false) {
            const title = t(column.title);
            return { ...column, title };
        }
        return column;
    });
}
