import { StoreStateUi } from "../types/types";

let storeKey = 'stackbase';

export function getUiState(state: any): StoreStateUi {
    return state[storeKey];
}

export function setStoreKey(key: string) {
    storeKey = key;
}
