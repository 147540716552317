import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {List, Map} from 'immutable'
import _ from 'lodash';

import { shallowEqual } from '../../../core/designer/helper/reduxHelper';
import createInputComponent from './fieldinput/fieldInputFactory';

/**
 * Field input factory wrapper.
 * Uses the field config to map store to field state, and delegates to
 * a factory fn to generate the inner input component.
 */

/**
 * The properties to pass to the input component
 */
function getFieldProps(props) {
  const fieldProps = {
    ...props,
    meta: props.meta,
    input: { ...props.input }
  }

  fieldProps.input.disabled = props.fieldConfig.get('disabled', false);
  return fieldProps;
}

function FieldInputDecorator(props) {
  const fieldProps = getFieldProps(props);
  const fieldType = props.fieldConfig.get('type');
  const inputComponent = createInputComponent(fieldType, fieldProps);

  return <div className='field-input'>
    { inputComponent }
  </div>
}

FieldInputDecorator.propTypes = {
  input: PropTypes.object.isRequired,
  fieldConfig: PropTypes.instanceOf(Map).isRequired,
  fieldState: PropTypes.object.isRequired
}

function applyFieldStateMapping(ownProps, state) {
  const fieldConfig = ownProps.fieldConfig;
  const fns = fieldConfig.get('stateMapping', List())
  return fns.reduce((result, stateMappingFn) => {
      return result.merge(stateMappingFn(ownProps, state))
  }, Map());
}

function areStatePropsEqual(prev, next) {
  // performance: avoid rendering every state change
  const { fieldState: prevFieldState, ...prevShallow } = prev;
  const { fieldState: nextFieldState, ...nextShallow } = next;
  return shallowEqual(prevShallow, nextShallow)
    && _.isEqual(prevFieldState, nextFieldState);
}

export default connect(
  (state, ownProps) => {
      return {
        fieldState: applyFieldStateMapping(ownProps, state).toObject()
      }
  },
  null, null,
  { areStatePropsEqual }
)(FieldInputDecorator);
