
export type Listener<E> = (event: E) => void;

export class Listeners<E> {
    private readonly listeners: Listener<E>[] = [];

    trigger(event: E) {
        this.listeners.forEach(li => li(event));
    }

    add(listener: Listener<E>): Listener<E> {
        this.listeners.push(listener);
        return listener;
    }

    remove(listener: Listener<E>) {
        const index =  this.listeners.indexOf(listener);
        if (index >= 0) {
            this.listeners.splice(index, 1);
        }
    }
}
