import { PgExts } from "../types";
import { GridCreationContext } from "./extensionTypes";

export function createSortingExtension(exts: PgExts, context: GridCreationContext) {
    exts.sorting = {
        multiSort: context.multiSort,
        onSortColumnsChanged(sortColumns: string[]) {
            const callbacks = context.callbacks.current;
            if (callbacks.onSortColumnsChanged) {
                callbacks.onSortColumnsChanged(sortColumns);
            }
        }
    }
}

