/*global draw2d*/

/**
 * This class helps with testing canvas functions, by 
 * allowing to pass through separate implementations for the production code and tests.
 */

export default class CanvasMethods {
    constructor(canvas) {
        this.canvas = canvas;
    }

    setBackgroundColor(color) {
        const htmlContainer = this.canvas.getHtmlContainer();
        htmlContainer.parent()[0].style['background-color'] = color;
    }

    getHtmlContainer() {
        return this.canvas.getHtmlContainer();
    }

    setZoom(zoomFactor, animated) {
        this.canvas.setZoom(zoomFactor, animated);
    }

    setSize(width, height) {
        // set the size of the canvas
        this.canvas.setDimension(
            new draw2d.geo.Rectangle(0, 0, width, height));
    }

    getParentSize() {
        // compute parent dimensions
        const parent = this.canvas.html.parent();
        return [
            parent.innerWidth(),
            parent.innerHeight() ];
    }

    createSnapToGridEditPolicy(nextGridSize) {
        return new draw2d.policy.canvas.SnapToGridEditPolicy(nextGridSize);
    }

    installEditPolicy(editPolicy) {
        this.canvas.installEditPolicy(editPolicy);
    }

    uninstallEditPolicy(editPolicy) {
        this.canvas.uninstallEditPolicy(editPolicy);
    }
}
