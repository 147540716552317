import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

/**
 * A simple loading placeholder with an animated spinner
 */

export default function LoadingPlaceholder(props) {
    return <div className="wrap-loader">
            <div>
                <FontAwesome className="loading-spinner" spin name="circle-o-notch"/>
            </div>
            { props.message
                && <div className="loading-text">{ props.message }</div> }
        </div>
}

LoadingPlaceholder.propTypes = {
    message: PropTypes.string
}
