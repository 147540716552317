import uuid from 'uuid';
import { Map, List } from 'immutable';
import { mapDeep } from './treeHelper';
import { toNumber } from './mathHelper';
import { getFigureX, getFigureY } from './figureConfigHelper';

export function clearFigureInput(input) {
  return input ? input.map(() => null) : Map();
}
export function updateFiguresConfigById(state, designId, figureId, updateFn) {
  return state.updateIn(['designs', designId, 'figures-config'], (allFigureConfigs) => allFigureConfigs.map((figureConfig) => {
      if(figureId === figureConfig.getIn(['mainConfig', 'data', 'id'])) {
        return updateFn(figureConfig);
      }
      return figureConfig;
  }));
}

export function createFigureConfig(figureProps) {
    return Map({
      data: figureProps
    })
}

/**
 * Deeply copies a canvas figure generating new ids for itself and
 * its children
 * @param {*} figureConfig 
 */
export function copyFigureConfig(figureConfig) {
    return mapDeep([figureConfig], 
        (fig) => fig.get('children'), 
        (fig, children, parent) => {
            return fig
                .set('children', children)
                .updateIn(['mainConfig', 'data'], (data) => {
                    // generate new ids
                    data = data.set('id', uuid.v4());

                    // offset position of the copy
                    if(!parent) {
                        data = data
                            .set('x', toNumber(getFigureX(fig)) + 10)
                            .set('y', toNumber(getFigureY(fig)) + 10);
                    }
                    
                    return data;
                });
        }).get(0);
}
