import React from 'react';

interface Props {
    breadcrumb: String;
}

/**
 * Component only for a workaround on breadcrumbs navigation issue on 4.11.0
 * See how it is used on VCO
 * A proper refactor of breadcrumbs will be done on 4.13.0
 */
export default function BreadcrumbsAux(props: Props) {
    return (
        <p className="m-2 font-weight-bold">{props.breadcrumb}</p>
    )
}
