import { Map, List } from 'immutable';

import DesignerActionTypes from '../datarequest/actions';
import ActionTypes from '../actions/undoActions';

/**
 * Handles undo/redo of particular parts of the store.
 * Maintains a history of "past" and "future" states.
 */

/**
 * @typedef {object} FilterPath
 * @property {string} groupId - id of the undo group
 * @property {(state: any, historyId: string) => any} getChanges - resolve changes from the store
 * @property {(state: any, historyId: string, changes) => any} applyChanges - apply changes from the history to the store
 * @property {(state: any) => string} getHistoryId - get the current history id 
 * @property {number} limit - maximum number of items in the history
 */

 /**
  * @param {FilterPath[]} filterPaths - list of paths in the store to make undo-able
  */
export default function createUndoReducers(filterPaths) {
    return (state, action) => {
        switch(action.type) {
            case DesignerActionTypes.RETRIEVE_DATA_DESIGN:
                return handleInitHistory(state, filterPaths);
            case ActionTypes.UPDATE_HISTORY:
                return handleUpdateHistory(state, filterPaths, action);
            case ActionTypes.UNDO:
                return handleUndoHistory(state, filterPaths, action);
            case ActionTypes.REDO:
                return handleRedoHistory(state, filterPaths, action);
        }
        
        return state;
    }
}

function findFilterPath(filterPaths, groupId) {
    return filterPaths.find((filterPath) => filterPath.groupId === groupId);
}

function getHistoryStorePath(groupId, subgroupId) {
    const path = ['history', groupId];
    if(subgroupId != null) path.push(subgroupId);
    return path;
}

function handleUpdateHistory(state, filterPaths, { groupId }) {
    const filterPath = findFilterPath(filterPaths, groupId);
    if(filterPath != null) {
        const subgroupId = filterPath.getSubgroupId && filterPath.getSubgroupId(state);
        const curr = filterPath.select(state, subgroupId);

        const historyPath = getHistoryStorePath(filterPath.groupId, subgroupId);
        return state.updateIn(historyPath, Map(), (history) => {
            let past = history.get('past', List()).push(curr);
            if(past.size > filterPath.limit) {
                past = past.shift();
            }

            return Map({
                past,
                future: List() // clear future
            });
        });
    }

    return state;
}

function handleUndoHistory(state, filterPaths, { groupId }) {
    const filterPath = findFilterPath(filterPaths, groupId);
    if(filterPath != null) {
        const subgroupId = filterPath.getSubgroupId && filterPath.getSubgroupId(state);
        let prev, curr;

        const historyPath = getHistoryStorePath(filterPath.groupId, subgroupId);
        state = state.updateIn(historyPath, Map(), (history) => {
            let past = history.get('past', List());
            if(past.size >= 2) {
                curr = past.get(past.size - 1);
                prev = past.get(past.size - 2);
    
                const future = history.get('future', List()).push(curr);
                past = past.pop();
                
                return Map({ future, past });
            } else {
                return history;
            }
        });
    
        if(prev != null) {
            const newState = filterPath.apply(state, prev, subgroupId);
            return afterHistoryChanged(newState);
        }
    }
        
    return state;
}

function handleRedoHistory(state, filterPaths, { groupId }) {
    const filterPath = findFilterPath(filterPaths, groupId);
    if(filterPath != null) {
        const subgroupId = filterPath.getSubgroupId && filterPath.getSubgroupId(state);

        let next;
        const historyPath = getHistoryStorePath(filterPath.groupId, subgroupId);
        state = state.updateIn(historyPath, Map(), (history) => {
            let future = history.get('future', List());
            next = future.last();
    
            if(next != null) {
                const past = history.get('past', List()).push(next);
                future = future.pop();
    
                return Map({ future, past });
            } else {
                return history;
            }
        });
    
        if(next != null) {
            const newState = filterPath.apply(state, next, subgroupId);
            return afterHistoryChanged(newState);
        }
    }

    return state;
}

function handleInitHistory(state, filterPaths) {
    return filterPaths.reduce((state, filterPath) => {
        const subgroupId = filterPath.getSubgroupId && filterPath.getSubgroupId(state);
        const curr = filterPath.select(state, subgroupId);

        const historyPath = getHistoryStorePath(filterPath.groupId, subgroupId);
        return state.setIn(historyPath, Map({
            past: List([ curr ]),
            future: List()
        }));
    }, state);
}

function afterHistoryChanged(state) {
    const designUid = state.getIn(['selected-design', 'uuid']);
    // clear the figure selection after undo & redo
    return state.deleteIn(['designs', designUid, 'selected-figure'])
}
