import { getCommonState } from "../../selectors";
import { RequestState } from "./types";

export function getCachedRequest(state: any, requestId: string | string[]): RequestState | undefined {
    const commonState = getCommonState(state);
    let requests = commonState.requests;
    if (typeof requestId === 'string') {
        return requests.get('requests').get(requestId);

    } else {
        if (!requestId.length) return;
        let req = requests.get('requests').get(requestId[0]);
        for (let reqId of requestId.slice(1)) {
            req = req && req.get('requests').get(reqId);
        }
        return req;
    }
}
