import { takeEvery, select, call, put } from 'redux-saga/effects'
import paramsBuilder from '../../utils/RequestBuilder'
import ServerDataActionTypes, * as actions from './server-data-actions';
import {identity} from '../../utils/utils';

const DATA_REQUEST_URL = 'sec/api/datarequest'

/**
 * A handler must be passed as props to this method depending on the datasource.
 * Handler should contain a prepareRequest method. 
 * @param {*} config 
 * Example 
 * config: {
 *      handlers: {
 *      datasourceName1: datasourceHandler1
 *      }
 * }
 * 
 * datasourceHandler1: {
 *      prepareRequest(state, request) => return preparedRequest
 * }
 */

export default function *(config) {
    yield [
        takeEvery(ServerDataActionTypes.FETCH_SERVER_DATA, handleFetchClientData, config.handlers)
    ]
}

export function *handleFetchClientData(handlers, {requests}) {
    try {
        const state = yield select()
        const preparedRequests = prepareRequests(state, handlers, requests)
        if (preparedRequests.length > 1) {
            const payload = preparedRequests.map(toClientDataRequest)
            const multiRequest = toClientDataRequest({datasource: 'multi', payload})
            const results = yield call(doFetchClientData, multiRequest)
            yield put(actions.fetchServerDataSuccess(results, preparedRequests))
        } else if(preparedRequests.length == 1) {
            const result = yield call(doFetchClientData, toClientDataRequest(preparedRequests[0]))
            yield put(actions.fetchServerDataSuccess([result], preparedRequests))
        }
    } catch (e) {
        yield put(actions.fetchServerDataError(e))    
    }
}

function prepareRequests(state, handlers, requests){
    const preparedRequests = requests.map(r => {
        const handler = handlers[r.datasource]
        if (handler) {
            return handler.prepareRequest(state, r)
        } else {
            return r
        }})
    return preparedRequests.filter(identity)
}
function toClientDataRequest({datasource, payload}) {
    return {
        datasource,
        payload: JSON.stringify(payload)
    }
}

export function doFetchClientData(request) {
    try {
        return paramsBuilder()
            .withJsonBody(request)
            .post(DATA_REQUEST_URL)
            .asJson()
            .promise()
    } catch (error) {
        throw new Error(error)
    }
}
