import { getSelectedPrimaryFigureId, getSelectionType } from '../../helper/selector/selectionSelectors';
import { createTimer } from '../../helper/perfMonitor';

const updateSelectionTimer = createTimer('update-selection');

/**
 * Applies selection changes
 */
export default function (prev, next, draw2dCanvas, draw2dFigures) {
    const prevSelection = prev && prev.design && prev.design.get('selected-figure');
    const nextSelection = next && next.design && next.design.get('selected-figure');

    if(prevSelection !== nextSelection) {
        const timer = updateSelectionTimer.start();
        const selection = draw2dCanvas.getSelection();

        if(nextSelection) {
            const id = getSelectedPrimaryFigureId(nextSelection);
            const figureType = getSelectionType(nextSelection);
            if(id && figureType === 'canvas') {

                // update current selection
                const figure = draw2dFigures.get(id);
                if(figure != null && !selection.contains(figure)) {
                    draw2dCanvas.setCurrentSelection(figure);
                }
            }

        } else if(prevSelection) {
            const figureType = getSelectionType(prevSelection);
            if(figureType === 'canvas' && selection.getSize() > 0) {
                
                // clear the selection
                draw2dCanvas.setCurrentSelection(null);
            }
        }
        timer.stop();
    }
}
