import React from 'react';
import { List, Record } from 'immutable';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';

import SelectBinder, { Option } from './SelectBinder';

interface Props {
    input: WrappedFieldInputProps,
    meta: WrappedFieldMetaProps,
    dependsOn?: {},
    disabled: boolean,
    requestId: string
}

function dataToOptions(data: List<Record<Option<string>>> | undefined): Array<Option<string>> {
    return data ? data.map((d) => ({ value: d.get('value'), label: d.get('label') })).toArray() : [];
}

export default function RequestIdSelectBinder(props: Props) {
    return <RequestIdFetcher
        requestId={props.requestId}
        render={(data: unknown) => {
            const opts = dataToOptions(data as List<Record<Option<string>>> | undefined);
            return <SelectBinder options={opts} { ...props }/>
        }}
    />;
}
