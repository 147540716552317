import {Map, fromJS} from 'immutable'
const lodash = require('lodash')

export function newId(prefix) {
  return lodash.uniqueId(prefix)
}

export function getFormData(state, formId) {
  const forms = state.form;
  if (Map.isMap(forms)) {
    // redux-form/immutable was used to save form state
    return forms.getIn([formId, 'values'], Map())
  }

  // below can be removed in case all forms are redux-form/immutable:
  let form = forms[formId];
  if (form === undefined) {
    return Map()
  }
  let data = form.values
  if (data === undefined) {
    return Map()
  }
  return fromJS(data)
}

export function initialValues(datasourceConfig, datasourceValues) {
  let initialValues = Map()
  if (datasourceValues !== undefined) {
    const setters = datasourceConfig.get('set')
    initialValues = datasourceValues.reduce((result,value,datasourceField) => {
      if (setters.has(datasourceField)) {
        return result.set(setters.get(datasourceField), value)
      } else {
        return result
      }
    }, Map())
  }
  return initialValues
}
