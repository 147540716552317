import { Map, Set } from 'immutable';

function heirarchyTree() {
  return {
    'draw2d.Figure': {
      fields: ['alpha', 'angle', 'boundingBox', 'cssClass', 'cssClass', 'glow', 'height', 'keepAspectRatio', 'minHeight', 'minWidth', 'opacity', 'resizeable', 'selectable', 'userData', 'visible', 'width', 'x', 'y',
        'x=', 'y=', 'width=', 'height=', 'visible=', 'parent-name', 'onDblClickAction', 'anchor-x', 'anchor-y', 'drilldown', 'repeat'],
      'custom.PDF': {
        fields: ['url', 'page']
      },
      'draw2d.shape.basic.Line': {
        fields: ['color', 'dasharray', 'end', 'endX', 'endY', 'glow', 'outlineColor', 'outlineStroke', 'start', 'startX', 'startY', 'stroke', 'vertices'],
        'draw2d.shape.basic.PolyLine': {
          fields: ['radius', 'the'],
          'draw2d.Connection': {  
            'draw2d.shape.state.Connection': {}
          }
        }
      },
      'draw2d.shape.node.Node': {
        fields: ['indicate'],
        'draw2d.VectorFigure': {
          fields: ['bgColor', 'color', 'dasharray', 'radius', 'stroke', 'bgColor='],
          'draw2d.shape.basic.Arc': {},
          'draw2d.shape.basic.Diamond': {},
          'draw2d.shape.basic.Oval': {
            'draw2d.shape.basic.Circle': {
              fields: ['diameter', 'radius'],
              'draw2d.Corona': {},
              'draw2d.Port': {
                'draw2d.HybridPort': {},
                'draw2d.InputPort': {},
                'draw2d.OutputPort': {}
              },
              'draw2d.shape.basic.LineResizeHandle': {
                'draw2d.shape.basic.GhostVertexResizeHandle': {},
                'draw2d.shape.basic.LineEndResizeHandle': {},
                'draw2d.shape.basic.LineStartResizeHandle': {}
              },
              'draw2d.shape.state.End': {}
            }
          },
          'draw2d.shape.basic.Polygon': {},
          'draw2d.shape.basic.Rectangle': {
            fields: ['dash'],
            'draw2d.ResizeHandle': {
              'draw2d.shape.basic.VertexResizeHandle': {}
            },
            'draw2d.SetFigure': {
              'draw2d.SVGFigure': {
                'draw2d.shape.analog.OpAmp': {},
                'draw2d.shape.analog.ResistorBridge': {},
                'draw2d.shape.analog.ResistorVertical': {},
                'draw2d.shape.analog.VoltageSupplyHorizontal': {},
                'draw2d.shape.analog.VoltageSupplyVertical': {},
                'draw2d.shape.arrow.CalligrapherArrowDownLeft': {},
                'draw2d.shape.arrow.CalligrapherArrowLeft': {},
                'draw2d.shape.basic.Label': {
                  fields: ['bold', 'fontColor', 'fontFamily', 'fontSize', 'outlineColor', 'outlineStroke', 'padding', 'set', 'text', 'text-template'],
                  'draw2d.shape.basic.Text': {},
                  'draw2d.shape.note.PostIt': {}
                }
              },
              'draw2d.shape.diagram.Diagram': {
                fields: ['data'],
                'draw2d.shape.diagram.Pie': {
                  fields: ['diameter', 'radius']
                },
                'draw2d.shape.diagram.Sparkline': {}
              },
              'draw2d.shape.icon.Icon': {
                'draw2d.shape.icon.GitHub': {},
                'draw2d.shape.icon.SVGPathShape': {}
                // ...
              },
              'draw2d.shape.widget.Widget': {
                'draw2d.shape.widget.Slider': {
                  fields: ['padding', 'value']
                }
              }
            },
            'draw2d.shape.composite.Composite': {
              'draw2d.shape.composite.StrongComposite': {
                'draw2d.shape.composite.Group': {},
                'draw2d.shape.composite.Jailhouse': {}
              },
              'draw2d.shape.composite.WeakComposite': {
                'draw2d.shape.composite.Raft': {}
              }
            },
            'draw2d.shape.layout.Layout': {
              fields: ['padding'],
              'draw2d.shape.layout.FlexGridLayout': {},
              'draw2d.shape.layout.HorizontalLayout': {
                fields: ['gap']
              },
              'draw2d.shape.layout.StackLayout': {},
              'draw2d.shape.layout.TableLayout': {
                fields: ['padding']
              },
              'draw2d.shape.layout.VerticalLayout': {
                fields: ['gap'],
                'draw2d.shape.pert.Activity': {},
                'draw2d.shape.pert.Start': {},
                'draw2d.shape.state.State': {}
              }
            },
            'draw2d.shape.node.Between': {},
            'draw2d.shape.node.End': {},
            'draw2d.shape.node.Hub': {
              fields: ['text'],
              'draw2d.shape.node.Fulcrum': {},
              'draw2d.shape.node.HorizontalBus': {},
              'draw2d.shape.node.VerticalBus': {}
            },
            'draw2d.shape.node.Start': {},
            'draw2d.shape.state.Start': {}
          },
          'draw2d.shape.flowchart.Document': {}
        }, 

        'draw2d.shape.basic.Image': {
          fields: ['path']
        }
      }
    }
  }
}

/**
 * Create a lookup map for available field properties, by class name
 * @returns {Immutable.Map<string, Immutable.Set<string>>} - map class name to available fields
 */
export function createFigureHeirarchyLookup(tree = null) {
  if(!tree) { 
    tree = heirarchyTree();
  }

  let lookup = Map();
  const queue = [];
  queue.push({ name: '_root', item: tree, parentKeys: Set() });

  while(queue.length > 0) {
    const { name, item, parentKeys } = queue.shift();
    let keys = parentKeys;

    if(item.fields) {
      keys = keys.union(item.fields);
    }

    for(let key in item) {
      if(key !== 'fields') {
        queue.push({ name: key, item: item[key], parentKeys: keys });
      }
    }
    lookup = lookup.set(name, keys);
  }

  return lookup;
}
