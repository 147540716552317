import { DataView, DataViewObserver } from "./viewTypes";
import { Row } from "../../components/types";

export class BufferedView implements DataView {
    private readonly parent: DataView;
    private readonly observers: DataViewObserver[] = [];
    private readonly bufferedData = new Map<string, Row>();

    constructor(parent: DataView) {
        this.parent = parent;
        parent.addObserver(this.reset.bind(this));
    }

    getChanges(): { prev: Row, next: Row }[] {
        const changedRows = [] as { prev: Row, next: Row }[];
        for (let next of this.bufferedData.values()) {
            const prev = this.parent.getRecordById(next.id);
            changedRows.push({ prev, next });
        }
        return changedRows;
    }

    getRecordById(id: string): Row {
        if (this.bufferedData.has(id)) {
            return this.bufferedData.get(id)!;
        }
        return this.parent.getRecordById(id);
    }

    setValue(id: string, colKey: number, value: unknown) {
        let buffRow = this.bufferedData.get(id);
        if (!buffRow) {
            buffRow = this.parent.getRecordById(id);
        }

        buffRow = Object.assign([], buffRow);
        buffRow[colKey] = value;
        this.bufferedData.set(id, buffRow);

        for (let observer of this.observers) {
            observer('set-value');
        }
    }

    getData(start?: number, end?: number): Row[] {
        let data = this.parent.getData();

        if (!start) start = 0;
        if (!end) end = data.length;
        data = data.slice(start, end);

        for (let i = 0, n = data.length; i < n; i++) {
            const rowId = data[i].id;
            if (this.bufferedData.has(rowId)) {
                data[i] = this.bufferedData.get(rowId)!;
            }
        }

        return data;
    }

    recordCount(): number {
        return this.parent.recordCount();
    }

    addObserver(observer: DataViewObserver) {
        this.observers.push(observer);
    }

    reset(source?: string) {
        this.bufferedData.clear();
        for (let observer of this.observers) {
            observer(source);
        }
    }
}
