import { fieldFromFigureKey } from '../../helper/DesignerHelper';
import {
    evaluate,
    evaluateCondition } from '../../helper/eval/evaluator';

/**
 * Compute the value of the property with the current context
 */
export function resolveProperty(propKey, expr, evalContext) {
    if(expr == null) return null;

    const fieldProp = fieldFromFigureKey(propKey);
    const validator = fieldProp && fieldProp.get('validator');

    let value, error;
    try {

        if(!expr.get('dynamic')) {
            // expression is not dynamic. It's either a static string, or an object that can't be 
            // evaluated. Use the expression itself as the value.
            value = expr.get('value');

        } else {
            const evalFn = propKey === 'condition' ? evaluateCondition : evaluate;
            evalContext.key = propKey;
            value = evalFn(expr.get('value'), evalContext);
        }

        if(validator) {
            value = validator.validate(value);
        }

    } catch(e) {
        value = validator ? validator.defaultValue : null;
        error = e;
    }
    
    return { value, error };
}
