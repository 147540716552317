import React from 'react';
import Select from 'react-select'
import PropTypes from 'prop-types';
import {translate} from '../translation/util';
import {List} from 'immutable'
import { Field } from 'redux-form';

export default function SelectField(props) {
    return <Field 
        name={props.name}
        component={FieldSelect}
        componentProps={props}/>
}

SelectField.propTypes = {
    name: PropTypes.string.isRequired
}

FieldSelect.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
}

function FieldSelect({input, componentProps}) {
    const { multi=false, options=Array(),
        placeHolder='Selection ...', 
        isLoading=false,
        valueRenderer, optionRenderer} = componentProps
    const optionsArray = List.isList(options) ? options.toArray(): options
    const wrappedOptions = optionsArray.map((value) => ({value}))
    let onChange = (v) => input.onChange(v ? null: v.value)
    let value = null
 
    if (multi) {
        value = (input.value || []).map((value) => ({value}))
        onChange = (v) => input.onChange(v.map((v) => v.value))
    } else if (input.value) {
        value = {value: input.value}
    }

    return <Select {...{multi, placeHolder, isLoading, value, onChange, 
        options: wrappedOptions,
        valueRenderer: optionValueRenderer(valueRenderer), 
        optionRenderer: optionValueRenderer(optionRenderer)}} />
}

function optionValueRenderer(customRenderer) {
    return ({value}) => {
        if (customRenderer) return customRenderer(value)
        return renderValueDefault(value)
    }
}

function renderValueDefault(value) {
    if (typeof value === 'string' || value instanceof String) {
        return translate(value)
    } else {
        return value
    }
}

