import { render, unmountComponentAtNode } from 'react-dom';
import { createElement, ComponentType } from 'react';
import { Plugin, PluginCreationParams, PluginActions } from "./types/pluginTypes";

export type ReactPluginProps<C, R> = {
    config: C;
    data?: unknown;
    props?: { [key: string]: unknown };
    resources?: R;
    actions: PluginActions<C>;
    refreshCount: number;
}

export class ReactPluginAdapter<C, R> implements Plugin<C, R> {
    private readonly component: ComponentType<ReactPluginProps<C, R>>;
    private readonly actions: PluginActions<C>;

    private config: C;
    private data?: unknown;
    private props?: { [key: string]: unknown };
    private resources?: R;
    private container?: HTMLElement;
    private refreshCount: number = 0;

    constructor(component: ComponentType<ReactPluginProps<C, R>>, params: PluginCreationParams<C>) {
        this.component = component;
        this.actions = params.actions;
        this.config = params.config;
        this.data = params.data;
        this.props = params.props;
    }

    mount(container: HTMLElement): void {
        this.container = container;
        this.render();
    }
    
    unmount(): void {
        if (this.container) {
            unmountComponentAtNode(this.container);
            this.container = undefined;
        }
    }

    setConfig(config: C): void {
        this.config = config;
        this.render();
    }

    setProps(props: { [key: string]: unknown }): void {
        this.props = props;
        this.render();
    }

    setData(data: unknown): void {
        this.data = data;
        this.render();
    }

    setResources(resources: R): void {
        this.resources = resources;
        this.render();
    }

    refresh(): void {
        this.refreshCount += 1;
        this.render();
    }

    private render() {
        if (this.container) {
            render(createElement(this.component, {
                config: this.config,
                data: this.data,
                props: this.props,
                resources: this.resources,
                actions: this.actions,
                refreshCount: this.refreshCount
            }), this.container);
        }
    }
}
