import { decodeAuthTokenPayload, comparePayload } from "./authTokenDecoder";
import { AuthTokenHolderListener, AuthToken, TokenPayload } from "./authTypes";

const KEY_LOCAL_STORAGE = 'auth-token';

/**
 * Manages global access to the auth-token
 */

export class AuthTokenHolder {
    private readonly listeners: AuthTokenHolderListener[] = [];

    private invalidated: boolean = false;
    private token: AuthToken | null;

    constructor() {
        const jwt = this.resolveInitialJwt();
        this.token = jwt ? this.parseTokenFromJwt(jwt) : null;

        // listen for storage changed event
        window.addEventListener('storage', this.onStorageChanged.bind(this));
    }

    getToken(): AuthToken | null {
        return this.token;
    }

    getJwt(): string | null {
        return this.token ? this.token.jwt : null;
    }

    getPayload(): TokenPayload | null {
        return this.token ? this.token.payload : null;
    }

    setJwt(jwt: string | null) {
        const token = jwt ? this.parseTokenFromJwt(jwt) : null;
        this.setToken(token);
    }

    setToken(token: AuthToken | null) {
        this.token = token;

        // set the jwt string in the local-storage
        if (token) {
            window.localStorage.setItem(KEY_LOCAL_STORAGE, token.jwt);
        } else {
            window.localStorage.removeItem(KEY_LOCAL_STORAGE);
        }

        // notify listeners
        this.trigger(token);
    }

    clear() {
        this.setToken(null);
    }
    
    isInvalidated(): boolean {
        return this.invalidated;
    }

    addListener(listener: AuthTokenHolderListener): AuthTokenHolderListener {
        this.listeners.push(listener);
        return listener;
    }

    removeListener(listener: AuthTokenHolderListener): boolean {
        const index = this.listeners.indexOf(listener);
        if (index < 0) return false;

        this.listeners.splice(index, 1);
        return true;
    }

    private resolveInitialJwt(): string | null {
        let searchParams = new URLSearchParams(location.search);
        if (searchParams.has(KEY_LOCAL_STORAGE)) {
            return searchParams.get(KEY_LOCAL_STORAGE);
        }

        searchParams = new URLSearchParams(location.hash);
        if (searchParams.has(KEY_LOCAL_STORAGE)) {
            return searchParams.get(KEY_LOCAL_STORAGE);
        }

        return window.localStorage.getItem(KEY_LOCAL_STORAGE);
    }

    private onStorageChanged(event: StorageEvent) {
        if (event.key === KEY_LOCAL_STORAGE) {
            const nextJwt = event.newValue;
            const nextToken = nextJwt ? this.parseTokenFromJwt(nextJwt) : null;

            // validate the new token against the current one.
            // If the new token is incompatible then the user is thrown back to the login screen.
            if (comparePayload(this.token, nextToken)) {
                this.setToken(nextToken);

            } else {
                this.invalidated = true;
                this.token = null;
                this.trigger(null);
            }
        }
    }

    private parseTokenFromJwt(jwt: string): AuthToken | null {
        try {
            const payload = decodeAuthTokenPayload(jwt);
            return { jwt, payload };

        } catch(e) {
            console.error(e);
            return null;
        }
    }

    private trigger(token: AuthToken | null) {
        this.listeners.forEach(li => li(token));
    }
}
