import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Column, dataToRows, Row } from '@pearlchain/component-lib-react-pg';

import TextBinder from "./TextBinder";
import { BaseBinderProps } from './types';
import SearchBinder from './SearchBinder';
import { FormFieldConfig, formFieldConfig } from '../form/types';
import CheckboxBinder from './CheckboxBinder';
import SelectBinder from './SelectBinder';
import { RequestBuilder } from '@pearlchain/component-lib-common';

library.add(faSearch);

type Props = {
    modalRoot: string,
    doSearch?: (values: any) => Promise<Row[]>
} & BaseBinderProps;

const URL_FIND_PRODUCTS = 'sec/api/product/find';

const columns: Column[] = [
    { _key: 'no', width: 150, title: 'common.binder.product.grid.no' },
    { _key: 'source', width: 150, title: 'common.binder.product.grid.source' },
    { _key: 'description', width: 150, title: 'common.binder.product.grid.description' },
    { _key: 'productGroup', width: 150, title: 'common.binder.product.grid.productGroup' },
    { _key: 'status', width: 150, title: 'common.binder.product.grid.status', translateValue: true },
    { _key: 'stockUom', width: 150, title: 'common.binder.product.grid.stockUom', translateValue: true },
    { _key: 'uom2', width: 150, title: 'common.binder.product.grid.uom2', translateValue: true },
    { _key: 'uom3', width: 150, title: 'common.binder.product.grid.uom3', translateValue: true },
    { _key: 'uom4', width: 150, title: 'common.binder.product.grid.uom4', translateValue: true },
    { _key: 'warehouse', width: 150, title: 'common.binder.product.grid.warehouse', translateValue: true },
    { _key: 'altDescription', width: 150, title: 'common.binder.product.grid.altDescription' },
    { _key: 'remark', width: 150, title: 'common.binder.product.grid.remark' }
]

const formFields: { [key: string]: FormFieldConfig } = {
    companyCodes: formFieldConfig({ label: 'common.selector.company', binder: TextBinder }),
    productNo: formFieldConfig({ label: 'common.binder.product.selector.no', binder: TextBinder }),
    remark: formFieldConfig({ label: 'common.binder.product.selector.remark', binder: TextBinder }),
    isActive: formFieldConfig({ label: 'common.binder.product.selector.isActive', binder: CheckboxBinder }),
    description: formFieldConfig({ label: 'common.binder.product.selector.description', binder: TextBinder }),
    limit: formFieldConfig({ label: 'common.binder.selector.limit', binder: SelectBinder, translateOptions: false, options: [
            { label: '10', value: 10 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 }
        ]
    })
}

function selectId(d: any) {
    return d.no;
}

export default function ProductBinder(props: Props) {
    function doSearch(values: any): Promise<Row[]> {
        return new RequestBuilder()
            .post(URL_FIND_PRODUCTS)
            .setBodyJson(values)
            .toJS<any[]>()
            .then((data) => {
                return dataToRows(data, selectId, columns);
            });
    }

    return <SearchBinder
        formId="product-binder"
        columns={columns}
        formFields={formFields}
        formValueField="productNo"
        modalRoot={props.modalRoot}
        doSearch={props.doSearch || doSearch}
        disabled={props.disabled}
        input={props.input}
        meta={props.meta}/>
}
