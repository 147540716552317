import { call, put } from 'redux-saga/effects';

import { beginFetching, completeFetching } from '../core/designer/designlist/actions';
import { remoteCall, makeErrorResponseHandler } from './remote';

export function *runRemoteCall(url, requestBody, requestId) {
    if (requestId) {
        yield put(beginFetching(requestId));
    }

    let result, error;
    try {
        result = yield call(() => new Promise(function (resolve, reject) {
            remoteCall(url, requestBody, resolve, makeErrorResponseHandler(function() {
                reject(arguments);
            }, (x) => x));
        }));

    } catch ([ errorMessage, errorAction ]) {
        yield put(errorAction);
        error = errorMessage;
    }

    if (requestId) {
        yield put(completeFetching(requestId, error));
    }

    return [ result, error ];
}
