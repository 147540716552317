import React from 'react';
import { History } from 'history';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSignOutAlt, faHome, faCashRegister, faChartBar, faBarcode, faBookOpen, faSearchDollar} from '@fortawesome/free-solid-svg-icons'
import '../styles/styles.css';
import { AppSwitcher } from './AppSwitcher';
import { useTranslation } from 'react-i18next';
import { SwitcherAppProperty } from '../types/types';

library.add(faSignOutAlt,faHome,faCashRegister, faChartBar, faBarcode, faBookOpen, faSearchDollar);

export interface SubPage{
    id: string;
    label: string;
    path: string;
    icon?: IconProp;
}

interface Props { 
    appIcon: IconProp;
    appTitle: string;
    subPages?: SubPage[];
    availableAppsConfig?: SwitcherAppProperty[];
    onPageSelected: (subPage: SubPage) => void;
    onSignOut?: () => void;
    history: History,
}

/**
 * Main stack navigation bar. Contains app switcher and a list  of all available web apps.
 */
export const  StackNavigation = (props: Props) => {
    const { t } = useTranslation();
    const pathname = props.history.location.pathname;

    const handleSignOut = () => {
        if(props.onSignOut){
            props.onSignOut();
        }
    }

    return (
        <div className="app-switcher-side-menu">
            <AppSwitcher appTitle={props.appTitle} appIcon={props.appIcon} availableAppsConfig={props.availableAppsConfig} />

            <div className="app-switcher-side-menu-list">
                {
                    props.subPages && props.subPages.map(function(subPage) {

                        const active = classNames('side-item', {
                            'active-item': pathname === subPage.path
                        });

                        return <a className={active} onClick={() => props.onPageSelected(subPage)} key={subPage.id}>
                            {subPage.icon && <FontAwesomeIcon icon={subPage.icon} />}
                            {t(subPage.label)}
                        </a>
                    })
                }
            </div>

            <a className="side-item" onClick={() => handleSignOut()}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                Sign Out
            </a>
        </div>

    );
}
