import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getDatasources, getDatasourcesLoading, getSelectorConfigMergeFormValues } from '../../helper/selector/datasourceSelectors';
import { invokeDatasource, fetchDatasourceDefinition } from '../../datarequest/actions'

/**
 * 
 */

class DatasourcesUpdater extends React.Component {

    componentDidMount() {
        if(this.props.selectorConfigs) {
            // fetch datasource definitions on mount
            const datasourceNames = this.getDatasourceNames(this.props.selectorConfigs);
            this.props.fetchDatasourceDefinitions(datasourceNames);
        }
    }

    componentDidUpdate(prevProps) {
        // update the widget loading indicator
        if (this.props.loading !== prevProps.loading) {
            if (this.props.onLoadingChanged) {
                this.props.onLoadingChanged(this.props.loading);
            }
        }

        if (this.dsDefinitionsLoaded(this.props)) {
            if (this.props.refreshCount !== this.refreshCount) {
                this.refreshCount = this.props.refreshCount;
                
                // invoke all datasources when the refresh-count changed
                this.doInvoke(this.props);
            }
        }
    }

    doInvoke(props) {
        // invoke datasources
        const selectorConfigs = props.selectorConfigs;

        if(selectorConfigs) {
            const payload = this.bulkSelectorConfigs(selectorConfigs);
            this.props.invokeDatasourcesBulk(payload, this.props.designId); 
        }
    }

    getDatasourceNames(selectorConfigs) {
        return selectorConfigs.map((cfg) => cfg.get('datasource').toUpperCase())
    }

    /**
     * Check whether all definitions have been loaded
     */
    dsDefinitionsLoaded(props) {
        if(props.selectorConfigs) {
            if(props.datasources == null) return false;
            
            const datasourceIds = this.getDatasourceNames(props.selectorConfigs);
            for(let dsId of datasourceIds) {
                const dsDefinition = props.datasources.getIn([dsId, 'definition']);
                if(dsDefinition == null) return false;
            }
        }
        return true;
    }

    bulkSelectorConfigs(selectorConfigs) {
        return selectorConfigs.map((cfg) => [cfg.get('datasource').toUpperCase(), cfg]);
    }

    render() {
        // this class just updates state
        return <noscript/>;
    }
}

DatasourcesUpdater.propTypes = {
    // own props
    designId: PropTypes.string.isRequired,
    refreshCount: PropTypes.number.isRequired,
    onLoadingChanged: PropTypes.func,
    
    // connect
    loading: PropTypes.bool.isRequired,
    datasources: ImmutablePropTypes.map,
    selectorConfigs: ImmutablePropTypes.list,
    invokeDatasourcesBulk: PropTypes.func.isRequired,
    fetchDatasourceDefinitions: PropTypes.func.isRequired
}

export default connect(
    (state, { designId }) => ({
        selectorConfigs: getSelectorConfigMergeFormValues(state, designId),
        datasources: getDatasources(state),
        loading: getDatasourcesLoading(state)
    }),
    {
        invokeDatasourcesBulk: invokeDatasource.invokeBulk,
        fetchDatasourceDefinitions: fetchDatasourceDefinition.invokeBulk
    }
)(DatasourcesUpdater);
