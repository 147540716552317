import $ from 'jquery';

import { Row, ReverseDep, IDatastore } from './types';
import { IDatasource } from '../datasource/datasourceTypes';

type ResolveAttributeCallback = (row: Row, property: string, attribute: string) => unknown;

export class ApplyCallbackDatastore implements IDatastore {
    private readonly datasource: IDatasource;
    private readonly properties: Set<string>;
    private readonly attributes: Set<string>;
    private readonly callback: ResolveAttributeCallback;

    private readonly globalProperties = new Map<string, Map<string, unknown>>();

    constructor(
        properties: string[],
        attributes: string[],
        callback: ResolveAttributeCallback,
        datasource: IDatasource
    ) {
        this.properties = new Set(properties);
        this.attributes = new Set(attributes);
        this.callback = callback;
        this.datasource = datasource;

        $(datasource)
            .on('valuechanged', this.onValueChanged.bind(this))
            .on('datachanged', this.onDataChanged.bind(this));
    }

    registerGlobalAttribute(property: string, attribute: string, value: unknown) {
        let attribs = this.globalProperties.get(property);
        if (attribs == null) {
            attribs = new Map<string, unknown>();
            this.globalProperties.set(property, attribs);
        }

        attribs.set(attribute, value);
    }

    getGlobalAttribute(rowId: string, property: string, attribute: string) {
        if (this.properties.has(property) && this.attributes.has(attribute)) {
            const row = this.datasource.getRecordById(rowId);
            return this.callback(row, property, attribute);
        }

        const globalAttribs = this.globalProperties.get(property);
        if (globalAttribs != null) {
            return globalAttribs.get(attribute);
        }
    }

    resolveReverseDependencies(rowIds: string[]): ReverseDep[] {
        const result: ReverseDep[] = [];

        for (let rowId of rowIds) {
            for (let property of this.properties) {
                for (let attribute of this.attributes) {
                    result.push({
                        attribute,
                        property,
                        row: rowId
                    });
                }
            }
        }
        
        return result;
    }

    private onValueChanged(event: any, data: { id: string }) {
        const rowIds = [data.id];
        $(this).trigger('valueschanged', [rowIds]);
    }

    private onDataChanged(event: any, data: { data: Row[], oldData: Row[], values: { id: string, key: number }[] }) {
        const seen = new Set<string>();
        const rowIds = [] as string[];
        for (let value of data.values) {
            if (!seen.has(value.id)) {
                seen.add(value.id);
                rowIds.push(value.id);
            }
        }
        
        if (rowIds.length) {
            $(this).trigger('valueschanged', [rowIds]);
        }
    }
}
