// @ts-check

/** @type {Map<string, Timer>} */
let timers = new Map();

class MovingAverage {
    constructor() {
        this.values = new Array(100);
        this.count = 0;
        this.i = 0;
    }

    next(v) {
        this.values[this.i] = v;
        this.count += 1;
        this.i = (this.i + 1) % 100;
    }

    calc() {
        let avg = 0;
        let n = Math.min(this.count, 100);
        for(let i = 0; i < n; i++) {
            avg += this.values[i] / n; 
        }
        return avg;
    }
}

class TimerInstance {
    constructor(monitor) {
        this.monitor = monitor;
        this.running = true;
        this.startTime = window.performance.now();
        this.delta = 0;
    }

    pause() {
        this.running = false;
        this.delta += window.performance.now() - this.startTime;
    }

    continue() {
        this.running = true;
        this.startTime = window.performance.now();
    }

    stop() {
        let value = this.delta;
        if(this.running)
            value += window.performance.now() - this.startTime;

        this.monitor.record(value);
    }
}

class Timer {
    constructor(id) {
        this.id = id;
        this.values = [];
        this.delta = 0;

        this.total = 0;
        this.max = -1;
        this.min = -1;
        this.avg = new MovingAverage();
        this.calls = 0;
        this.prev = -1;
    }

    start() {
        return new TimerInstance(this);
    }

    record(value) {
        this.values.push(value);
    }

    display() {
        let frameTotal = 0;
        const frameCalls = this.values.length;
        for(let value of this.values) {
            if(this.max < 0 || value > this.max) {
                this.max = value;
            }
            if(this.min < 0 || value < this.min) {
                this.min = value;
            }
            this.avg.next(value);
            this.prev = value;
            frameTotal += value;
        }

        this.values = [];
        const avg = this.avg.calc();
        console.debug(`[${this.id}] max: ${this.max}, avg: ${avg}, min: ${this.min}, prev: ${this.prev}, total: ${this.total}, calls: ${this.calls}, total (this frame): ${frameTotal}, calls (this frame): ${frameCalls}`);
    }
}

let handle;
export function monitor() {
    if(handle != null) window.clearInterval(handle);
    window.setInterval(handle = function () {
        console.debug('----- PERFORMANCE -----');
        for(let mon of timers.values()) {
            mon.display();
        }
    }, 5000);
}

export function createTimer(id) {
    const timer = new Timer(id);
    if(timers.has(id)) console.error('Error creating monitor. Duplicate id -', id);
    timers.set(id, timer);
    return timer;
}
