import React from 'react';
import { FormFieldConfig } from "./types";
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormLabel } from 'react-bootstrap';

type Props = {
    /**
     * Binder configuration
     */
    config: FormFieldConfig,
    /**
     * Redux-form field input prop
     */
    input: WrappedFieldInputProps,
    /**
     * Redux-form field meta prop
     */
    meta: WrappedFieldMetaProps,
    inline: boolean
}

export default function FormField(props: Props) {
    let { binder, label, translateLabel, labelWidth, fieldWidth, ...binderProps } = props.config;

    // translate the label
    const { t } = useTranslation();
    if (translateLabel !== false) {
        label = t(label);
    }

    return (
        <FormGroup>
            <FormLabel>{ label }</FormLabel>
            { React.createElement(binder, {
                input: props.input,
                meta: props.meta,
                ...binderProps
            })}
            { props.meta.touched && props.meta.error && (
                <div className="text-error">{ props.meta.error }</div>
            )}
        </FormGroup>
    )
}
