import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SwitcherAppProperty } from '../types/types';

interface Props {
    appTitle: string;
    appIcon: IconProp;
    availableAppsConfig?: SwitcherAppProperty[];
}

export const  AppSwitcher = (props: Props) => {
    return (
        <Dropdown className="side-item app-switcher">
            <Dropdown.Toggle as="div" id="dropdown-basic" className="side-item" >
                <FontAwesomeIcon icon={props.appIcon} />
                {props.appTitle}
            </Dropdown.Toggle>

            <Dropdown.Menu as="div" className="app-switcher-menu">
                {
                    props.availableAppsConfig?.map(function(app) {
                        return <Dropdown.Item className="app-switcher-menu-item" href={app.url} key={app.title}>
                            <FontAwesomeIcon icon={app.icon} />
                        </Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}