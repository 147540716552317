
/**
 * Manages access to a web-param.
 * First tries to read the query param from the URL, then falls
 * back to selecting from local-storage.
 */

export class WebParam {
    private readonly key: string;
    private value: string | null;
    private searchValue?: string;

    constructor(key: string) {
        this.key = key;
        this.value = localStorage.getItem(key);
        this.onLocationChanged();
        window.addEventListener('hashchange', this.onLocationChanged.bind(this));
    }

    set(value: string) {
        this.value = value;
        localStorage.setItem(this.key, value);
    }

    clear() {
        this.value = null;
        localStorage.removeItem(this.key);
    }

    get() {
        return this.value;
    }

    private onLocationChanged() {
        const params = new URLSearchParams(location.hash);

        if (params.has(this.key)) {
            const value: string = params.get(this.key)!;

            if (value !== this.searchValue) {
                this.searchValue = value;
                this.value = value;
            }

        } else if (this.searchValue != null) {
            this.searchValue = undefined;
            this.value = localStorage.getItem(this.key);
        }
    }
}
