import {List, fromJS} from 'immutable'
import {getDatasourceParametersList} from './datasource'
import {isFieldHidden, getFormStateBinding} from '../core/designer/data/datasourceFieldsData';
import {clearLinkedValues,triggerDatasourceSearch,assistInDialog,
        ifNoValidSuggestions,suggestionsDisplay,mapDatasourceState,
        mapFormValuesState,
        navigateSuggestions}
        from './binder'

/**
 * Maps the datasource definition parameters to renderable fields
 */
export function fieldsFromDatasourceDefinition(datasourceDefinition) {
  const datasourceName = datasourceDefinition.get('name');
  const parameters = getDatasourceParametersList(datasourceDefinition.get('set'));
  return parameters
  // skip field if hidden in UI
    .filter((definitionField) => !isFieldHidden(datasourceName, definitionField.get('name')))
    .map((definitionField) => {
        const extensions = [];
        const liveAssist = definitionField.get('liveAssist');
        const fieldName = definitionField.get('name');
        
        // add live-assist decorator
        if(liveAssist) {
          extensions.push((f) => withDatasourceLiveAssist(f, datasourceName));
        }
        
        // add field-binding decorators
        const formStateBinding = getFormStateBinding(datasourceName, fieldName);
        if(formStateBinding && formStateBinding.size > 0) {
          extensions.push((f) => withFieldBindings(f, formStateBinding))
        }

        return field({
            name: fieldName,
            label: definitionField.get('name'),
            type: definitionField.get('type'),
            optional: definitionField.get('optional'),
            liveAssist
          },
          extensions
        )
    });
}

export function field(props, extensions) {
  return List(extensions).reduce(
    (r, extend) => extend(r),
    fromJS(props))
}

export function withDatasourceLiveAssist(field, datasourceName) {
  const fieldName = field.get('name')
  const clearValues = clearLinkedValues(datasourceName, fieldName)
  const triggerSearch = triggerDatasourceSearch(field)
  field = field.update('onFocus', List(), (l) => l.push(ifNoValidSuggestions(triggerSearch)))
  field = field.update('onChange', List(), (l) => l.push(clearValues).push(triggerSearch))
  field = field.update('decorators', List(), (l) => l.push(suggestionsDisplay()))
  field = field.update('onKeyDown', List(), (l) => l.push(navigateSuggestions()))
  field = field.update('stateMapping', List(), (l) => l.push(mapDatasourceState()))
  return field
}

export function withAssistInDialog(field, datasource, dialogClass) {
  field = field.update('decorators', List(), (l) => l.push(assistInDialog(datasource, dialogClass)))
  field = field.update('stateMapping', List(), (l) => l.push(mapDatasourceState(field, datasource)))
  return field
}

export function withFieldBindings(field, fieldBindings) {
  for(let binding of fieldBindings) {
    const fromName = binding.get('from');
    const asName = binding.get('as');
    field = field.update('stateMapping', List(), (l) => l.push(mapFormValuesState(asName, fromName)));
  }
  return field;
}
