const ActionTypes = {
    DRILLDOWN: 'core.designer.drilldown.DRILLDOWN',
    DRILLDOWN_FROM_BREADCRUMB: 'core.designer.drilldown.DRILLDOWN_FROM_BREADCRUMB'
}

export default ActionTypes;

export function drilldown(designId, params) {
    return {
        type: ActionTypes.DRILLDOWN,
        designId,
        params
    }
}

export function drilldownFromBreadcrumb(designId, params, idx) {
    return {
        type: ActionTypes.DRILLDOWN_FROM_BREADCRUMB,
        designId,
        params,
        idx
    }
}
