import Imm from 'immutable';
import { toNumber } from '../../helper/mathHelper';
import { getCanvasFigureParentId } from '../../helper/selector/canvasFigureConfigSelectors';
import { resolveProperty } from './propertyResolver';
import { resolveAltExpressions } from './altExpressionsResolver';

// properties used only for context calculation
const keysModifyContext = Imm.Set(['repeat', 'width', 'height']);


/**
 * Re-calculates the computed figures.
 * 
 * Computed figures contain the fully resolved properties
 * which will be applied on the canvas.
 */

export function updateComputedFigure(compValues, figureConfigId, params) {
    const parentContexts = getParentContexts(compValues, figureConfigId, params);
    return updateComputedFiguresDeep(compValues, figureConfigId, parentContexts, params);
}

export function updateComputedFiguresDeep(compValues, figureConfigId, parentContexts, params) {

    // calculate figure contexts
    const figureContexts = getFigureContexts(figureConfigId, parentContexts, params);
    compValues = compValues.setIn([figureConfigId, 'contexts'], figureContexts);

    // calculate computed figures
    const computedFigures = getComputedFigures(figureConfigId, figureContexts, params);
    compValues = compValues.setIn([figureConfigId, 'figures'], computedFigures);

    // recursively update children
    const children = params.childMapping.get(figureConfigId);
    if(children != null) {
        for(let childConfigId of children) {
            compValues = updateComputedFiguresDeep(compValues, childConfigId, figureContexts, params);
        }
    }

    return compValues;
}

function getFigureContexts(figureConfigId, parentContexts, params) {

    // alt-expressions which may update the context are evaluated before
    // the main evaluation step, with the parent-context.
    const altExpressions = params.computedValues
        .getIn([figureConfigId, 'expressions'])
        .filter((altExpr, idx) => idx === 0 || altExpressionModifiesContext(altExpr));

    return Imm.List().withMutations((list) => {
        let i = 0;
        for(let parentContext of parentContexts) {

            const parentId = parentContext.get('id');
            const parentWidth = parentContext.get('width');
            const parentHeight = parentContext.get('height');
            const parentExecContext = executionContextFromFigureContext(parentContext, params, false);
            const mergeExpressions = resolveAltExpressions(altExpressions, parentExecContext);
            const repeatExpr = mergeExpressions.get('repeat');

            // resolve width and height separately to make it available
            // in the context, so it can be used in property evaluation of this figure
            // and it's children
            const widthExpr = mergeExpressions.get('width');
            const heightExpr = mergeExpressions.get('height');
            
            if(repeatExpr) {
                const repeatVal = resolveProperty('repeat', repeatExpr, parentExecContext).value;
                const repeatEnumerable = repeatPropToEnumerable(repeatVal);
                
                let j = 0;
                for(let item of repeatEnumerable) {
                    const execContext = { ...parentExecContext, item, idx: j };
                    const width = widthExpr && resolveProperty('width', widthExpr, execContext).value;
                    const height = heightExpr && resolveProperty('height', heightExpr, execContext).value;
                    
                    const canvasFigureId = getFigureId(figureConfigId, i++);
                    list.push(createFigureContext(canvasFigureId, parentId, item, j, parentWidth, parentHeight, width, height));
                    j += 1;
                }
                
            } else {
                const width = widthExpr && resolveProperty('width', widthExpr, parentExecContext).value;
                const height = heightExpr && resolveProperty('height', heightExpr, parentExecContext).value;
                const parentItem = parentContext.get('item');
                const parentIdx = parentContext.get('idx');

                const canvasFigureId = getFigureId(figureConfigId, i++);
                list.push(createFigureContext(canvasFigureId, parentId, parentItem, parentIdx, parentWidth, parentHeight, width, height));
            }
        }
    });
}

function getComputedFigures(figureConfigId, figureContexts, params) {

    // Exclude alt-configs used only for context calculation (previous step).
    const altExpressions = params.computedValues
        .getIn([figureConfigId, 'expressions'])
        .filter((altExpr, idx) => idx === 0 || altExpr.size > 1 || !altExpressionModifiesContext(altExpr));

    return figureContexts.map((figureContext) => {
        const execContext = executionContextFromFigureContext(figureContext, params, true);
        const expressions = resolveAltExpressions(altExpressions, execContext);

        // loop through the compiled expressions, and calculate figure values
        // with the current evaluation context.
        return Imm.Map().withMutations((computedFigure) => {
            for(let [key, expr] of expressions.entrySeq()) {

                // ignore repeat property
                if(keysModifyContext.contains(key)) continue;

                const value = resolveProperty(key, expr, execContext).value;
                computedFigure.set(key, value);
            }
            
            const figureId = figureContext.get('id');
            const parentId = figureContext.get('parentId');
            const width = figureContext.get('width');
            const height = figureContext.get('height');
            const viewId = params.viewId;
            
            // set the final computed values on the figure
            computedFigure.set('id', figureId);
            computedFigure.set('viewId', viewId);

            // width and height were calculated during the context calculation
            // phase. Don't calculate, just copy onto the new figure.
            computedFigure.set('width', width);
            computedFigure.set('height', height);

            if(parentId != null) {
                computedFigure.set('parentId', parentId);
            }
        });
    });
}

function getFigureId(figureConfigId, i) {
    return i === 0 ? figureConfigId : figureConfigId + '_' + i;
}

function getParentContexts(compValues, figureConfigId, params) {
    const parentConfigId = getCanvasFigureParentId(params.parentsMapping, figureConfigId);
    if(parentConfigId) {
        const parentContexts = compValues.getIn([parentConfigId, 'contexts']);
        if(parentContexts != null) return parentContexts;
    } 
    // figure had no parent,
    // or the parent context hasn't been computed yet
    return createDefaultFigureContexts(params.baseWidth, params.baseHeight);
}

function createFigureContext(id, parentId, item, idx, parentWidth, parentHeight, width, height) {
    return Imm.Map({
        id,
        parentId,
        item,
        idx,
        width,
        height,
        parentWidth,
        parentHeight
    });
}

function createDefaultFigureContexts(baseWidth, baseHeight) {
    return Imm.List().push(Imm.Map()
        .set('width', baseWidth)
        .set('height', baseHeight));
}

function altExpressionModifiesContext(altExpr) {
    return keysModifyContext.some((k) => altExpr.getIn([k, 'value']) != null);
}

/**
 * The items passed as the :item variable in the execution context
 * If numeric, defaults to 0, 1, 2... 
 */
function repeatPropToEnumerable(repeatVal) {
    if(Array.isArray(repeatVal)) {
        return repeatVal;
    } else {
        const n = toNumber(repeatVal);
        if(n != null) {
            const result = new Array(n);
            for(let i = 0; i < n; ++i) {
                result[i] = i;
            }
            return result
        }
        return [0];
    }
}

function executionContextFromFigureContext(figureContext, params, useParentDimensions) {
    const context = params.evalContext;
    return {
        template: {
            ...context,
            'item': figureContext.get('item'),
            'idx': figureContext.get('idx'),
        },
        percent: {
            parentWidth: (useParentDimensions ? figureContext.get('parentWidth') : figureContext.get('width')) || 0,
            parentHeight: (useParentDimensions ? figureContext.get('parentHeight') : figureContext.get('height')) || 0
        }
    }
}
