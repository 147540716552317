import React from 'react'
import * as DatasourceActions from '../common/view/actions/DatasourceActions'
import { createSuggestionResultExtractor } from './datasource';
import {List, Map} from 'immutable'

const FIELD_DATASOURCE_STATE = 'datasource-state'
const FIELD_FORM_STATE = 'form-state'

export function triggerDatasourceSearch(field) {
  const liveAssist = field.get('liveAssist')
  return ({ formId, dispatch, fieldConfig }) => {
    const fieldId = fieldConfig.get('name');
    dispatch(DatasourceActions.fetchSuggestionsOnChange({
      formId,
      fieldId,
      liveAssist
    }))
  }
}

function hasMessage(suggestions) {
  const msg = suggestions.get('message')
  return !(msg == null || msg.length == 0)
}

function renderSuggestions(suggestions, suggestionDecorator,
    acceptSuggestionFn, onSuggestionsFocus, onSuggestionsBlur) {
  let messageDom = null
  if (hasMessage(suggestions)) {
    messageDom = <div><label>{suggestions.get('message')}</label></div>
  }
  let itemsDom = null
  if (!suggestions.get('items', List()).isEmpty()) {
    const items = suggestions.get('items')
    itemsDom = items.map((i, idx) => {
      let className = 'suggestion-row'
      if (idx == suggestions.get('focused', 0)) {
        className = className + ' focused'
      }
      return <div className={className}
          onClick={() => acceptSuggestionFn(i)}>
          {suggestionDecorator(i)}
      </div>
    })
    itemsDom = <div>{itemsDom}</div>
  }
  return <div className='field-suggestions'
      onMouseEnter={onSuggestionsFocus}
      onMouseLeave={onSuggestionsBlur}>
    {itemsDom}
    {messageDom}
  </div>
}

export function mapDatasourceState() {
  return (props, state) => {
    const fieldId = props.fieldConfig.get('name');
    const formId = props.formId;
    return Map().set(FIELD_DATASOURCE_STATE, Map({
      definition: props.datasourceDefinition,
      data: state.datasource.getIn([formId, fieldId], Map())
    }))
  }
}
  
export function mapFormValuesState(asName, fromName) {
  return (props, state) => {
    return Map().setIn([FIELD_FORM_STATE, asName], props.formValuesSelector(state, fromName));
  }
}

function getFieldDatasourceDialog(fieldProps) {
  return getFieldDatasourceSuggestions(fieldProps).get('dialog')
}

function getFieldDatasourceStatus(fieldProps) {
  return getFieldDatasourceSuggestions(fieldProps).get('status')
}

function getFieldDatasourceSuggestions(fieldProps) {
  return fieldProps.fieldState[FIELD_DATASOURCE_STATE].getIn(['data', 'suggestions'], Map())
}

function getDatasourceDefinition(fieldProps) {
  return fieldProps.fieldState[FIELD_DATASOURCE_STATE].get('definition')
}

function defaultSuggestionDecorator(fieldConfig) {
  // extract the field to display from the suggestion
  const liveAssist = fieldConfig.get('liveAssist');
  const getLabel = createSuggestionResultExtractor(liveAssist, ['RESULT_DISPLAY', 'RESULT'])
  const suggestionDecorator = (suggestion) => <label>{ getLabel(suggestion) }</label>
  return suggestionDecorator
}

export function suggestionsDisplay() {
  return (dom, props) => {
    const fieldConfig = props.fieldConfig;
    const formId = props.formId;
    const fieldId = fieldConfig.get('name');
    const liveAssist = fieldConfig.get('liveAssist');
    const getSuggestionResult = createSuggestionResultExtractor(liveAssist, ['RESULT']);
    
    // always returning a list with the input dom as first (otherwise focus is lost when the DOM changes)
    let result = List().push(dom)
    const suggestions = getFieldDatasourceSuggestions(props)
    if (suggestions && (props.meta.active || suggestions.get('active'))) {
      const requester = suggestions.get('requester')
      
      const acceptSuggestionFn = (suggestion) => {
        const resultValue = getSuggestionResult(suggestion);
        props.dispatch(DatasourceActions.acceptSuggestion(
          formId, 
          fieldId, 
          resultValue, 
          suggestion, 
          requester))
      }

      let suggestionDecorator = fieldConfig.get('suggestionDecorator')
      if (suggestionDecorator === undefined) {
        suggestionDecorator = defaultSuggestionDecorator(fieldConfig, props)
      }
      const suggestionsDom = renderSuggestions(suggestions, suggestionDecorator, acceptSuggestionFn,
        () => {props.dispatch(DatasourceActions.suggestionsActive(requester))},
        () => {props.dispatch(DatasourceActions.suggestionsInactive(requester))})
      result = result.push(suggestionsDom)
    }
    return result
  }
}

export function assistInDialog(datasource, dialogClass) {
  return (dom, props) => {
    const fieldConfig = props.fieldConfig;
    const fieldId = fieldConfig.get('name');
    const liveAssist = fieldConfig.get('liveAssist');
    const getSuggestionResult = createSuggestionResultExtractor(liveAssist, ['RESULT']);

    const showSuggestionDialogFn = () => {
      props.dispatch(DatasourceActions.showSuggestionDialog({
        formId: props.formId,
        fieldId,
        formDatasource: getDatasourceDefinition(props)
      }))
    }
    let result = List().push(dom).push(<input key='assist-button'
      type='button' value='~' onClick={showSuggestionDialogFn}/>)
    const dialog = getFieldDatasourceDialog(props)
    if (dialog) {

      const selectFn = (suggestion) => {
        const resultValue = getSuggestionResult(suggestion);
        const requester = List([props.formId, fieldId])
        props.dispatch(DatasourceActions.acceptSuggestion(
          props.formId, 
          fieldId, 
          resultValue, 
          suggestion, 
          requester))
      }

      const cancelFn = () => {
        const requester = List([props.formId, fieldId])
        props.dispatch(DatasourceActions.closeSuggestionsDialog(requester))
      }

      const dialogProps = {
        selectFn, cancelFn,
        initialDatasourceValues: dialog.get('initialDatasourceValues')
      }
      const dialogRender = React.createElement(dialogClass, dialogProps)
      result = result.push(<div className='dialog'>
          <div className='dialog-content'>{dialogRender}</div>
        </div>)
    }
    return result
  }
}

export function navigateSuggestions() {
  return (fieldProps, event) => {
    const fieldName = fieldProps.input.name;
    if (event.keyCode == 38) { // arrow up
      const requester = List([fieldProps.formId, fieldName])
      fieldProps.dispatch(DatasourceActions.navigateSuggestionUp(requester))
    } else if (event.keyCode == 40) { // arrow down
      const requester = List([fieldProps.formId, fieldName])
      fieldProps.dispatch(DatasourceActions.navigateSuggestionDown(requester))
    } else if (event.keyCode == 13) { // enter
      const liveAssist = fieldProps.fieldConfig.get('liveAssist')
      const getResultValue = createSuggestionResultExtractor(liveAssist, ['RESULT'])
      const requester = List([fieldProps.formId, fieldName])
      fieldProps.dispatch(DatasourceActions.navigateSuggestionAccept(
        fieldProps.formId, fieldName, getResultValue, requester))
    }
  }
}

export function clearLinkedValues() {
  return (fieldProps) => {
    const {formId, input, dispatch} = fieldProps
    dispatch(DatasourceActions.clearLinkedValues({
      formDatasource: getDatasourceDefinition(fieldProps),
      formId,
      fieldId: input.name
    }))
  }
}

export function ifNoValidSuggestions(triggerSearch) {
  return (fieldProps) => {
    const suggestionsStatus = getFieldDatasourceStatus(fieldProps)
    if (suggestionsStatus != 'done' && suggestionsStatus != 'fetching') {
      triggerSearch(fieldProps)
    }
  }
}
