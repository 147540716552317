const ActionTypes = {
    FETCH_DESIGN_LIST: 'core.designer.designlist.FETCH_DESIGN_LIST',
    RECEIVE_DESIGN_LIST: 'core.designer.designlist.RECEIVE_DESIGN_LIST',
    REQUEST_UPDATE_DESIGN_PROPERTY: 'core.designer.designlist.SET_DESIGN_PROPERTY',
    RECEIVE_UPDATE_DESIGN_PROPERTY: 'core.designer.designlist.UPDATE_DESIGN_PROPERTY',
    CHANGE_EDITABLE_FIELD_VALUE: 'core.designer.designlist.CHANGE_EDITABLE_FIELD_VALUE',
    CLEAR_EDITABLE_FIELD_VALUE: 'core.designer.designlist.CLEAR_EDITABLE_FIELD_VALUE',
    REQUEST_REMOVE_DESIGN: 'core.designer.designlist.REQUEST_REMOVE_DESIGN',    
    REMOVE_DESIGN: 'core.designer.designlist.REMOVE_DESIGN',
    BEGIN_FETCHING: 'core.designer.designlist.BEGIN_FETCHING',
    COMPLETE_FETCHING: 'core.designer.designlist.COMPLETE_FETCHING',
    SET_DESIGN_NAME: 'core.designer.designlist.SET_DESIGN_NAME',
    SET_IMPORT_ENABLED: 'core.designer.designlist.SET_IMPORT_ENABLED',
    SET_IMPORT_TEXT: 'core.designer.designlist.SET_IMPORT_TEXT',
    CLEAR_INPUTS: 'core.designer.designlist.CLEAR_INPUTS'
}

export default ActionTypes;

export function setDesignName(designName) {
    return {
        type: ActionTypes.SET_DESIGN_NAME,
        designName
    }
}

export function fetchDesignList() {
    return {
        type: ActionTypes.FETCH_DESIGN_LIST
    }
}

export function receiveDesignList(designList) {
    return {
        type: ActionTypes.RECEIVE_DESIGN_LIST,
        designList
    }
}

export function receiveUpdateDesignProperty(fieldKey, value, version) {
    return {
        type: ActionTypes.RECEIVE_UPDATE_DESIGN_PROPERTY,
        fieldKey,
        value,
        version
    }
}

export function requestUpdateDesignProperty(fieldKey, value) {
    return {
        type: ActionTypes.REQUEST_UPDATE_DESIGN_PROPERTY,
        fieldKey,
        value
    }
}

export function changeEditableFieldValue(fieldKey, value) {
    return {
        type: ActionTypes.CHANGE_EDITABLE_FIELD_VALUE,
        fieldKey,
        value
    }
}

export function clearEditableFieldValue(fieldKey) {
    return {
        type: ActionTypes.CLEAR_EDITABLE_FIELD_VALUE,
        fieldKey
    }
}

export function requestRemoveDesign(designId) {
    return {
        type: ActionTypes.REQUEST_REMOVE_DESIGN,
        designId
    }
}

export function removeDesign(designId) {
    return {
        type: ActionTypes.REMOVE_DESIGN,
        designId
    }
}

export function beginFetching(requestId) {
    return {
        type: ActionTypes.BEGIN_FETCHING,
        requestId
    }
}

export function completeFetching(requestId, error) {
    return {
        type: ActionTypes.COMPLETE_FETCHING,
        requestId,
        error
    }
}

export function setImportEnabled(enabled) {
    return {
        type: ActionTypes.SET_IMPORT_ENABLED,
        enabled
    }
}

export function setImportText(text) {
    return {
        type: ActionTypes.SET_IMPORT_TEXT,
        text
    }
}

export function clearInputs() {
    return { 
        type: ActionTypes.CLEAR_INPUTS
    }
}
