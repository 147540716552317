import React from 'react';
import PropTypes from 'prop-types';
import DatasourcesUpdater from './DatasourcesUpdater';
import BreadcrumbTrail from '../header/Breadcrumb';
import SplitPaneHideLeft from '../common/SplitPaneHideLeft';
import DesignerCanvas from '../DesignerCanvas';
import DesignerHeader from '../header/DesignerHeader';
import DesignerDataSources from '../datasources/DesignerDataSources';

require('../../../../styles/sass/viewer.scss');

export default class DesignerViewPage extends React.Component {

    /**
     * This function triggered by Dashboards whenever the widget dimensions changed
     */
    resize() {
        this.props.setWindowSize(
            window.innerWidth,
            window.innerHeight,
            true
        );
    }

    render() {
        const design = this.props.design
        const designId = this.props.designId
        const canvasId = this.props.canvasId;

        return <div className="design-page">
            { this.props.showHeader &&
                <DesignerHeader mode="View"
                    handleRedirectRoot={() => this.props.gotoRoot()}
                    handleSwitchMode={() => this.props.switchMode()}/> }

            <DatasourcesUpdater
                designId={designId}
                onLoadingChanged={this.props.onLoadingChanged}
                refreshCount={this.props.refreshCount}
            />
                
            <BreadcrumbTrail/>
            <div className="design-page-content">
            <SplitPaneHideLeft renderLeft={renderDatasources}>
                
                {/* by making the key the current designId,
                    we force React to remount the DesignerCanvas component whenever the design changes,
                    completely resetting the canvas state */}
                <DesignerCanvas key={designId} canvasId={canvasId} design={design} mode="View" {...this.props}/>
            </SplitPaneHideLeft>
            </div>
        </div>
    }
}

function renderDatasources() {
    return <DesignerDataSources/>
}

DesignerViewPage.propTypes = {
    design: PropTypes.object.isRequired,
    designId: PropTypes.string.isRequired,
    canvasId: PropTypes.string,
    gotoRoot: PropTypes.func.isRequired,
    switchMode: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
    refreshCount: PropTypes.number.isRequired,
    onLoadingChanged: PropTypes.func
}
