import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showDesignData } from '../datarequest/actions';
import { isFetchingDesign, getDesign, getCurrentDesignId, getCurrentDesignParams } from '../helper/selector/selectors';
import LoadingPlaceholder from './LoadingPlaceholder';

/**
 * Monitors the current design id, and
 * loads the next design when it changed
 */

class DesignLoader extends React.Component {

    UNSAFE_componentWillMount() {
        this.loadDesignIfInvalidated(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.loadDesignIfInvalidated(nextProps);
    }

    loadDesignIfInvalidated(nextProps) {
        if(nextProps.design == null && !nextProps.fetchingDesign && nextProps.designId) {
            this.props.showDesignData(nextProps.designId, nextProps.designParams);
        }
    }

    shouldComponentUpdate(nextProps) {
        const isDesignChanged = (this.props.design == null) !== (nextProps.design == null);
        const areChildrenChanged = nextProps.children !== this.props.children;
        return isDesignChanged || areChildrenChanged;
    }

    render() {
        if(this.props.design == null) {
            return this.renderLoading();
        } else {
            return this.props.children;
        }
    }

    renderLoading() {
        return <div className="wrap-panel-page">
            <div className="panel-full-page">
                <LoadingPlaceholder message="Loading Design..."/>
            </div>
        </div>
    }
}

DesignLoader.propTypes = {
    design: PropTypes.object,
    designId: PropTypes.string,
    designParams: PropTypes.object,
    fetchingDesign: PropTypes.bool,
    showDesignData: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
}

export default connect(
    (state) => {
        const designId = getCurrentDesignId(state);
        return {
            designId,
            designParams: getCurrentDesignParams(state),
            fetchingDesign: isFetchingDesign(state, designId),
            design: getDesign(state, designId)
        }
    },
    { showDesignData }
)(DesignLoader);
