import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Seq, List, Map } from 'immutable'

import { bulkRemoveUpdateComputedProps, bulkClearComputedProps, recomputeFigureProps } from '../actions/computedPropsActions';
import { getAllComputedValues } from '../helper/selector/canvasFigureConfigSelectors';
import { getEvalContext } from '../helper/selector/datasourceSelectors';
import {
    getViewFiguresConfig,
    getViewId,
    getViewCanvasFigureIdsOrdered,
    getViewCanvasFigureIds,
    getViewParentMapping } from '../helper/selector/canvasFigureViewSelectors';

import { getFigureId } from '../helper/figureConfigHelper';
import * as DesignerHelper from '../helper/DesignerHelper'
import { pairwiseDeep } from '../helper/treeHelper';
import { createTimer } from '../helper/perfMonitor';

const updateFiguresTimer = createTimer('update-figures');

/**
 * Calculates changes to the canvas figure configs and computed values, performing updates as required.
 * When the canvas figure configs changed, updates the computed values.
 * When the computed values changed, updates the figures on the canvas.
 * 
 * Merge figure into bulk actions for performance reasons, to avoid triggering state changes for all components
 * whenever any figure changed.
 */

// ignoring some keys because they are used for calculations outside of draw2d
// and are not actually draw2d figure attributes, because of this it makes
// no sense to keep their diff so their change can be applied to the figure
// (all dynamic props (used to calc actual props) + condition (used to select config)
const IGNORE_DIFF_KEYS = DesignerHelper.DYNAMIC_PROPS.keySeq().toSet()
    .add('id')
    .add('parentId');

export class DesignerCanvasFigures extends React.Component {

    UNSAFE_componentWillMount() {
        this.updateFigures(undefined, this.props);
    }

    componentWillUnmount() {
        // clear all computed props when the canvas is unmounted
        const figureIds = this.props.figureConfigs.map(getFigureId);
        this.props.bulkClearComputedProps(this.props.designId, figureIds);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // update figures
        if(this.props.figureConfigs !== nextProps.figureConfigs
            || this.props.computedValues !== nextProps.computedValues) {
            this.updateFigures(this.props, nextProps);
        }

        // recompute figure-props when the datasource results or params changed
        if(this.props.evalContext !== nextProps.evalContext) {
            nextProps.recomputeFigureProps(this.props.designId)
        }
    }

    /**
     * Detect changes to the canvas figures
     */

    updateFigures(prevProps, nextProps) {
        const timer = updateFiguresTimer.start();
        let nRemoved = 0, nUpdated = 0, nComputedUpdated = 0;

        const prevFigureConfigs = prevProps && prevProps.figureConfigs || List();
        const prevComputed = prevProps && prevProps.computedValues || Map();
        const prevViewId = prevProps && prevProps.viewId;
        const nextFigureConfigs = nextProps.figureConfigs || List();
        const nextComputed = nextProps.computedValues || Map();
        const nextViewId = nextProps.viewId;
        const nextFigureIds = nextProps.figureIds;

        const removed = {};
        const updated = {};
        const computedUpdated = {};

        // configs updated
        pairwiseDeep(prevFigureConfigs, nextFigureConfigs, (f) => f.get('children'), getFigureId,
            (prevFigure, nextFigure) => {
                if(nextFigure == null) {
                    nRemoved++;
                    const figureId = getFigureId(prevFigure);
                    removed[figureId] = prevFigure;

                } else if(prevFigure !== nextFigure) {
                    nUpdated++;
                    const figureId = getFigureId(nextFigure);
                    updated[figureId] = [prevFigure, nextFigure];
                }
            }
        )

        // computed properties updated
        for(let figureId of prevComputed.keySeq()
            .concat(nextComputed.keySeq())) {
            if(figureId in computedUpdated)
                continue;

            const prev = prevComputed.getIn([figureId, 'figures']);
            const next = nextComputed.getIn([figureId, 'figures']);
            if(prev != next || prevViewId !== nextViewId) {
                nComputedUpdated++;
                computedUpdated[figureId] = [ prev, next ];
            }
        }

        if (nRemoved || nUpdated);
            this.handleFiguresConfigChanged(removed, updated, nextProps);
        
        if (nComputedUpdated)
            this.handleComputedPropsChanged(computedUpdated, nextFigureIds);
        
        if (this.props.figuresOrdered !== nextProps.figuresOrdered)
            this.props.updateDraw2DFiguresOrderBulk(nextProps.figuresOrdered);

        timer.stop();
    }

    handleFiguresConfigChanged(removed, updated, nextProps) {
        const removedIds = this.getFigureConfigsRemoved(removed);
        const diffs = this.getFigureConfigDiffs(updated);
        if(removedIds.length || diffs.length) {
            this.props.bulkRemoveUpdateComputedProps(nextProps.designId, removedIds, diffs);
        }
    }

    getFigureConfigsRemoved(removed) {
        return Object.keys(removed);
    }

    getFigureConfigDiffs(updated) {
        let figureDiffs = [];
        for(let figureId in updated) {
            const [prevFigureConfigs, nextFigureConfigs] = updated[figureId];
            const prevAltConfigs = prevFigureConfigs && prevFigureConfigs.get('altConfigs');
            const nextAltConfigs = nextFigureConfigs && nextFigureConfigs.get('altConfigs');
            const prevMainConfig = prevFigureConfigs && prevFigureConfigs.get('mainConfig');
            const nextMainConfig = nextFigureConfigs && nextFigureConfigs.get('mainConfig');

            const n = prevAltConfigs == nextAltConfigs ? 0
                : prevAltConfigs == null ? nextAltConfigs.size
                : nextAltConfigs == null ? prevAltConfigs.size
                : Math.max(prevAltConfigs.size, nextAltConfigs.size);

            for(let i = 0; i < n + 1; i++) {
                const prevConfig = i === 0 ? prevMainConfig
                    : prevAltConfigs != null ? prevAltConfigs.get(i - 1) : undefined;
                
                const nextConfig = i === 0 ? nextMainConfig
                    : nextAltConfigs != null ? nextAltConfigs.get(i - 1) : undefined;

                if(prevConfig !== nextConfig) {
                    const prevData = prevConfig ? prevConfig.get('data') : Map();
                    const nextData = nextConfig ? nextConfig.get('data') : Map();
                    const figDiff = this.configDiff(prevData, nextData);

                    if(figDiff.size > 0) {
                        figureDiffs.push({ figureId, altIndex: i, figDiff, figResult: nextData });
                    }
                }
            }
        }

        return figureDiffs;
    }

    handleComputedPropsChanged(updates, figureIds) {
        const updated = [];
        const removed = [];
        for(let baseFigureId in updates) {
            const [
                prevFigures=List(),
                nextFigures=List() ] = updates[baseFigureId];

            // Figures are removed if they are not present in the "nextFigures" list (deleted from the canvas)
            // or if the view id changed
            let removedFigs = Map().withMutations((m) => {
                for(let prevFigure of prevFigures) m.set(prevFigure.get('id'), prevFigure);
            });

            // figures created / updated
            for(let nextFigure of nextFigures) {
                const figureId = nextFigure.get('id');
                const viewId = nextFigure.get('viewId');
                const parentId = nextFigure.get('parentId');
                let prevFigure = removedFigs.get(figureId);

                // consider as a totally new figure if the viewId was changed
                if(prevFigure != null) {
                    if(prevFigure.get('viewId') !== viewId) {
                        prevFigure = null;
                    }
                }

                if(prevFigure != null) {
                    removedFigs = removedFigs.delete(figureId);
                }

                const computedDiff = this.getComputedPropDiff(prevFigure, nextFigure);
                const figResult = nextFigure.filter((v, k) => !IGNORE_DIFF_KEYS.has(k));

                // if the parent figure id isn't included in the current view,
                // set to undefined
                const viewParentId = figureIds.has(parentId) ? parentId : undefined;

                if(computedDiff.size > 0) {
                    updated.push({
                        figureId,
                        parentId: viewParentId,
                        figDiff: computedDiff,
                        figResult,
                        configId: baseFigureId
                    });
                }
            }

            // remove remaining figures
            for(let removedId of removedFigs.keySeq())
                removed.push(removedId);
        }

        // apply changes
        if(removed.length > 0) this.props.deleteDraw2DFiguresBulk(removed);
        if(updated.length > 0) this.props.updateDraw2DFiguresBulk(updated);
    }

    shouldComponentUpdate() {
        // don't render
        return false;
    }

    render() {
        // this class just used for updating state
        return <noscript />
    }

    getComputedPropDiff(prevComputed, nextComputed) {
        if (prevComputed === nextComputed) return Map();
        const currValues = prevComputed || Map();
        const nextValues = nextComputed || Map();
        return this.configDiff(currValues, nextValues);
    }

    /* determine the configs to use and calculate the figure properties: */
    calculateNextStateValue(nextProps) {
        let prevStateValue = nextProps.prevStateValue
        let nextStateValue = prevStateValue
        nextStateValue = this.updateFigConfig(prevStateValue, nextStateValue, nextProps)
        nextStateValue = this.updateFigResult(prevStateValue, nextStateValue, nextProps)
        return nextStateValue
    }

    // REMARK: this method makes the following assumption:
    // when a value is defined in an alternative config,
    // then it should also exist in the default config with a default
    // value so that if the condition for the alternative config is no
    // longer true -> the value doesn't 'stick'... otherwise we can't know
    // the 'default' value to go back to once the alt config condition is false
    configDiff(prevConfig, curConfig) {
        let diff = Map()
        let allKeys = curConfig.keySeq()
            .concat(prevConfig ? prevConfig.keySeq() : Seq())
            .toSetSeq();

        for (let k of allKeys) {
            if (!IGNORE_DIFF_KEYS.has(k) && (prevConfig == null || prevConfig.get(k) != curConfig.get(k))) {
                let v = curConfig.get(k);
                diff = diff.set(k, v);
            }
        }
        return diff
    }
}

DesignerCanvasFigures.propTypes = {
    // own-props
    designId: PropTypes.string.isRequired,
    updateDraw2DFiguresBulk: PropTypes.func.isRequired,
    deleteDraw2DFiguresBulk: PropTypes.func.isRequired,
    updateDraw2DFiguresOrderBulk: PropTypes.func.isRequired,

    evalContext: PropTypes.object.isRequired,
    viewId: PropTypes.string,
    parentsMapping: ImmutablePropTypes.map.isRequired,
    computedValues: ImmutablePropTypes.map.isRequired,
    figureConfigs: ImmutablePropTypes.list.isRequired,
    figuresOrdered: ImmutablePropTypes.list.isRequired,
    figureIds: ImmutablePropTypes.set.isRequired,
    bulkRemoveUpdateComputedProps: PropTypes.func.isRequired,
    bulkClearComputedProps: PropTypes.func.isRequired,
    recomputeFigureProps: PropTypes.func.isRequired
}

export default connect(
    (state, { designId }) => ({
        evalContext: getEvalContext(state.designer, designId),
        viewId: getViewId(state, designId),
        parentsMapping: getViewParentMapping(state, designId),
        computedValues: getAllComputedValues(state, designId),
        figureConfigs: getViewFiguresConfig(state, designId),
        figuresOrdered: getViewCanvasFigureIdsOrdered(state, designId),
        figureIds: getViewCanvasFigureIds(state, designId)
    }),
    { bulkRemoveUpdateComputedProps, bulkClearComputedProps, recomputeFigureProps }
)(DesignerCanvasFigures);

