import { parentsToRelativePath } from './util/createFigureParentsSelector';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { getFigureId as getCanvasFigureId } from '../figureConfigHelper';
import { parentMappingSelector, canvasFigureSelector, getCanvasFiguresBasePath } from './canvasFigureConfigSelectors';
import { baseFigureSelector,
    customFigureSelector,
    getFigureDescriptorId,
    getBaseFigureParentsMapping,
    getCustomFigureParentsMapping,
    getCustomFiguresBasePathPath,
    baseFigureDescriptorsPath } from './figureDescriptorsSelector';

export const figureParentsSelector = createCachedSelector(
    parentMappingSelector,
    getCustomFigureParentsMapping,
    getBaseFigureParentsMapping,
    (canvasParentsMapping, customParentsMapping, baseParentsMapping) => (figureIdentifier) => {
        const { figureType, figureId } = figureIdentifier;
        switch(figureType) {
            case 'canvas':
                return canvasParentsMapping.get(figureId);
            case 'custom':
                return customParentsMapping.get(figureId);
            default:
                return baseParentsMapping.get(figureId);
        }
    }
)((state, designId) => designId);

export const figureSelector = createCachedSelector(
    canvasFigureSelector,
    customFigureSelector,
    baseFigureSelector,
    (canvasFigureSelector, customFigureSelector, baseFigureSelector) => (figureIdentifier) => {
        const { figureType, figureId } = figureIdentifier;
        switch(figureType) {
            case 'canvas':
                return canvasFigureSelector(figureId);
            case 'custom':
                return customFigureSelector(figureId);
            default:
                return baseFigureSelector(figureId);
        }
    }
)((state, designId) => designId);

export const figurePathSelector = createCachedSelector(
    createSelector((_, designId) => designId, getCanvasFiguresBasePath),
    createSelector((_, designId) => designId, getCustomFiguresBasePathPath),
    figureParentsSelector,
    (canvasFiguresBasePath, customFiguresBasePath, figureParentsSelector) => (figureIdentifier) => {
        const figureType = figureIdentifier.figureType;
        const figureParents = figureParentsSelector(figureIdentifier);
        const relativePath = figureParents && parentsToRelativePath(figureParents);

        switch(figureType) {
            case 'canvas': return canvasFiguresBasePath.concat(relativePath);
            case 'custom': return customFiguresBasePath.concat(relativePath);
            default: return baseFigureDescriptorsPath.concat(relativePath)
        }
    }
)((state, designId) => designId);

export function getFigureId(figure, figureType) {
    switch(figureType) {
        case 'canvas':
            return getCanvasFigureId(figure);
        default:
            return getFigureDescriptorId(figure);
    }
}
