const ActionTypes = {
    SET_COLOR_PICKER_VISIBLE: 'core.designer.common.SET_COLOR_PICKER_VISIBLE'
}

export default ActionTypes;

export function setColorPickerVisible(fieldId, visible) {
    return {
        type: ActionTypes.SET_COLOR_PICKER_VISIBLE,
        fieldId,
        visible
    }
}
