
import React, { useState } from 'react';
import { GridReactive, Column, Row } from '@pearlchain/component-lib-react-pg';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';

import SearchBinderForm from './SearchBinderForm';
import { FormFieldConfig } from '../form/types';

type Props = {
    formId: string,
    value: string|undefined,
    isOpen: boolean,
    columns: Column[],
    formValueKey: string,
    formFields: { [key: string]: FormFieldConfig },
    onClosed: () => void,
    onComplete: (value: string) => void,
    doSearch: (formValues: any) => Promise<Row[]>
}

export default function SearchBinderModal(props: Props) {
    const [value, setValue] = useState(props.value);
    const [data, setData] = useState<any[]>([]);

    function fetchData(formValues: any) {
        props.doSearch(formValues)
            .then((rows) => {
                // update the state with the new rows returned
                setData(rows);
            })
    }

    function handleComplete() {
        if (value == null) return;
        props.onComplete(value);
    }

    return (
        <Modal isOpen={props.isOpen} size="lg" toggle={props.onClosed}>
            <ModalHeader toggle={props.onClosed}>Product</ModalHeader>
            <ModalBody>
                <SearchBinderForm
                    form={props.formId}
                    fields={props.formFields}
                    initialValues={{ [props.formValueKey]: value }}
                    onSubmit={fetchData}/>

                <GridReactive
                    gridId={props.formId}
                    style={{ height: 400 }}
                    columns={props.columns}
                    data={data}
                    onRowSelected={(value: string) => setValue(value)}/>

            </ModalBody>
            <ModalFooter>
                <Button onClick={props.onClosed}>Cancel</Button>
                <Button color="primary" disabled={!value} onClick={handleComplete}>Select</Button>
            </ModalFooter>
        </Modal>);
}
