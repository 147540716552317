import React, { useState } from "react";
import { History } from 'history';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SubPage, StackNavigation } from "./StackNavigation";
import { useAppSwitcherProperties, useProperties } from "./PropertiesLoader";

interface Props {
    appIcon: IconProp;
    appTitle: string;
    subPages?: SubPage[];
    availableAppsConfigUrl?: string;
    history: History,
    onPageSelected?: (subPage: SubPage) => void;
    onSignOut?: () => void;
}

/**
 * Component that contains al data handling for the stack navigation bar
 */
export default function SimpleStackNavigation(props: Props) {
    const appSwitcherProperties = useAppSwitcherProperties();
    const properties = useProperties();

    const handlePageSelected = (subPage: SubPage) => {
        props.history?.push(subPage.path);
        props.onPageSelected?.(subPage);
    }

    const handleSignOut = () => {
        window.location.assign(properties?.urlLogin+ "#/?redirect=" + window.location.href);
    }

    return (
        <StackNavigation
            appIcon={props.appIcon}
            appTitle={props.appTitle}
            subPages={props.subPages}
            onPageSelected={handlePageSelected}
            onSignOut={handleSignOut}
            availableAppsConfig={appSwitcherProperties}
            history={props.history}
        />
    );
}
