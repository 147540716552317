import { useEffect, useState } from "react";
import { PluginDefinition } from "./types/pluginTypes";
import { useMemo } from "react";

export function usePluginDefinition<C, R>(pluginId: string): PluginDefinition<C, R> | undefined {
    const pluginCache = useMemo(() => window.plugins.getPlugin<C, R>(pluginId), [pluginId]);
    const [definition, setDefinition] = useState<PluginDefinition<C, R> | undefined>(undefined);

    useEffect(() => {
        if (!pluginCache) return;

        const definition = pluginCache.definition;
        if (definition.hasValue) {
            setDefinition(definition.value!);
            return;
        }

        // wait for the definition to be loaded
        const handle = definition.addListener(setDefinition);

        // load the plugin definition
        if (!definition.isLoading) {
            definition.load();
        }

        return () => {
            definition.removeListener(handle);
        }
    }, [pluginCache]);

    return definition;
}
