
export enum ActionType {
    INVOKE='complib.requesthandler.INVOKE'
}

/**
 * @deprecated removed in version 4.7.0
 */
export function invokeRequestHandler(requestId: string, ...params: unknown[]): InvokeRequestHandlerAction {
    return {
        type: ActionType.INVOKE,
        requestId,
        params
    };
}

export interface InvokeRequestHandlerAction {
    type: ActionType.INVOKE,
    requestId: string,
    params: unknown[]
}
