
export const ATTRIB_DATA_NAME = 'data-name';

export function getCurrentPluginName(): string {
    const script = document.currentScript;
    if (script == null) {
        throw new Error('Must be called from a script!');
    }

    const name = script.getAttribute(ATTRIB_DATA_NAME);
    if (name == null) {
        throw new Error(`attribute "${ATTRIB_DATA_NAME}" not set on script tag`);
    }

    return name;
}
