import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import designer, { designerFormReducer } from '../core/designer/reducers/DesignerReducers';
import datasourceReducer from '../common/view/reducers/DatasourceReducer';
import {reducer as notifications} from 'react-notification-system-redux';
import componentLibReducer from '../component-lib/reducers';

const combineReducer = combineReducers({
    designer,
    form: formReducer,
    datasource: datasourceReducer,
    notifications,
    componentLib: componentLibReducer
});

const rootReducer = function(state, action) {
  state = combineReducer(state, action);
  state = designerFormReducer(state, action);
  return state
};

export default rootReducer
