import { useReducer } from "react";

/**
 * Helpful React hook to synchronize a value with localStorage
 * @param key local-storage key
 * 
 * Usage:
 * const [value, setValue] = useLocalStorage('key');
 *  
 * // ...
 * 
 * // set the value in the localStorage, and update the component with the new value
 * setValue('new-value') 
 */

export function useLocalStorage(key: string): [string|null, (v: string|null) => void] {
    const [s, d] = useReducer(localStorageReducer, key, localStorageInitialState);
    return [s.value, d];
}

function localStorageInitialState(key: string): { key: string, value: string|null } {
    const value = localStorage.getItem(key);
    return { key, value };
}

function localStorageReducer(state: { key: string, value: string|null }, nextValue: string|null) {
    if (nextValue == null) {
        localStorage.removeItem(state.key);
    } else {
        localStorage.setItem(state.key, nextValue);
    }

    return { key: state.key, value: nextValue };
}
