import { call, put, select, all, takeEvery } from 'redux-saga/effects';
import { fromJS } from 'immutable';

import actionTypes, { receiveFiguresManifest, retrieveDataDesign, saveDesignResult, setDesignDatasourceConfig, receiveAvailableDatasources, receiveDatasourceDefinition, receiveDatasourceResults } from './actions'
import { runRemoteCall } from '../../../util/remoteSagaHelper'
import Notifications from 'react-notification-system-redux';
import { mapSagaOverBulkValues } from '../helper/bulkHelper';
import { createDatasourceRequest, mapFormToDatasourceRequestValues, createFieldInputEvaluator } from '../../../util/datasource';
import { getDatasources, getSelectorConfigMergeFormValues } from '../helper/selector/datasourceSelectors';
import { tagDesignWithCurrVersion } from '../helper/DesignerHelper';
import { setDatasourcesLoading } from '../actions/DesignerActions';

const RemoteUrl = {
  INVOKE_FIND: 'sec/api/webcomponent/',
  INVOKE_SAVE: 'sec/api/webcomponent/save',
  LIST_DATASOURCES: 'sec/api/datasource/list',
  INVOKE_DATASOURCE: 'sec/api/datasource/invoke',
  FIGURES_MANIFEST: 'api/resource/manifest.json',
  GET_DATASOURCE_DEFINITION: (definitionName) => 'sec/api/datasource/definition/' + definitionName
}

function *getDesignDataFromBackend(action){
  const uuidSelect = action.uuid;
  const params = action.params;
  const config = action.config;
  const [ data ] = yield* runRemoteCall(RemoteUrl.INVOKE_FIND + uuidSelect)
  if(data) {
    yield put(retrieveDataDesign(data, params, config));
  }
}

function *handleFetchAvailableDatasources() {
  const [ data ] = yield* runRemoteCall(RemoteUrl.LIST_DATASOURCES);
  if(data) {
    yield put(receiveAvailableDatasources(data));
  }
}

function *handleFetchDatasourceDefinitionsBulk({ values }) {
  let dsDefinitions = yield mapSagaOverBulkValues(fetchDatasourceDefinition, values);
  // definitions can be undefined if the request failed. filter out undefined because it crashes the app..
  dsDefinitions = dsDefinitions.filter((def) => def != null);
  yield put(receiveDatasourceDefinition.invokeBulk(dsDefinitions));
}

function *fetchDatasourceDefinition(datasourceName) {
  const url = RemoteUrl.GET_DATASOURCE_DEFINITION(datasourceName);
  const [ definition ] = yield* runRemoteCall(url);
  return fromJS(definition); 
}

function *handleInvokeDatasourceBulk({ values, designId }) {
  const datasources = yield select(getDatasources);
  const vs = yield mapSagaOverBulkValues(invokeDatasource, values, datasources);
  yield put(setDatasourcesLoading(true));
  yield put(receiveDatasourceResults.invokeBulk(vs, designId));
  yield put(setDatasourcesLoading(false));
}

function *invokeDatasource([datasourceName, selectorConfig], datasources) {
  const dsId = selectorConfig.get('id');
  const dsDefinition = datasources.getIn([datasourceName, 'definition']);
  const values = selectorConfig.get('fieldValues');

  const fieldInputEvaluator = yield select(createFieldInputEvaluator);
  const requestValues = mapFormToDatasourceRequestValues(dsDefinition, values, fieldInputEvaluator);
  const requestBody = createDatasourceRequest(datasourceName, requestValues);
  const [ result ] = yield* runRemoteCall(RemoteUrl.INVOKE_DATASOURCE, requestBody);
  return { dsId, datasourceName, result: fromJS(result) };
}

function *handleFetchFigureDescriptors() {
  const [ figuresManifest ] = yield* runRemoteCall(RemoteUrl.FIGURES_MANIFEST);
  if(!figuresManifest) return;
  yield put(receiveFiguresManifest(figuresManifest));
}

export function *selectDesigner() {
     const { designer } = yield select((state) => ({
         designer: state.designer
     }));
     return designer ;
 }

 export function *fetchDescription() {
      const { description } = yield select((state) => ({
          description: state.designer.getIn(['selected-design', 'description'])
      }));
      return description ;
  }

function *saveDesignInBackend(fetchDesignId) {
  const designId = fetchDesignId.designId;
  const configValues = yield select((state) => getSelectorConfigMergeFormValues(state, designId));
  yield put(setDesignDatasourceConfig(designId, configValues));

  let description = ''
  const designer = yield selectDesigner();
  let existingDescription = yield fetchDescription();
  let design = designer.getIn(['designs', designId])
  let version = designer.getIn(['web-components', designId, 'version'])
  if (version == undefined) {
    version = 0
  }
  if (fetchDesignId.description == undefined) {
    description = existingDescription
  } else {
    description = fetchDesignId.description
  }

  design = tagDesignWithCurrVersion(design);

  const requestBody = {
    version,
    'uniqueIdentifier': designId,
    'type': 'design',
    description,
    'data': JSON.stringify(design)
  }

  const notificationSuccess = {
    title: '',
    message: 'Saved Successfully!',
    autoDismiss: 5
  };

  const [ result ] = yield* runRemoteCall(RemoteUrl.INVOKE_SAVE, requestBody, 'save');
  if(!result) return; 
  
  result.description = description;
  result.uniqueIdentifier = designId;
  
  yield put(Notifications.success(notificationSuccess));
  yield put(saveDesignResult(designId, fromJS(result)));
}

// export function *designList() {
//   yield call(getListOfDesigns);

// }

export function *getDesignData(action) {
  yield call(getDesignDataFromBackend, action);
}

export function *saveDesign(designId) {
  yield call(saveDesignInBackend, designId);
}

export default function rootSaga() {
    return all([
        takeEvery(actionTypes.GET_DESIGN_DATA, getDesignData),
        takeEvery(actionTypes.SAVE_DESIGN, saveDesign),
        takeEvery(actionTypes.FETCH_FIGURE_DESCRIPTORS, handleFetchFigureDescriptors),
        takeEvery(actionTypes.FETCH_AVAILABLE_DATASOURCES, handleFetchAvailableDatasources),
        takeEvery(actionTypes.FETCH_DATASOURCE_DEFINITION, handleFetchDatasourceDefinitionsBulk),
        takeEvery(actionTypes.INVOKE_DATASOURCE, handleInvokeDatasourceBulk)
    ]);
}
