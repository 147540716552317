import React from 'react'
import PropTypes from 'prop-types'
import {Map} from 'immutable'

import { createForm } from './FieldsContainer'
import * as DatasourceActions from '../../../common/view/actions/DatasourceActions'
import { connect } from 'react-redux'

// specialization of the datasource fields renderer (find button)
class Selector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formComponent: createForm(this.props)
    }
  }

  dispatchFind() {
    const definition = this.props.datasourceDefinition;
    const datasourceName = definition.get('name');
    this.props.dispatch(DatasourceActions.find(this.props.formId,
      definition,
      [this.props.formId, datasourceName]))
  }

  render() {
    const Form = this.state.formComponent;
    return <div>
      <input className="btn-datasource-find" type="button" value="Find" onClick={() => this.dispatchFind()}/>
      <Form initialValues={this.props.initialValues}/>
    </div>
  }
}

Selector.propTypes = {
  formId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  datasourceDefinition: PropTypes.instanceOf(Map).isRequired,
  initialValues: PropTypes.instanceOf(Map)
}

export default connect((state) => state)(Selector)
