import {List, Map, fromJS} from 'immutable'

import {isFieldHidden, getFieldDefaultValue} from '../core/designer/data/datasourceFieldsData';
import { getEvalContext } from '../core/designer/helper/selector/datasourceSelectors';
import { compileAndEvaluate } from '../core/designer/helper/eval/evaluator';

export function createDatasourceRequest(datasource, data) {
  return fromJS([
    {
      datasource,
      payload: JSON.stringify(data)
    }
  ])
}

/**
 * Extracts a list of datasource parameters from the datasource definition.
 * @param {*} getSet - 'get' or 'set' fields from the definition
 */
export function getDatasourceParametersList(getSet) {
  if(getSet == null) {
    return List();
  } else if(getSet instanceof List) {
    return getSet;
  } else {
    return List().push(getSet);
  }
}

export function createFieldInputEvaluator(state) {
  const designer = state.designer;
  const designId = designer.getIn(['selected-design', 'uuid']);
  const evalContext = getEvalContext(designer, designId);
  const context = { template: evalContext };
  
  return (input, definitionField) => {
    if (!definitionField.has('liveAssist') && typeof input === 'string') {
      return compileAndEvaluate('' + input, context);
    } else {
      return input;
    }
  }
}

/**
 * Maps the form values to HTTP request parameters
 */
export function mapFormToDatasourceRequestValues(datasourceDefinition, values, mapFieldInput) {
  const datasourceId = datasourceDefinition.get('name');
  const setParams = getDatasourceParametersList(datasourceDefinition.get('set'));
    return setParams.reduce((r, definitionField) => {
      const fieldName = definitionField.get('name');
      const fieldType = definitionField.get('type');
      
      let formValue = values.get(fieldName);
      if(formValue == null && isFieldHidden(datasourceId, fieldName)) {
        formValue = getFieldDefaultValue(datasourceId, fieldName);  
      }

      const requestValue = mapFormToDatasourceRequestValue(formValue, fieldType, mapFieldInput, definitionField);
      if (requestValue != null) {
        return r.set(fieldName, requestValue)
      }
      return r
    }, Map())
}

/**
 * Map a form value to a request parameter
 */
function mapFormToDatasourceRequestValue(value, fieldType, mapFieldInput, definitionField) {
  fieldType = fieldType.toLowerCase();
  
  // list type
  if(fieldType.endsWith('[]')) {
    if(!value) return List();
    return value.get('values', List()).map((input) => mapFieldInput(input, definitionField));
  }
  
  switch(fieldType) {
    case 'map': {
      if(!value) return Map();
      // flatten params into a map
      const values = value.get('values');
      return values.reduce((result, entry) => 
        result.set(entry.get('key'), mapFieldInput(entry.get('value'), definitionField)), Map());
    }

    default: return mapFieldInput(value, definitionField);
  }
}

/**
 * Build the live-assist datasource payload from the datasource definition
 * and the current form values
 */
export function createLiveAssistRequestPayload(liveAssist, fieldId, values) {
  const value = values.get(fieldId);
  const payload = {};
  for(let inputMapping of liveAssist.get('inputMapping')) {
    const role = inputMapping.get('role');
    const name = inputMapping.get('name');
    const valueField = inputMapping.get('valueField');
    let v;
    switch(role) {
      case 'PROVIDED':
        // provided: static value, provided in the ds definition
        v = inputMapping.get('value');
        break;

      case 'QUERY':
        // query: use the value from the input field
        v = value;
        break;
    }
    
    if(valueField) {
      // we can only have one value-field, which comprises the whole payload
      return v;

    } else if(v != null) {
      payload[name] = v;
    }
  }
  return Map(payload);
}

export function getOutputMappingByRole(liveAssist, matchRoles) {
  let idx;
  let resultMapping;
  for(let outputMapping of liveAssist.get('outputMapping')) {
    const role = outputMapping.get('role');

    let i = 0;
    for(let matchRole of matchRoles) {
      if(matchRole === role && (idx == null || idx > i)) {
        resultMapping = outputMapping;
        idx = i;
      }
      ++i;
    }
  }
  return resultMapping;
}

export function createSuggestionResultExtractor(liveAssist, matchRoles) {
  const resultMapping = getOutputMappingByRole(liveAssist, matchRoles);
  if(resultMapping == null) return;
  const valueField = resultMapping.get('valueField', false);
  const name = resultMapping.get('name');
  return (suggestion) => valueField ? suggestion : suggestion.get(name);
}
