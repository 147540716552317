import { PgExts } from './types';
import { GridCreationContext } from './extensions/extensionTypes';
import { createActionButtonsExtension } from './extensions/actionButtonsExtension';
import { createFormattingExtension } from './extensions/formattingExtension';
import { createSelectionExtension } from './extensions/selectionExtension';
import { createSortingExtension } from './extensions/sortingExtension';

export function createExtensions(exts: PgExts, context: GridCreationContext) {
    if (!('selection' in exts)) {
        createSelectionExtension(exts, context);
    }

    if (!('formatting' in exts)) {
        createFormattingExtension(exts, context);
    }

    if (!('editing' in exts)) {
        exts.editing = true;
    }

    if (!('actionbuttons' in exts)) {
        createActionButtonsExtension(exts, context);
    }

    if (!('validation' in exts)) {
        exts.validation = true;
    }

    if (!('sorting' in exts)) {
        createSortingExtension(exts, context);
    }
}
