import { all } from "redux-saga/effects";
import translationSagas from "./components/translation/translation-sagas";
import requestHandlerSagas from './components/requesthandlers/sagas';

export default function sagas(){
    return all([
        translationSagas(),
        requestHandlerSagas()
    ]);
}
