import { fromJS } from 'immutable';

/**
 * A list of built-in figure descriptors
 */

export const baseFigureDescriptors = fromJS([
    {
        id: 'pdf',
        label: 'PDF',
        'figure-props': {
            readOnly: true,
            mainConfig: {
                data: {
                    type: 'custom.PDF',
                    width: 300,
                    height: 300,
                    url: '',
                    page: 1
                },
                input: {}
            }
        }
    },
    {
        id: 'shape2D.Circle',
        label: 'Circle',
        'figure-props': {
            readOnly: true,
            mainConfig: {
                data: {
                    type: 'draw2d.shape.basic.Circle',
                    width: '50',
                    height: '50'
                },
                input: {}
            }
        }
    },
    {
        id: 'shape2D.Diamond',
        label: 'Diamond',
        'figure-props': {
            readOnly: true,
            mainConfig: { 
                data: {
                    type: 'draw2d.shape.basic.Diamond',
                    width: '50',
                    height: '50'
                },
                input: {}
            }
        }
    },
    {
        id: 'shape2D.Image',
        label: 'Image',
        'figure-props': {
            readOnly: true,
            mainConfig: {
                data: {
                    type: 'draw2d.shape.basic.Image',
                    width: '50',
                    height: '50',
                    path: ''
                },
                input: {}
            }
        }
    },
    {
        id: 'shape2D.Label',
        label: 'Label',
        'figure-props': {
            readOnly: true,
            mainConfig: { 
                data: {
                    type: 'draw2d.shape.basic.Label'
                },
                input: {}
            }
        }
    },
    {
        id: 'shape2D.Line',
        label: 'Line',
        'figure-props': {
            readOnly: true,
            mainConfig: { 
                data: {
                    type: 'draw2d.shape.basic.Line'
                },
                input: {}
            }
        }
    },
    {
        
        id: 'shape2D.Oval',
        label: 'Oval',
        'figure-props': {
            readOnly: true,
            mainConfig: { 
                data: {
                    type: 'draw2d.shape.basic.Oval',
                    width: '50',
                    height: '50'
                },
                input: {}
            }
        }
    },
    {
        id: 'shape2D.Polygon',
        label: 'Polygon',
        'figure-props': {
            readOnly: true,
            mainConfig: { 
                data: {
                    type: 'draw2d.shape.basic.Polygon'
                },
                input: {}
            }
        }
    },
    {
        
        id: 'shape2D.Rectangle',
        label: 'Rectangle',
        'figure-props': {
            readOnly: true,
            mainConfig: {
                data: {
                    type: 'draw2d.shape.basic.Rectangle',
                    width: '50',
                    height: '50'
                },
                input: {}
            }
        }
    },
    {
        
        id: 'shape2D.Text',
        label: 'Text',
        'figure-props': {
            readOnly: true,
            mainConfig: {
                data: {
                    type: 'draw2d.shape.basic.Text'
                },
                input: {}
            }
        }
    },
    {
        
        id: 'shape2D.NodeStart',
        label: 'Node Start',
        'figure-props': {
            readOnly: true,
            mainConfig: {
                data: {
                    type: 'draw2d.shape.node.Start'
                },
                input: {}
            }
        }
    },
    {
        id: 'shape2D.NodeEnd',
        label: 'Node End',
        'figure-props': {
            readOnly: true,
            mainConfig: {
                data: {
                    type: 'draw2d.shape.node.End'
                },
                input: {}
            }
        }
    }
]);
