import Imm from 'immutable';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

export function getAvailableDatasources(state) {
    return state.designer.getIn(['datasources', 'availableDatasources']);
}

export function getSelectedDatasource(state) {
    return state.designer.getIn(['datasources', 'selected']);
}

export function getDatasources(state) {
    return state.designer.get('datasources')
}

export function getDatasourcesLoading(state) {
    return state.designer.get('datasources-loading', false);
}

export function getDatasourceDefinition(state, definitionId) {
    return state.designer.getIn(['datasources', definitionId, 'definition']);
}

export function getDatasourceResults(state, designId) {
    return state.getIn(['designs', designId, 'datasource-results'], Imm.Map());
}

export function getParams(state, designId) {
    const selectedDesign = state.get('selected-design');
    if (selectedDesign.get('uuid') === designId) {
        return selectedDesign.get('params', Imm.Map());
    } else {
        return Imm.Map();
    }
}

// the context the expressions will be evaluated with
export const getEvalContext = createSelector(getDatasourceResults, getParams,
    (datasourceResults, params) => ({
        ...datasourceResults.toJS(),
        params: params.toJS()
    }));

export function getSelectorConfigs(state, designId) {
    return state.designer.getIn(['designs', designId, 'selector-configs']);
}

export function getFormMappings(state) {
    return state.designer.get('formMapping');
}

/**
 * Return the selector values in redux-form.
 * Returns the same instance if mappings / form-values are unchanged.
 */
export const getFormMappingValues = createSelector(
    getFormMappings,
    (state) => state,
    function() {
        let prev = Imm.Map();
        return (formMapping, state) => {
            if(formMapping == null) return prev;
            return prev = prev.withMutations((m) => {
                for(let [formId, formMapping] of formMapping.entrySeq()) {
                    const selector = formValueSelector(formId);
                    const fieldNames = formMapping.get('fieldNames');

                    m.set(formId, m.get('formId', Imm.Map()).withMutations((m2) => {
                        for(let fieldName of fieldNames) {
                            const fieldValue = selector(state, fieldName);
                            if(fieldValue == null) {
                                // couldn't find the form value (maybe because the form is unmounted).
                                // delete from the mapping. Will be ignored in the resulting config merge.
                                if(m2.has(fieldName)) m2.delete(fieldName)
                                continue;
                            }

                            const fieldValueImm = Imm.fromJS(fieldValue);
                            const prevFieldValue = m2.get(fieldName);
                            if(prevFieldValue == null || !prevFieldValue.equals(fieldValueImm)) {
                                m2.set(fieldName, fieldValueImm);
                            }
                        }
                    }));
                }
            });
        }
    }()
)

/**
 * Merge the current config values in the design, with the form values from redux-form.
 * Returns the same instance if values / config unchanged.
 */
export const getSelectorConfigMergeFormValues = createSelector(
    getFormMappings,
    getSelectorConfigs,
    getFormMappingValues,
    (state, designId) => designId,
    (formMappings, selectorConfigs, formMappingValues, designId) => {
        if(formMappings == null || selectorConfigs == null) return selectorConfigs || Imm.List();
        return selectorConfigs.withMutations((selConfigs) => {
            for(let [formId, formMapping] of formMappings.entrySeq()) {
                const selectorPath = formMapping.get('selectorPath');
                if(!startsWith(selectorPath,
                    Imm.List.of('designs', designId, 'selector-configs'))) continue;

                const configValues = formMappingValues.get(formId);
                if(configValues == null) continue;
                    
                const selectorPathRel = selectorPath.slice(3, selectorPath.size);
                const configValuesImm = Imm.fromJS(configValues);
                selConfigs.mergeIn(selectorPathRel, configValuesImm);
            }
        });
    }
)

function startsWith(a, b) {
    if(a.size < b.size) return false;
    for(let i = 0; i < b.size; ++i) {
        if(a.get(i) !== b.get(i)) return false;
    }
    return true;
}

