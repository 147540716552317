import { toNumber } from '../../helper/mathHelper';
import {
    getCanvasConfig,
    getCanvasWidth,
    getCanvasHeight,
    getCanvasBgColor,
    getCanvasGridShowing,
    getCanvasGridSize } from '../../helper/selector/canvasConfigSelectors';
import { getViewWidth, getFiguresView, getViewHeight } from '../../helper/selector/canvasFigureViewSelectors';
import { ShowViewBoundsEditPolicy } from './ShowViewBoundsEditPolicy';

const minViewWidth = 800;
const minViewHeight = 600;

export default function(prev, next, context, canvas) {
    if(next.mode === 'View') {
        applyZoomResizeViewMode(prev, next, canvas);

    } else {
        applyResizeDesignMode(prev, next, canvas);
        context = applyCanvasGridChanges(prev, next, context, canvas);
        context = applyViewBoundsChanges(prev, next, context, canvas);
    }

    applyBackgroundColorChanges(prev, next, canvas);
    return context;
}

export function applyBackgroundColorChanges(prev, next, canvas) {
    const prevConfig = getCanvasConfig(prev && prev.design);
    const nextConfig = getCanvasConfig(next.design);
    const prevBgColor = getCanvasBgColor(prevConfig);
    const bgColor = getCanvasBgColor(nextConfig);

    if(prev == null || prev.design == null || (prevBgColor !== bgColor)) {
        canvas.setBackgroundColor(bgColor);
    }
}

export function applyZoomResizeViewMode(prev, next, canvas) {
    const [ canvasWidth, canvasHeight ] = getCanvasDims(next.design);
    const [ prevCanvasWidth, prevCanvasHeight ] = getCanvasDims(prev && prev.design);

    const requiresUpdate = prev == null || prev.designer == null || prev.design == null
        || prev.designer.get('splitPane') !== next.designer.get('splitPane')
        || prev.designer.get('windowSize') !== next.designer.get('windowSize')
        || prev.figures !== next.figures
        || prevCanvasWidth !== canvasWidth
        || prevCanvasHeight !== canvasHeight;

    if(requiresUpdate) {

        // resize canvas dimensions after a short delay
        // to allow time for parent size recalculation
        window.setTimeout(function() {
            const [ width, height ] = computeBoundingBox(next.figures);
            const [ parentWidth, parentHeight ] = canvas.getParentSize();

            // set the size of the canvas to be much larger than the parent
            // to prevent clipping when zoomed
            canvas.setSize(parentWidth * 10, parentHeight * 10);
                     
            const zoomFactor = Math.max(
                width / parentWidth, 
                height / parentHeight);
            
            // center the canvas horizontally
            const container = canvas.getHtmlContainer();
            let offX = 0, offY = 0;
            if(width / parentWidth < height / parentHeight) {
                offX = (parentWidth - width / zoomFactor) / 2;
            }
            
            container.css('margin-left', offX);
            container.css('margin-top', offY);

            // zoom the canvas
            canvas.setZoom(zoomFactor, false);
        }, 100);
    }
}

function computeBoundingBox(figures) {
    let width = minViewWidth;
    let height = minViewHeight;
    for(let figure of figures.valueSeq()) {
        const bounds = figure.getBoundingBox();
        width = Math.max(width, bounds.getWidth() + bounds.getX());
        height = Math.max(height, bounds.getHeight() + bounds.getY());
    }

    // apply padding, to give a small border to the visible region
    width += 10;
    height += 10;

    return [ width, height ];
}

export function applyResizeDesignMode(prev, next, canvas) {
    const [ width, height ] = getCanvasDims(next.design);
    const [ prevWidth, prevHeight ] = getCanvasDims(prev && prev.design);

    const requiresUpdate = prev == null || prev.design == null
        || prevWidth !== width
        || prevHeight !== height;

    if(requiresUpdate) {
        // set the size of the canvas
        canvas.setSize(width, height);
    }
}

function getCanvasDims(design) {
    const canvasConfig = getCanvasConfig(design);
    return [
        getCanvasWidth(canvasConfig),
        getCanvasHeight(canvasConfig)
    ];
}

function applyCanvasGridChanges(prev, next, context, canvas) {
    const prevConfig = getCanvasConfig(prev && prev.design);
    const nextConfig = getCanvasConfig(next.design);

    const prevGridShowing = getCanvasGridShowing(prevConfig);
    const nextGridShowing = getCanvasGridShowing(nextConfig);
    const prevGridSize = getCanvasGridSize(prevConfig);
    const nextGridSize = getCanvasGridSize(nextConfig);
    const gridSize = toNumber(nextGridSize, null);

    if(prevGridShowing !== nextGridShowing
        || prevGridSize !== nextGridSize) {
        
        let editPolicy = context.get('editPolicy');
        if(nextGridShowing && gridSize != null) {
            if(!editPolicy) {
                editPolicy = canvas.createSnapToGridEditPolicy(gridSize);
                canvas.installEditPolicy(editPolicy);
                return context.set('editPolicy', editPolicy);

            } else {
                editPolicy.setGrid(gridSize);
            }
        } else {
            if(editPolicy) {
                canvas.uninstallEditPolicy(editPolicy);
                return context.delete('editPolicy');
            }
        }
    }

    return context;
}

function applyViewBoundsChanges(prev, next, context, canvas) {
    const prevView = prev && getFiguresView({ designer: prev.designer }, prev.designId);
    const nextView = next && getFiguresView({ designer: next.designer }, next.designId);
    const prevWidth = getViewWidth(prevView), prevHeight = getViewHeight(prevView);
    const nextWidth = getViewWidth(nextView), nextHeight = getViewHeight(nextView);

    if(prevWidth !== nextWidth || prevHeight !== nextHeight) {
        let editPolicy = context.get('viewBoundsEditPolicy');
        if(nextView == null) {
            if(editPolicy != null) {
                canvas.uninstallEditPolicy(editPolicy);
                return context.delete('viewBoundsEditPolicy');
            }

        } else {
            const viewBounds = { x: 0, y: 0, width: nextWidth, height: nextHeight };
            if(editPolicy != null) {
                editPolicy.setViewBounds(viewBounds);
            } else {
                editPolicy = new ShowViewBoundsEditPolicy(viewBounds);
                canvas.installEditPolicy(editPolicy);
                return context.set('viewBoundsEditPolicy', editPolicy);
            }
        }
    }
    return context;
}
