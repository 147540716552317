import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';

import { connect } from 'react-redux';

function StoreData(props) {
    if (Array.isArray(props.children)) {
        return props.children.map((c) => React.cloneElement(c, props[c.key]))
    } else if(props.children){
        return React.cloneElement(props.children, props)
    } else {
        return null
    }
}

StoreData.propTypes = {
    stateToProps: PropTypes.object.isRequired
}

/**
 * Converts a nested java object with keys and eventually path arrays into the 
 * same nested java object but with the arrays resolved from the state
 * 
 * Example:
 *   State: {key1: {key2: 'Value'}}
 *   StateToProps: {a: {b: ['key1', 'key2']}}
 *   Result: {a: {b: 'Value'}}
 */
function convertStateToProps(state, stateToProps) {
    let result = {}
    for(const key in stateToProps) {
        const value = stateToProps[key]
        if (Array.isArray(value) ) {
            if (value.length === 1) {
                const v = value[0];
                if (Array.isArray(v) || List.isList(v)) {
                    result[key] = state.getIn(v);
                } else {
                    result[key] = state.get(v);
                }
                continue
            }
            const [head, ...tail] = value;
            if (Map.isMap(state)) {
                result[key] = state.getIn(value)
            } else {
                result[key] = state[head].getIn(tail)
            }
        } else {
            result[key] = convertStateToProps(state, value)
        }

        
    }
    return result
}

export default connect(
    (state, props) => convertStateToProps(state, props.stateToProps)
)(StoreData)
