import { AuthToken } from "./authTypes";
import { useState, useEffect } from "react";
import { authTokenHolder } from "./authUtils";

/**
 * Returns the current auth-token
 */

export function useAuthToken(): AuthToken | null {
    const [token, setToken] = useState(() => authTokenHolder.getToken());

    // listen to the auth-token holder for events
    useEffect(() => {
        const listener = authTokenHolder.addListener(setToken);
        return () => void authTokenHolder.removeListener(listener);
    }, []);

    return token;
}
