import { getSelectedSecondaryFigureIds, getSelectedPrimaryFigureId } from './selector/selectionSelectors';

export function isCanvasSelectionUnchanged(primaryId, secondaryIds, selection) {
    const primary = getSelectedPrimaryFigureId(selection);
    const secondary = getSelectedSecondaryFigureIds(selection);
    return primaryId === primary
        && (secondary == null
            ? !secondaryIds.length
            : secondaryIds.every((secondaryId) => secondary.findIndex((x) => x === secondaryId) > -1));
}
