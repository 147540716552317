import { combineReducers } from 'redux';

import { StoreStateCommon } from './types';
import locationReducers from './components/location/reducers';
import requestReducers from './components/fetcher/reducers';
import translationReducers from './components/translation/translation-reducers';

export default combineReducers<StoreStateCommon>({
    location: locationReducers,
    requests: requestReducers,
    translation: translationReducers
});
