import { Map } from 'immutable';
import { getChildMapping } from '../../helper/selector/canvasFigureConfigSelectors';
import { createTimer } from '../../helper/perfMonitor';

import applySelectionChanges from './applySelectionChanges';
import applyCanvasChanges from './applyCanvasChanges';
import CanvasMethods from './CanvasMethods';

const canvasSyncTimer = createTimer('canvas-sync');

/**
 * Applies changes to the draw2D canvas, based on what changed in the store.
 */
export default class FiguresSync {

    applyChanges(draw2DCanvas, draw2dFigures, props) {
        if(!draw2dFigures || !draw2dFigures.size) return;

        const timer = canvasSyncTimer.start();
        this.synching = true;

        const nextDesign = props.design;
        const figuresConfig = nextDesign.get('figures-config');
        const figuresChildren = getChildMapping(figuresConfig);

        let ctx = this.context;
        if(ctx == null) {
            ctx = Map();
        }
        
        const next = {
            figures: draw2dFigures,
            figuresConfig,
            figuresChildren,
            ...props
        }

        ctx = applyCanvasChanges(this.prev, next, ctx, new CanvasMethods(draw2DCanvas));

        applySelectionChanges(this.prev, next, draw2DCanvas, draw2dFigures);
        
        this.context = ctx;
        this.synching = false;
        this.prev = next;
        timer.stop();
    }
}
