import { all, takeEvery, put } from "redux-saga/effects";
import { ActionType, InvokeRequestHandlerAction } from "./actions";
import { callRequestHandler } from "./helpers";
import { receiveCachedData, receiveCachedError } from "../fetcher/actions";

export default function() {
    return all([
        takeEvery(ActionType.INVOKE, handleInvokeRequestHandler)
    ]);
}

/**
 * @deprecated removed in version 4.7.0
 */

function *handleInvokeRequestHandler({ requestId, params }: InvokeRequestHandlerAction) {
    const [result, error] = yield* callRequestHandler(requestId, ...params);
    if (result) {
        yield put(receiveCachedData(requestId, result));
    } else {
        yield put(receiveCachedError(requestId, error));
    }
} 
