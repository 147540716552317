import React, { useMemo } from 'react';

import { FetcherState } from './fetcherTypes';
import { useFetcher } from '../../utils/hooks/useFetcher';

type OwnProps<T> = {
    onDataReceived?: (data: T) => void;
    onError?: (error: any) => void;
    updater: () => Promise<T>;
    render: (data: FetcherState<T>) => React.ReactElement;
}

/**
 * Component wrapper around the useFetcher hook
 */

export default function Fetcher<T>({ render, updater, onDataReceived, onError }: OwnProps<T>) {
    const data = useFetcher(updater, {
        onDataReceived,
        onError
    });

    return useMemo(() => render(data), [render, data]);
}
