import { useState, useEffect } from "react";
import { LocalParamAccessor } from "../LocalParamAccessor";

export function useLocalParam(param: LocalParamAccessor) {
    const [value, setValue] = useState(() => param.get());

    useEffect(() => {
        // listen for changes to the param
        return param.addChangedListener(() => setValue(param.get()));
    }, []);

    return value;
}
