import { Action } from "redux";

export const SET_TRANSLATIONS = 'core.translation.SET_TRANSLATIONS';

export function setTranslations(translations: any): SetTranslationsAction {
  return {
    type: SET_TRANSLATIONS,
    translations
  }
}

export interface SetTranslationsAction extends Action {
  translations: any
}
