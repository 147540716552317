import Imm from 'immutable';
import { resolveProperty } from './propertyResolver';

/**
 * Evaluates the alt expressions,
 * returning a merge of all configs that pass the condition
 */

export function resolveAltExpressions(altExpressions, evalContext) {
    return Imm.Map().withMutations((map) => {
        let i = 0;
        for(let altExpression of altExpressions) {
            let conditionPassed;
            if(i++ > 0) {
                const conditionExpr = altExpression.get('condition');
                if(conditionExpr) {
                    const conditionResult = resolveProperty('condition', conditionExpr, evalContext).value;
                    conditionPassed = isTruthy(conditionResult);
                } else {
                    conditionPassed = false;
                }
                
            } else {
                // main config always passes
                conditionPassed = true;
            }
    
            if(conditionPassed) {
                map.merge(altExpression);
                if(i > 1) break;
            }
        }
    });
}

function isTruthy(value) {
    if (value != null && Array.isArray(value)) {
        return value.length > 0 && !value.some((item) => !isTruthy(item));
    } else {
        return !!value;
    }
}
