import {List, Map} from 'immutable'
import { push } from 'react-router-redux'

const DesignerActionTypes = {
  SET_DESIGNER_MODE: 'core.designer.SET_DESIGNER_MODE',
  SET_SELECTED_DESIGN: 'core.designer.SET_SELECTED_DESIGN',
  FIELD_VALUE_UPDATED: 'core.designer.FIELD_VALUE_UPDATED',
  CREATE_FIGURE_ON_CANVAS: 'core.designer.CREATE_FIGURE_ON_CANVAS',
  CHANGE_FIGURE_CONFIG: 'core.designer.CHANGE_FIGURE_CONFIG',
  DESIGN_CREATED: 'core.designer.DESIGN_CREATED',
  CHANGE_FIGURES: 'core.designer.CHANGE_FIGURES',
  DELETE_FIGURES: 'core.designer.DELETE_FIGURES',
  SELECT_SECONDARY_FIGURE: 'core.designer.SELECT_SECONDARY_FIGURE',
  SELECT_FIGURE: 'core.designer.SELECT_FIGURE',
  DELETE_FIGURE_CONFIG: 'core.designer.DELETE_FIGURE_CONFIG',
  CLEAR_SELECTED_FIGURE: 'core.designer.CLEAR_SELECTED_FIGURE',
  EXECUTE_FIGURE_ACTION: 'core.designer.EXECUTE_FIGURE_ACTION',
  SET_DATASOURCE_RESULT: 'core.designer.SET_DATASOURCE_RESULT',
  MOUNT_SELECTOR: 'core.designer.MOUNT_SELECTOR',
  REMOVE_FIGURE_CONFIG: 'core.designer.REMOVE_FIGURE_CONFIG',
  ADD_FIGURE_CONFIG: 'core.designer.ADD_FIGURE_CONFIG',
  SHOW_FIGURE_CONFIG_DETAILS: 'core.designer.SHOW_FIGURE_CONFIG_DETAILS',
  CANCEL_FIGURE_CONFIG_DETAILS: 'core.designer.CANCEL_FIGURE_CONFIG_DETAILS',
  ACCEPT_FIGURE_CONFIG_DETAILS: 'core.designer.ACCEPT_FIGURE_CONFIG_DETAILS',
  MOVE_FIGURE_CONFIG_DOWN: 'core.designer.MOVE_FIGURE_CONFIG_DOWN',
  MOVE_FIGURE_CONFIG_UP: 'core.designer.MOVE_FIGURE_CONFIG_UP',
  INCLUDE_FIGURE_PROPERTY: 'core.designer.INCLUDE_FIGURE_PROPERTY',
  EXCLUDE_FIGURE_PROPERTY: 'core.designer.EXCLUDE_FIGURE_PROPERTY',
  DESIGN_SELECTION_HINTS_FETCH_STARTED: 'core.designer.DESIGN_SELECTION_HINTS_FETCH_STARTED',
  DESIGN_SELECTION_HINTS_FETCH_ERROR: 'core.designer.DESIGN_SELECTION_HINTS_FETCH_ERROR',
  DESIGN_SELECTION_HINTS_FETCH_DONE: 'core.designer.DESIGN_SELECTION_HINTS_FETCH_DONE',
  UPDATE_SELECTED_INPUT_FIELD_VALUE: 'core.designer.UPDATE_SELECTED_INPUT_FIELD_VALUE',
  UPDATE_SELECTED_DATA_FIELD_VALUE: 'core.designer.UPDATE_SELECTED_DATA_FIELD_VALUE',
  SET_CLONE_FIGURE_NAME: 'core.designer.SET_CLONE_FIGURE_NAME',
  CLONE_FIGURE_CONFIG: 'core.designer.CLONE_FIGURE_CONFIG',
  MOVE_FIGURE_Z_DOWN: 'core.designer.MOVE_FIGURE_Z_DOWN',
  MOVE_FIGURE_Z_UP: 'core.designer.MOVE_FIGURE_Z_UP',
  MOVE_FIGURE_Z_TO_BOTTOM: 'core.designer.MOVE_FIGURE_Z_TO_BOTTOM',
  MOVE_FIGURE_Z_TO_TOP: 'core.designer.MOVE_FIGURE_Z_TO_TOP',
  SET_CANVAS_CONTEXT_MENU_TOGGLED: 'core.designer.SET_CANVAS_CONTEXT_MENU_TOGGLED',
  ASSIGN_TO_PARENT: 'core.designer.ASSIGN_TO_PARENT',
  UNASSIGN_FROM_PARENT: 'core.designer.UNASSIGN_FROM_PARENT',
  COPY_FIGURE: 'core.designer.COPY_FIGURE',
  UPDATE_FIGURE_FILTER_TEXT: 'core.designer.UPDATE_FIGURE_FILTER_TEXT',
  SPLIT_PANE_RESIZED: 'core.designer.SPLIT_PANE_RESIZED',
  SET_FIGURE_NAME: 'core.designer.SET_FIGURE_NAME',
  SET_WINDOW_SIZE: 'core.designer.SET_WINDOW_SIZE',
  CANVAS_CONFIG_SAVE_CHANGES: 'core.designer.CANVAS_CONFIG_SAVE_CHANGES',
  TRIGGER_REFRESH_DATASOURCES: 'core.designer.TRIGGER_REFRESH_DATASOURCES',
  SET_DATASOURCES_LOADING: 'core.designer.SET_DATASOURCES_LOADING',
  PUSH_VIEW: 'core.designer.PUSH_VIEW',
  POP_VIEW: 'core.designer.POP_VIEW'
}

export default DesignerActionTypes

export function setDesignerMode(mode) {
  return {
    type: DesignerActionTypes.SET_DESIGNER_MODE,
    mode
  }
}

export function updateSelectedInputFieldValue(configPaths, fieldKey, value) {
  return { 
    type: DesignerActionTypes.UPDATE_SELECTED_INPUT_FIELD_VALUE,
    configPaths,
    fieldKey,
    value
  }
}

export function updateSelectedDataFieldValue(configPaths, fieldKey, value) {
  return {
    type: DesignerActionTypes.UPDATE_SELECTED_DATA_FIELD_VALUE,
    configPaths,
    fieldKey,
    value
  }
}

export function includeFigureProperty(configPath, propertyKey) {
  return {
    type: DesignerActionTypes.INCLUDE_FIGURE_PROPERTY,
    configPath, propertyKey
  }
}

export function excludeFigureProperty(configPath, propertyKey) {
  return {
    type: DesignerActionTypes.EXCLUDE_FIGURE_PROPERTY,
    configPath, propertyKey
  }
}

export function showFigureConfigDetails(designId, configKey) {
  return {
    type: DesignerActionTypes.SHOW_FIGURE_CONFIG_DETAILS,
    designId,
    configKey
  }
}

export function cancelFigureConfigDetails(designId) {
  return {
    type: DesignerActionTypes.CANCEL_FIGURE_CONFIG_DETAILS,
    designId
  }
}

export function acceptFigureConfigDetails(designId, actions) {
  return {
    type: DesignerActionTypes.ACCEPT_FIGURE_CONFIG_DETAILS,
    designId,
    actions
  }
}

export function removeFigureConfig(configPath) {
  return {
    type: DesignerActionTypes.REMOVE_FIGURE_CONFIG,
    configPath
  }
}

export function addFigureConfig(figureIdentifier) {
  return {
    type: DesignerActionTypes.ADD_FIGURE_CONFIG,
    figureIdentifier
  }
}

export function moveFigureConfigDown(configPath) {
  return {
    type: DesignerActionTypes.MOVE_FIGURE_CONFIG_DOWN,
    configPath
  }
}

export function moveFigureConfigUp(configPath) {
  return {
    type: DesignerActionTypes.MOVE_FIGURE_CONFIG_UP,
    configPath
  }
}

export function mountSelector(formId, selectorPath, resultPath, fieldNames) {
  return {
    type: DesignerActionTypes.MOUNT_SELECTOR,
    formId, selectorPath, resultPath, fieldNames
  }
}

export function fieldValueUpdated(path, value) {
  return {
    type: DesignerActionTypes.FIELD_VALUE_UPDATED,
    path,
    value
  }
}

export function fetchDesignSelectionHints(designIdInput) {
  const requestBody = Map().set('designIdInput', designIdInput)
  return (dispatch, getState) => {
    dispatch({
      type: DesignerActionTypes.DESIGN_SELECTION_HINTS_FETCH_DONE,
      request: requestBody,
      result: List(getState().designer.get('designs').keys()).map((k) => Map({designId: k}))
    })
  }
}

export function createDesign(designId, designContent) {
  return (dispatch, getState) => {
    if (designContent === undefined) {
      designContent = getState().designer.get('design-base')
    }
    dispatch({
      type: DesignerActionTypes.DESIGN_CREATED,
      designId, designContent
    })
  }
}

export function executeFigureAction(designId, figureId, actionCode) {
  return {
    type: DesignerActionTypes.EXECUTE_FIGURE_ACTION,
    designId,
    figureId,
    actionCode
  }
}

export function setDataSourceResult(designId, datasourceKey, datasourceResult) {
  return {
    type: DesignerActionTypes.SET_DATASOURCE_RESULT,
    designId,
    datasourceKey,
    datasourceResult
  }
}

export function updateFigureFilterText(text) {
  return { 
    type: DesignerActionTypes.UPDATE_FIGURE_FILTER_TEXT,
    text
  }
}

export function createFigureOnCanvas(designId, figure) {
  return {
    type: DesignerActionTypes.CREATE_FIGURE_ON_CANVAS,
    designId,
    figure
  }
}

export function clearSelectedFigure(designId) {
  return { 
    type: DesignerActionTypes.CLEAR_SELECTED_FIGURE,
    designId
  }
}

/**
 * @param {string} designId
 * @param {string} figureId 
 * @param {Iterable<string>} secondaryIds 
 * @param {string} figureType 
 */
export function selectFigure(designId, primary, secondary, figureType) {
  return {
    type: DesignerActionTypes.SELECT_FIGURE,
    designId,
    primary,
    secondary,
    figureType
  }
}

export function selectSecondaryFigure(designId, figureId, figureType) {
  return {
    type: DesignerActionTypes.SELECT_SECONDARY_FIGURE,
    designId,
    figureId,
    figureType
  }
}

export function deleteFigureConfig(figureIdentifier) {
  return {
    type: DesignerActionTypes.DELETE_FIGURE_CONFIG,
    figureIdentifier
  }
}


export function changeFigureConfig(dataPath, configDiffs) {
  return {
    type: DesignerActionTypes.CHANGE_FIGURE_CONFIG,
    dataPath,
    configDiffs
  }
}

export function changeFigures(designId, figuresDiffs) {
  return {
    type: DesignerActionTypes.CHANGE_FIGURES,
    designId,
    figuresDiffs
  }
}

export function deleteFigures(designId, figureIds) {
  return {
    type: DesignerActionTypes.DELETE_FIGURES,
    designId,
    figureIds
  }
}

export function setCloneFigureNameValue(cloneFigureName) {
  return { 
    type: DesignerActionTypes.SET_CLONE_FIGURE_NAME,
    cloneFigureName
  }
}

export function cloneFigureConfig(designId, figureConfig, newFigureName) { 
  return {
    type: DesignerActionTypes.CLONE_FIGURE_CONFIG,
    designId,
    figureConfig,
    newFigureName
  }
}

export function moveFigureZDown(figureId, designId) {
  return {
    type: DesignerActionTypes.MOVE_FIGURE_Z_DOWN,
    figureId,
    designId
  }
}

export function moveFigureZUp(figureId, designId) {
  return { 
    type: DesignerActionTypes.MOVE_FIGURE_Z_UP,
    figureId,
    designId
  }
}

export function moveFigureZToBottom(figureId, designId) {
  return {
    type: DesignerActionTypes.MOVE_FIGURE_Z_TO_BOTTOM,
    figureId,
    designId
  }
}

export function moveFigureZToTop(figureId, designId) {
  return {
    type: DesignerActionTypes.MOVE_FIGURE_Z_TO_TOP,
    figureId,
    designId
  }
}

export function setCanvasContextMenuToggled(isToggled, position) {
  return {
    type: DesignerActionTypes.SET_CANVAS_CONTEXT_MENU_TOGGLED,
    isToggled,
    position
  }
}


export function assignToParent(designId, parentId, figureIds) {
  return {
    type: DesignerActionTypes.ASSIGN_TO_PARENT,
    designId,
    parentId,
    figureIds
  }
}

export function unassignFromParent(designId, primaryId, secondaryIds) {
  return {
    type: DesignerActionTypes.UNASSIGN_FROM_PARENT,
    designId,
    primaryId,
    secondaryIds
  }
}

export function copyFigure() {
  return { 
    type: DesignerActionTypes.COPY_FIGURE
  }
}

export function splitPaneResized(pane, size) {
  return {
    type: DesignerActionTypes.SPLIT_PANE_RESIZED,
    pane,
    size
  }
}

export function redirectToRootPage() {
  return push('/'); 
}

export function setFigureName(figureIdentifier, value) {
  return {
    type: DesignerActionTypes.SET_FIGURE_NAME,
    figureIdentifier,
    value
  }
}

export function setWindowSize(width, height, forceUpdate) {
  return {
    type: DesignerActionTypes.SET_WINDOW_SIZE,
    width,
    height,
    forceUpdate
  };
}

export function canvasConfigSaveChanges(designId, formResult) {
  return {
    type: DesignerActionTypes.CANVAS_CONFIG_SAVE_CHANGES,
    designId,
    formResult
  }
}

export function setSelectedDesign(designId, params) {
  return {
    type: DesignerActionTypes.SET_SELECTED_DESIGN,
    designId,
    params
  }
}

export function triggerRefreshDatasources() {
  return {
    type: DesignerActionTypes.TRIGGER_REFRESH_DATASOURCES
  }
}

export function setDatasourcesLoading(loading) {
  return {
    type: DesignerActionTypes.SET_DATASOURCES_LOADING, loading
  }
}

export function pushView(designId, figureId, width, height) {
  return {
    type: DesignerActionTypes.PUSH_VIEW, designId, figureId, width, height
  }
}

export function popView(designId, viewId) {
  return {
    type: DesignerActionTypes.POP_VIEW, designId, viewId
  }
}
