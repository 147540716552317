import { createSelector } from 'reselect';
import { List } from 'immutable';
import { getDesigner, getCurrentDesignId } from './selectors';
import { createFigureParentsMapping, parentsToRelativePath } from './util/createFigureParentsSelector';
import { createFigureChildrenSelector } from './util/createFigureChildrenSelector';
import { createFolder } from '../../datarequest/helper/FigureDescriptorsRequestHelper';

export const baseFigureDescriptorsPath = List(['base-figure-descriptors']);

export function getCustomFiguresBasePathPath(designId) {
    return List(['designs', designId, 'figure-descriptors']);
}


export const getBaseFigureDescriptors = createSelector(getDesigner,
    (designer) => designer && designer.getIn(baseFigureDescriptorsPath));
    
export const getCustomFigureDescriptors = createSelector(getDesigner, getCurrentDesignId,
    (designer, designId) => designer && designId && designer.getIn(getCustomFiguresBasePathPath(designId)));

export const getBaseFigureDescriptorsFolder = createSelector(getBaseFigureDescriptors, (figureDescriptors) => createFolder('Base', '_base_', figureDescriptors));

export const getCustomFigureDescriptorsFolder = createSelector(getCustomFigureDescriptors, (figureDescriptors) => createFolder('Custom', '_custom_', figureDescriptors));

export function getFigureDescriptorId(figureDescriptor) {
    return figureDescriptor && figureDescriptor.get('id');
}

export const getCustomFigureChildrenMapping = createSelector(getCustomFigureDescriptors, createFigureChildrenSelector(getFigureDescriptorId));

export const getBaseFigureChildrenMapping = createSelector(getBaseFigureDescriptors, createFigureChildrenSelector(getFigureDescriptorId));

export const getCustomFigureParentsMapping = createSelector(getCustomFigureChildrenMapping, createFigureParentsMapping());

export const getBaseFigureParentsMapping = createSelector(getBaseFigureChildrenMapping, createFigureParentsMapping());

export const customFigureSelector = createSelector(
    getCustomFigureDescriptors,
    getCustomFigureParentsMapping,
    (figures, parentsMapping) => (figureId) => {
        const parents = parentsMapping.get(figureId);
        const relativePath = parentsToRelativePath(parents);
        return figures.getIn(relativePath);
    }
);

export const baseFigureSelector = createSelector(
    getBaseFigureDescriptors,
    getBaseFigureParentsMapping,
    (figures, parentsMapping) => (figureId) => {
        const parents = parentsMapping.get(figureId);
        const relativePath = parentsToRelativePath(parents);
        return figures.getIn(relativePath);
    }
);
