import { List, Map, Iterable, fromJS } from 'immutable';
import { createFigureHeirarchyLookup } from './propertyHeirarchy';
import { BoolValidator, 
  ColorValidator, 
  StringValidator, 
  NumberValidator,
  ImagePathValidator,
  DrilldownValidator,
  PdfUrlValidator,
  AnyValidator } from './validator';

export const STATIC_TYPE = 'const'
export const DYNAMIC_TYPE_EVAL = 'eval'
export const DYNAMIC_TYPE_TEMPLATE = 'template'

const figureHeirarchyLookup = createFigureHeirarchyLookup();

const GROUP_LAYOUT = 'layout';
const GROUP_APPEARANCE = 'appearance';
const GROUP_TEXT = 'text';
const GROUP_ACTIONS = 'actions';
const GROUP_IMAGE = 'image';
const GROUP_PDF = 'pdf';

export const GROUPS = Map()
  .set(GROUP_LAYOUT, Map({ id: GROUP_LAYOUT, display: 'Layout', order: 0 }))
  .set(GROUP_TEXT, Map({ id: GROUP_TEXT, display: 'Text', order: 1 }))
  .set(GROUP_IMAGE, Map({ id: GROUP_IMAGE, display: 'Image', order: 2 }))
  .set(GROUP_PDF, Map({ id: GROUP_PDF, display: 'PDF', order: 3 }))
  .set(GROUP_APPEARANCE, Map({ id: GROUP_APPEARANCE, display: 'Appearance', order: 4 }))
  .set(GROUP_ACTIONS, Map({ id: GROUP_ACTIONS, display: 'Actions', order: 5 }));

export const CONFIGURABLE_FIGURE_PROPS = fromJS([
  { 
    key: 'x',
    order: 0,
    group: GROUP_LAYOUT,
    validator: new NumberValidator(),
    evaltype: STATIC_TYPE,
    description: 'Use to set the x coordinate of the figure. Higher x values make the figure appear more to the right on the canvas.'
  },
  {
    key: 'x=',
    order: 1,
    group: null,
    validator: new StringValidator(),
    evaltype: DYNAMIC_TYPE_EVAL,
    into: 'x',
    description: 'Calculated version of the \'x\' property'
  },
  {
    key: 'y',
    order: 2,
    group: GROUP_LAYOUT,
    validator: new NumberValidator(),
    evaltype: STATIC_TYPE,
    description: 'Use to set the y coordinate of the figure. Higher y values make the figure appear lower on the canvas.'
  },
  {
    key: 'y=',
    order: 3,
    group: null,
    validator: new StringValidator(),
    evaltype: DYNAMIC_TYPE_EVAL,
    into: 'y',
    description: 'Calculated version of the \'y\' property'
  },
  {
    key: 'width',
    order: 4,
    group: GROUP_LAYOUT,
    validator: new NumberValidator({ min: 0, defaultValue: null }),
    evaltype: STATIC_TYPE,
    description: 'Use to set the width of the figure'
  },
  {
    key: 'width=',
    order: 5,
    group: null,
    validator: new StringValidator(),
    evaltype: DYNAMIC_TYPE_EVAL,
    into: 'witdh',
    description: 'Calculated version of the \'width\' property'
  },
  {
    key: 'height',
    order: 6,
    group: GROUP_LAYOUT,
    validator: new NumberValidator({ min: 0, defaultValue: null }),
    evaltype: STATIC_TYPE,
    description: 'Use to set the height of the figure'
  },
  {
    key: 'height=',
    order: 7,
    group: null,
    validator: new StringValidator(),
    evaltype: DYNAMIC_TYPE_EVAL,
    into: 'height',
    description: 'Calculated version of the \'height\' property'
  },
  {
    key: 'color',
    order: 8,
    group: GROUP_APPEARANCE,
    validator: new ColorValidator(),
    evaltype: STATIC_TYPE,
    description: 'Defines the foreground color used to paint the figure'
  },
  {
    key: 'color=',
    order: 9,
    group: null,
    validator: new ColorValidator(),
    evaltype: DYNAMIC_TYPE_EVAL,
    description: 'Calculated version of the \'color\' property'
  },
  {
    key: 'bgColor',
    order: 10,
    group: GROUP_APPEARANCE,
    validator: new ColorValidator(),
    evaltype: STATIC_TYPE,
    description: 'Defines the background color used to paint the figure'
  },
  {
    key: 'bgColor=',
    order: 11,
    group: null,
    validator: new StringValidator(),
    evaltype: DYNAMIC_TYPE_EVAL,
    description: 'Calculated version of the \'bgColor\' property'
  },
  {
    key: 'cssClass',
    order: 12,
    group: GROUP_APPEARANCE,
    validator: new StringValidator({ minLength: 1 }),
    evaltype: STATIC_TYPE,
    description: 'Defines a cssClass to apply to the figure element. Additional styles for the SVG element can be configured this way.'
  },
  {
    key: 'alpha',
    order: 13,
    group: GROUP_APPEARANCE,
    validator: new NumberValidator({ min: 0, max: 1 }),
    evaltype: STATIC_TYPE,
    description: 'Defines the alpha blending for the figure. Value between 0 and 1. 0 is completely see through, 1 is completely opaque.'
  },
  {
    key: 'angle',
    order: 14,
    group: GROUP_APPEARANCE,
    validator: new NumberValidator({ min: 0 }),
    evaltype: STATIC_TYPE,
    description: 'Defines the rotation at which to show the figure'
  },
  {
    key: 'stroke',
    order: 15,
    group: GROUP_APPEARANCE,
    validator: new NumberValidator({ min: 0 }),
    evaltype: STATIC_TYPE,
    description: 'Defines the widths of the borders'
  },
  {
    key: 'radius',
    order: 16,
    group: GROUP_APPEARANCE,
    validator: new NumberValidator({ min: 0 }),
    evaltype: STATIC_TYPE,
    description: 'Defines the roundness of the corners'
  },
  {
    key: 'onDblClickAction',
    order: 17,
    group: GROUP_ACTIONS,
    validator: new StringValidator({ minLength: 1 }),
    alias: 'onDoubleClick',
    label: 'DblClick',
    evaltype: STATIC_TYPE,
    description: 'Configure an action to be executed when double clicking the figure'
  },
  {
    key: 'text',
    order: 18,
    group: GROUP_TEXT,
    validator: new StringValidator({ minLength: 1 }),
    evaltype: STATIC_TYPE,
    description: 'Configure the text to display in a label figure'
  },
  {
    key: 'bold',
    order: 19,
    group: GROUP_TEXT,
    validator: new BoolValidator(),
    evalType: STATIC_TYPE,
    description: 'indicator if bold text should be used'
  },
  {
    key: 'dash',
    order: 20,
    group: GROUP_APPEARANCE,
    validator: new StringValidator(),
    description: 'The dot/dash pattern for the line style'
  },
  {
    key: 'text-template',
    order: 21,
    group: null,
    validator: new StringValidator({ minLength: 1 }),
    evaltype: DYNAMIC_TYPE_TEMPLATE,
    into: 'text',
    description: 'Use to create a message containing datasource result values. Example: \'The quantity: ${ds1.quantity}\''
  },
  {
    key: 'visible',
    order: 22,
    group: GROUP_APPEARANCE,
    validator: new BoolValidator({ defaultValue: true }),
    evaltype: STATIC_TYPE,
    description: 'Hides the figure when false'
  },
  {
    key: 'visible=',
    order: 23,
    group: null,
    validator: new StringValidator(),
    evaltype: DYNAMIC_TYPE_EVAL,
    into: 'visible',
    description: 'Calculated version of the \'visible\' property'
  },
  {
    key: 'path',
    order: 24,
    group: GROUP_IMAGE,
    validator: new ImagePathValidator(),
    evalType: STATIC_TYPE,
    description: 'Path of the image to use'
  },
  {
    key: 'anchor-x',
    order: 25,
    group: GROUP_LAYOUT,
    validator: new NumberValidator({ min: 0, max: 1 }),
    evalType: STATIC_TYPE,
    description: '"Aligns" the x position, relative to the width of the figure. 0 = align left, 0.5 = align center, 1 = align right.'
  },
  {
    key: 'anchor-y',
    order: 26,
    group: GROUP_LAYOUT,
    validator: new NumberValidator({ min: 0, max: 1 }),
    evalType: STATIC_TYPE,
    description: '"Aligns" the y position, relative to the height of the figure. 0 = align top, 0.5 = align center, 1 = align bottom.'
  },
  { 
    key: 'parent-name',
    order: 27,
    group: GROUP_LAYOUT,
    validator: new StringValidator(),
    evaltype: STATIC_TYPE,
    description: 'Set the parent of a figure dynamically (via name).'
  },
  {
    key: 'fontColor',
    order: 28,
    group: GROUP_TEXT,
    validator: new ColorValidator({ defaultValue: '#000000' }),
    evalType: STATIC_TYPE,
    description: 'Color of the text'
  },
  {
    key: 'fontSize',
    order: 29,
    group: GROUP_TEXT,
    validator: new NumberValidator({ min: 0 }),
    evalType: STATIC_TYPE,
    description: 'Size of the font'
  },
  {
    key: 'drilldown',
    order: 30,
    group: GROUP_ACTIONS,
    validator: new DrilldownValidator(),
    evalType: STATIC_TYPE,
    description: 'Design to open when the figure is clicked'
  },
  {
    key: 'repeat',
    order: 31,
    group: GROUP_LAYOUT,
    evalType: STATIC_TYPE,
    description: 'Number of times to repeat the component',
    validator: new AnyValidator({ required: true })
  },
  {
    key: 'url',
    order: 32,
    group: GROUP_PDF,
    evalType: STATIC_TYPE,
    description: 'Location of the PDF to load',
    validator: new PdfUrlValidator({ minLength: 1 })
  },
  {
    key: 'page',
    order: 33,
    group: GROUP_PDF,
    evalType: STATIC_TYPE,
    description: 'Page of the PDF to show',
    validator: new NumberValidator({ min: 1 })
  }
])

export const DYNAMIC_PROPS = CONFIGURABLE_FIGURE_PROPS.reduce((r, prop) => {
  switch (prop.get('evaltype', STATIC_TYPE)) {
    case DYNAMIC_TYPE_EVAL:
    case DYNAMIC_TYPE_TEMPLATE: return r.set(prop.get('key'),
      Map({into: prop.get('into'), type: prop.get('evaltype')}))
    default: return r
  }}, Map())

export function diffSet(key, value) {
  return Map().set('type', '+')
              .set('key', key)
              .set('value', value)
}

export function diffUnset(key) {
  return Map().set('type', '-')
              .set('key', key)
}

export function applyDiffs(initialProps, diffs) {
  let props = initialProps
  diffs.forEach((d) => {
    const k = d.get('key')
    const isPut = ('+' == d.get('type'))
    const v = d.get('value')
    if (Array.isArray(k) || Iterable.isIterable(k)) {
      if (isPut) {
        props = props.setIn(k, v)
      } else {
        props = props.deleteIn(k)
      }
    } else {
      if (isPut) {
        props = props.set(k, v)
      } else {
        props = props.delete(k)
      }
    }
  })
  return props
}

// lookup: key -> figure-prop
const configurableFigurePropsKeyLookup = CONFIGURABLE_FIGURE_PROPS.reduce(
  (m, figureProp) => m.set(figureProp.get('key'), figureProp), Map());

export function getAvailableFigureProps(figureType) {
  const fields = figureHeirarchyLookup.get(figureType);
  if(!fields) {
    return List();
  }

  return fields
    .map((f) => configurableFigurePropsKeyLookup.get(f))
    .filter((x) => x != null);
}

export function fieldFromFigureKey(figureKey) {
  return configurableFigurePropsKeyLookup.get(figureKey);
}

export function getDefaultValue(figureKey, def=null) {
  const field = configurableFigurePropsKeyLookup.get(figureKey);
  if(field) {
    const validator = field.get('validator');
    return validator ? validator.defaultValue : def;
  }
  return def;
}

export function tagDesignWithCurrVersion(design) {
  // eslint-disable-next-line
  return design.set('_designerVersion', __DESIGNER_VERSION__);
}
