import React from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { showModal } from '../../../../component-lib/actions';
import { fieldInputPropTypes } from '../../models';

/**
 * Input field for a reportTransform-type datasource parameter
 */

function FieldInputReportTransform(props) {
    const value = props.input.value;
    const displayLabel = getDisplayLabel(value);
    return <div className="field-input-report-transform">
        <span className="ellipsis">{ displayLabel }</span>
        <FontAwesome className="field-input-icon-btn" name="pencil" onClick={() => props.showModal('designer-modal', getModalParams(props))}/>
    </div>
}

function getDisplayLabel(value) {
    if(!value || !value.type) return 'TRANSFORM';
    let displayLabel = value.type;
    if(value.params) {
        let ps = [];
        for(let k in value.params) {
            ps.push(value.params[k]);
        }
        displayLabel += ' (' + ps.join(', ') + ')';
    }
    return displayLabel;
}

// show a modal to edit the transform
function getModalParams(props) {
    const formState = props.fieldState['form-state'];
    const reportName = formState && formState.get('reportName');
    const onModalConfirmed = onModalConfirmedHandler(props.input);
    return fromJS({
        title: 'Edit Transform',
        body: {
            componentId: 'CONTENT_EDIT_REPORT_TRANSFORM',
            params: { reportName }
        },
        values: props.input.value,
        actions: [
            { label: 'Confirm', style: 'success', type: 'submit', action: onModalConfirmed }
        ]   
    });
}

function onModalConfirmedHandler(input) {
    return (values) => values && input.onChange(values);
}

FieldInputReportTransform.propTypes = {
    ...fieldInputPropTypes,
    input: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired
}

export default connect(
    null,
    { showModal }
)(FieldInputReportTransform);
