
/* global draw2d */

// custom edit policy that disables figure
// editing/selection in view mode, but still triggers some user events.

export default draw2d.policy.canvas.ReadOnlySelectionPolicy.extend({

    NAME: 'draw2d.policy.canvas.CustomReadOnlySelectionPolicy',
    
    onDoubleClick(figure, mouseX, mouseY, shiftKey, ctrlKey) {
        if(figure != null){
            figure.fireEvent('dblclick', {x:mouseX, y:mouseY, shiftKey, ctrlKey});
            figure.onDoubleClick();
        }
    }
});
