import Imm from 'immutable';
import { createSelector } from 'reselect';
import { getFigureId, getFigureChildren } from '../figureConfigHelper';
import { getFigureConfigs, getAllComputedValues } from './canvasFigureConfigSelectors';
import { createFigureChildrenSelector } from './util/createFigureChildrenSelector';
import { createFigureParentsMapping } from './util/createFigureParentsSelector';
import { createImmEqualsSelector } from '../reselectHelper';
import { getCanvasFigureIdsOrdered } from '../../components/utils/figuresOrdering';

/**
 * Selectors related to the view stack.
 * 
 * Current view filters which figures will be shown on the canvas. 
 */


// the view stack
export const getViewStack = (state, designId) => state.designer.getIn(['viewStack', designId]);

export const getViewStackFiguresView = (viewStack) => viewStack && viewStack.size
    ? viewStack.get(viewStack.size - 1) : undefined;

// the current figure view
export const getFiguresView = createSelector(getViewStack, getViewStackFiguresView)

// the id of the current view
export const getViewId = createSelector(getFiguresView, (view) => view && view.get('viewId'));

export const getViewWidth = (view) => view && view.get('width');

export const getViewHeight = (view) => view && view.get('height');

// all figure configs within the current view
export const getViewFiguresConfig = createSelector(
    getFigureConfigs,
    getFiguresView,
    (figureConfigs, view) => {
        if(!figureConfigs || !view) return figureConfigs;
        
        // search for the matching figure config, and include only its children
        // on the canvas
        return view.get('rootPath').reduce((acc, figureId) => {
            for(let fig of acc) {
                 if(getFigureId(fig) === figureId) return getFigureChildren(fig);
            }
            throw 'Figure not found with id - ' + figureId;
        }, figureConfigs);
    });

// figure config id -> child mapping (filtered for the current view)
export const getViewFiguresChildren = createSelector(getViewFiguresConfig,
    createFigureChildrenSelector(getFigureId));

// figure config id -> parent mapping (filtered for the current view)
export const getViewParentMapping = createSelector(getViewFiguresChildren,
    createFigureParentsMapping(getFigureId));
    
// canvas figure ordering (filtered for the current view)
export const getViewCanvasFigureIdsOrdered = createImmEqualsSelector(
        getAllComputedValues,
        getViewFiguresChildren,
        getCanvasFigureIdsOrdered)

// all canvas figure ids in the current view
export const getViewCanvasFigureIds = createSelector(getViewCanvasFigureIdsOrdered,
    (figureIds) => figureIds && Imm.Set(figureIds));
