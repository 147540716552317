import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import Selector from '../../../../common/view/components/Selector';
import { getDatasourceDefinition } from '../../helper/selector/datasourceSelectors';
import { getCurrentDesignId, isViewMode } from '../../helper/selector/selectors'; 
import { mountSelector } from '../../actions/DesignerActions';
import { clearDatasource } from '../../../../common/view/actions/DatasourceActions'
import { removeDatasourceFromDesign, fetchDatasourceDefinition } from '../../datarequest/actions';
import { fieldsFromDatasourceDefinition } from '../../../../util/field';

class DesignerSelectorConfig extends React.Component {

    constructor() {
        super();
        this.state = { fields: undefined };
    }

    UNSAFE_componentWillMount() {
        // fetch datasource definition
        if(!this.props.datasourceDefinition) {
            const datasourceName = this.props.datasourceConfig.get('datasource').toUpperCase();
            this.props.fetchDatasourceDefinition(datasourceName);

        } else {
            // definition is already available
            // (e.g. because another selector with same datasource)
            this.mountSelector(this.props);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // mount selector once we've finished receiving definition
        if(!this.props.datasourceDefinition && nextProps.datasourceDefinition) {
            this.mountSelector(nextProps);
        }
    }

    mountSelector(props) {
        const formId = 'designer-selector-' + props.datasourceConfig.get('id');
        const fields = fieldsFromDatasourceDefinition(props.datasourceDefinition);

        // avoid recalculating
        this.setState({ fields });

        const configPath = getConfigPath(props.designId, props.selectorIdx);
        const resultPath = getResultPath(props.designId, props.selectorId);

        props.mountSelector(
            formId,
            configPath.push('fieldValues'),
            resultPath,
            this.getFieldNames(fields))
    }

    getFieldNames(fields) {
        return fields.map((field) => field.get('name'));
    }

    getSelectorProps() {
        const { datasourceDefinition, datasourceConfig } = this.props;
        const fields = this.state.fields;
        return {
            formId: 'designer-selector-' + datasourceConfig.get('id'),
            layout: Map({ columns: 1 }), // TODO: hard-coded
            fields,
            datasourceDefinition,
            initialValues: datasourceConfig.get('fieldValues')
        }
    }

    componentWillUnmount() {
        // save form state in design?
    }

    handleDatasourceRemoved() {
        const datasourceId = this.props.datasourceConfig.get('id');
        this.props.removeDatasourceFromDesign(this.props.designId, datasourceId);
        this.props.clearDatasource(datasourceId);
    }

    render() {
        const label = this.props.datasourceConfig.get('label');
        const id = this.props.datasourceConfig.get('id');
        
        return <div className="selector-config">
            { !this.props.viewMode &&
                <div className="datasource-remove" onClick={this.handleDatasourceRemoved.bind(this)}>x</div> }
            <div>
                <label className="ellipsis">{ `${label} [${id}]` }</label>
            </div>
            { this.state.fields &&
                <Selector {...this.getSelectorProps()} /> 
            }
        </div>
    }
}

function getConfigPath(designId, selectorIdx) {
    return List.of('designs', designId, 'selector-configs', selectorIdx);
}

function getResultPath(designId, selectorId) {
    return List.of('designs', designId, 'datasource-results', selectorId);
}

DesignerSelectorConfig.propTypes = {
    selectorIdx: PropTypes.number.isRequired,
    selectorId: PropTypes.string.isRequired,
    datasourceConfig: ImmutablePropTypes.map.isRequired,
    
    // connect
    viewMode: PropTypes.bool,
    designId: PropTypes.string.isRequired,
    datasourceDefinition: ImmutablePropTypes.map,
    mountSelector: PropTypes.func.isRequired,
    fetchDatasourceDefinition: PropTypes.func.isRequired,
    removeDatasourceFromDesign: PropTypes.func.isRequired,
    clearDatasource: PropTypes.func.isRequired
}

export default connect(
    (state, { datasourceConfig }) => {
        const datasourceId = datasourceConfig
            .get('datasource').toUpperCase();

        return {
            viewMode: isViewMode(state),
            designId: getCurrentDesignId(state),
            datasourceDefinition: getDatasourceDefinition(state, datasourceId)    
        }
    },
    { removeDatasourceFromDesign,
        fetchDatasourceDefinition: fetchDatasourceDefinition.invokeSingle,
        mountSelector,
        clearDatasource }
)(DesignerSelectorConfig);
