const ActionTypes = {
    FIND_REPORT_DEFINITION_BY_NAME: 'core.designer.report.FIND_REPORT_DEFINITION_BY_NAME',
    RECEIVE_REPORT_DEFINITION: 'core.designer.report.RECEIVE_REPORT_DEFINITION',
    FETCH_REPORT_DEFINITION_ERROR: 'core.designer.report.FETCH_REPORT_DEFINITION_ERROR'
}

export default ActionTypes;

export function findReportDefinitionByName(reportName) {
    return {
        type: ActionTypes.FIND_REPORT_DEFINITION_BY_NAME,
        reportName
    }
}

export function receiveReportDefinition(reportName, result) {
    return {
        type: ActionTypes.RECEIVE_REPORT_DEFINITION,
        reportName,
        result
    }
}

export function fetchReportDefinitionError(reportName, error) {
    return {
        type: ActionTypes.FETCH_REPORT_DEFINITION_ERROR,
        reportName,
        error
    }
}
