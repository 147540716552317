import { Permission } from "../../types";
import { WebParam } from "../../utils/WebParam";
import { AuthTokenHolder } from "./AuthTokenHolder";

export const authTokenHolder = new AuthTokenHolder();

export const KEY_LANGUAGE = 'language';
export const languageParam = new WebParam(KEY_LANGUAGE);

export interface UserDetails {
    language: string,
    token: string
}

export function isAuthenticated() {
    const authToken = authTokenHolder.getToken();
    return authToken != null;
}

export function authenticate(userDetails: UserDetails) {
    const { language, token } = userDetails;
    authTokenHolder.setJwt(token);
    languageParam.set(language);
}

export function clearAuthentication() {
    authTokenHolder.setToken(null);
    languageParam.clear();
}

export function hasPermission(perm: Permission): boolean {
    const token = authTokenHolder.getToken();
    return token ? token.payload.permissions.has(perm) : false;
}

/**
 * @deprecated
 * 
 * use authTokenHolder.getPayload()!.loginName
 */
export function getUsername(): string {
    const payload = authTokenHolder.getPayload();
    if (payload == null) throw new Error('Cannot get login-name. Auth-token not present');
    return payload.loginName;
}

/**
 * @deprecated
 * 
 * use languageParam.get()
 */
export function getLanguage(): string {
    const lang = languageParam.get();
    if (lang == null) throw new Error('language not set in local-storage');
    return lang;
}

/**
 * @deprecated
 * 
 * use authTokenHolder.getPayload()!.realm
 */
export function getRealmNo(): string {
    const payload = authTokenHolder.getPayload();
    if (payload == null) throw new Error('Cannot get current realm. Auth-token not present');
    return payload.realm;
}

/**
 * @deprecated
 * 
 * use authTokenHolder.setJwt
 */
export function setAuthToken(authToken: string) {
    authTokenHolder.setJwt(authToken);
}

/**
 * @deprecated
 * 
 * use authTokenHolder.getJwt()
 */
export function getAuthToken(): string {
    const jwt = authTokenHolder.getJwt();
    if (jwt == null) throw new Error('Cannot get jwt token. Auth-token not present');
    return jwt;
}
