import { push } from 'react-router-redux';
import Notifications from 'react-notification-system-redux';

import { getToken, setAuthToken } from '../component-lib/utils/AuthUtils'

const ErrorCodeUnauthorized = 403;
const AuthHeader = 'Authorization';

export function remoteCall(url, requestBody, okFn, failFn) {
  if (failFn === undefined) {
    failFn = (response) => {
      throw new Error('Bad response from server: Got status ' + response.status)
    }
  }

  // do post if request body present
  const request = requestBody
    ? jsonApiPost(requestBody) : apiGet();

  if (url.startsWith('sec')) {
    request.headers['Authorization'] = 'Bearing ' + getToken()
  }
  return fetch(url, request).then((response) => {
    if (response.ok) {
      const newAuthToken = response.headers.get(AuthHeader);
      if(newAuthToken) setAuthToken(newAuthToken);
      return response.json().then(okFn);
    } else {
      failFn(response)
    }
  })
}

function apiGet() {
  return {
    method: 'get',
    headers: {
      Accept: 'application/json'
    }
  }
}

function jsonApiPost(body) {
  if (typeof body != 'string') {
    body= JSON.stringify(body)
  }
  return {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body
  }
}

/**
 * Remote call error handler that displays notifications and
 * redirects to the root page if token expired
 */
export function makeErrorResponseHandler(callback, dispatch) {
  return (errResponse) => {
    if(errResponse.status === ErrorCodeUnauthorized) {
      const errorMessage = 'Unauthorized';
      callback(errorMessage, dispatch(unauthorizedActionCreator()));
    } else {
      errResponse.text()
        .then((errMessage) => {
          const { message, displayMessage } = parseErrorMessage(errMessage);
          callback(message, dispatch(remoteErrorActionCreator(displayMessage)));
        })
        .catch((err) => {
          callback(err, dispatch(remoteErrorActionCreator('Unknown Error')));
        })
    }
  }
}

/**
 * Parse the error json and 
 * return the message to display
 */
function parseErrorMessage(message) {
    let displayMessage = message;

    try {
        const messageObj = JSON.parse(message);
        if(messageObj.detail) {
            displayMessage = messageObj.detail;
        }
    } catch(err) { /* ignored */ }

    return { 
        message, 
        displayMessage };
}

function unauthorizedActionCreator() {
  return (dispatch) => {
    dispatch(push('/login'));

    dispatch(Notifications.error({
        title: 'Request Failed',
        message: 'Unauthorized',
        autoDismiss: 5
      }));
  }
}

function remoteErrorActionCreator(message) {
  return Notifications.error({
      title: 'Request Failed',
      message,
      autoDismiss: 5
    })
}
