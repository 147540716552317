import React from 'react';
import PropTypes from 'prop-types';
import SplitPane from 'react-split-pane';
import FontAwesome from 'react-fontawesome';

export default class SplitPaneHideLeft extends React.PureComponent {

    constructor() {
        super();
        this.toggleLeft = this.toggleLeft.bind(this);
        this.state = {
            size: 200,
            hidden: true
        }
    }

    render() {
        const size = this.state.hidden ? 1 : this.state.size;
        const allowResize = !this.state.hidden;
        return <SplitPane
            split="vertical"
            allowResize={allowResize}
            size={size}
            onChange={(size) => this.setState({ size })}
            pane1Style={{ backgroundColor: '#e0e0e0' }}>
            { this.renderLeft() }
            { this.renderRight() }
        </SplitPane>
    }

    renderLeft() {
        return this.state.hidden
            ? <div></div>
            : this.props.renderLeft();
    }

    renderRight() {
        return <div>
            <div className="toggle-split-pane-left" onClick={this.toggleLeft}>
                <FontAwesome
                    name={ this.state.hidden ? 'angle-right' : 'angle-left' }/> 
            </div>
            { this.props.children }
        </div>;
    }

    toggleLeft() {
        this.setState({ hidden: !this.state.hidden });
    }
}

SplitPaneHideLeft.propTypes = {
    renderLeft: PropTypes.func.isRequired
}

