import React, { useCallback } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownItem } from "reactstrap";
import { LocationItem } from '@pearlchain/component-lib-common';
import { LocationMenuItem } from "./types";

type Props = {
    page: LocationMenuItem;
    onLocationChanged: (location: LocationItem) => void;
}

export default function NavDropdownItem({ page, onLocationChanged }: Props) {
    const title = page.title;
    const icon = page.icon && <FontAwesomeIcon icon={page.icon}/>;

    const onNavigate = useCallback(() => {
        onLocationChanged(page.location);
    }, [onLocationChanged]);

    return (
        <DropdownItem onClick={onNavigate}>
            { icon } { title }
        </DropdownItem>
    );
}
