const ActionTypes = {
    RESET_PATH: 'core.designer.gallery.RESET_PATH',
    SET_PATH: 'core.designer.gallery.SET_PATH'
}

export default ActionTypes;

export function resetPath() {
    return { type: ActionTypes.RESET_PATH };
}

export function setPath(path) {
    return { type: ActionTypes.SET_PATH, path };
}
