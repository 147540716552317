import { Map } from 'immutable';
import ActionTypes from './actions';

export default function(state = Map(), action) {
    switch(action.type) {
        case ActionTypes.SHOW_MODAL:
            return handleShowModal(state, action);
        case ActionTypes.HIDE_MODAL:
            return handleHideModal(state, action);
        case ActionTypes.SET_MODAL_VALUES:
            return handleSetModalValues(state, action);
    }
    return state;
}

function handleShowModal(state, { modalId, config }) {
    const values = config.get('values', null);
    return state.setIn(['modal', modalId], Map({
        config,
        values,
        showing: true
    }))
}

function handleHideModal(state, { modalId }) {
    return state.setIn(['modal', modalId, 'showing'], false)
}

function handleSetModalValues(state, { modalId, values }) {
    return state.setIn(['modal', modalId, 'values'], values);
}

