import { createSelector, createStructuredSelector } from 'reselect';
import { getCurrentDesign } from './selectors';

export const DefaultCanvasWidth = 1024;
export const DefaultCanvasHeight = 768;
export const DefaultCanvasColor = 'white';
export const DefaultGridSize = 20;

export const getCanvasWidth = (canvasConfig) =>
    canvasConfig ? canvasConfig.get('canvasWidth', DefaultCanvasWidth) : DefaultCanvasWidth;

export const getCanvasHeight = (canvasConfig) =>
    canvasConfig ? canvasConfig.get('canvasHeight', DefaultCanvasHeight) : DefaultCanvasHeight;

export const getCanvasBgColor = (canvasConfig) =>
    canvasConfig ? canvasConfig.get('bgColor', DefaultCanvasColor) : DefaultCanvasColor;

export const getCanvasGridShowing = (canvasConfig) => 
    canvasConfig ? canvasConfig.get('showGrid', false) : false;

export const getCanvasGridSize = (canvasConfig) =>
    canvasConfig ? canvasConfig.get('gridSize', DefaultGridSize) : DefaultGridSize;

export const getCanvasConfig = (design) => design && design.get('canvasConfig');

export const canvasConfigSelector = createSelector(getCurrentDesign, getCanvasConfig);

export const canvasBgColorSelector = createSelector(canvasConfigSelector, getCanvasBgColor);

export const canvasWidthSelector = createSelector(canvasConfigSelector, getCanvasWidth);

export const canvasHeightSelector = createSelector(canvasConfigSelector, getCanvasHeight);

export const canvasGridShowingSelector = createSelector(canvasConfigSelector, getCanvasGridShowing);

export const canvasGridSizeSelector = createSelector(canvasConfigSelector, getCanvasGridSize);

export const getCanvasConfigInitialValues = createStructuredSelector({
    canvasWidth: canvasWidthSelector,
    canvasHeight: canvasHeightSelector,
    showGrid: canvasGridShowingSelector,
    gridSize: canvasGridSizeSelector,
    bgColor: canvasBgColorSelector
});
