import { toNumber } from '../../mathHelper';
import * as mathjs from 'mathjs'

export default {
    token: '%',
    greedy: false,
    read(reader) {
        // read until the first non-numeric character
        return reader.findPrev((v) => isNaN(parseFloat(v)));
    },
    evaluate(values, { context }) {
        let v = mathjs.eval(values.join(''));
        v = toNumber(v);
        
        const key = context.key;
        const percent = context.percent;
        const width = percent.parentWidth;
        const height = percent.parentHeight;
        if(v == null)  return '' + v;
        
        let pVal;
        if(key === 'x') {
            pVal = width;

        } else if(key === 'y') {
            pVal = height;

        } else if(key === 'width') {
            pVal = width;

        } else if(key === 'height') {
            pVal = height;
        }

        if(pVal != null) {
            pVal = parseFloat(pVal) || 0;
            return v / 100 * pVal;
        }

        return v;
    }
}
