import { LocationItem, createLocationItem } from "./types";
import { List } from "immutable";

export enum ActionType {
    INIT_BREADCRUMBS = 'ui.location.INIT_BREADCRUMBS',
    RESET_BREADCRUMBS = 'ui.location.RESET_BREADCRUMBS',
    SET_BREADCRUMBS = 'ui.location.SET_BREADCRUMBS',
    PUSH_LOCATION = 'ui.location.PUSH_LOCATION',
    POP_LOCATION = 'ui.location.POP_LOCATION',
    SET_LOCATION = 'ui.location.SET_LOCATION',
    REPLACE_LOCATION = 'ui.location.REPLACE_LOCATION'
}

export function initBreadcrumbs(items: List<LocationItem>, params?: { [key: string]: unknown }): InitBreadcrumbsAction {
    return {
        type: ActionType.INIT_BREADCRUMBS,
        items,
        params
    };
}

export function resetBreadcrumbs(): ResetBreadcrumbsAction {
    return {
        type: ActionType.RESET_BREADCRUMBS
    }
}

export function setBreadcrumbs(items: List<LocationItem>, params?: { [key: string]: unknown }): SetBreadcrumbsAction {
    return {
        type: ActionType.SET_BREADCRUMBS,
        items,
        params
    };
}

export function pushLocation(pathName: string, title: string, params?: { [key: string]: unknown }): PushLocationAction {
    return pushLocationItem(createLocationItem({ pathName, title, params }));
}

export function pushLocationItem(item: LocationItem): PushLocationAction {
    return {
        type: ActionType.PUSH_LOCATION,
        item
    };
}

export function popLocation(n?: number): PopLocationAction {
    return {
        type: ActionType.POP_LOCATION,
        n
    };
}

export function setLocation(items: List<LocationItem>): SetLocationAction {
    return {
        type: ActionType.SET_LOCATION,
        items
    };
}

export function replaceLocation(pathName: string, title: string, params?: { [key: string]: unknown }): ReplaceLocationAction {
    return replaceLocationItem(createLocationItem({ pathName, title, params }));
}

export function replaceLocationItem(item: LocationItem): ReplaceLocationAction {
    return {
        type: ActionType.REPLACE_LOCATION,
        item
    };
}


export type InitBreadcrumbsAction = {
    type: ActionType.INIT_BREADCRUMBS;
    items: List<LocationItem>;
    params: { [key: string]: unknown } | undefined;
}

export type ResetBreadcrumbsAction = {
    type: ActionType.RESET_BREADCRUMBS;
}

export type SetBreadcrumbsAction = {
    type: ActionType.SET_BREADCRUMBS;
    items: List<LocationItem>;
    params: { [key: string]: unknown } | undefined;
}

export type PushLocationAction = {
    type: ActionType.PUSH_LOCATION;
    item: LocationItem;
}

export type PopLocationAction = {
    type: ActionType.POP_LOCATION;
    n?: number;
}

export type SetLocationAction = {
    type: ActionType.SET_LOCATION;
    items: List<LocationItem>;
}

export type ReplaceLocationAction = {
    type: ActionType.REPLACE_LOCATION;
    item: LocationItem;
}
