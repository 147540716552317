import React, { useEffect, useState } from 'react';
import { useProperties } from './PropertiesLoader';

interface Props {
    children: React.ReactChild
}

type EventData = {
    type: string;
    token: string | null;
}

export default function TokenListener(props: Props) {
    const properties = useProperties();
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        if (!properties) return;

        window.addEventListener('message', event => {
            if (typeof event.data === 'object' && event.data.type === "TOKEN_CHANGED") {
                const data: EventData = event.data;
                if (data.token) {
                    window.localStorage.setItem('auth-token', data.token);
                    setIsAuthorized(true);
                } else {
                    const url = properties.urlLogin + "#/?redirect=" + window.location.href;
                    window.location.assign(url);
                }
            }
        });
    }, [properties]);

    return <>
        <iframe id="receiver" src={properties?.urlLogin+"#/sso"} className="stack-app-iframe"></iframe>
        {isAuthorized ? props.children : null}
    </>
}
