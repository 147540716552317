import { Row } from "../../components/types";
import { DataView, DataViewObserver } from "./viewTypes";

export class SortedView implements DataView {
    private readonly parent: DataView;
    private readonly observers: DataViewObserver[] = [];

    private comparator: ((a: Row, b: Row) => number) | undefined;
    private dataSorted: Row[] | undefined;

    constructor(parent: DataView) {
        this.parent = parent;
        parent.addObserver(this.recompute.bind(this));
    }

    getRecordById(id: string) {
        return this.parent.getRecordById(id);
    }

    recordCount() {
        if (this.dataSorted) {
            return this.dataSorted.length;
        } else {
            return this.parent.recordCount();
        }
    }

    getData(start?: number, end?: number) {
        if (this.dataSorted) {
            if (!start && !end) return this.dataSorted;
            if (!start) start = 0;
            if (!end) end = this.dataSorted.length;
            return this.dataSorted.slice(start, end);

        } else {
            return this.parent.getData(start, end);
        }
    }

    sort(comparator: (a: Row, b: Row) => number) {
        this.comparator = comparator;
        this.recompute('sort');
    }

    addObserver(observer: DataViewObserver) {
        this.observers.push(observer);
    }

    private recompute(source?: string) {
        if (this.comparator) {
            const data = this.parent.getData();
            this.dataSorted = data.sort(this.comparator);
        } else {
            this.dataSorted = undefined;
        }

        for (let observer of this.observers) {
            observer(source);
        }
    }
}

