import { fromJS } from 'immutable';
import { authTokenHolder } from '../components/auth/authUtils';
import { ApiError } from './requestHelpers';

export type RequestProcessor = (opts: RequestParams) => void;

export type ResponseProcessor = (response: Response, error: string | undefined) => void;

export type ResponseValidator = (response: Response) => boolean;

export interface RequestParams {
    headers: { [key: string]: string },
    body?: string,
    url: string,
    method: HttpRequestMethod,
    authorized?: boolean,
    requestHandlers: RequestProcessor[],
    responseHandlers: ResponseProcessor[],
    validator?: ResponseValidator,
    extra?: { [key: string]: unknown }
}

export enum HttpRequestMethod {
    GET = 'GET',
    POST = 'POST'
}

export const Header = {
    HEADER_AUTHORIZATION: 'Authorization',
    HEADER_ACCEPT: 'Accept',
    HEADER_CONTENT_TYPE: 'Content-Type',
    HEADER_SIMULATION_UID: 'Simulation-Id'
};

export enum Status {
    OK = 200
}

/**
 * Sets the Authorization header on the request
 */
function handleRequestSetAuthHeader(opts: RequestParams): RequestParams {
    if (opts.authorized) {
        const jwt = authTokenHolder.getJwt();
        const value = 'Bearing ' + jwt;
        opts.headers[Header.HEADER_AUTHORIZATION] = value;
    }
    return opts;
}

/**
 * Update the JWT token in local-storage from the refresh header in the response
 */
function handleResponseReadAuthHeader(response: Response): void {
    const header: string | null = response.headers.get(Header.HEADER_AUTHORIZATION);
    if(header) {
        authTokenHolder.setJwt(header);
    }
}

/**
 * Validates the status-code of the response matches expected range
 */
function createHttpStatusValidator(expectedStatus: number | number[]) {
    return (response: Response): boolean => {
        const status = response.status;
        return status === expectedStatus
            || Array.isArray(expectedStatus) && expectedStatus.indexOf(status) > -1;
    }
}

/**
 * Constructs the default options object which will be used as the base for all RequestBuilders
 */
function createDefaultOpts(): RequestParams {
    const requestHandlers: RequestProcessor[] = [];
    requestHandlers.push(handleRequestSetAuthHeader);

    const responseHandlers: ResponseProcessor[] = [];
    responseHandlers.push(handleResponseReadAuthHeader);

    return {
        headers: {},
        url: '/',
        method: HttpRequestMethod.POST,
        requestHandlers,
        responseHandlers,
        authorized: true,
        validator: createHttpStatusValidator(Status.OK)
    };
}

function cloneOpts(opts: RequestParams): RequestParams {
    opts = Object.assign({}, opts);
    if (opts.headers) {
        opts.headers = Object.assign({}, opts.headers);
    }
    return opts;
}

export default class RequestBuilder {
    public static readonly Opts: RequestParams = createDefaultOpts();

    public readonly opts: RequestParams;

    constructor(opts?: RequestParams) {
        this.opts = cloneOpts(RequestBuilder.Opts);
        if (opts != null) {
            Object.assign(this.opts, opts);
        }
    }

    get(url: string): RequestBuilder {
        this.setUrl(url);
        this.setHttpMethod(HttpRequestMethod.GET);
        return this;
    }

    post(url: string): RequestBuilder {
        this.setUrl(url);
        this.setHttpMethod(HttpRequestMethod.POST);
        return this;
    }

    setHeader(key: string, value: string): RequestBuilder {
        const headers = this.opts.headers || (this.opts.headers = {});
        headers[key] = value;
        return this;
    }

    setUnauthorized() {
        this.opts.authorized = false;
        return this;
    }

    setHeaderContentType(value: string) {
        return this.setHeader(Header.HEADER_CONTENT_TYPE, value);
    }

    setHeaderAccept(value: string) {
        return this.setHeader(Header.HEADER_ACCEPT, value);
    }

    setHeaderAuthorization(value: string) {
        return this.setHeader(Header.HEADER_AUTHORIZATION, value);
    }

    setHeaderSimulationUid(value: string) {
        return this.setHeader(Header.HEADER_SIMULATION_UID, value);
    }

    setBody(body: string): RequestBuilder {
        this.opts.body = body;
        return this;
    }

    setBodyText(body: string): RequestBuilder {
        return this
            .setBody(body)
            .setHeaderContentType('text/plain');
    }

    setBodyJson(body: any) {
        return this
            .setBody(JSON.stringify(body))
            .setHeaderContentType('application/json');
    }

    setValidator(validator: ResponseValidator | undefined) {
        this.opts.validator = validator;
        return this;
    }
    
    setExpectedStatus(status: number | number[]) {
        return this.setValidator(createHttpStatusValidator(status));
    }

    setUrl(url: string): RequestBuilder {
        this.opts.url = url;
        return this;
    }

    setHttpMethod(method: HttpRequestMethod) {
        this.opts.method = method;
        return this;
    }

    toJS<T>(): Promise<T> {
        return this
            .setHeaderAccept('application/json')
            .fetch()
            .then(response => response.json());
    }

    toImmutable(): Promise<any> {
        return this.toJS().then(data => fromJS(data));
    }

    toString(): Promise<string> {
        return this
            .setHeaderAccept('text/plain')
            .fetch()
            .then(response => response.text());
    }

    async fetch(): Promise<Response> {
        let opts = this.opts;

        // process through the request params
        for (let requestHandler of opts.requestHandlers) {
            requestHandler(opts);
        }

        // perform the request
        let response = await fetch(opts.url, {
            headers: opts.headers,
            method: opts.method,
            body: opts.body,
            ...(opts.extra || {})
        });

        
        

        // validate the response
        if (this.opts.validator && !this.opts.validator(response)) {

            let errorBody: string = 'Api Error';
            if (response.body) {
                // read the error body as a UTF-8 stream
                errorBody = await response.text();
            }

            // process the response
            opts.responseHandlers.forEach(handler => handler(response, errorBody));
            throw new ApiError(errorBody, response.status);

        } else {
            // process the response
            opts.responseHandlers.forEach(handler => handler(response, undefined));
            return response;
        }
    }
}
