import uuid from 'uuid';
import { List, Map, fromJS } from 'immutable';
import DesignerActionTypes from '../actions/DesignerActions'
import ActionTypes from '../actions/computedPropsActions'
import { getParentsMapping } from '../helper/selector/canvasFigureConfigSelectors';
import { parentsToRelativePath } from '../helper/selector/util/createFigureParentsSelector';
import { mapDeep } from '../helper/treeHelper';

/**
 * Reducers related to figure grouping 
 */

export default function(state = Map(), action) {
    switch(action.type) {
        case DesignerActionTypes.SET_CANVAS_CONTEXT_MENU_TOGGLED:
            return handleSetCanvasContextMenuToggled(state, action);
        case DesignerActionTypes.ASSIGN_TO_PARENT:
            return handleAssignToParent(state, action);
        case DesignerActionTypes.UNASSIGN_FROM_PARENT:
            return handleUnassignFromParent(state, action);
        case ActionTypes.BULK_REMOVE_UPDATE_COMPUTED_PROPS:
            return handleBulkRemoveUpdateComputedProps(state, action);
        case ActionTypes.RECOMPUTE_FIGURE_PROPS:
            return handleRecomputeFigureProps(state, action);
    }
    return state;
}

/**
 * Applies the parent assignment for all dynamic defined parent-name properties
 * when recomputing all figure props occured.
 * The parent assigned is the value calculated by the computedPropsReducer which happened
 * before this reducer.
 */
function handleRecomputeFigureProps(state, action) {
    const computed = state.getIn(['computed', action.designId])
    const figuresConfig = state.getIn(['designs', action.designId, 'figures-config'])
    const updateFn = (fc) => {
        const figureId = fc.getIn(['mainConfig', 'data', 'id'])
        const isDynamic = computed.getIn([figureId, 'expressions', 0, 'parent-name', 'dynamic']);
        if (isDynamic) {
            // parent name is a dynamic expression, 
           state = updateAssignedParent(state, action.designId, figureId)
        }
        fc.get('children', List()).forEach(updateFn)
    }
    figuresConfig.forEach(updateFn)
    return state
}

// Update parent when the parent-name property was (re)calculated
function handleBulkRemoveUpdateComputedProps(state, action) {
    const computed = state.getIn(['computed', action.designId])
    action.diffs.forEach((diff) => {
        if (diff.figDiff.has('parent-name')) {
            // already coimputed figDiff result because computed reducer happens before this reducer
            state = updateAssignedParent(state, action.designId, diff.figureId)
        }
    })
    return state
}

/**
 * Assign to parent when parent was found, unassign when parent was not found
 */
function updateAssignedParent(state, designId, figureId) {
    const parentName = state.getIn(['computed', designId, figureId, 'figures', 0, 'parent-name'])
    const parentConfig = parentName ? findParentConfigByName(parentName, 
        state.getIn(['designs', designId, 'figures-config'])): undefined
    if (parentConfig !== undefined) {
        return handleAssignToParent(state, {
            designId, figureIds: List().push(figureId),
            parentId: parentConfig.getIn(['mainConfig', 'data', 'id'])})
    } else {
        return handleUnassignFromParent(state, {
            designId, primaryId: figureId,
            secondaryIds: []})
    }
}

/**
 * Look for the figure with the given parentName in the figuresConfig data.
 */
function findParentConfigByName(parentName, figuresConfig) {
    let pc = undefined
    if (!figuresConfig) {
        return pc
    }
    figuresConfig.find((fc) => {
        if (fc.get('name') == parentName) {
            pc = fc
            return true
        }
        pc = findParentConfigByName(parentName, fc.get('children'))
        return !!pc // found as one of the nested children, stop finding
    })
    return pc
}

function handleSetCanvasContextMenuToggled(state, { isToggled, position }) {
    return state.set('canvas-context-menu-toggled', Map({ isToggled, position: fromJS(position) }));
}

function handleAssignToParent(state, { designId, parentId, figureIds }) {
    return state.updateIn(['designs', designId, 'figures-config'], (figuresConfig) => {
        return figureIds.reduce((figuresConfig, figureId) => {
            const parentsMapping = getParentsMapping(figuresConfig);
            const figParents = parentsMapping.get(figureId);
            const parentParents = parentsMapping.get(parentId);
            if(!figParents || !parentParents) return figuresConfig;

            const figurePath = parentsToRelativePath(figParents);
            const groupPath = parentsToRelativePath(parentParents);
            const figure = figuresConfig.getIn(figurePath);

            if (figParents.size > 1 && figParents.get(figParents.size-2).get('id') == parentId) {
                // this figure already has a parent and that parent is the one we're trying to assign
                // as parent, ignore this assignment.
                return figuresConfig;
            }
            
            // delete the old figure, and add to the new group 
            return figuresConfig
                .updateIn(groupPath.push('children'), List(), (groupChildren) => groupChildren.push(cloneFigure(figure)))
                .deleteIn(figurePath);
        }, figuresConfig)
    })
    // clear the current selection
    .deleteIn(['designs', designId, 'selected-figure']);
}

function handleUnassignFromParent(state, { designId, primaryId, secondaryIds }) {
    return state.updateIn(['designs', designId, 'figures-config'], (figuresConfig) => {
        return List()
            .push(primaryId)
            .concat(secondaryIds)
            .reduce((acc, figureId) => {
                const figParents = getParentsMapping(acc).get(figureId);
                const figurePath = parentsToRelativePath(figParents);
                const figure = acc.getIn(figurePath);

                if (figParents.size == 1) {
                    // already at root
                    return acc
                }

                // delete from the old group, and add to the root figures list 
                return acc
                    .deleteIn(figurePath)
                    .push(cloneFigure(figure));
            }, figuresConfig);
    })
    // clear the current selection
    .deleteIn(['designs', designId, 'selected-figure']);
}

/**
 * Deeply clones a figure, by assigning new ids to it and its children
 */
function cloneFigure(figure) {
    return mapDeep([figure],
        (figure) => figure.get('children'),
        (figure, children) => figure
            .setIn(['mainConfig', 'data', 'id'], uuid.v4())
            .set('children', children))
        .get(0);
}
