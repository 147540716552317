import React, { useEffect, useRef, useMemo } from "react";
import { PluginActions, PluginCreationParams, Evaluator } from "./types/pluginTypes"
import { PluginInstance } from "./PluginInstance";

type Props<C> = {
    pluginId: string;
    actions: PluginActions<C>;
    config: C;
    data?: unknown;
    props: { [key: string]: unknown };
    refreshCount: number;
    evaluator: Evaluator;
}

export function ReactPluginLoader<C>(props: Props<C>) {
    const {
        pluginId,
        actions,
        config,
        data,
        props: pluginProps,
        refreshCount,
        evaluator
    } = props;

    const pluginCache = useMemo(() => {
        return window.plugins.getPlugin<C, unknown>(pluginId);
    }, [pluginId]);

    const elRef = useRef<HTMLDivElement>(null);
    const pluginRef = useRef<PluginInstance<C, unknown>>();
    const prevRef = useRef({ config, data, pluginProps, refreshCount });

    /**
     * Create a new plugin instance when the component is mounted
     */
    useEffect(() => {
        const el = elRef.current;
        if (!pluginCache || !el) {
            return;
        }

        let plugin = pluginRef.current;
        if (!plugin) {

            // create a new plugin instance
            const creationParams: PluginCreationParams<C> = { actions, config, data, props: pluginProps, evaluator };
            plugin = new PluginInstance<C, unknown>(pluginCache, creationParams);
            pluginRef.current = plugin;
            plugin.mount(el);

            // set the "previous" state to the initial params, to avoid
            // calling setConfig, setData... etc. unnecessarily
            prevRef.current = {
                config, data, pluginProps, refreshCount
            };
        }

        return () => {
            plugin!.unmount();
        }
    }, [pluginCache]);

    /**
     * Update the plugin instance with the next props passed in
     */
    useEffect(() => {
        const plugin = pluginRef.current;
        if (!plugin) return;

        const prev = prevRef.current;

        // update the plugin config
        if (config && prev.config !== config) {
            prev.config = config;
            plugin.setConfig(config);
        }

        // update the plugin data
        if (data && prev.data !== data) {
            prev.data = data;
            plugin.setData(data);
        }

        // update the plugin props
        if (pluginProps && prev.pluginProps !== pluginProps) {
            prev.pluginProps = pluginProps;
            plugin.setProps(pluginProps);
        }

        // trigger the plugin to refresh
        if (refreshCount !== prev.refreshCount) {
            prev.refreshCount = refreshCount;
            plugin.refresh();
        }

    }, [config, data, pluginProps, refreshCount]);

    return (
        <div className="plugin-container" ref={elRef}/>
    );
}
