import React, { CSSProperties, useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formatter } from '@pearlchain/component-lib-common';

import Grid from './Grid';
import { PowergridOpts, Row, EventHandler, PgExts, IDatastore } from './types';
import { IDatasource, ValidateCallback, ValueChangedListener } from '../datasource/datasourceTypes';
import { createExtensions } from './extensions';
import { translateRows, translateColumns } from './helpers';
import { GridCreationContext, Callbacks, TranslateFn } from './extensions/extensionTypes';
import { Datasource } from '../datasource/Datasource';

/**
 * A convenience wrapper around the imperative powergrid API.
 * Watches changes in the component props and triggers changes automatically.
 */

type ExtraPowergridOpts = Pick<PowergridOpts, Exclude<keyof PowergridOpts, 'dataSource' | 'extensions' | 'gridId'>>;

type Props = {
    /**
     * Id of the grid
     */
    gridId?: string,
    /**
     * Class-name of the outer div
     */
    classNameOuter?: string,
    /**
     * Style to apply to the grid
     */
    style?: CSSProperties,
    /**
     * Data to show on the grid
     */
    data: Row[],
    /**
     * Datasource
     */
    createDatasource?: (data: Row[]) => IDatasource,
    /**
     * Datastore creator
     */
    createDatastore?: (datasource: IDatasource) => IDatastore,
    /**
     * Powergrid extensions
     */
    extensions?: PgExts,
    /**
     * Event handlers
     */
    events?: EventHandler[],
    /**
     * Callback when a value on the grid was changed
     */
    onValueChanged?: ValueChangedListener,
    /**
     * Callback when a row on the grid was selected
     */
    onRowSelected?: (id: string) => void,
    /**
     * Callback when the sort-columns were changed
     */
    onSortColumnsChanged?: (sortColumns: string[]) => void,
    /**
     * Callback when the grid was mounted. Can be used to extend the internal objects
     * with custom behaviour
     */
    onCreated?: (grid: any, datasource: IDatasource, datastore: IDatastore | undefined) => void,
    /**
     * 
     */
    validate?: ValidateCallback
    /**
     * Whether the grid allows multiple columns to be sorted on
     */
    multiSort?: boolean,
    /**
     * Custom formatters to use
     */
    formatters?: { [key: string]: Formatter }
} & ExtraPowergridOpts;

let i = 0;

export default function GridReactive(props: Props) {
    const {
        gridId,
        classNameOuter,
        style,
        data,
        createDatasource,
        createDatastore,
        columns,
        events,
        extensions,
        onValueChanged,
        onRowSelected,
        onSortColumnsChanged,
        onCreated,
        validate,
        formatters,
        multiSort,
        ...extraPgOpts
    } = props;

    const callbacks: Callbacks = { onRowSelected, onSortColumnsChanged };
    const prevCallbacks = useRef(callbacks);

    // the react-18next translation function
    let { t } = useTranslation();
    const prevTranslate = useRef<TranslateFn>(t);

    // Translate the rows
    const rowsTranslated = useMemo(() => {
        return translateRows(t, columns, data);
    }, [data, t]);

    // Translate the columns
    const columnsTranslated = useMemo(() => {
        return translateColumns(t, columns);
    }, [columns, t]);

    // Initial state
    const [{ datasource, datastore, exts, _gridId }] = useState(() => {

        // create the datasource
        const datasource: IDatasource = createDatasource
            ? createDatasource(rowsTranslated)
            : new Datasource(rowsTranslated);

        // create the datastore  
        const datastore = createDatastore ? createDatastore(datasource) : undefined;
        const creationCtx: GridCreationContext = {
            multiSort: !!multiSort,
            datastore,
            translate: prevTranslate,
            callbacks: prevCallbacks,
            formatters
        }

        // create the extensions
        const exts: PgExts = Object.assign({}, extensions);
        createExtensions(exts, creationCtx);
        
        // use the grid-id passed in, or generate it automatically
        const _gridId = gridId || 'grid_' + (i++);

        // initial state
        return {
            datasource,
            exts,
            datastore,
            _gridId
        };
    });

    const prevOnValueChanged = useRef<ValueChangedListener | undefined>(undefined);
    const prevValidate = useRef<ValidateCallback | undefined>(undefined);
    const prevRows = useRef(rowsTranslated);

    useEffect(() => {
        // update the valueChanged listener
        if (prevOnValueChanged.current !== onValueChanged) {
            prevOnValueChanged.current = onValueChanged;
            if (datasource.setValueChangedListener) {
                datasource.setValueChangedListener(onValueChanged);
            }
        }

        // update the validator function
        if (prevValidate.current !== validate) {
            prevValidate.current = validate;
            if (datasource.setValidator) {
                datasource.setValidator(validate);
            }
        }

        // update the datasource data
        if (prevRows.current !== rowsTranslated) {
            prevRows.current = rowsTranslated;
            datasource.update(rowsTranslated);
        }

        // update remaining references
        prevCallbacks.current = callbacks;
        prevTranslate.current = t;
    });

    return <Grid
        className={classNameOuter}
        events={events}
        style={style}
        onCreated={(grid) => onCreated && onCreated(grid, datasource, datastore)}
        opts={{
            gridId: _gridId,
            dataSource: datasource,
            columns: columnsTranslated,
            extensions: exts,
            ...extraPgOpts
        }}/>
}
