const ServerDataActionTypes = {
    FETCH_SERVER_DATA: 'core.serverdata.FETCH_SERVER_DATA',
    FETCH_SERVER_DATA_SUCCESS: 'core.serverdata.FETCH_SERVER_DATA_SUCCESS',
    FETCH_SERVER_DATA_ERROR: 'core.serverdata.FETCH_SERVER_DATA_ERROR'
}

export default ServerDataActionTypes

export function fetchServerData(request){
    return {
            type: ServerDataActionTypes.FETCH_SERVER_DATA,
            requests: wrapRequest(request)
    }
}

function wrapRequest(request){
    return Array.isArray(request) ? request : [request]
}

export function fetchServerDataSuccess(results, requests) {
    return {
        type: ServerDataActionTypes.FETCH_SERVER_DATA_SUCCESS,
        results,
        requests
    }
}

export function fetchServerDataError(error) {
    return {
        type: ServerDataActionTypes.FETCH_SERVER_DATA_ERROR,
        error
    }
}
