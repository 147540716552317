import React from 'react';
import PropTypes from 'prop-types';

export default function FieldInputCheckbox(props) {
    const checked = props.input.value;
    return <input
        name={props.input.name}
        type="checkbox"
        checked={checked}
        onChange={() => props.input.onChange(!checked)}
        />
}

FieldInputCheckbox.propTypes = {
    input: PropTypes.object.isRequired
}
