import { Map } from 'immutable';

import ActionTypes from './actions';

export default function(state = Map(), action) {
    switch(action.type) {
        case ActionTypes.SET_COLOR_PICKER_VISIBLE:
            return handleSetColorPickerVisible(state, action);
    }
    return state;
}

function handleSetColorPickerVisible(state, { visible, fieldId }) {
    return state.setIn(['colorPicker', fieldId], visible);
}
