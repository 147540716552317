import React, { useState, useCallback, useEffect } from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { languageParam } from '@pearlchain/component-lib-common';

type Props = {
    languageCodes?: string[];
}

const DEFAULT_LANGUAGE_CODES = ['en', 'nl', 'fr'];

// refreshes the cached translations by first
// clearing the local storage and then refreshing i18next
function refreshTranslations(i18n: i18next.i18n) {
    for(let i = 0, n = localStorage.length; i < n; ++i) {
        const key = localStorage.key(i);
        if(key && key.startsWith('i18next_res_')) {
            localStorage.removeItem(key);
        }
    }
    i18n.reloadResources();
}

function getInitialLanguage(langCodes: string[]) {
    return languageParam.get() || langCodes[0];
}

export default React.memo(
    function LanguageChooser(props: Props) {
        const langCodes = props.languageCodes || DEFAULT_LANGUAGE_CODES;
        const { i18n } = useTranslation();
        const [lang, setLang] = useState(() => getInitialLanguage(langCodes));

        const onRefreshTranslations = useCallback(() => {
            refreshTranslations(i18n);
        }, [i18n]);

        useEffect(() => {
            if (lang && i18n.language !== lang) {
                // set the current language in local storage
                languageParam.set(lang);

                // change the current language in react-i18n
                i18n.changeLanguage(lang);
            }
        }, [i18n, lang]);

        return (
            <UncontrolledDropdown>
                <DropdownToggle nav caret>
                    { lang }
                </DropdownToggle>
                <DropdownMenu>
                    {langCodes.map((langCode) => (
                        <DropdownItem
                            key={langCode}
                            onClick={() => setLang(langCode)}
                        >{ langCode }</DropdownItem>    
                    ))}
                    <DropdownItem divider/>
                    <DropdownItem onClick={onRefreshTranslations}>
                        Refresh Translations
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
);
