import React from 'react';
import PropTypes from 'prop-types';
import Imm from 'immutable';

/**
 * A text input component.
 * 
 * This is the only input component that can currently display a live-assist dropdown.
 */

function doExtendedBehaviour(props, event, fnKey) {
    props.fieldConfig.get(fnKey, Imm.List()).forEach(
        (behaviour) => behaviour(props, event))
}

function onInputChange(onInputChange, fieldProps) {
    return (event) => {
        onInputChange(event)
        doExtendedBehaviour(fieldProps, event, 'onChange')
    }
}

function onInputFocus(onInputFocus, fieldProps) {
    return (event) => {
        onInputFocus(event)
        doExtendedBehaviour(fieldProps, event, 'onFocus')
    }
}

function onInputKeyDown(fieldProps) {
    return (event) => {
        doExtendedBehaviour(fieldProps, event, 'onKeyDown')
    }
}

export default function FieldInputText(props) {
    const fieldConfig = props.fieldConfig;
    const decoratorFns = fieldConfig.get('decorators', Imm.List());

    const inputProps = {...props.input};
    inputProps.onChange = onInputChange(props.input.onChange, props);
    inputProps.onFocus = onInputFocus(props.input.onFocus, props);
    inputProps.onKeyDown = onInputKeyDown(props);

    const inputComponent =
        <input style={{ width: '100%' }} type="text" {...inputProps}/>

    const decorated = decoratorFns.reduce(
        (r, dfn) => dfn(r, props), inputComponent)

    return <div>
        { decorated }
    </div>
}

FieldInputText.propTypes = {
    fieldConfig: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired
}
