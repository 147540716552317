import { createSelectorCreator, defaultMemoize } from 'reselect';

/**
 * Creates selectors that are a function of the previous and current result.
 */
export const createScanSelector = createSelectorCreator(function(func) {
    let prev = undefined;
    return defaultMemoize(function() {
        prev = func.apply(null, [...arguments, prev]);
        return prev;
    });
});

export const createImmEqualsSelector = createSelectorCreator(defaultMemoize, (a, b) => a.equals(b));
