import { List } from "immutable";
import { getHistory } from "../../globals";
import { LocationState, LocationItem, HistoryState } from "./types";
import { getCommonState } from "../../selectors";

export function getLocationState(state: any): LocationState {
    return getCommonState(state).location;
}

export function getBreadcrumbs(state: any): List<LocationItem> {
    const locState = getLocationState(state);
    return locState.breadcrumbs;
}

export function getLocationParam<T>(key: string): T | undefined;
export function getLocationParam<T>(key: string, valueNotSet: T): T;
export function getLocationParam<T>(key: string, valueNotSet?: T): T | undefined {
    const history = getHistory();
    const state = history.location.state as Partial<HistoryState> | undefined;
    if (state != null) {
        const params = state.params;
        if (params != null && params.hasOwnProperty(key)) {
            return params[key] as T;
        }
    }
    return valueNotSet;
}
