import i18n from "i18next";

export class ApiError extends Error {
    public readonly body: string | undefined;
    public readonly statusCode: number;

    constructor(body: string | undefined, statusCode: number) {
        super(body);
        this.body = body;
        this.statusCode = statusCode;
    }
}

export function parseApiErrorMessage(errorBody: string | undefined): string {
    if (errorBody) {
        try {
            const body = JSON.parse(errorBody);
            if (body.hasOwnProperty('message')) {
                const message = body.message;
                if (typeof message === 'string') {
                    return message;
                }
            }
            return errorBody;
        } catch(err) {
            console.warn(err);
        }
    }
    return i18n.t('web.api.error.parseError', [errorBody]);
}

const statusMessage = new Map<number, string>([
    [400, 'web.api.error.status.badRequest'],
    [401, 'web.api.error.status.unauthorized'],
    [403, 'web.api.error.status.forbidden'],
    [404, 'web.api.error.status.notFound'],
    [500, 'web.api.error.status.internalServerError'],
    [502, 'web.api.error.status.badGateway'],
    [503, 'web.api.error.status.serviceUnavailable'],
    [504, 'web.api.error.status.gatewayTimeout']
]);

export function parseApiErrorTitle(status: number) {
    if (statusMessage.has(status)) {
        return i18n.t(statusMessage.get(status)!);
    } else {
        return i18n.t('web.api.error.status.unknown', [status]);
    }
}

export function createNotifyOnErrorResponseHandler(notifyError: (args: { title: string, message: string }) => void) {
    return (response: Response, error?: string) => {
        const status = response.status;
        if (error != null) {
            const message = parseApiErrorMessage(error);
            const title = parseApiErrorTitle(status);
            notifyError({ title, message })
        }
    }
}
