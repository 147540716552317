import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { getAvailableDatasources, getSelectedDatasource } from '../../helper/selector/datasourceSelectors';
import { getCurrentDesignId } from '../../helper/selector/selectors';
import { fetchAvailableDatasources, setSelectedDatasource, addDatasourceToDesign } from '../../datarequest/actions';

class DatasourceChooser extends React.Component  {

    UNSAFE_componentWillMount() {
        this.props.fetchAvailableDatasources();
    }

    render() {
        const value = this.props.selectedDatasourceName || '';
        return <div className="datasource-chooser">
            <select value={value} onChange={this.handleChanged.bind(this)}>
                <option value="" disabled={true}>-- select a datasource --</option>
                { this.props.availableDatasources && this.props.availableDatasources.map((datasourceName) => {
                    return <option 
                        key={datasourceName}
                        value={datasourceName}>{ datasourceName }</option>
                })}
            </select>
            <button disabled={!value} onClick={this.handleAdd.bind(this)}>Add</button>
        </div>
    }

    handleChanged(e) {
        this.props.setSelectedDatasource(e.target.value);
    }

    handleAdd() {
        this.props.addDatasourceToDesign(this.props.designId, this.props.selectedDatasourceName);
    }
}

DatasourceChooser.propTypes = {
    // connect
    availableDatasources: ImmutablePropTypes.list,
    selectedDatasourceName: PropTypes.string,
    designId: PropTypes.string.isRequired,
    fetchAvailableDatasources: PropTypes.func.isRequired,
    setSelectedDatasource: PropTypes.func.isRequired,
    addDatasourceToDesign: PropTypes.func.isRequired
}

export default connect(
    (state) => ({
        availableDatasources: getAvailableDatasources(state),
        selectedDatasourceName: getSelectedDatasource(state),
        designId: getCurrentDesignId(state)
    }),
    { fetchAvailableDatasources, 
        setSelectedDatasource, 
        addDatasourceToDesign }
)(DatasourceChooser);
