const ActionTypes = {
    SHOW_MODAL: 'core.designer.common.SHOW_MODAL',
    HIDE_MODAL: 'core.designer.common.HIDE_MODAL',
    SET_MODAL_VALUES: 'core.designer.common.SET_MODAL_VALUES'
}

export default ActionTypes;

export function showModal(modalId, config) {
    return {
        type: ActionTypes.SHOW_MODAL,
        modalId,
        config
    }
}

export function hideModal(modalId) {
    return {
        type: ActionTypes.HIDE_MODAL,
        modalId
    }
}

export function setModalValues(modalId, values) {
    return {
        type: ActionTypes.SET_MODAL_VALUES,
        modalId,
        values
    }
}
