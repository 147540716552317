import { Record, List, RecordOf } from 'immutable';

export type HistoryState = {
    breadcrumbs: LocationItemProps[];
    params?: { [key: string]: unknown };
}

type LocationItemProps = {
    pathName: string;
    title: string;
    translateTitle?: boolean;
    params?: { [key: string]: unknown };
}

export type LocationItem = RecordOf<LocationItemProps>;

type InitialStateProps = {
    breadcrumbs: List<LocationItem>;
    params: { [key: string]: unknown } | undefined;
}

export type InitialState = RecordOf<InitialStateProps>;

type LocationStateProps = {
    initial: InitialState;
    breadcrumbs: List<LocationItem>;
    params: { [key: string]: unknown } | undefined;
}

export type LocationState = RecordOf<LocationStateProps>;

export const createLocationItem = Record<LocationItemProps>({
    pathName: '',
    title: '',
    translateTitle: true,
    params: undefined
});

export const createInitialState = Record<InitialStateProps>({
    breadcrumbs: List(),
    params: undefined
});

export const createLocationState = Record<LocationStateProps>({
    initial: createInitialState(),
    breadcrumbs: List(),
    params: undefined
});
