import React from 'react';
import { InjectedFormProps, reduxForm } from "redux-form";
import { Button, Row, Form } from 'reactstrap';

import { FormFieldConfig } from "../form/types";
import FormFieldsLayout from '../form/FormFieldsLayout';

type OwnProps = {
    fields: { [key: string]: FormFieldConfig }
};

function ProductBinderForm(props: InjectedFormProps<any, OwnProps> & OwnProps) {
    return (
        <Form>
            <FormFieldsLayout fields={props.fields}/>
            <Row form={true as any}>
                <Button onClick={props.handleSubmit}>Search</Button>
            </Row>
        </Form>);
}

export default reduxForm<any, OwnProps>({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(ProductBinderForm);
