import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSagas from './sagas/root-sagas';

// import the root reducer
import rootReducer from './reducers/index-lib';

import designer from './core/designer/data/datamock';

// thunk
import thunkMiddleware from 'redux-thunk';

// create an object for the default data
const defaultState = {
    designer
};

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  defaultState,
  compose(
    applyMiddleware(thunkMiddleware, sagaMiddleware),
    window.devToolsExtension ? window.devToolsExtension({
      name: 'Enterprise Designer',
      stateSanitizer
    }) : (f) => f
  ));

function stateSanitizer(state) {
  // figure-descriptors object can be very large, so hide in redux-dev-tools for performance
  const designer = state.designer && state.designer.update('base-figure-descriptors', (figureDescriptors) => figureDescriptors ? '<<LARGE_OBJECT>>' : figureDescriptors);
  return { ...state, designer };
}  

sagaMiddleware.run(rootSagas);

if(module.hot) {
  module.hot.accept('./reducers/',() => {
    const nextRootReducer = require('./reducers/index').default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
