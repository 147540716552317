import { useEffect, useReducer, Reducer, useMemo } from "react";
import { FetcherState, FetcherActions } from "../../components/fetcher/fetcherTypes";


export type Options<T> = {
    onDataReceived?: (data: T) => void;
    onError?: (err: any) => void;
    delay?: number;
}

type State<T> = {
    data: T | undefined;
    error: any;
    fetching: boolean;
    refreshCount: number;
}

enum ActionType {
    RECEIVE_DATA,
    RECEIVE_ERROR,
    BEGIN_FETCH,
    TRIGGER_REFRESH
}

type Action<T> =
    { type: ActionType.RECEIVE_DATA, data: T } |
    { type: ActionType.RECEIVE_ERROR, error: any } |
    { type: ActionType.BEGIN_FETCH } |
    { type: ActionType.TRIGGER_REFRESH }

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
    switch (action.type) {
        case ActionType.BEGIN_FETCH:
            // optimization: return previous state to avoid component re-render, when no change is required
            if (state.fetching && !state.error) return state;
            return { ...state, fetching: true, error: undefined };
        case ActionType.RECEIVE_DATA:
            return { ...state, data: action.data, fetching: false, error: undefined };
        case ActionType.RECEIVE_ERROR:
            return { ...state, fetching: false, error: action.error };
        case ActionType.TRIGGER_REFRESH:
            return { ...state, fetching: true, refreshCount: state.refreshCount + 1 };
        default:
            return state;
    }
}

/**
 * Uncached fetcher hook. Fetches new data whenever the component is mounted or when a new updater function was provided.
 * @param updater - the function to call to fetch new data
 * @param opts - fetcher options
 */

export function useFetcher<T>(
    updater: () => Promise<T>,
    opts?: Options<T>
): FetcherState<T> {
    const onDataReceived = opts && opts.onDataReceived;
    const onError = opts && opts.onError;
    let delay = opts && opts.delay;
    if (delay == null) {
        delay = 0;
    }

    const initialState: State<T> = { refreshCount: 0, data: undefined, error: undefined, fetching: true };
    const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(reducer, initialState);

    useEffect(() => {
        let cancelled = false;
        dispatch({ type: ActionType.BEGIN_FETCH });
        const handle = setTimeout(() => {
            updater()
                .then(
                    (data) => {
                        // received a result
                        if (!cancelled) {
                            dispatch({ type: ActionType.RECEIVE_DATA, data });
                            if (onDataReceived) {
                                onDataReceived(data);
                            }
                        }
                    },
                    (error) => {
                        // received an error
                        console.error(error);
                        if (!cancelled) {
                            dispatch({ type: ActionType.RECEIVE_ERROR, error });
                            if (onError) {
                                onError(error);
                            }
                        }
                    }
                );
        }, delay);

        return () => {
            cancelled = true;
            clearTimeout(handle);
        }
    }, [updater, delay, state.refreshCount]);

    // Construct the actions to expose to the consumer
    const actions = useMemo((): FetcherActions => ({
        refreshData() {
            dispatch({ type: ActionType.TRIGGER_REFRESH });
        }
    }), []);

    return {
        data: state.data,
        error: state.error,
        fetching: state.fetching,
        actions
    }
}
