import React from 'react';
import PropTypes from 'prop-types';
import { resourceIdToPath } from '../../helper/figurePathHelper';
import pdfjs from 'pdfjs-dist';

const PAGER_HEIGHT = 25;
const PAGER_PADDING = 3;
const BTN_WIDTH = 50;

export default class PdfFigureComponent extends React.Component {
    constructor(props) {
        super(props);

        // use state to track the current page, because
        // component not part of the main app
        this.state = { page: props.page };

        this.inited = false;
        this.loading = false;
        this.canvasScale = 1;
        this.canvas = document.createElement('canvas');

        // bind callbacks to this instance
        this.movePageLeft = this.movePage.bind(this, -1);
        this.movePageRight = this.movePage.bind(this, 1);
    }

    getCanvasZoomLevel() {
        // quick and dirty way to get the zoom level of the canvas
        const paper = this.props.canvas.paper;
        const viewBox = paper.canvas.viewBox.baseVal;
        const width = paper.canvas.width.baseVal.value;
        const height = paper.canvas.height.baseVal.value;
        return Math.min(width / viewBox.width, height / viewBox.height);
    }

    render() {
        return (
            <React.Fragment>
                {/* header */}
                <rect x="0" y="0" width={this.props.width} height={this.props.height} className="pdf-border"/>
                <rect x="0" y="0" width={this.props.width} height={PAGER_HEIGHT} className="pdf-pager"/>
                <text x={this.props.width/2} y={PAGER_HEIGHT/2}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    className="pdf-pager-text">{ this.getPagerText() }</text>
                
                {/* pager buttons */}
                {this.renderPrevButton()}
                {this.renderNextButton()}

                {/* canvas */}
                { this.renderImage() }
            </React.Fragment>);
    }

    getPagerText() {
        return `${this.state.page} / ${this.totalPages}`;
    }

    renderPrevButton() {
        if(this.state.page < 2) return;

        const x1 = PAGER_PADDING;
        const x2 = x1 + BTN_WIDTH;
        const y1 = PAGER_PADDING;
        const y2 = PAGER_HEIGHT - PAGER_PADDING;

        return (
            <React.Fragment>
                <rect x={x1} y={y1} rx="5" ry="5" width={x2 - x1} height={y2 - y1} className="pdf-header-btn" onClick={this.movePageLeft}/>
                { this.renderLabel((x1 + x2) / 2, (y1 + y2) / 2, 'Prev', this.movePageLeft) }
            </React.Fragment>);
    }

    renderNextButton() {
        if(this.state.page === this.totalPages) return;

        const x1 = this.props.width - PAGER_PADDING - BTN_WIDTH;
        const x2 = this.props.width - PAGER_PADDING;
        const y1 = PAGER_PADDING;
        const y2 = PAGER_HEIGHT - PAGER_PADDING;

        return (
            <React.Fragment>
                <rect x={x1} y={y1} rx="5" ry="5" width={x2 - x1} height={y2 - y1} className="pdf-header-btn" onClick={this.movePageRight}/>
                { this.renderLabel((x1 + x2) / 2, (y1 + y2) / 2, 'Next', this.movePageRight) }
            </React.Fragment>);
    }

    renderLabel(x, y, text, onClick) {
        return <text x={x} y={y}
            textAnchor="middle"
            alignmentBaseline="central"
            className="pdf-header-btn-txt"
            onClick={onClick}>{text}</text>
    }


    movePage(offset) {
        this.setState({ page: this.state.page + offset });
    }

    renderImage() {
        const imageWidth = this.canvas.width / this.canvasScale;
        const imageHeight = this.canvas.height / this.canvasScale;
        const xOffset = Math.round((this.props.width - imageWidth) / 2);
        return <image x={xOffset} y={PAGER_HEIGHT}
            width={imageWidth}
            height={imageHeight}
            xlinkHref={this.dataUrl}/>
    }

    UNSAFE_componentWillMount() {
        if(this.hasRequiredAttributes(this.props, this.state)) {
            this.inited = true;
            this.loadPdfViewer();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.page !== nextProps.page) {
            this.setState({ page: nextProps.page });
        }
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if(this.loading || !this.hasRequiredAttributes(nextProps, nextState)) return;

        const props = this.props;
        const state = this.state;
        this.props = nextProps;
        this.state = nextState;

        if(!this.inited) {
            this.inited = true;
            this.loadPdfViewer();

        } else if(props.url !== nextProps.url) {
            this.loadPdfViewer();

        } else if(this.pdf && state.page !== nextState.page) {
            this.loadPdfPage(this.pdf);

        } else if(this.page && (props.width !== nextProps.width || props.height !== nextProps.height)) {
            this.renderPdfPage(this.page);
        }
    }

    loadPdfViewer() {
        this.loading = true;
        const pdfUrl = this.props.url;

        // load the PDF
        const fullUrl = resourceIdToPath(pdfUrl);
        pdfjs.getDocument(fullUrl).promise.then((pdf) => {
            this.pdf = pdf;

            console.log('pdf loaded');
            this.loadPdfPage(pdf);

        }, (reason) => {
            console.error('Error loading PDF', reason);
        });
    }

    loadPdfPage(pdf) {
        this.loading = true;
        let page = this.state.page;
        page = this.clipPageToRange(page);

        pdf.getPage(page).then((page) => {
            this.page = page;
            this.renderPdfPage(page);
            
        }, (reason) => {
            console.error('Error loading page: ' + page, reason);
        });
    }

    renderPdfPage(page) {
        this.loading = true;
        
        const props = this.props;
        const view = page.pageInfo.view;
        const viewW = view[2];
        const viewH = view[3];
        const availableW = props.width;
        const availableH = props.height - PAGER_HEIGHT;

        // scale to the size of the figure
        const scale = Math.min(availableW / viewW, availableH / viewH);

        // take into account the viewbox of the svg
        const canvasScale = this.getCanvasZoomLevel();

        const viewport = page.getViewport(scale * canvasScale);
        
        // render to off-screen canvas
        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;
        const ctx = this.canvas.getContext('2d');
        const renderContext = {
            canvasContext: ctx,
            viewport
        };
        
        page.render(renderContext).then(() => {
            
            // width and height changed since we started rendering.
            // Render again with the latest values.
            if(props.width !== this.props.width || props.height !== this.props.height) {
                this.renderPdfPage(page);
                return;
            } 
            
            // done loading and rendering
            // force the React component to re-render
            this.loading = false;
            this.canvasScale = canvasScale;
            this.dataUrl = this.canvas.toDataURL('image/png');
            this.forceUpdate();
        });
    }

    hasRequiredAttributes(props, state) {
        const size = this.props.canvas.paper.getSize();
        if(size.height <= 0) return false;

        return props.url
            && state.page != null
            && props.width != null
            && props.height != null;
    }

    clipPageToRange(page) {
        return Math.max(1, Math.min(page, this.totalPages));
    }

    get totalPages() {
        if(!this.pdf) return 1;
        return this.pdf.numPages;
    }
}

PdfFigureComponent.propTypes = {
    url: PropTypes.string,
    page: PropTypes.any,
    width: PropTypes.number,
    height: PropTypes.number,
    hidePager: PropTypes.bool,
    canvas: PropTypes.object
}
