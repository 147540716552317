import {List, fromJS} from 'immutable'
import {remoteCall, makeErrorResponseHandler} from '../../../util/remote'
import {createDatasourceRequest, mapFormToDatasourceRequestValues, createLiveAssistRequestPayload, createFieldInputEvaluator} from '../../../util/datasource'
import {getFormData} from '../../../util/form'
import {change as reduxFormChange} from 'redux-form';
import _ from 'lodash';

const DatasourceActionTypes = {
  SHOW_SUGGESTION_DIALOG: 'datasource.SHOW_SUGGESTION_DIALOG',
  CLOSE_SUGGESTIONS_DIALOG: 'datasource.CLOSE_SUGGESTIONS_DIALOG',
  CLOSE_SUGGESTIONS_SELECT: 'datasource.CLOSE_SUGGESTIONS_SELECT',
  UPDATE_SUGGESTIONS_DONE: 'datasource.UPDATE_SUGGESTIONS_DONE',
  UPDATE_SUGGESTIONS_STARTED: 'datasource.UPDATE_SUGGESTIONS_STARTED',
  UPDATE_SUGGESTIONS_ERROR: 'datasource.UPDATE_SUGGESTIONS_ERROR',
  SUGGESTION_ACCEPTED: 'datasource.SUGGESTION_ACCEPTED',
  SUGGESTIONS_ACTIVE: 'datasource.SUGGESTIONS_ACTIVE',
  SUGGESTIONS_INACTIVE: 'datasource.SUGGESTIONS_INACTIVE',
  TRIM_SUGGESTIONS: 'datasource.TRIM_SUGGESTIONS',
  NAVIGATE_SUGGESTION_UP: 'datasource.NAVIGATE_SUGGESTION_UP',
  NAVIGATE_SUGGESTION_DOWN: 'datasource.NAVIGATE_SUGGESTION_DOWN',
  NAVIGATE_SUGGESTION_ACCEPT: 'datasource.NAVIGATE_SUGGESTION_ACCEPT',
  FIND_DONE: 'datasource.FIND_DONE',
  FIND_STARTED: 'datasource.FIND_STARTED',
  FIND_ERROR: 'datasource.FIND_ERROR',
  CLEAR_DATASOURCE: 'datasource.CLEAR_DATASOURCE'
}
export default DatasourceActionTypes

const RemoteUrl = {
  INVOKE_DATASOURCE: 'sec/api/datasource/invoke'
}

export function find(formId, datasourceDefinition, recipient) {
  return (dispatch, getState) => {
    const state = getState()
    const formValues = getFormData(state, formId)
    const fieldInputEvaluator = createFieldInputEvaluator(state)
    const datasourceValues = mapFormToDatasourceRequestValues(datasourceDefinition, formValues, fieldInputEvaluator)
    const datasourceType = datasourceDefinition.get('name');
    const requestBody = createDatasourceRequest(datasourceType, datasourceValues)

    const successFn =  (results) => dispatch({
        type: DatasourceActionTypes.FIND_DONE,
        request: requestBody,
        result: fromJS(results),
        datasourceType,
        formId,
        recipient
    })
    const failFn = (error) => dispatch({
        type: DatasourceActionTypes.FIND_ERROR,
        request: requestBody,
        result: 'Error ' + error,
        formId, recipient
    })
    dispatch({
        type: DatasourceActionTypes.FIND_STARTED,
        request: requestBody,
        formId, recipient
    })
    remoteCall(RemoteUrl.INVOKE_DATASOURCE, requestBody, successFn, makeErrorResponseHandler(failFn, dispatch))
  }
}

export function fetchSuggestionsOnChange({formId, fieldId, liveAssist}) {
  //console.log(JSON.stringify(props, null, 2))
  return (dispatch, getState) => {
    const requester = List([formId, fieldId])
    let formValues = getFormData(getState(), formId)
    const fieldValue = formValues.getIn(_.toPath(fieldId)) || '';
    formValues = formValues.set(fieldId, fieldValue + '%')

    const payload = createLiveAssistRequestPayload(liveAssist, fieldId, formValues)
    const datasourceName = liveAssist.get('datasource')
    const requestBody = createDatasourceRequest(datasourceName, payload)
    
    const minLength = liveAssist.get('minLength', 3)
    if (fieldValue.length < minLength) {
      dispatch({
          type: DatasourceActionTypes.UPDATE_SUGGESTIONS_DONE,
          request: requestBody,
          requester,
          result: List(),
          message: 'Input [' + fieldValue + '] too short'
      })
      return
    }

    const successFn =  (results) => dispatch({
        type: DatasourceActionTypes.UPDATE_SUGGESTIONS_DONE,
        request: requestBody,
        requester,
        result: fromJS(results).get(0)
    })
    const failFn =  (response) => dispatch({
        type: DatasourceActionTypes.UPDATE_SUGGESTIONS_ERROR,
        request: requestBody,
        requester,
        message: 'Error ' + response.status
    })
    dispatch({
        type: DatasourceActionTypes.UPDATE_SUGGESTIONS_STARTED,
        requester,
        request: requestBody
    })
    remoteCall(RemoteUrl.INVOKE_DATASOURCE, requestBody, successFn, makeErrorResponseHandler(failFn, dispatch))
  }
}

export function showSuggestionDialog({formId, fieldId, formDatasource}) {
  return (dispatch, getState) => {
    const requester = List([formId, fieldId])
    const state = getState();
    const formValues = getFormData(state, formId)
    const fieldInputEvaluator = createFieldInputEvaluator(state);
    const datasourceValues = mapFormToDatasourceRequestValues(formDatasource, formValues, fieldInputEvaluator)

    dispatch({
      type: DatasourceActionTypes.SHOW_SUGGESTION_DIALOG,
      requester,
      initialDatasourceValues: datasourceValues
    })
  }
}

export function trimSuggestions(requester, trimmed) {
  return {
    type: DatasourceActionTypes.TRIM_SUGGESTIONS,
    requester,
    trimmed
  }
}

export function acceptSuggestion(formId, fieldId, value, suggestion, requester) {
  return (dispatch) => {
    dispatchAcceptSuggestion(dispatch, formId, fieldId, value, suggestion, requester)
  }
}

function dispatchAcceptSuggestion(dispatch, formId, fieldId, value, suggestion, requester) {
  // dispatch close + dispatch form field changes:
  dispatch(closeSuggestionsOnSelect(requester))
  dispatch(closeSuggestionsDialog(requester))
  dispatch(reduxFormChange(formId, fieldId, value));
  dispatch(suggestionAccepted(requester, suggestion))
}

function suggestionAccepted(requester, item) {
  return {
    type: DatasourceActionTypes.SUGGESTION_ACCEPTED,
    requester, item
  }
}

export function clearLinkedValues({formId, fieldId, formDatasource}) {
  return (dispatch) => {
    formDatasource.get('get').forEach((datasourceField, formField) => {
      if (formField != fieldId) {
        dispatch(reduxFormChange(formId, formField, ''))
      }
    })
  }
}

export function navigateSuggestionUp(requester) {
  return {
    type: DatasourceActionTypes.NAVIGATE_SUGGESTION_UP,
    requester
  }
}

export function navigateSuggestionDown(requester) {
  return {
    type: DatasourceActionTypes.NAVIGATE_SUGGESTION_DOWN,
    requester
  }
}

export function navigateSuggestionAccept(formId, fieldId, valueFn, requester) {
  return (dispatch, getState) => {
    const state = getState()
    const focused = state.datasource.getIn(requester.push('suggestions', 'focused'), 0);
    const suggestion = state.datasource.getIn(requester.push('suggestions', 'items', focused));
    
    if (suggestion != null) {
      const value = valueFn(suggestion);
      dispatchAcceptSuggestion(dispatch, formId, fieldId, value, suggestion, requester)
    }
  }
}

export function suggestionsActive(requester) {
  return {
    type: DatasourceActionTypes.SUGGESTIONS_ACTIVE,
    requester
  }
}

export function suggestionsInactive(requester) {
  return {
    type: DatasourceActionTypes.SUGGESTIONS_INACTIVE,
    requester
  }
}

export function closeSuggestionsOnSelect(requester) {
  return {
    type: DatasourceActionTypes.CLOSE_SUGGESTIONS_SELECT,
    requester
  }
}

export function closeSuggestionsDialog(requester) {
  return {
    type: DatasourceActionTypes.CLOSE_SUGGESTIONS_DIALOG,
    requester
  }
}

export function clearDatasource(datasourceId) {
  return {
    type: DatasourceActionTypes.CLEAR_DATASOURCE,
    datasourceId
  }
}
