import React, { useState } from "react";
import { InputGroup, InputGroupAddon, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column, Row } from "@pearlchain/component-lib-react-pg";

import TextBinder from "./TextBinder";
import ModalContainer from "../common/ModalContainer";
import SearchBinderModal from "./SearchBinderModal";
import { BaseBinderProps } from "./types";
import { FormFieldConfig } from "../form/types";

type Props = {
    columns: Column[],
    formId: string,
    formValueField: string,
    formFields: { [key: string]: FormFieldConfig },
    modalRoot: string,
    doSearch: (formValues: any) => Promise<Row[]>
} & BaseBinderProps;

export default function SearchInModalBinder(props: Props) {
    const [isOpen, setOpen] = useState(false);
    const value = props.input.value as string|undefined;

    function handleClosed() {
        setOpen(false);
    }

    function handleComplete(value: string) {
        props.input.onChange(value);
        setOpen(false);
    }

    return (
        <React.Fragment>
            <InputGroup>
                <TextBinder
                    input={props.input}
                    meta={props.meta}
                    datacy={props.datacy}
                    disabled={props.disabled}/>
                <InputGroupAddon addonType="append">
                    <Button onClick={() => setOpen(true)}>
                        <FontAwesomeIcon icon={['fas', 'search']}/>
                    </Button>
                </InputGroupAddon>
            </InputGroup>

            <ModalContainer rootId={props.modalRoot}>
                <SearchBinderModal
                    value={value}
                    isOpen={isOpen}
                    onClosed={handleClosed}
                    onComplete={handleComplete}
                    formId={props.formId}
                    formValueKey={props.formValueField}
                    formFields={props.formFields}
                    columns={props.columns}
                    doSearch={props.doSearch}
                />
            </ModalContainer>
        </React.Fragment>);
}

