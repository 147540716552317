import Imm from 'immutable';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { figureSelector, figurePathSelector, figureParentsSelector } from './figuresConfigSelector';
import { getAllComputedValues } from './canvasFigureConfigSelectors';
import { createFigureIdentifier } from '../figurePathHelper';
import { getFigureId } from '../figureConfigHelper';

export const getSelection = (state, designId) => state.designer.getIn(['designs', designId, 'selected-figure']);

export const getSelectedPrimaryFigureId = (selection) => {
    if(selection == null) return undefined;
    return selection.get('figures', Imm.List()).last();
}

export const getSelectedSecondaryFigureIds = (selection) => {
    if(selection == null) return Imm.List();
    return selection.get('figures', Imm.List()).pop();
}

export const getSelectionType = (selection) => selection && selection.get('figureType');

export const getAllSelectedFigureIds = (selection) => {
    if(selection == null) return Imm.List();

    const primaryId = getSelectedPrimaryFigureId(selection);
    const secondaryIds = getSelectedSecondaryFigureIds(selection);
    if(primaryId && !secondaryIds)
        return Imm.List().push(primaryId);

    if(secondaryIds && !primaryId)
        return secondaryIds;

    if(primaryId && secondaryIds) {
        return Imm.List().push(primaryId).concat(secondaryIds);
    }

    return Imm.List();
}

export function getComputedValuesForSelection(computedValues, selection) {
    if(getSelectionType(selection) !== 'canvas') return;
    const figureIds = getAllSelectedFigureIds(selection);
    return Imm.List().withMutations((li) => {
        for(let figureId of figureIds) {
            const computedValue = computedValues.get(figureId);
            computedValue != null && (li.push(computedValue));
        }
    });
}

export function getFiguresForSelection(figureSelector, selection) {
    if(selection == null) return Imm.List();
    const figureType = getSelectionType(selection);
    const figureIds = getAllSelectedFigureIds(selection);
    return figureIds.map((figureId) => {
        return figureSelector({ figureType, figureId });
    })
}

export function getPrimaryFigureForSelection(allSelectedFigures, selection) {
    if(selection == null) return;
    const primaryId = getSelectedPrimaryFigureId(selection);
    return allSelectedFigures.find((figure) => getFigureId(figure) === primaryId);
}

export function getSecondaryFiguresForSelection(allSelectedFigures, selection) {
    if(selection == null) return Imm.List();
    const primaryId = getSelectedPrimaryFigureId(selection);
    return allSelectedFigures.filter((figure) => getFigureId(figure) !== primaryId);
}

export function getAltIndexes(figures) {
    if(figures == null) return [];

    let max = 0;
    for(let figure of figures) {
        const altConfigs = figure.get('altConfigs');
        if(altConfigs && altConfigs.size > max) max = altConfigs.size;
    }

    const result = [-1];
    for(let i = 0; i < max; ++i) result.push(i);
    return Imm.List(result);
}

export function getPropKeys(figures, altIndex) {
    const configPath = getAltConfigPath(altIndex);
    return figures.reduce((acc, figure, idx) => {
        const figureConfig = figure.getIn(configPath);
        if(figureConfig != null) {
            const data = figureConfig.get('data');
            const input = figureConfig.get('input');
            let keys = Imm.Seq();
            
            if(data != null) keys = data.keySeq();

            if(input != null) keys = keys.concat(input.keySeq());
            
            return idx ? acc.intersect(keys) : acc.union(keys);
        }

        return acc;
    }, Imm.Set()).toList();
}

function mergeValues(values, selector) {
    if(values == null) return;

    let prev, i = 0;
    for(let figureConfig of values) {
        const value = selector(figureConfig);
        if(i++ === 0 || value === prev) {
            prev = value;
        } else return;
    }
    return prev;
}

export function getAltConfigPath(altIndex) {
    return altIndex < 0 ? ['mainConfig'] : ['altConfigs', altIndex]; 
}

export function getPropInputForSelection(figureSelector, selection, propKey, altIndex) {
    if(selection == null) return Imm.Map();
    const figureConfigs = getFiguresForSelection(figureSelector, selection);
    return mergeValues(figureConfigs, (figureConfig) => figureConfig.getIn(getAltConfigPath(altIndex).concat('input', propKey)));
}

export function getPropDataForSelection(figureSelector, selection, propKey, altIndex) {
    if(selection == null) return;
    const figureConfigs = getFiguresForSelection(figureSelector, selection);
    return mergeValues(figureConfigs, (figureConfig) => figureConfig.getIn(getAltConfigPath(altIndex).concat('data', propKey)));
}

export function getPropResultForSelection(computedValues, selection, propKey) {
    if(selection == null) return Imm.Map();
    const values = getComputedValuesForSelection(computedValues, selection);
    return values.flatMap((value) => value.get('figures')).map((fig) => fig.get(propKey));
}

export function getConfigPathsForSelection(pathSelector, selection) {
    if(selection == null) return Imm.List();
    const figureIds = getAllSelectedFigureIds(selection);
    const figureType = getSelectionType(selection);
    return figureIds.map((figureId) => pathSelector({ figureType, figureId }));
}

export function getSelectedFigureIdentifiers(figureIds, selectionType, designId) {
    return figureIds.map(createFigureIdentifier.bind(null, selectionType, designId));
}

/**
 * Calculates whether a figure is selected
 * and its relationship to the current selection
 */
export function getFigureSelectionState(primaryIdentifier, figureIdentifiers, figureParents, figureId) {
    if(primaryIdentifier) {
        if(primaryIdentifier.figureId === figureId) return 'primary';
        const parents = figureParents(primaryIdentifier);
        if(parents && parents.findIndex((p) => p.get('id') === figureId) > -1) {
            return 'child-primary';
        }
    }

    if(figureIdentifiers) {
        for(let secondaryIdentifier of figureIdentifiers) {
            if(secondaryIdentifier.figureId === figureId) return 'secondary';
            const parents = figureParents(secondaryIdentifier);
            if(parents && parents.findIndex((p) => p.get('id') === figureId) > -1) {
                return 'child-secondary';
            }
        }
    }
    
    return 'unselected';
}

export const extractDesignId = (state, designId) => designId;

// shared selectors

export const figuresSelector = createSelector(figureSelector, getSelection, getFiguresForSelection);

export const primaryFigureSelector = createSelector(figuresSelector, getSelection, getPrimaryFigureForSelection);

export const secondaryFiguresSelector = createSelector(figuresSelector, getSelection, getSecondaryFiguresForSelection);

export const figurePathsSelector = createSelector(figurePathSelector, getSelection, getConfigPathsForSelection);

export const selectedFigureIdsSelector = createSelector(getSelection, getAllSelectedFigureIds);

export const primaryFigureIdSelector = createSelector(getSelection, getSelectedPrimaryFigureId);

export const secondaryFigureIdsSelector = createSelector(getSelection, getSelectedSecondaryFigureIds);

export const selectionTypeSelector = createSelector(getSelection, getSelectionType);

export const altIndexesSelector = createSelector(figuresSelector, getAltIndexes);

export const figureIdentifiersSelector = createSelector(selectedFigureIdsSelector, selectionTypeSelector, extractDesignId,
    getSelectedFigureIdentifiers);

export const primaryFigureIdentifierSelector = createSelector(selectionTypeSelector, extractDesignId, primaryFigureIdSelector,
    createFigureIdentifier);

export const figureSelectionStateSelector = createCachedSelector(
    primaryFigureIdentifierSelector,
    figureIdentifiersSelector,
    figureParentsSelector,
    (state, designId, figureId) => figureId,
    getFigureSelectionState)((state, designId, figureId) => [designId, figureId].join());

// selector creators

export const createPropResultSelector = (propKey, altIndex) => createSelector(
    getAllComputedValues, getSelection, (computedVals, selection) => getPropResultForSelection(computedVals, selection, propKey, altIndex))

export const createPropDataSelector = (propKey, altIndex) => createSelector(
    figureSelector, getSelection, (figureSelector, selection) => getPropDataForSelection(figureSelector, selection, propKey, altIndex));

export const createPropInputSelector = (propKey, altIndex) => createSelector(
    figureSelector, getSelection, (figureSelector, selection) => getPropInputForSelection(figureSelector, selection, propKey, altIndex));
    
export const createPropKeysSelector = (altIndex) => createSelector(
    figuresSelector, (figures) => getPropKeys(figures, altIndex));
