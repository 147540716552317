import React, { useMemo } from 'react';

import { useCachedFetcher } from '../../utils/hooks/useCachedFetcher';
import { FetcherState } from './fetcherTypes';

type OwnProps<T> = {
    requestId: string | string[];
    onDataReceived?: (data: T) => void;
    onError?: (error: any) => void;
    isUpdateRequired?: (data: T | undefined) => boolean;
    updater: () => Promise<T>;
    render: (data: FetcherState<T>) => React.ReactElement;
}

/*
 * Component wrapper around the useCachedFetcher hook
 */

export default function CachedFetcher<T>({ render, updater, requestId, isUpdateRequired, onDataReceived, onError }: OwnProps<T>) {
    const data = useCachedFetcher(updater, requestId, {
        isUpdateRequired,
        onDataReceived,
        onError
    });

    return useMemo(() => render(data), [render, data]);
}
