import DesignerActionTypes from '../actions/DesignerActions'
import * as DesignerHelper from '../helper/DesignerHelper'
import DatasourceActions from '../../../common/view/actions/DatasourceActions'
import { Map, List, fromJS } from 'immutable'
import {actionTypes as REDUX_FORM_ACTIONS} from 'redux-form';
import dataRequestReducer from '../datarequest/reducers'
import commonReducers from '../common/reducers';
import figureGroupReducers from './FigureGroupReducers';
import computedPropsReducers from './computedPropsReducers';
import galleryReducers from './galleryReducers';
import drilldownReducers from './drilldownReducers';
import { clearFigureInput, createFigureConfig, copyFigureConfig } from '../helper/designerReducerHelper';
import { canvasFigureToConfig } from '../helper/figureConfigHelper';
import { figurePathSelector } from '../helper/selector/figuresConfigSelector';
import { getParentsMapping } from '../helper/selector/canvasFigureConfigSelectors';
import { parentsToRelativePath } from '../helper/selector/util/createFigureParentsSelector';
import { mapDeep } from '../helper/treeHelper';
import { getFigureId } from '../helper/figureConfigHelper';
import { primaryFigureSelector } from '../helper/selector/selectionSelectors';
import { getCurrentDesignId } from '../helper/selector/selectors';
import designerUndoReducers from './designerUndoReducers';
import reportReducers from './reportReducers';
import { extractMetaAndValuesFromResult, datasourcesData } from '../data/datasourceFieldsData';
import _ from 'lodash';

export default function designerReducer(state = Map(), action) {
  switch(action.type) {
    case DesignerActionTypes.SET_DESIGNER_MODE:
      return handleSetDesignerMode(state, action);
    case DesignerActionTypes.SET_SELECTED_DESIGN:
      return handleSetSelectedDesign(state, action);
    case DesignerActionTypes.CREATE_FIGURE_ON_CANVAS:
      return handleCreateFigureOnCanvas(state, action)
    case DesignerActionTypes.SELECT_FIGURE:
      return handleFigureSelected(state, action)
    case DesignerActionTypes.SELECT_SECONDARY_FIGURE:
      return handleSelectSecondaryFigure(state, action)
    case DesignerActionTypes.DELETE_FIGURE_CONFIG:
      return handleDeleteFigureConfig(state, action)
    case DesignerActionTypes.CLEAR_SELECTED_FIGURE:
      return handleClearSelectedFigure(state, action)
    case DesignerActionTypes.CHANGE_FIGURES:
      return handleChangeFigures(state, action)
    case DesignerActionTypes.DELETE_FIGURES:
      return handlDeleteFigures(state, action)
    case DesignerActionTypes.CHANGE_FIGURE_CONFIG:
      return handleChangeFigureConfig(state, action)
    case DesignerActionTypes.SET_DATASOURCE_RESULT:
      return handleSetDatasourceResult(state, action)
    case DesignerActionTypes.EXECUTE_FIGURE_ACTION:
      return handleExecuteFigureAction(state, action)
    case DesignerActionTypes.DESIGN_SELECTION_HINTS_FETCH_DONE:
      return handleDesignSelectionHintsFetchDone(state, action)
    case DesignerActionTypes.DESIGN_SELECTION_HINTS_FETCH_ERROR:
      return handleDesignSelectionHintsFetchError(state, action)
    case DesignerActionTypes.DESIGN_SELECTION_HINTS_FETCH_STARTED:
      return handleDesignSelectionHintsFetchStarted(state)
    case DesignerActionTypes.FIELD_VALUE_UPDATED:
      return handleFieldValueUpdated(state, action)
    case DesignerActionTypes.MOUNT_SELECTOR:
      return handleMountSelector(state, action)
    case DesignerActionTypes.DESIGN_CREATED:
      return handleDesignCreated(state, action)
    case DesignerActionTypes.REMOVE_FIGURE_CONFIG:
      return handleRemoveFigureConfig(state, action)
    case DesignerActionTypes.ADD_FIGURE_CONFIG:
      return handleAddFigureConfig(state, action)
    case DesignerActionTypes.MOVE_FIGURE_CONFIG_DOWN:
      return handleMoveFigureConfigDown(state, action)
    case DesignerActionTypes.MOVE_FIGURE_CONFIG_UP:
      return handleMoveFigureConfigUp(state, action)
    case DesignerActionTypes.SHOW_FIGURE_CONFIG_DETAILS:
      return handleShowFigureConfigDetails(state, action)
    case DesignerActionTypes.CANCEL_FIGURE_CONFIG_DETAILS:
      return handleCancelFigureConfigDetails(state, action)
    case DesignerActionTypes.ACCEPT_FIGURE_CONFIG_DETAILS:
      return handleAcceptFigureConfigDetails(state, action)
    case DesignerActionTypes.INCLUDE_FIGURE_PROPERTY:
      return includeFigureProperty(state, action)
    case DesignerActionTypes.EXCLUDE_FIGURE_PROPERTY:
      return excludeFigureProperty(state, action)
    case DesignerActionTypes.UPDATE_SELECTED_INPUT_FIELD_VALUE:
      return handleSelectedInputFieldValueUpdated(state, action)
    case DesignerActionTypes.UPDATE_SELECTED_DATA_FIELD_VALUE:
      return handleSelectedDataFieldValueUpdated(state, action)
    case DesignerActionTypes.SET_CLONE_FIGURE_NAME:
      return handleSetCloneFigureName(state, action);
    case DesignerActionTypes.CLONE_FIGURE_CONFIG:
      return handleCloneFigureConfig(state, action);
    case DesignerActionTypes.MOVE_FIGURE_Z_DOWN:
      return handleMoveFigureZDown(state, action);
    case DesignerActionTypes.MOVE_FIGURE_Z_UP:
      return handleMoveFigureZUp(state, action);
    case DesignerActionTypes.MOVE_FIGURE_Z_TO_BOTTOM:
      return handleMoveFigureZToBottom(state, action);
    case DesignerActionTypes.MOVE_FIGURE_Z_TO_TOP:
      return handleMoveFigureZToTop(state, action);
    case DesignerActionTypes.COPY_FIGURE:
      return handleCopyFigure(state, action);
    case DesignerActionTypes.SPLIT_PANE_RESIZED:
      return handleSplitPaneResized(state, action);
    case DesignerActionTypes.SET_FIGURE_NAME:
      return handleSetFigureName(state, action);
    case DesignerActionTypes.SET_WINDOW_SIZE:
      return handleSetWindowSize(state, action);
    case DesignerActionTypes.CANVAS_CONFIG_SAVE_CHANGES:
      return handleCanvasConfigSaveChanges(state, action);
    case DesignerActionTypes.TRIGGER_REFRESH_DATASOURCES:
      return handleTriggerRefreshDatasources(state, action);
    case DesignerActionTypes.SET_DATASOURCES_LOADING:
      return handleSetDatasourcesLoading(state, action);
    case DesignerActionTypes.PUSH_VIEW:
      return handlePushView(state, action);
    case DesignerActionTypes.POP_VIEW:
      return handlePopView(state, action);
    default:
      return _.flow([
        (state) => computedPropsReducers(state, action),
        (state) => dataRequestReducer(state, action),
        (state) => figureGroupReducers(state, action),
        (state) => commonReducers(state, action),
        (state) => designerUndoReducers(state, action),
        (state) => reportReducers(state, action),
        (state) => drilldownReducers(state, action),
        (state) => galleryReducers(state, action)
      ])(state);
  }
}

function handleSetDesignerMode(state, { mode }) {
  return state.set('mode', mode);
}

function handleSetSelectedDesign(state, { designId, params }) {
  return state.set('selected-design', Map({
    uuid: designId,
    params
  }))
}

function includeFigureProperty(state, {configPath, propertyKey}) {
  // create new input key
  const value = DesignerHelper.getDefaultValue(propertyKey, '');
  return state
    .setIn(configPath.push('data', propertyKey), value)
    .setIn(configPath.push('input', propertyKey), value);
}

function excludeFigureProperty(state, {configPath, propertyKey}) {
  return state
    .deleteIn(configPath.push('input', propertyKey))
    .deleteIn(configPath.push('data', propertyKey));
}

function handleCancelFigureConfigDetails(state, { designId }) {
  return state.deleteIn(['designs', designId, 'show-config-details']);
}

function handleAcceptFigureConfigDetails(state, { designId, actions }) {
  // close dialog
  state = state.deleteIn(['designs', designId, 'show-config-details']);
  return actions.reduce((s, a) => designerReducer(s, a), state)
}

function handleShowFigureConfigDetails(state, { designId, configKey }) {
  // designs/designId
  return state.setIn(['designs', designId, 'show-config-details'], configKey);
}

function handleRemoveFigureConfig(state, {configPath}) {
  return state.deleteIn(configPath)
}

function handleAddFigureConfig(state, {figureIdentifier}) {
  const newFigureConfig = createFigureConfig(Map({ condition: '' }));
  const designId = figureIdentifier.designId;
  const figurePath = figurePathSelector({ designer: state }, designId)(figureIdentifier);
  return state.updateIn(figurePath.push('altConfigs'), List(), (l) => l.push(newFigureConfig));
}

function handleSelectedInputFieldValueUpdated(state, { configPaths, fieldKey, value }) {
  return configPaths.reduce((s, configPath) => 
    s.setIn(configPath.push('input', fieldKey), value), state);
}

function handleSelectedDataFieldValueUpdated(state, { configPaths, fieldKey, value }) {
  return configPaths.reduce((s, configPath) =>
    s.setIn(configPath.push('data', fieldKey), value), state);
}

function handleMoveFigureConfigDown(state, { configPath }) {
  const configIdx = configPath.last()
  const figurePath = configPath.butLast()
  return state.updateIn(figurePath, (fc) => {
    if (fc.size-1 == configIdx) {
      return fc
    }
    return fc.splice(configIdx, 2, fc.get(configIdx+1), fc.get(configIdx))
  })
}

function handleMoveFigureConfigUp(state, {configPath}) {
  const configIdx = configPath.last()
  if (configIdx <= 0) {
    return state
  } else {
    const figurePath = configPath.butLast()
    return state.updateIn(figurePath, (fc) => {
      return fc.splice(configIdx-1, 2, fc.get(configIdx), fc.get(configIdx-1))
    })
  }
}

function handleDesignCreated(state, {designId, designContent}) {
  return state.update('designs', (m) => m.set(designId, designContent))
}

function handleMountSelector(state, { formId, selectorPath, resultPath, fieldNames }) {
  return state.update('formMapping', Map(),
    (m) => m.set(formId, Map({ selectorPath, resultPath, fieldNames })))
}

function handleFieldValueUpdated(state, {path, value}) {
  return state.setIn(path, value)
}

function handleDesignSelectionHintsFetchStarted(state) {
  return state.update('designer-root-hints', Map(),
      (h) => h.set('status', 'fetching').delete('error-message'))
}

function handleDesignSelectionHintsFetchDone(state, {result}) {
  return state.set('designer-root-hints', Map()
      .set('items', result).set('status', 'done'))
}

function handleDesignSelectionHintsFetchError(state, {result}) {
  return state.set('designer-root-hints', Map()
     .set('error', result)
     .set('status', 'error'))
}

function handleExecuteFigureAction(state, {actionCode}) {
  return new Function(`${actionCode}; return this;`).call(state);
}

function handleSetDatasourceResult(state, {designId, datasourceKey, datasourceResult}) {
  if (!Map.isMap(datasourceResult)) {
    datasourceResult = fromJS(datasourceResult)
  }

  return state.setIn(['designs', designId, 'datasource-results', datasourceKey], datasourceResult)
}

function handleFigureSelected(state, action) {
  return state.setIn(['designs', action.designId, 'selected-figure'], fromJS({
    designId: action.designId,
    figures: action.secondary.concat(action.primary),
    figureType: action.figureType
  }))
}

function handleSelectSecondaryFigure(state, action) {
  const figureId = action.figureId;
  const designId = action.designId;
  const figureType = action.figureType;
  return state.updateIn(['designs', action.designId, 'selected-figure'], Map(), (selectedFigure) => {
    let figures = designId === selectedFigure.get('designId') && figureType === selectedFigure.get('figureType')
      ? selectedFigure.get('figures') : List();

    const idx = figures.findIndex((figId) => figId === figureId);
    if(idx > -1) figures = figures.delete(idx);
      
    return Map({
      designId,
      figureType,
      figures: figures.push(figureId)
    });
  });
}

function handleDeleteFigureConfig(state, { figureIdentifier }) {
  const designId = figureIdentifier.designId;
  const path = figurePathSelector({ designer: state }, designId)(figureIdentifier);
  return state.deleteIn(path.pop());
}

function handleClearSelectedFigure(state, action) {
  return state.deleteIn(['designs', action.designId, 'selected-figure']);
}

function handleCreateFigureOnCanvas(state, action) {
  return state.updateIn(['designs', action.designId, 'figures-config'], List(),
    (list) => list.push(action.figure))
}

function handleChangeFigureConfig(state, {dataPath, configDiffs}) {
  return state.updateIn(dataPath, (configProps) => DesignerHelper.applyDiffs(configProps, configDiffs))
}

function handleCloneFigureConfig(state, {designId, figureConfig, newFigureName}) {
  const newFigure = canvasFigureToConfig(newFigureName, figureConfig);

  return state.updateIn(['designs', designId, 'figure-descriptors'], (
    figureDescriptors) => figureDescriptors.push(newFigure));
}

function handleSetCloneFigureName(state, { cloneFigureName }) {
  return state.set('cloneFigureName', cloneFigureName);
}

function moveFigure(state, figureId, designId, positionFn) {
  return state.updateIn(['designs', designId, 'figures-config'], (figures) => {
    const parents = getParentsMapping(figures).get(figureId);
    const path = parentsToRelativePath(parents);
  
    return figures.updateIn([...path.pop()], (figures) => {
      const oldIdx = path.last();
      const newIdx = positionFn(oldIdx, figures.size);
      if(newIdx === oldIdx || newIdx < 0 || newIdx >= figures.size) return figures;

      return figures.map((figure, idx) => {
        if(idx >= oldIdx && idx < newIdx) {
          return figures.get(idx + 1);

        } else if(idx <= oldIdx && idx > newIdx) {
          return figures.get(idx - 1);

        } else if(idx === newIdx) {
          return figures.get(oldIdx);

        } else {
          return figure;
        }
      });
    });
  }); 
}

function handleMoveFigureZDown(state, { figureId, designId }) {
  return moveFigure(state, figureId, designId, (idx) => idx - 1);
}

function handleMoveFigureZUp(state, { figureId, designId }) {
  return moveFigure(state, figureId, designId, (idx) => idx + 1);
}

function handleMoveFigureZToBottom(state, { figureId, designId }) {
  return moveFigure(state, figureId, designId, () => 0);
}

function handleMoveFigureZToTop(state, { figureId, designId }) {
  return moveFigure(state, figureId, designId, (idx, count) => count - 1);
}

function handleCopyFigure(state) {
  const fullState = { designer: state };
  const designId = getCurrentDesignId(fullState);
  const selectedFigureConfig = primaryFigureSelector(fullState, designId);
  
  return state.updateIn(['designs', designId, 'figures-config'], 
    (figures) => figures.push(copyFigureConfig(selectedFigureConfig)));
}

function handleSplitPaneResized(state, { pane, size }) {
  return state.setIn(['splitPane', pane], size);
}

function handleSetFigureName(state, { figureIdentifier, value }) {
  const designId = figureIdentifier.designId;
  const figurePath = figurePathSelector({ designer: state }, designId)(figureIdentifier);
  return state.setIn(figurePath.push('name'), value);
}

function handleSetWindowSize(state, { width, height, forceUpdate }) {
  return state.update('windowSize', Map(), (windowSize) => {
    windowSize = windowSize.set(width).set(height);
    if(forceUpdate) {
      windowSize = windowSize.set('update', windowSize.get('update', 0) + 1);
    }
    return windowSize;
  });
}

function handleCanvasConfigSaveChanges(state, { designId, formResult }) {
  return state.setIn(['designs', designId, 'canvasConfig'], Map(formResult));
}

/**
 * Actions from the canvas to modify figures on the canvas
 */
function handleChangeFigures(state, {designId, figuresDiffs}) {
  return state.updateIn(['designs', designId, 'figures-config'], (figuresConfig) => {
    return mapDeep(figuresConfig, 
      (figure) => figure.get('children') || List(), 
      (figure, children) => {

        const figureId = getFigureId(figure);
        const figureDiff = figuresDiffs.get(figureId);

        if(!figureDiff) return figure
          .set('children', children);

        return figure
          .set('children', children)
          .update('mainConfig', (mainConfig) => {
            return Map({
              data: DesignerHelper.applyDiffs(mainConfig.get('data'), figureDiff),
              input: clearFigureInput(mainConfig.get('input'))
            })
          });
      })
  });
}

function handlDeleteFigures(state, {designId, figureIds}) {
  state = state.updateIn(['designs', designId, 'figures-config'], (figuresConfig) => figureIds.reduce((cfg, id) => {
        const parentsMapping = getParentsMapping(cfg);
        const parents = parentsMapping.get(id);
        const relativePath = parentsToRelativePath(parents);
        return cfg.deleteIn(relativePath);
      }, figuresConfig))
    
  // clear the selected figure when any figures are deleted
  return state.deleteIn(['designs', designId, 'selected-figure'])
}

export function designerFormReducer(allState, action) {
  const designerState = designerFormReducerHandler(allState.designer, allState, action)
  if (designerState !== allState.designer) {
    allState = Object.assign({}, allState, {designer: designerState})
  }
  return allState
}

function designerFormReducerHandler(state, allState, action) {
  switch (action.type) {
    case REDUX_FORM_ACTIONS.CHANGE:
      return handleReduxFormChange(state, state.form, action)
    case DatasourceActions.FIND_DONE:
      return handleDatasourceFindDone(state, state.datasource, action)
    default:
      return state
  }
}

function handleDatasourceFindDone(state, datasource, action) {
  let resultPath = state.getIn(['formMapping', action.formId, 'resultPath']);
  if (resultPath != null) {
    // separate the id part from the rest of the result path
    const id = resultPath.last();
    resultPath = resultPath.pop();

    const datasourceData = datasourcesData.get(action.datasourceType);
    const { meta, values } = extractMetaAndValuesFromResult(action.result, datasourceData);
    if(meta != null)
      state = state.setIn(resultPath.push('_meta').push(id), meta);
    return state.setIn(resultPath.push(id), values)
      
  }
  return state
}

function handleReduxFormChange(state, form, action) {
  // Object {type: "redux-form/CHANGE", field: "carrierUuid", value: "", form: "designer-selector-ds1"}
  const formMapping = state.getIn(['formMapping', action.form])
  if (formMapping !== undefined) {
    state = state.setIn(formMapping.get('selectorPath').push(action.field), action.value)
  }
  return state
}

function handleTriggerRefreshDatasources(state) {
  return state.update('refreshCount', (rc) => rc == null ? 1 : rc + 1);
}

function handleSetDatasourcesLoading(state, action) {
  return state.set('datasources-loading', action.loading);
}

function handlePushView(state, { designId, figureId, width, height }) {
  let vs = state.getIn(['viewStack', designId]) || List();
  return state.setIn(['viewStack', designId], vs.push(Map({
    rootPath: (vs.size ? vs.get(vs.size - 1).get('rootPath') : List()).push(figureId),
    name: 'View ' + vs.size,
    viewId: 'v_' + vs.size,
    width,
    height
  })));
}

function handlePopView(state, { designId, viewId }) {
  let vs = state.getIn(['viewStack', designId]);
  if(vs == null || !vs.size) return state;
  let prevRemoved;

  do {
    prevRemoved = vs.get(vs.size - 1);
    vs = vs.pop();
  } while(viewId != null && vs.size && prevRemoved.get('viewId') !== viewId);

  return state.setIn(['viewStack', designId], vs);
}
