const KEY_AUTH_TOKEN = 'auth-token';
const KEY_REALM = 'realm';

export function isAuthenticated() {
    const authToken = window.localStorage.getItem(KEY_AUTH_TOKEN);
    return !!authToken;
}

export function setAuthToken(authToken) {
    window.localStorage.setItem(KEY_AUTH_TOKEN, authToken)
}

export function authenticate(authToken, realm) { 
    window.localStorage.setItem(KEY_AUTH_TOKEN, authToken);
    window.localStorage.setItem(KEY_REALM, realm);
}

export function clearAuthentication() { 
    window.localStorage.removeItem(KEY_AUTH_TOKEN);
    window.localStorage.removeItem(KEY_REALM)
}

export function getCurrentRealmName(state) {
    const realms = state.dashboard.getIn(['auth', 'realms'])
    const realmNo = window.localStorage.getItem(KEY_REALM)
    if(!realms || !realmNo) return

    const realm = realms.find((realm) => realm.get('no') === realmNo)
    return realm && realm.get('description')
}

export function getToken() { 
    return window.localStorage.getItem(KEY_AUTH_TOKEN)
}

export function decodePayload(token) { 
    const payloadEncoded = token.split('.')[1];
    return JSON.parse(window.atob(payloadEncoded));
}
