import { History } from 'history';

let storeKey: string = 'common';
let history: History | undefined;

export function setStoreKey(key: string) {
    storeKey = key;
}

export function getStoreKey(): string {
    return storeKey;
}

export function setHistory(hist: History) {
    history = hist;
}

export function getHistory(): History {
    return history!;
}
