import { List, Map } from 'immutable';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

export function getColorPicker(state) {
    return state.designer.get('colorPicker');
}

export const getColorPickerVisibleById = createCachedSelector(getColorPicker,
    (state, id) => id,
    (colorPicker, id) => {
        return colorPicker && colorPicker.get(id) || false;
    })((state, fieldId) => fieldId);

export function isViewMode(state) {
    return state.designer.get('mode') === 'View';
}

export function getCurrentDesignId(state) {
    return state.designer.getIn(['selected-design', 'uuid']);
}

export function getFigureHistory(state) {
    return state.designer.getIn(['history', 'figures']);
}

export function getCurrentDesignParams(state) {
    return state.designer.getIn(['selected-design', 'params']);
}

export function getCurrentDesignName(state) {
    return state.designer.getIn(['web-components', getCurrentDesignId(state), 'description']);
}

export function getDesign(state, designId) {
    return state.designer.getIn(['designs', designId]);
}

export function getPollInterval(state, designId) {
    return state.designer.getIn(['designs', designId, 'pollInterval'], 10000);
}

export function getCurrentDesign(state) {
    const designId = getCurrentDesignId(state);
    return getDesign(state, designId);
}

export function getDesignDefaults(state, designId) {
    const design = getDesign(state, designId);
    return design && design.get('defaults') || Map();
}

export function getDesigner(state) {
    return state.designer;
}

export function getFolder(group) {
    return (state) => {
        const folderPath = group.get('path');
        const folders = state.designer.get('folders');
        return folders && folders.getIn(folderPath); 
    }
}

/**
 * Whether the folder is collapsed or not
 */
export const getFolderCollapsed = (folderPath, defaultValue) =>
    createSelector(getFolder(folderPath), (folder) => {
        if(!folder) 
            return defaultValue;

        return folder.get('collapsed', defaultValue);
    });


export const getCurrentFigureHistory = createSelector(getCurrentDesignId, getFigureHistory,
    (currentDesignId, figureHistory) => figureHistory && figureHistory.get(currentDesignId));

export const isUndoAvailable = createSelector(getCurrentFigureHistory, (history) => history ? history.get('past', List()).size > 1 : false )

export const isRedoAvailable = createSelector(getCurrentFigureHistory, (history) => history ? history.get('future', List()).size > 0 : false);

export function getDrilldownParents(state) {
    return state.designer.get('drilldown');
}

export const getDrilldownBreadcrumbs = createSelector(
    getDrilldownParents,
    getCurrentDesignId,
    getCurrentDesignName,
    getCurrentDesignParams,
    (drilldownParents, designId, name, params) =>  {
        return (drilldownParents || List()).push(Map({ designId, name, params }));
    });

export const isFetchingAnyDesign = createSelector(
    (state) => state.designer.get('requests'),
    (fetchRequests) => fetchRequests ? fetchRequests.some((x) => x.get('fetching', false)) : false);

export function isFetchingDesign(state, designId) {
    return state.designer.getIn(['requests', designId, 'fetching'], false);
}
