/**
 * Entry point for draw2d dependencies.
 */

require('../lib/draw2d/lib/raphael.js');
require('../lib/draw2d/lib/shifty.js');
require('../lib/draw2d/lib/jquery-1.12.0.min.js');
require('../lib/draw2d/lib/jquery.autoresize.js');
require('../lib/draw2d/lib/jquery-touch_punch.js');
require('../lib/draw2d/lib/jquery.contextmenu.js');
require('../lib/draw2d/lib/StackBlur.js');
require('../lib/draw2d/lib/rgbcolor.js');
require('../lib/draw2d/lib/canvg.js');
require('../lib/draw2d/lib/Class.js');
require('../lib/draw2d/lib/json2.js');
require('../lib/draw2d/lib/pathfinding-browser.min.js');
require('../lib/draw2d/draw2d.js');

require('../lib/draw2d/util/Polyfill.js');
require('../lib/draw2d/util/Base64.js');
require('../lib/draw2d/util/Debug.js');
require('../lib/draw2d/util/Color.js');
require('../lib/draw2d/util/ArrayList.js');
require('../lib/draw2d/util/SVGUtil.js');
require('../lib/draw2d/util/JSONUtil.js');
require('../lib/draw2d/util/UUID.js');
require('../lib/draw2d/util/spline/Spline.js');
require('../lib/draw2d/util/spline/CubicSpline.js');
require('../lib/draw2d/util/spline/CatmullRomSpline.js');
require('../lib/draw2d/util/spline/BezierSpline.js');
require('../lib/draw2d/geo/PositionConstants.js');
require('../lib/draw2d/geo/Point.js');
require('../lib/draw2d/geo/Rectangle.js');
require('../lib/draw2d/geo/Util.js');
require('../lib/draw2d/geo/Ray.js');
require('../lib/draw2d/geo/Line.js');
require('../lib/draw2d/command/CommandType.js');
require('../lib/draw2d/command/Command.js');
require('../lib/draw2d/command/CommandCollection.js');
require('../lib/draw2d/command/CommandStack.js');
require('../lib/draw2d/command/CommandStackEvent.js');
require('../lib/draw2d/command/CommandStackEventListener.js');
require('../lib/draw2d/command/CommandMove.js');
require('../lib/draw2d/command/CommandAttr.js');
require('../lib/draw2d/command/CommandMoveLine.js');
require('../lib/draw2d/command/CommandMoveConnection.js');
require('../lib/draw2d/command/CommandMoveVertex.js');
require('../lib/draw2d/command/CommandMoveVertices.js');
require('../lib/draw2d/command/CommandResize.js');
require('../lib/draw2d/command/CommandRotate.js');
require('../lib/draw2d/command/CommandConnect.js');
require('../lib/draw2d/command/CommandReconnect.js');
require('../lib/draw2d/command/CommandDelete.js');
require('../lib/draw2d/command/CommandDeleteGroup.js');
require('../lib/draw2d/command/CommandAdd.js');
require('../lib/draw2d/command/CommandGroup.js');
require('../lib/draw2d/command/CommandUngroup.js');
require('../lib/draw2d/command/CommandAddVertex.js');
require('../lib/draw2d/command/CommandAssignFigure.js');
require('../lib/draw2d/command/CommandBoundingBox.js');
require('../lib/draw2d/command/CommandRemoveVertex.js');
require('../lib/draw2d/command/CommandReplaceVertices.js');
require('../lib/draw2d/layout/connection/ConnectionRouter.js');
require('../lib/draw2d/layout/connection/DirectRouter.js');
require('../lib/draw2d/layout/connection/RubberbandRouter.js');
require('../lib/draw2d/layout/connection/VertexRouter.js');
require('../lib/draw2d/layout/connection/ManhattanConnectionRouter.js');
require('../lib/draw2d/layout/connection/ManhattanBridgedConnectionRouter.js');
require('../lib/draw2d/layout/connection/InteractiveManhattanConnectionRouter.js');
require('../lib/draw2d/layout/connection/CircuitConnectionRouter.js');
require('../lib/draw2d/layout/connection/SplineConnectionRouter.js');
require('../lib/draw2d/layout/connection/FanConnectionRouter.js');
require('../lib/draw2d/layout/connection/MazeConnectionRouter.js');
require('../lib/draw2d/layout/connection/MuteableManhattanConnectionRouter.js');
require('../lib/draw2d/layout/connection/SketchConnectionRouter.js');
require('../lib/draw2d/layout/mesh/MeshLayouter.js');
require('../lib/draw2d/layout/mesh/ExplodeLayouter.js');
require('../lib/draw2d/layout/mesh/ProposedMeshChange.js');
require('../lib/draw2d/layout/locator/Locator.js');
require('../lib/draw2d/layout/locator/PortLocator.js');
require('../lib/draw2d/layout/locator/DraggableLocator.js');
require('../lib/draw2d/layout/locator/XYAbsPortLocator.js');
require('../lib/draw2d/layout/locator/XYRelPortLocator.js');
require('../lib/draw2d/layout/locator/InputPortLocator.js');
require('../lib/draw2d/layout/locator/OutputPortLocator.js');
require('../lib/draw2d/layout/locator/ConnectionLocator.js');
require('../lib/draw2d/layout/locator/ManhattanMidpointLocator.js');
require('../lib/draw2d/layout/locator/PolylineMidpointLocator.js');
require('../lib/draw2d/layout/locator/ParallelMidpointLocator.js');
require('../lib/draw2d/layout/locator/TopLocator.js');
require('../lib/draw2d/layout/locator/BottomLocator.js');
require('../lib/draw2d/layout/locator/LeftLocator.js');
require('../lib/draw2d/layout/locator/RightLocator.js');
require('../lib/draw2d/layout/locator/CenterLocator.js');
require('../lib/draw2d/policy/EditPolicy.js');
require('../lib/draw2d/policy/canvas/CanvasPolicy.js');
require('../lib/draw2d/policy/canvas/ZoomPolicy.js');
require('../lib/draw2d/policy/canvas/WheelZoomPolicy.js');
require('../lib/draw2d/policy/canvas/KeyboardPolicy.js');
require('../lib/draw2d/policy/canvas/DefaultKeyboardPolicy.js');
require('../lib/draw2d/policy/canvas/ExtendedKeyboardPolicy.js');
require('../lib/draw2d/policy/canvas/SelectionPolicy.js');
require('../lib/draw2d/policy/canvas/SingleSelectionPolicy.js');
require('../lib/draw2d/policy/canvas/GhostMoveSelectionPolicy.js');
require('../lib/draw2d/policy/canvas/PanningSelectionPolicy.js');
require('../lib/draw2d/policy/canvas/BoundingboxSelectionPolicy.js');
require('../lib/draw2d/policy/canvas/ReadOnlySelectionPolicy.js');
require('../lib/draw2d/policy/canvas/DecorationPolicy.js');
require('../lib/draw2d/policy/canvas/FadeoutDecorationPolicy.js');
require('../lib/draw2d/policy/canvas/CoronaDecorationPolicy.js');
require('../lib/draw2d/policy/canvas/SnapToEditPolicy.js');
require('../lib/draw2d/policy/canvas/ShowGridEditPolicy.js');
require('../lib/draw2d/policy/canvas/SnapToGridEditPolicy.js');
require('../lib/draw2d/policy/canvas/ShowDotEditPolicy.js');
require('../lib/draw2d/policy/canvas/ShowChessboardEditPolicy.js');
require('../lib/draw2d/policy/canvas/SnapToGeometryEditPolicy.js');
require('../lib/draw2d/policy/canvas/SnapToVerticesEditPolicy.js');
require('../lib/draw2d/policy/canvas/SnapToInBetweenEditPolicy.js');
require('../lib/draw2d/policy/canvas/SnapToCenterEditPolicy.js');
require('../lib/draw2d/policy/canvas/DropInterceptorPolicy.js');
require('../lib/draw2d/policy/connection/ConnectionCreatePolicy.js');
require('../lib/draw2d/policy/connection/ComposedConnectionCreatePolicy.js');
require('../lib/draw2d/policy/connection/ClickConnectionCreatePolicy.js');
require('../lib/draw2d/policy/connection/OrthogonalConnectionCreatePolicy.js');
require('../lib/draw2d/policy/connection/DragConnectionCreatePolicy.js');
require('../lib/draw2d/policy/figure/FigureEditPolicy.js');
require('../lib/draw2d/policy/figure/DragDropEditPolicy.js');
require('../lib/draw2d/policy/figure/RegionEditPolicy.js');
require('../lib/draw2d/policy/figure/HorizontalEditPolicy.js');
require('../lib/draw2d/policy/figure/VerticalEditPolicy.js');
require('../lib/draw2d/policy/figure/SelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/ResizeSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/RectangleSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/BigRectangleSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/RoundRectangleSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/BusSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/WidthSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/VBusSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/HBusSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/AntSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/GlowSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/SlimSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/figure/VertexSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/line/LineSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/line/VertexSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/line/OrthogonalSelectionFeedbackPolicy.js');
require('../lib/draw2d/policy/port/PortFeedbackPolicy.js');
require('../lib/draw2d/policy/port/ElasticStrapFeedbackPolicy.js');
require('../lib/draw2d/policy/port/IntrusivePortsFeedbackPolicy.js');
require('../lib/draw2d/Configuration.js');
require('../lib/draw2d/Canvas.js');
require('../lib/draw2d/Selection.js');
require('../lib/draw2d/Figure.js');
require('../lib/draw2d/shape/node/Node.js');
require('../lib/draw2d/VectorFigure.js');
require('../lib/draw2d/shape/basic/Rectangle.js');
require('../lib/draw2d/SetFigure.js');
require('../lib/draw2d/SVGFigure.js');
require('../lib/draw2d/shape/node/Hub.js');
require('../lib/draw2d/shape/node/HorizontalBus.js');
require('../lib/draw2d/shape/node/VerticalBus.js');
require('../lib/draw2d/shape/node/Fulcrum.js');
require('../lib/draw2d/shape/basic/Oval.js');
require('../lib/draw2d/shape/basic/Circle.js');
require('../lib/draw2d/shape/basic/Label.js');
require('../lib/draw2d/shape/basic/Text.js');
require('../lib/draw2d/shape/basic/Line.js');
require('../lib/draw2d/shape/basic/PolyLine.js');
require('../lib/draw2d/shape/basic/Image.js');
require('../lib/draw2d/shape/basic/Polygon.js');
require('../lib/draw2d/shape/basic/Diamond.js');
require('../lib/draw2d/shape/composite/Composite.js');
require('../lib/draw2d/shape/composite/StrongComposite.js');
require('../lib/draw2d/shape/composite/Group.js');
require('../lib/draw2d/shape/composite/Jailhouse.js');
require('../lib/draw2d/shape/composite/WeakComposite.js');
require('../lib/draw2d/shape/composite/Raft.js');
require('../lib/draw2d/Connection.js');
require('../lib/draw2d/VectorFigure.js');
require('../lib/draw2d/ResizeHandle.js');
require('../lib/draw2d/shape/basic/LineResizeHandle.js');
require('../lib/draw2d/shape/basic/LineStartResizeHandle.js');
require('../lib/draw2d/shape/basic/LineEndResizeHandle.js');
require('../lib/draw2d/shape/basic/VertexResizeHandle.js');
require('../lib/draw2d/shape/basic/GhostVertexResizeHandle.js');
require('../lib/draw2d/Port.js');
require('../lib/draw2d/InputPort.js');
require('../lib/draw2d/OutputPort.js');
require('../lib/draw2d/HybridPort.js');
require('../lib/draw2d/layout/anchor/ConnectionAnchor.js');
require('../lib/draw2d/layout/anchor/ChopboxConnectionAnchor.js');
require('../lib/draw2d/layout/anchor/FanConnectionAnchor.js');
require('../lib/draw2d/layout/anchor/ShortesPathConnectionAnchor.js');
require('../lib/draw2d/layout/anchor/CenterEdgeConnectionAnchor.js');
require('../lib/draw2d/shape/arrow/CalligrapherArrowLeft.js');
require('../lib/draw2d/shape/arrow/CalligrapherArrowDownLeft.js');
require('../lib/draw2d/shape/node/Start.js');
require('../lib/draw2d/shape/node/End.js');
require('../lib/draw2d/shape/node/Between.js');
require('../lib/draw2d/shape/note/PostIt.js');
require('../lib/draw2d/shape/flowchart/Document.js');
require('../lib/draw2d/shape/widget/Widget.js');
require('../lib/draw2d/shape/widget/Slider.js');
require('../lib/draw2d/shape/diagram/Diagram.js');
require('../lib/draw2d/shape/diagram/Pie.js');
require('../lib/draw2d/shape/diagram/Sparkline.js');
require('../lib/draw2d/shape/analog/OpAmp.js');
require('../lib/draw2d/shape/analog/ResistorBridge.js');
require('../lib/draw2d/shape/analog/ResistorVertical.js');
require('../lib/draw2d/shape/analog/VoltageSupplyHorizontal.js');
require('../lib/draw2d/shape/analog/VoltageSupplyVertical.js');
require('../lib/draw2d/shape/layout/Layout.js');
require('../lib/draw2d/shape/layout/HorizontalLayout.js');
require('../lib/draw2d/shape/layout/VerticalLayout.js');
require('../lib/draw2d/shape/layout/TableLayout.js');
require('../lib/draw2d/shape/layout/FlexGridLayout.js');
require('../lib/draw2d/shape/layout/StackLayout.js');
require('../lib/draw2d/shape/icon/Icon.js');
require('../lib/draw2d/shape/icon/Thunder.js');
require('../lib/draw2d/shape/icon/Snow.js');
require('../lib/draw2d/shape/icon/Hail.js');
require('../lib/draw2d/shape/icon/Rain.js');
require('../lib/draw2d/shape/icon/Cloudy.js');
require('../lib/draw2d/shape/icon/Sun.js');
require('../lib/draw2d/shape/icon/Undo.js');
require('../lib/draw2d/shape/icon/Detour.js');
require('../lib/draw2d/shape/icon/Merge.js');
require('../lib/draw2d/shape/icon/Split.js');
require('../lib/draw2d/shape/icon/Fork.js');
require('../lib/draw2d/shape/icon/ForkAlt.js');
require('../lib/draw2d/shape/icon/Exchange.js');
require('../lib/draw2d/shape/icon/Shuffle.js');
require('../lib/draw2d/shape/icon/Refresh.js');
require('../lib/draw2d/shape/icon/Ccw.js');
require('../lib/draw2d/shape/icon/Acw.js');
require('../lib/draw2d/shape/icon/Contract.js');
require('../lib/draw2d/shape/icon/Expand.js');
require('../lib/draw2d/shape/icon/Stop.js');
require('../lib/draw2d/shape/icon/End.js');
require('../lib/draw2d/shape/icon/Start.js');
require('../lib/draw2d/shape/icon/Ff.js');
require('../lib/draw2d/shape/icon/Rw.js');
require('../lib/draw2d/shape/icon/ArrowRight.js');
require('../lib/draw2d/shape/icon/ArrowLeft.js');
require('../lib/draw2d/shape/icon/ArrowUp.js');
require('../lib/draw2d/shape/icon/ArrowDown.js');
require('../lib/draw2d/shape/icon/ArrowLeft2.js');
require('../lib/draw2d/shape/icon/ArrowRight2.js');
require('../lib/draw2d/shape/icon/Smile2.js');
require('../lib/draw2d/shape/icon/Smile.js');
require('../lib/draw2d/shape/icon/Alarm.js');
require('../lib/draw2d/shape/icon/Clock.js');
require('../lib/draw2d/shape/icon/StopWatch.js');
require('../lib/draw2d/shape/icon/History.js');
require('../lib/draw2d/shape/icon/Future.js');
require('../lib/draw2d/shape/icon/GlobeAlt2.js');
require('../lib/draw2d/shape/icon/GlobeAlt.js');
require('../lib/draw2d/shape/icon/Globe.js');
require('../lib/draw2d/shape/icon/Warning.js');
require('../lib/draw2d/shape/icon/Code.js');
require('../lib/draw2d/shape/icon/Pensil.js');
require('../lib/draw2d/shape/icon/Pen.js');
require('../lib/draw2d/shape/icon/Plus.js');
require('../lib/draw2d/shape/icon/Minus.js');
require('../lib/draw2d/shape/icon/TShirt.js');
require('../lib/draw2d/shape/icon/Sticker.js');
require('../lib/draw2d/shape/icon/Page2.js');
require('../lib/draw2d/shape/icon/Page.js');
require('../lib/draw2d/shape/icon/Landscape1.js');
require('../lib/draw2d/shape/icon/Landscape2.js');
require('../lib/draw2d/shape/icon/Plugin.js');
require('../lib/draw2d/shape/icon/Bookmark.js');
require('../lib/draw2d/shape/icon/Hammer.js');
require('../lib/draw2d/shape/icon/Users.js');
require('../lib/draw2d/shape/icon/User.js');
require('../lib/draw2d/shape/icon/Customer.js');
require('../lib/draw2d/shape/icon/Employee.js');
require('../lib/draw2d/shape/icon/Anonymous.js');
require('../lib/draw2d/shape/icon/Skull.js');
require('../lib/draw2d/shape/icon/Mail.js');
require('../lib/draw2d/shape/icon/Picture.js');
require('../lib/draw2d/shape/icon/Bubble.js');
require('../lib/draw2d/shape/icon/CodeTalk.js');
require('../lib/draw2d/shape/icon/Talkq.js');
require('../lib/draw2d/shape/icon/Talke.js');
require('../lib/draw2d/shape/icon/Home.js');
require('../lib/draw2d/shape/icon/Lock.js');
require('../lib/draw2d/shape/icon/Clip.js');
require('../lib/draw2d/shape/icon/Star.js');
require('../lib/draw2d/shape/icon/StarOff.js');
require('../lib/draw2d/shape/icon/Star2.js');
require('../lib/draw2d/shape/icon/Star2Off.js');
require('../lib/draw2d/shape/icon/Star3.js');
require('../lib/draw2d/shape/icon/Star3Off.js');
require('../lib/draw2d/shape/icon/Chat.js');
require('../lib/draw2d/shape/icon/Quote.js');
require('../lib/draw2d/shape/icon/Gear2.js');
require('../lib/draw2d/shape/icon/Gear.js');
require('../lib/draw2d/shape/icon/Wrench.js');
require('../lib/draw2d/shape/icon/Wrench2.js');
require('../lib/draw2d/shape/icon/Wrench3.js');
require('../lib/draw2d/shape/icon/ScrewDriver.js');
require('../lib/draw2d/shape/icon/HammerAndScrewDriver.js');
require('../lib/draw2d/shape/icon/Magic.js');
require('../lib/draw2d/shape/icon/Download.js');
require('../lib/draw2d/shape/icon/View.js');
require('../lib/draw2d/shape/icon/Noview.js');
require('../lib/draw2d/shape/icon/Cloud.js');
require('../lib/draw2d/shape/icon/Cloud2.js');
require('../lib/draw2d/shape/icon/CloudDown.js');
require('../lib/draw2d/shape/icon/CloudUp.js');
require('../lib/draw2d/shape/icon/Location.js');
require('../lib/draw2d/shape/icon/Volume0.js');
require('../lib/draw2d/shape/icon/Volume1.js');
require('../lib/draw2d/shape/icon/Volume2.js');
require('../lib/draw2d/shape/icon/Volume3.js');
require('../lib/draw2d/shape/icon/Key.js');
require('../lib/draw2d/shape/icon/Ruler.js');
require('../lib/draw2d/shape/icon/Power.js');
require('../lib/draw2d/shape/icon/Unlock.js');
require('../lib/draw2d/shape/icon/Flag.js');
require('../lib/draw2d/shape/icon/Tag.js');
require('../lib/draw2d/shape/icon/Search.js');
require('../lib/draw2d/shape/icon/ZoomOut.js');
require('../lib/draw2d/shape/icon/ZoomIn.js');
require('../lib/draw2d/shape/icon/Cross.js');
require('../lib/draw2d/shape/icon/Check.js');
require('../lib/draw2d/shape/icon/Settings.js');
require('../lib/draw2d/shape/icon/SettingsAlt.js');
require('../lib/draw2d/shape/icon/Feed.js');
require('../lib/draw2d/shape/icon/Bug.js');
require('../lib/draw2d/shape/icon/Link.js');
require('../lib/draw2d/shape/icon/Calendar.js');
require('../lib/draw2d/shape/icon/Picker.js');
require('../lib/draw2d/shape/icon/No.js');
require('../lib/draw2d/shape/icon/CommandLine.js');
require('../lib/draw2d/shape/icon/Photo.js');
require('../lib/draw2d/shape/icon/Printer.js');
require('../lib/draw2d/shape/icon/Export.js');
require('../lib/draw2d/shape/icon/Import.js');
require('../lib/draw2d/shape/icon/Run.js');
require('../lib/draw2d/shape/icon/Magnet.js');
require('../lib/draw2d/shape/icon/NoMagnet.js');
require('../lib/draw2d/shape/icon/ReflectH.js');
require('../lib/draw2d/shape/icon/ReflectV.js');
require('../lib/draw2d/shape/icon/Resize2.js');
require('../lib/draw2d/shape/icon/Rotate.js');
require('../lib/draw2d/shape/icon/Connect.js');
require('../lib/draw2d/shape/icon/Disconnect.js');
require('../lib/draw2d/shape/icon/Folder.js');
require('../lib/draw2d/shape/icon/Man.js');
require('../lib/draw2d/shape/icon/Woman.js');
require('../lib/draw2d/shape/icon/People.js');
require('../lib/draw2d/shape/icon/Parent.js');
require('../lib/draw2d/shape/icon/Notebook.js');
require('../lib/draw2d/shape/icon/Diagram.js');
require('../lib/draw2d/shape/icon/BarChart.js');
require('../lib/draw2d/shape/icon/PieChart.js');
require('../lib/draw2d/shape/icon/LineChart.js');
require('../lib/draw2d/shape/icon/Apps.js');
require('../lib/draw2d/shape/icon/Locked.js');
require('../lib/draw2d/shape/icon/Ppt.js');
require('../lib/draw2d/shape/icon/Lab.js');
require('../lib/draw2d/shape/icon/Umbrella.js');
require('../lib/draw2d/shape/icon/Dry.js');
require('../lib/draw2d/shape/icon/Ipad.js');
require('../lib/draw2d/shape/icon/Iphone.js');
require('../lib/draw2d/shape/icon/Jigsaw.js');
require('../lib/draw2d/shape/icon/Lamp.js');
require('../lib/draw2d/shape/icon/Lamp_alt.js');
require('../lib/draw2d/shape/icon/Video.js');
require('../lib/draw2d/shape/icon/Palm.js');
require('../lib/draw2d/shape/icon/Fave.js');
require('../lib/draw2d/shape/icon/Help.js');
require('../lib/draw2d/shape/icon/Crop.js');
require('../lib/draw2d/shape/icon/BioHazard.js');
require('../lib/draw2d/shape/icon/WheelChair.js');
require('../lib/draw2d/shape/icon/Mic.js');
require('../lib/draw2d/shape/icon/MicMute.js');
require('../lib/draw2d/shape/icon/IMac.js');
require('../lib/draw2d/shape/icon/Pc.js');
require('../lib/draw2d/shape/icon/Cube.js');
require('../lib/draw2d/shape/icon/FullCube.js');
require('../lib/draw2d/shape/icon/Font.js');
require('../lib/draw2d/shape/icon/Trash.js');
require('../lib/draw2d/shape/icon/NewWindow.js');
require('../lib/draw2d/shape/icon/DockRight.js');
require('../lib/draw2d/shape/icon/DockLeft.js');
require('../lib/draw2d/shape/icon/DockBottom.js');
require('../lib/draw2d/shape/icon/DockTop.js');
require('../lib/draw2d/shape/icon/Pallete.js');
require('../lib/draw2d/shape/icon/Cart.js');
require('../lib/draw2d/shape/icon/Glasses.js');
require('../lib/draw2d/shape/icon/Package.js');
require('../lib/draw2d/shape/icon/Book.js');
require('../lib/draw2d/shape/icon/Books.js');
require('../lib/draw2d/shape/icon/Icons.js');
require('../lib/draw2d/shape/icon/List.js');
require('../lib/draw2d/shape/icon/Db.js');
require('../lib/draw2d/shape/icon/Paper.js');
require('../lib/draw2d/shape/icon/TakeOff.js');
require('../lib/draw2d/shape/icon/Landing.js');
require('../lib/draw2d/shape/icon/Plane.js');
require('../lib/draw2d/shape/icon/Phone.js');
require('../lib/draw2d/shape/icon/HangUp.js');
require('../lib/draw2d/shape/icon/SlideShare.js');
require('../lib/draw2d/shape/icon/Twitter.js');
require('../lib/draw2d/shape/icon/TwitterBird.js');
require('../lib/draw2d/shape/icon/Skype.js');
require('../lib/draw2d/shape/icon/Windows.js');
require('../lib/draw2d/shape/icon/Apple.js');
require('../lib/draw2d/shape/icon/Linux.js');
require('../lib/draw2d/shape/icon/NodeJs.js');
require('../lib/draw2d/shape/icon/JQuery.js');
require('../lib/draw2d/shape/icon/Sencha.js');
require('../lib/draw2d/shape/icon/Vim.js');
require('../lib/draw2d/shape/icon/InkScape.js');
require('../lib/draw2d/shape/icon/Aumade.js');
require('../lib/draw2d/shape/icon/Firefox.js');
require('../lib/draw2d/shape/icon/Ie.js');
require('../lib/draw2d/shape/icon/Ie9.js');
require('../lib/draw2d/shape/icon/Opera.js');
require('../lib/draw2d/shape/icon/Chrome.js');
require('../lib/draw2d/shape/icon/Safari.js');
require('../lib/draw2d/shape/icon/LinkedIn.js');
require('../lib/draw2d/shape/icon/Flickr.js');
require('../lib/draw2d/shape/icon/GitHub.js');
require('../lib/draw2d/shape/icon/GitHubAlt.js');
require('../lib/draw2d/shape/icon/Raphael.js');
require('../lib/draw2d/shape/icon/GRaphael.js');
require('../lib/draw2d/shape/icon/Svg.js');
require('../lib/draw2d/shape/icon/Usb.js');
require('../lib/draw2d/shape/icon/Ethernet.js');
require('../lib/draw2d/shape/pert/Activity.js');
require('../lib/draw2d/shape/pert/Start.js');
require('../lib/draw2d/shape/state/Start.js');
require('../lib/draw2d/shape/state/End.js');
require('../lib/draw2d/shape/state/State.js');
require('../lib/draw2d/shape/state/Connection.js');
require('../lib/draw2d/shape/icon/SVGPathShape.js');
require('../lib/draw2d/ui/LabelEditor.js');
require('../lib/draw2d/ui/LabelInplaceEditor.js');
require('../lib/draw2d/decoration/connection/Decorator.js');
require('../lib/draw2d/decoration/connection/ArrowDecorator.js');
require('../lib/draw2d/decoration/connection/DiamondDecorator.js');
require('../lib/draw2d/decoration/connection/CircleDecorator.js');
require('../lib/draw2d/decoration/connection/BarDecorator.js');
require('../lib/draw2d/io/Reader.js');
require('../lib/draw2d/io/Writer.js');
require('../lib/draw2d/io/svg/Writer.js');
require('../lib/draw2d/io/png/Writer.js');
require('../lib/draw2d/io/json/Writer.js');
require('../lib/draw2d/io/json/Reader.js');
require('../lib/draw2d/storage/FileStorage.js');
require('../lib/draw2d/storage/GoogleDrive.js');
require('../lib/draw2d/storage/LocalFileStorage.js');
require('../lib/draw2d/storage/TideSDKStorage.js');
