const conversionMap = new Map<string, string>();
conversionMap.set('a', 'A');
conversionMap.set('D', 'DDD');
conversionMap.set('DDD', 'DDDD');
conversionMap.set('d', 'D');
conversionMap.set('dd', 'DD');
conversionMap.set('E', 'ddd');
conversionMap.set('EE', 'ddd');
conversionMap.set('EEE', 'ddd');
conversionMap.set('EEEE', 'dddd');
conversionMap.set('u', 'E');
conversionMap.set('w', 'W');
conversionMap.set('ww', 'WW');
conversionMap.set('XX', 'ZZ');
conversionMap.set('XXX', 'Z');
conversionMap.set('YY', 'gg');
conversionMap.set('YYYY', 'gggg');
conversionMap.set('y', 'YYYY');
conversionMap.set('yy', 'YY');
conversionMap.set('yyy', 'YYYY');
conversionMap.set('yyyy', 'YYYY');
conversionMap.set('Z', 'ZZ');

function convert(jFormat: string) {
    // because java SimpleDateFormat uses different format to moment.js
    // this is a VERY rough conversion based on: https://www.angularfaces.net/cheatsheet/
    let buff = '';
    for (let i = 0, n = jFormat.length; i < n;) {
        let key: string | undefined;
        for (let k of conversionMap.keys()) {
            let match = true;
            for (let j = 0, m = k.length; j < m; j++) {
                if (jFormat[i + j] !== k[j]) {
                    match = false;
                    break;
                }
            }
            if (match && (!key || key.length < k.length)) {
                key = k;
            }
        }

        if (key) {
            buff += conversionMap.get(key)!;
            i += key.length;

        } else {
            buff += jFormat[i];
            i++;
        }
    }

    return buff;
}

// converting from java to moment format string is quite an expensive op
const conversionCache = new Map<string, { fmt: string, momentFormat: string }>();

export function javaToMomentFormatString(key: string, fmt: string) {
    let elem = conversionCache.get(key);
    if (!elem || elem.fmt !== fmt) {
        const momentFormat = convert(fmt);
        conversionCache.set(key, elem = { fmt, momentFormat });
    }
    return elem.momentFormat;
}
