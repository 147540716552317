import { standardFormatters, Formatter } from '@pearlchain/component-lib-common';

import { GridCreationContext } from "./extensionTypes";
import { PgExts, Row, Column } from '../types';

function columnFormatter(formatter: Formatter) {
    return (value: unknown, record: Row[], column: Column) => formatter(value, column.format);
}

export function createFormattingExtension(exts: PgExts, ctx: GridCreationContext) {
    const formatters = ctx.formatters || standardFormatters;
    const fmt: { [key: string]: any } = {};
    for (let key in formatters) {
        fmt[key] = columnFormatter(formatters[key as keyof typeof formatters]);
    }
    exts.formatting = fmt;
}
