import { useCallback, useEffect, useRef, useMemo } from 'react';
import { v1 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';

import { getCachedRequest } from '../../components/fetcher/selectors';
import { RequestState } from '../../components/fetcher/types';
import { useMemoCompare } from './useMemoCompare';
import { mountCachedFetcher, unmountCachedFetcher, receiveCachedData, setCachedFetching, receiveCachedError, clearCachedData } from '../../components/fetcher/actions';
import { FetcherState, FetcherActions } from '../../components/fetcher/fetcherTypes';

export type Options<T> = {
    isUpdateRequired?: (data: T | undefined) => boolean;
    onDataReceived?: (data: T) => void;
    onError?: (error: any) => void;
}

function compareCacheKeys(prev: string | string[], next: string | string[]) {
    if (typeof next === 'string' || typeof prev === 'string') {
        return prev === next;
    } else {
        return prev.length === next.length && !prev.some((p, i) => p !== next[i]);
    }
}

function useId(): string {
    const idRef = useRef<string | null>(null);
    if (idRef.current != null) return idRef.current;
    return idRef.current = v1();
}

type Prev<T> = {
    cacheKey: string | string[];
    isMounted: boolean;
    data: T | undefined;
}

/**
 * Cached fetcher hook. handles data fetching and resource locking.
 * 
 * It will only fetch data when it is not already available in the store.
 * 
 * Guarantees that only the first component mounted, with the same requestId, will trigger a data update;
 * so it's safe to have multiple components on the page at the same time.
 * 
 * @param updater - the function to call to fetch new data
 * @param cacheKey - the cache key(s) to use with the store
 * @param opts - fetcher options
 */

export function useCachedFetcher<T>(
    updater: () => Promise<T>,
    cacheKey: string | string[],
    opts?: Options<T>
): FetcherState<T> {
    const request: RequestState | undefined = useSelector(
        useCallback((state: any) => getCachedRequest(state, cacheKey), [cacheKey])
    );

    const id = useId();
    cacheKey = useMemoCompare(cacheKey, compareCacheKeys);

    const data = request && (request.data as T);
    const error = request && request.error;
    const onDataReceived = opts && opts.onDataReceived;
    const onError = opts && opts.onError;
    const isUpdateRequired = opts && opts.isUpdateRequired;

    const mounted = request ? request.fetchers.get(0) === id : false;
    const fetching = request ? request.isFetching : false;

    const reduxDispatch = useDispatch();
    useEffect(() => {
        reduxDispatch(mountCachedFetcher(cacheKey, id));
        return () => {
            reduxDispatch(unmountCachedFetcher(cacheKey, id));
        }
    }, [id, cacheKey]);
    
    const prevRef = useRef<Prev<T> | null>(null);
    useEffect(() => {
        // cached fetcher is more conservative with about what changes will trigger a refresh than "useFetcher".
        // By default, it will only trigger an update when there is no data available in the store:
        if (prevRef.current == null ||
            prevRef.current.data !== data ||
            prevRef.current.isMounted !== mounted ||
            prevRef.current.cacheKey !== cacheKey
        ) {
            prevRef.current = { data, isMounted: mounted, cacheKey: cacheKey };
            if (mounted) {
                const requiresUpdate = isUpdateRequired
                    ? isUpdateRequired(data) : data == null;

                if (requiresUpdate) {
                    reduxDispatch(setCachedFetching(cacheKey, true));
                    updater()
                        .then(
                            (data) => {
                                // received a result
                                reduxDispatch(receiveCachedData(cacheKey, data));
                                if (onDataReceived) {
                                    onDataReceived(data);
                                }
                            },
                            (error) => {
                                //received an error
                                error = '' + error;
                                reduxDispatch(receiveCachedError(cacheKey, error));
                                if (onError) {
                                    onError(error);
                                }
                            }
                        );
                }
            }
        }
    }, [
        cacheKey,
        mounted,
        data,
        onDataReceived,
        isUpdateRequired,
        updater
    ]);

    // Construct the actions to expose to the consumer
    const actions = useMemo((): FetcherActions => ({
        refreshData: () => {
            reduxDispatch(clearCachedData(cacheKey));
        }
    }), [reduxDispatch, cacheKey]);

    return {
        data,
        error,
        fetching,
        actions
    };
}
