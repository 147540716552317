import { call, takeEvery } from 'redux-saga/effects';

import { invalidateCache } from './util';
import { SET_TRANSLATIONS } from './translation-actions';

function *invalidateTranslationsCache() {
  yield call(invalidateCache);
}

export default function *() {
  yield [
    takeEvery(SET_TRANSLATIONS, invalidateTranslationsCache)
  ]
}
