const ActionTypes = {
    RECOMPUTE_FIGURE_PROPS: 'core.designer.figure.RECOMPUTE_FIGURE_PROPS',
    BULK_UPDATE_COMPUTED_PROPS: 'core.designer.figure.BULK_UPDATE_COMPUTED_PROPS',
    BULK_CLEAR_COMPUTED_PROPS: 'core.designer.figure.BULK_CLEAR_COMPUTED_PROPS',
    BULK_REMOVE_UPDATE_COMPUTED_PROPS: 'core.designer.figure.BULK_REMOVE_UPDATE_COMPUTED_PROPS'
}

export default ActionTypes;

export function recomputeFigureProps(designId) {
    return {
        type: ActionTypes.RECOMPUTE_FIGURE_PROPS,
        designId
    }
}

export function bulkRemoveUpdateComputedProps(designId, removedIds, diffs) {
    return {
        type: ActionTypes.BULK_REMOVE_UPDATE_COMPUTED_PROPS,
        designId,
        removedIds,
        diffs
    }
}


export function bulkUpdateComputedProps(designId, diffs) {
    return {
        type: ActionTypes.BULK_UPDATE_COMPUTED_PROPS,
        designId,
        diffs
    }
}

export function bulkClearComputedProps(designId, figureIds) {
    return {
        type: ActionTypes.BULK_CLEAR_COMPUTED_PROPS,
        designId,
        figureIds
    }
}
