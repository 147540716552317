/* global draw2d */

/**
 * Updates the position and dimension whenever
 * the bounds of the figure or its parents were changed.
 */
export default draw2d.layout.locator.Locator.extend({
    relocate(index, target) {
        updatePosition(target);
    }
});

export function updatePosition(target) {
    const userData = target.getUserData();
    if(userData == null) return;

    let x = userData.get('x', 0);
    let y = userData.get('y', 0);
    const anchorX = userData.get('anchor-x', 0);
    const anchorY = userData.get('anchor-y', 0);
    const bounds = target.getBoundingBox();
    x -= bounds.w * anchorX;
    y -= bounds.h * anchorY;
    target.setPosition(x, y);
}
