import { AUTH_TOKEN_PARAM, LANGUAGE_PARAM } from "./authUtils";
import { useLocalParam } from "../../utils/hooks/useLocalParam";

export function useAuthToken(): string | null {
    return useLocalParam(AUTH_TOKEN_PARAM);
}

export function useLanguage(): string | null {
    return useLocalParam(LANGUAGE_PARAM);
}
