import Imm from 'immutable';

/**
 * Creates a depth-first ordering of the figures on the canvas.
 */

export function getCanvasFigureIdsOrdered(computedValues, figuresChildren) {

    function f(figureConfigId, parentId) {
        const figs = computedValues.getIn([figureConfigId, 'figures']);
        if(figs == null) return [];

        const children = figuresChildren.get(figureConfigId);
        const arr = [];

        for(let fig of figs) {
            if(parentId != null
                && fig.get('parentId') !== parentId) continue;

            const figureId = fig.get('id');
            arr.push(figureId);

            if(children != null) {
                for (let childConfigId of children) {
                    const childFigureIds = f(childConfigId, figureId);
                    for(let childId of childFigureIds) arr.push(childId);
                }
            }
        }

        return arr;
    }

    const rootFigures = figuresChildren.get('root');
    if(!rootFigures) return Imm.List();

    return rootFigures.flatMap((figureConfigId) => f(figureConfigId, null));
}

