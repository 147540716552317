export function afterResize(handlerFn) {
    let timeoutListener;
    let handle;

    window.addEventListener('resize', handle = () => {
        //every time the window resize is called cancel the setTimeout() function
        clearTimeout(timeoutListener);

        // add a small delay to allow time for DOM to properly resize
        timeoutListener = setTimeout(handlerFn, 100);
    });

    // clear the event listeners
    return () => { 
        clearTimeout(timeoutListener);
        window.removeEventListener('resize', handle);
    }
}

// cross-browser getPosition from event
// https://www.sitepoint.com/building-custom-right-click-context-menu-javascript/
export function getPosition(e) {
  let posx = 0;
  let posy = 0;

  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  } else if (e.clientX || e.clientY) {
    posx = e.clientX + document.body.scrollLeft + 
                       document.documentElement.scrollLeft;
    posy = e.clientY + document.body.scrollTop + 
                       document.documentElement.scrollTop;
  }

  return {
    x: posx,
    y: posy
  }
}
