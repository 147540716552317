import { TokenPayload, AuthToken } from "./authTypes";

export function decodeAuthTokenPayload(token: string): TokenPayload {
    const payloadString = token.split('.')[1];
    const payload = JSON.parse(atob(payloadString)) as any;
    const permissions = new Set(JSON.parse(payload.per) as number[]);

    return {
        loginName: payload.log,
        language: payload.lng,
        expiry: payload.exp,
        realm: payload.rlm,
        permissions
    };
}

export function comparePayload(prevToken: AuthToken | null, nextToken: AuthToken | null): boolean {
    if (prevToken == null || nextToken == null) {
        return (prevToken == null) === (nextToken == null);
    }

    const prev = prevToken.payload;
    const next = nextToken.payload;
    
    return prev.loginName === next.loginName
        && prev.realm === next.realm
        && comparePermissions(prev.permissions, next.permissions);
}

function comparePermissions(prev: Set<number>, next: Set<number>) {
    if (prev.size === next.size) {
        for (let p of prev) {
            if (!next.has(p)) return false;
        }
        return true;
    } else {
        return false;
    }
}
