import { ATTRIB_DATA_NAME } from "./pluginHelpers";
import { PluginDefinition } from "./types/pluginTypes";
import { Cache } from "./utils/Cache";

/**
 * Loads the plugin definition and stores the result in a cache
 */

export class PluginDefinitionLoader<C, R> extends Cache<PluginDefinition<C, R>> {
    private readonly _id: string;
    private readonly _src: string;

    constructor(id: string, src: string) {
        super();
        this._id = id;
        this._src = src;
    }

    load(): void {
        if (this.hasValue || this.isLoading) {
            // the plugin has already been loaded
            return;
        }

        // begin loading the definition
        this.onLoading();

        // create a new script element
        const script = document.createElement('script');
        script.src = this._src;
        script.setAttribute(ATTRIB_DATA_NAME, this._id);
        script.async = true;

        script.onload = this.onScriptLoaded.bind(this);
        script.onerror = this.onScriptError.bind(this);

        // add the script to the document
        document.head.append(script);
    }

    onLoaded(definition: PluginDefinition<C, R>) {
        this.onResult(definition);
    }

    private onScriptLoaded(): void {
        if (!this.hasValue) {
            console.error(`Error loading plugin "${this._id}". Script finished executing, but plugin not registered!`);
            return;
        }
    }

    private onScriptError(err: any): void {
        console.error(`Error loading plugin "${this._id}". Reason: ` + err);
    }
}
