import { PluginCreationParams, Plugin, PluginDefinition } from "./types/pluginTypes";
import { Listeners, Listener } from "./utils/Listeners";
import { PluginCache } from "./PluginsManager";

export enum Event {
    LOADED = 'loaded'
}

/**
 * Manages a plugin instance. Handles lazy-loading the plugin definition, and calling internal life-cycle methods.
 */

export class PluginInstance<C, R> {
    private readonly listeners = new Listeners<Event>();

    private readonly cache: PluginCache<C, R>;
    private readonly params: PluginCreationParams<C>;

    private container?: HTMLElement;
    private plugin?: Plugin<C, R>;

    constructor(
        cache: PluginCache<C, R>,
        params: PluginCreationParams<C>
    ) {
        this.cache = cache;
        this.params = { ...params };
        this.onDefinitionLoaded = this.onDefinitionLoaded.bind(this);
        this.onResourcesLoaded = this.onResourcesLoaded.bind(this);
    }

    mount(container: HTMLElement): void {
        this.container = container;

        const definition = this.cache.definition;
        if (definition.hasValue) {
            this.onDefinitionLoaded(definition.value!);

        } else {
            definition.addListener(this.onDefinitionLoaded);

            // load the plugin definition
            if (!definition.isLoading) {
                definition.load();
            }
        }
    }

    unmount(): void {
        if (this.plugin && this.plugin.unmount) {
            this.plugin.unmount();
        }

        // clear state
        this.container = undefined;
        this.plugin = undefined;

        // remove event listeners
        const cache = this.cache;
        cache.definition.removeListener(this.onDefinitionLoaded);
        cache.resources.removeListener(this.onResourcesLoaded);
    }

    setConfig(config: C): void {
        if (this.params.config !== config) {
            this.params.config = config;

            // delegate to plugin adapter
            if (this.plugin && this.plugin.setConfig) {
                this.plugin.setConfig(config);
            }
        }
    }

    setProps(props: { [key: string]: unknown }): void {
        if (this.params.props !== props) {
            this.params.props = props;

            // delegate to plugin adapter
            if (this.plugin && this.plugin.setProps) {
                this.plugin.setProps(props);
            }
        }
    }

    setData(data: unknown): void {
        if (this.params.data !== data) {
            this.params.data = data;

            // delegate to plugin adapter
            if (this.plugin && this.plugin.setData) {
                this.plugin.setData(data);
            }
        }
    }

    refresh() {
        // delegate to plugin adapter
        if (this.plugin && this.plugin.refresh) {
            this.plugin.refresh();
        }
    }

    addListener(listener: Listener<Event>) {
        this.listeners.add(listener);
    }

    removeListener(listener: Listener<Event>) {
        this.listeners.remove(listener);
    }

    private onDefinitionLoaded(definition: PluginDefinition<C, R>): void {
        if (this.container) {
            
            // create a new plugin instance
            this.plugin = definition.create(this.params);
            this.listeners.trigger(Event.LOADED);

            // mount the plugin inside the container
            this.plugin.mount(this.container);
            
            // load the plugin resources
            if (this.plugin.loadResources) {
                const resources = this.cache.resources;
                
                if (resources.hasValue) {
                    this.onResourcesLoaded(resources.value!);
                    
                } else {
                    resources.addListener(this.onResourcesLoaded);

                    // Resources are shared between all plugin instances. The first plugin mounted will fetch the resources.
                    if (!resources.isLoading) {
                        resources.onLoading();
                        this.plugin.loadResources(resources.onResult.bind(resources));
                    }
                }
            }
        }
    }

    private onResourcesLoaded(resources: R) {
        // delegate to plugin adapter
        if (this.plugin && this.plugin.setResources) {
            this.plugin.setResources(resources);
        }
    }
}
