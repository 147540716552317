import { Middleware } from 'redux';
import { History } from 'history';
import { setBreadcrumbs, ActionType, PopLocationAction, PushLocationAction, ReplaceLocationAction, SetLocationAction, resetBreadcrumbs } from './actions';
import { getLocationState } from './selectors';
import { HistoryState, LocationItem, createLocationItem } from './types';
import { List } from 'immutable';

/**
 * Redux middleware. Handles actions which should update the current location, by updating the browser history.
 * Stores the "breadcrumbs" list in the history stack.
 */

function hasState(state: Partial<HistoryState> | undefined): state is HistoryState {
    return state != null
        && state.breadcrumbs != null;
}

function createHistoryState(
    breadcrumbs: List<LocationItem>,
    params: { [key: string]: unknown } | undefined
): HistoryState {
    return {
        breadcrumbs: breadcrumbs.toJS(),
        params
    };
}

export function createLocationMiddleware(history: History): Middleware {
    return (api) => {
        history.listen((location) => {
            const state = location.state as Partial<HistoryState> | undefined;
            if (hasState(state)) {
                const breadcrumbs = state.breadcrumbs;
                const params = state.params;
                const items = List(breadcrumbs.map(bc => createLocationItem(bc)));
                api.dispatch(setBreadcrumbs(items, params));
            } else {
                api.dispatch(resetBreadcrumbs());
            }
        });

        function getBreadcrumbs() {
            const state = api.getState();
            return getLocationState(state).breadcrumbs;
        }

        return (next) => (action) => {
            next(action);

            switch (action.type) {
                case ActionType.PUSH_LOCATION: {
                    const item = (action as PushLocationAction).item;
                    let breadcrumbs = getBreadcrumbs();
                    breadcrumbs = breadcrumbs.push(item);
                    history.push(item.pathName, createHistoryState(breadcrumbs, item.params));
                    break;
                }
                case ActionType.POP_LOCATION: {
                    let n = (action as PopLocationAction).n;
                    n = n == null ? 1 : n;
                    let breadcrumbs = getBreadcrumbs();
                    breadcrumbs = breadcrumbs.setSize(Math.max(1, breadcrumbs.size - n));
                    const item = breadcrumbs.last() as LocationItem | undefined;
                    if (item) history.push(item.pathName, createHistoryState(breadcrumbs, item.params));
                    break;
                }
                case ActionType.REPLACE_LOCATION: {
                    const item = (action as ReplaceLocationAction).item;
                    let breadcrumbs = getBreadcrumbs();
                    breadcrumbs = breadcrumbs.set(breadcrumbs.size - 1, item);
                    history.replace(item.pathName, createHistoryState(breadcrumbs, item.params));
                    break;
                }
                case ActionType.SET_LOCATION: {
                    const breadcrumbs = (action as SetLocationAction).items;
                    const item = breadcrumbs.last() as LocationItem | undefined;
                    if (item) history.push(item.pathName, createHistoryState(breadcrumbs, item.params));
                    break;
                }
            }
        }
    }
}
