import Imm from 'immutable';
import ActionTypes from '../actions/galleryActions';

export default function(state = Imm.Map(), action) {
    switch(action.type) {
        case ActionTypes.RESET_PATH: return handleResetPath(state, action);
        case ActionTypes.SET_PATH: return handleSetPath(state, action);
        default: return state;
    }
}

function handleResetPath(state) {
    return state.deleteIn(['gallery', 'path']);
}

function handleSetPath(state, action) {
    return state.setIn(['gallery', 'path'], action.path);
}
