import DatasourceActions from '../actions/DatasourceActions'
import {List, Map} from 'immutable'

function datasourceReducer(state = Map(), action) {
  switch(action.type) {
    case DatasourceActions.UPDATE_SUGGESTIONS_DONE:
      return handleUpdateSuggestionsDone(state, action)
    case DatasourceActions.UPDATE_SUGGESTIONS_STARTED:
      return handleUpdateSuggestionsStarted(state, action)
    case DatasourceActions.UPDATE_SUGGESTIONS_ERROR:
      return handleUpdateSuggestionsError(state, action)
    case DatasourceActions.SHOW_SUGGESTION_DIALOG:
      return handleShowSuggestionDialog(state, action)
    case DatasourceActions.CLOSE_SUGGESTIONS_SELECT:
      return handleSuggestionsInactive(state, action) // simply set inactive
    case DatasourceActions.CLOSE_SUGGESTIONS_DIALOG:
      return handleCloseSuggestionsDialog(state, action)
    case DatasourceActions.SUGGESTION_ACCEPTED:
     return handleSuggestionAccepted(state, action)
    case DatasourceActions.SUGGESTIONS_ACTIVE:
      return handleSuggestionsActive(state, action)
    case DatasourceActions.SUGGESTIONS_INACTIVE:
      return handleSuggestionsInactive(state, action)
    case DatasourceActions.TRIM_SUGGESTIONS:
      return handleTrimSuggestions(state, action)
    case DatasourceActions.NAVIGATE_SUGGESTION_UP:
      return handleNavigateSuggestionUp(state, action)
    case DatasourceActions.NAVIGATE_SUGGESTION_DOWN:
      return handleNavigateSuggestionDown(state, action)
    case DatasourceActions.FIND_DONE:
      return handleFindDone(state, action)
    case DatasourceActions.FIND_STARTED:
      return handleFindStarted(state, action)
    case DatasourceActions.FIND_ERROR:
      return handleFindError(state, action)
    case DatasourceActions.CLEAR_DATASOURCE:
      return handleClearDatasource(state, action);
    default:
      return state;
  }
}

function handleSuggestionAccepted(state, {requester, item}) {
  return handleTrimSuggestions(state, {requester, trimmed: List().push(item)})
}

function resetNavigateSuggestion(state, requester) {
  return state.deleteIn(requester.push('suggestions', 'focused'))
}

function handleNavigateSuggestionUp(state, {requester}) {
  const suggestionItems = state.getIn(requester.push('suggestions', 'items'), List())
  if (suggestionItems.isEmpty()) {
    return resetNavigateSuggestion(state, requester)
  }

  return state.updateIn(requester.push('suggestions', 'focused'),
    (f = 0) => {
      if (f-1 < 0) {
        return suggestionItems.size-1
      } else {
        return f-1
      }
    })
}

function handleNavigateSuggestionDown(state, {requester}) {
  const suggestionItems = state.getIn(requester.push('suggestions', 'items'), List())
  if (suggestionItems.isEmpty()) {
    return resetNavigateSuggestion(state, requester)
  }
  return state.updateIn(requester.push('suggestions', 'focused'),
    (f = 0) => {
      if (f+1 >= suggestionItems.size) {
        return 0
      } else {
        return f+1
      }
    })
}

function handleTrimSuggestions(state, {requester, trimmed}) {
  state = resetNavigateSuggestion(state, requester)
  return state.setIn(requester.push('suggestions').push('items'), trimmed)
}

function handleSuggestionsActive(state, {requester}) {
  return state.updateIn(requester.push('suggestions'), Map(),
    (m) => m.set('active', true))
}

function handleSuggestionsInactive(state, {requester}) {
  return state.updateIn(requester.push('suggestions'), Map(),
    (m) => m.set('active', false))
}

function handleUpdateSuggestionsDone(state, {requester, result, message}) {
  state = resetNavigateSuggestion(state, requester)
  return state.updateIn(requester.push('suggestions'), Map(),
    (m) => m.set('status', 'done')
            .set('requester', requester)
            .set('items', result)
            .set('message', message))
}

function handleUpdateSuggestionsError(state, {requester, message}) {
  state = resetNavigateSuggestion(state, requester)
  return state.updateIn(requester.push('suggestions'), Map(),
    (m) => m.set('status', 'error')
            .set('requester', requester)
            .set('message', message))
}

function handleUpdateSuggestionsStarted(state, {requester}) {
  return state.updateIn(requester.push('suggestions'), Map(),
    (m) => m.set('status', 'fetching')
            .set('requester', requester)
            .set('message', 'fetching...'))
}

function handleShowSuggestionDialog(state, {requester, initialDatasourceValues}) {
  return state.setIn(requester.push('suggestions').push('dialog'), Map({initialDatasourceValues}))
}

function handleCloseSuggestionsDialog(state, {requester}) {
  return state.deleteIn(requester.push('suggestions').push('dialog'))
}

function handleFindDone(state, {recipient, result}) {
  return state.updateIn(recipient, Map(),
    (m) => m.set('status', 'done')
            .set('items', result)
            .delete('error-message'))
}

function handleFindStarted(state, {recipient}) {
  return state.updateIn(recipient, Map(),
    (m) => m.set('status', 'fetching')
            .delete('error-message'))
}

function handleFindError(state, {recipient, result}) {
  return state.updateIn(recipient, Map(),
    (m) => m.set('status', 'error')
            .set('error-message', result))
}

function handleClearDatasource(state, { datasourceId }) {
  return state.delete('designer-selector-' + datasourceId);
}

export default datasourceReducer
