import Imm from 'immutable';
import {
    compile,
    compileCondition,
    compileStatic,
    isDynamicExpression,
    isCompileable,
    valueFromStaticExpression } from '../../helper/eval/evaluator';

/**
 * Applies figDiff key changes, by compiling new computed expressions.
 * 
 * Returns the modified computedValues map (with the new expressions included),
 * and a list of affected figureIds.
 */

export function updateExpressions(compValues, diffs) {
    let figureIds = [];
    for(let diff of diffs) {
        const figureId = diff.figureId;
        const altIndex = diff.altIndex;

        figureIds.push(figureId);
        diff.figDiff.entrySeq().forEach(([propKey, propValue]) => {
            if(propValue != null) { 
                // compile computed properties changed
                compValues = compileComputedProp(compValues, figureId, altIndex, propKey, propValue);

            } else {
                // remove computed property
                compValues = removeComputedProp(compValues, figureId, altIndex, propKey);
            }
        });
    }

    return {
        computedValues: compValues,
        figureIds
    };
}
    
/**
 * Compiles the prop expression
 */
function compileComputedProp(computedState, figureId, altIndex, propKey, propValue) {
    return updateAltExpression(computedState, figureId, altIndex, (expressions) => {

        try {
            let expression, dynamic;
            
            if(isCompileable(propKey, propValue)) {
                const compileFn = propKey === 'condition' ? compileCondition : compile;
                expression = compileFn(propKey, propValue);
                dynamic = isDynamicExpression(expression);
                
                if(!dynamic) {
                    expression = valueFromStaticExpression(expression)
                }

            } else {
                expression = propValue;
                dynamic = false;
            }

            return expressions.set(propKey, Imm.Map()
                .set('value', expression)
                .set('dynamic', dynamic));

        } catch(e) {
            const staticExpr = compileStatic(propValue);
            return expressions.set(propKey, Imm.Map()
                .set('error', e)
                .set('value', staticExpr)
                .set('dynamic', false));
        }
    });
}

function removeComputedProp(computedState, figureId, altIndex, propKey) {
    return updateAltExpression(computedState, figureId, altIndex, (expressions) => expressions.delete(propKey));
}

function updateAltExpression(computedState, figureId, altIndex, fn) {
    return computedState.updateIn([figureId, 'expressions'], Imm.List(),
        (expressions) => expressions.update(altIndex, Imm.Map(), fn));
}

