import PropTypes from 'prop-types'
import { Map } from 'immutable';

export const fieldInputPropTypes = {
    fieldConfig: PropTypes.instanceOf(Map).isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    datasourceDefinition: PropTypes.object.isRequired,
    fieldState: PropTypes.object.isRequired
};

export const FieldInputShape = PropTypes.shape(fieldInputPropTypes);
