import { createBulkActions } from '../helper/bulkHelper';

const ActionTypes = {
  LIST_DESIGN : 'core.designer.datarequest.LIST_DESIGN',
  DISPLAY_LIST_DESIGN : 'core.designer.datarequest.DISPLAY_LIST_DESIGN',
  GET_DESIGN_DATA : 'core.designer.datarequest.GET_DESIGN_DATA',
  RETRIEVE_DATA_DESIGN : 'core.designer.datarequest.RETRIEVE_DATA_DESIGN',
  SAVE_DESIGN : 'core.designer.datarequest.SAVE_DESIGN',
  SAVE_DESIGN_RESULT: 'core.designer.datarequest.SAVE_DESIGN_RESULT',
  SET_DESIGN_DATASOURCE_CONFIG: 'core.designer.datarequest.SET_DESIGN_DATASOURCE_CONFIG',
  FETCH_AVAILABLE_DATASOURCES: 'core.designer.datarequest.FETCH_AVAILABLE_DATASOURCES',
  RECEIVE_AVAILABLE_DATASOURCES: 'core.designer.datarequest.RECEIVE_AVAILABLE_DATASOURCES',
  SET_SELECTED_DATASOURCE: 'core.designer.datarequest.SET_SELECTED_DATASOURCE',
  ADD_DATASOURCE_TO_DESIGN: 'core.designer.datarequest.ADD_DATASOURCE_TO_DESIGN',
  REMOVE_DATASOURCE_FROM_DESIGN: 'core.designer.datarequest.REMOVE_DATASOURCE_FROM_DESIGN',
  FETCH_DATASOURCE_DEFINITION: 'core.designer.datarequest.FETCH_DATASOURCE_DEFINITION',
  INVOKE_DATASOURCE: 'core.designer.datarequest.INVOKE_DATASOURCE',
  RECEIVE_DATASOURCE_DEFINITION: 'core.designer.datarequest.RECEIVE_DATASOURCE_DEFINITION',
  RECEIVE_DATASOURCE_RESULTS: 'core.designer.datarequest.RECEIVE_DATASOURCE_RESULTS',
  FETCH_FIGURE_DESCRIPTORS: 'core.designer.datarequest.FETCH_FIGURE_DESCRIPTORS',
  RECEIVE_FIGURES_MANIFEST: 'core.designer.datarequest.RECEIVE_FIGURES_MANIFEST'
};
export default ActionTypes;

export function designList() {
    return {
        type: ActionTypes.LIST_DESIGN
    }
}

export  function displayListDesign(list) {
    return {
        type: ActionTypes.DISPLAY_LIST_DESIGN,
        list
    }
}

export  function showDesignData(uuid, params, config) {
    return {
        type: ActionTypes.GET_DESIGN_DATA,
        uuid,
        params,
        config
    }
}

export  function retrieveDataDesign(data, params, config) {
    return {
        type: ActionTypes.RETRIEVE_DATA_DESIGN,
        data,
        params,
        config
    }
}

export  function saveDesign(designId, description) {
    return {
        type: ActionTypes.SAVE_DESIGN,
        designId,
        description
    }
}

export function saveDesignResult(designId, result) {
    return {
        type: ActionTypes.SAVE_DESIGN_RESULT,
        designId,
        result
    }
}

export function setDesignDatasourceConfig(designId, datasourceConfig) {
    return {
        type: ActionTypes.SET_DESIGN_DATASOURCE_CONFIG,
        designId,
        datasourceConfig
    }
}

export function fetchAvailableDatasources() {
    return {
        type: ActionTypes.FETCH_AVAILABLE_DATASOURCES
    }
}

export function receiveAvailableDatasources(data) {
	return {
		type: ActionTypes.RECEIVE_AVAILABLE_DATASOURCES,
		data
	}
}

export function setSelectedDatasource(datasourceName) {
    return {
        type: ActionTypes.SET_SELECTED_DATASOURCE,
        datasourceName
    }
}

export function addDatasourceToDesign(designId, datasourceName) {
    return {
        type: ActionTypes.ADD_DATASOURCE_TO_DESIGN,
        designId,
        datasourceName
    }
}

export function removeDatasourceFromDesign(designId, datasourceId) {
    return {
        type: ActionTypes.REMOVE_DATASOURCE_FROM_DESIGN,
        designId,
        datasourceId
    }
}

export function fetchFigureDescriptors() {
  return {
    type: ActionTypes.FETCH_FIGURE_DESCRIPTORS
  }
}

export function receiveFiguresManifest(figuresManifest) {
    return {
        type: ActionTypes.RECEIVE_FIGURES_MANIFEST,
        figuresManifest
    }
}

export const invokeDatasource = createBulkActions(ActionTypes.INVOKE_DATASOURCE, 'designId');

export const receiveDatasourceResults = createBulkActions(ActionTypes.RECEIVE_DATASOURCE_RESULTS, 'designId');

export const fetchDatasourceDefinition = createBulkActions(ActionTypes.FETCH_DATASOURCE_DEFINITION);

export const receiveDatasourceDefinition = createBulkActions(ActionTypes.RECEIVE_DATASOURCE_DEFINITION);
