import React from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';

import { getCurrentDesignId, getCurrentDesignName, isUndoAvailable, isRedoAvailable } from '../../helper/selector/selectors'
import { getCanvasConfigInitialValues} from '../../helper/selector/canvasConfigSelectors';
import { showModal } from '../../../../component-lib/actions';
import { canvasConfigSaveChanges } from '../../actions/DesignerActions';
import { undo, redo } from '../../actions/undoActions';

function DesignerHeader(props) {
    const isDesignMode = props.mode === 'Design';
    return <div id="designer-header">
            <IconButton faName="arrow-left" label="Root" onClick={props.handleRedirectRoot}/>
            { isDesignMode
                ? <IconButton faName="eye" label="View" onClick={props.handleSwitchMode}/>
                : <IconButton faName="pencil" label="Design" onClick={props.handleSwitchMode}/> }
            { isDesignMode && designModeButtons(props) }
            <div id="designer-header-title">{ props.designName }</div>
            { isDesignMode && <IconButton faName="cog" label="Canvas Options" onClick={() => props.showModal('designer-modal', getModalParams(props))}/> }
        </div>
}

// show a modal to edit the canvas config
function getModalParams(props) {
    return fromJS({
        title: 'Canvas Options',
        body: {
            componentId: 'CANVAS_CONFIG_FORM',
            params: { }
        },
        values: props.canvasConfigInitialValues,
        actions: [
            { label: 'Confirm', style: 'success', type: 'submit', action: (v) => props.canvasConfigSaveChanges(props.designId, v) }
        ]
    });
}

function designModeButtons(props) {
    return [
        <IconButton key="save" faName="save" label="Save" onClick={props.handleSave}/>,
        <IconButton key="export" faName="file-text" label="Export" onClick={props.handleExport}/>,
        <div key="separator" className="designer-header-separator"/>,
        <IconButton key="undo" disabled={!props.isUndoAvailable} faName="undo" label="Undo" onClick={() => props.undo('figures')}/>,
        <IconButton key="repeat" disabled={!props.isRedoAvailable} faName="repeat" label="Redo" onClick={() => props.redo('figures')}/>
    ]
}

DesignerHeader.propTypes = {
    // ownprops
    mode: PropTypes.string.isRequired,
    handleRedirectRoot: PropTypes.func.isRequired,
    handleSwitchMode: PropTypes.func.isRequired,
    handleSave: PropTypes.func,
    handleExport: PropTypes.func,
    undo: PropTypes.func.isRequired,
    redo: PropTypes.func.isRequired,
    
    canvasConfigInitialValues: PropTypes.object,
    canvasConfigSaveChanges: PropTypes.func.isRequired,
    
    designId: PropTypes.string.isRequired,
    designName: PropTypes.string,
    isUndoAvailable: PropTypes.bool.isRequired,
    isRedoAvailable: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired
}

function IconButton(props) {
    const className = 'designericon-btn-header' + (props.disabled ? ' disabled' : '');
    return <div className={className} onClick={props.onClick}>
        <span className="designericon-btn-header-label">{ props.label }</span>
        <FontAwesome name={ props.faName }/>
    </div>
}

IconButton.propTypes = {
    faName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

export default connect(
    (state) => ({
        designId: getCurrentDesignId(state),
        designName: getCurrentDesignName(state),
        canvasConfigInitialValues: getCanvasConfigInitialValues(state),
        isUndoAvailable: isUndoAvailable(state),
        isRedoAvailable: isRedoAvailable(state)
    }),
    { showModal, undo, redo, canvasConfigSaveChanges }
)(DesignerHeader);
