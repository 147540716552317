import { Map } from 'immutable';

import ActionTypes from '../actions/reportActions';

export default function(state = Map(), action) {
    switch(action.type) {
        case ActionTypes.FIND_REPORT_DEFINITION_BY_NAME:
            return handleFindReportDefinition(state, action);
        case ActionTypes.RECEIVE_REPORT_DEFINITION:
            return handleReceiveReportDefinition(state, action);
        case ActionTypes.FETCH_REPORT_DEFINITION_ERROR:
            return handleFetchReportDefinitionError(state, action);
    }
    return state;
}

function handleFindReportDefinition(state, action) {
    return state.setIn(['reportDefinitions', action.reportName], Map({
        fetching: true
    }));
}

function handleReceiveReportDefinition(state, action) {
    return state.setIn(['reportDefinitions', action.reportName], Map({
        fetching: false,
        value: action.result
    }));
}

function handleFetchReportDefinitionError(state, action) {
    return state.setIn(['reportDefinitions', action.reportName], Map({
        fetching: false,
        error: true,
        errorDetail: action.error
    }));
}
