import { Set, Map, fromJS } from 'immutable';
import actionTypes from './actions'
import { createBulkReducer } from '../helper/bulkHelper';
import { datasourcesData, extractMetaAndValuesFromResult } from '../data/datasourceFieldsData';

function displayListDesign(state, { list }) {
  return state.set('displayListDesign', fromJS(list));
}

function retrieveDataDesign(state, action) {
  //data.data.data is same as :  responseData -> componentData -> designData
  const newddata = JSON.parse(action.data.data, (key, value) => {
    switch(key) {
      case 'secondaryIds': return Set(value);
      default: return value;
    }
  })

  const config = action.config;
  let designConfig = fromJS(newddata);
  if(config != null) {

    // merge the overriden config into the design
    designConfig = designConfig.merge(config);
  }

  const uuid = action.data.uniqueIdentifier;
  if(action){
    return state.setIn(['designs', uuid], designConfig)
      .setIn(['web-components', uuid], Map({ ...action.data }))
      .setIn(['selected-design', 'uuid'], uuid)
      .setIn(['selected-design', 'params'], action.params)
      .removeIn(['requests', uuid, 'fetching']);
  }
  return
}

function beginFetchDesignData(state, { uuid }) {
  return state
    // .deleteIn(['selected-design'])
    .setIn(['requests', uuid, 'fetching'], true);
}

function saveDesignReult(state, { designId, result }) {
  return state
    .setIn(['web-components', designId], fromJS(result));
}

function setDesignDatasourceConfig(state, { designId, datasourceConfig }) {
  return state.setIn(['designs', designId, 'selector-configs'], datasourceConfig);
}

function receiveAvailableDatasources(state, { data }) {
  return state.setIn(['datasources', 'availableDatasources'], fromJS(data));
}

function setSelectedDatasource(state, { datasourceName }) {
  return state.setIn(['datasources', 'selected'], datasourceName);
}

const receiveDatasourceDefinitionsBulk = createBulkReducer((state, value) => {
  const datasourceName = value.get('name');
  return state.setIn(['datasources', datasourceName, 'definition'], value);
});

const receiveDatasourceResultsBulk = createBulkReducer((state, value, action) => {
  const designId = action.designId;
  const dsId = value.dsId;
  const result = value.result;
  const datasourceName = value.datasourceName;
  const datasourceData = datasourcesData.get(datasourceName);
  const { meta, values } = extractMetaAndValuesFromResult(result, datasourceData);
  return state
    .setIn(['designs', designId, 'datasource-results', dsId], values)
    .setIn(['designs', designId, 'datasource-results', '_meta', dsId], meta);
});

function addDatasourceToDesign(state, { designId, datasourceName }) {
  return state.updateIn(['designs', designId, 'selector-configs'], (selectorConfigs) => {

    // get the largest datasource number and add 1
    const max = selectorConfigs.map((x) =>
      // parse from substring of the datasource id
      Number.parseFloat(x.get('id').substring(2, 3))
    ).max() || 0;

    const id = `ds${max + 1}`;
    return selectorConfigs.push(Map({
      id,
      label: datasourceName,
      datasource: datasourceName,
      fieldValues: Map()
    }));
  })
}

function receiveFiguresManifest(state, { figuresManifest }) {
  return state.set('figures-manifest', figuresManifest);
}

function removeDatasourceFromDesign(state, { designId, datasourceId }) {
  const pathSelectorConfigs = ['designs', designId, 'selector-configs'];
  const selectorConfigs = state.getIn(pathSelectorConfigs);
  return state.updateIn(pathSelectorConfigs,
      (selectorConfigs) => selectorConfigs.filter((config) => config.get('id') !== datasourceId)
    )
    .update('formMapping', (formMappings) => removeFromMapping(selectorConfigs, datasourceId, formMappings))
    .deleteIn(['formMapping', 'designer-selector-' + datasourceId])
    .deleteIn(['designs', designId, 'datasource-results', datasourceId]);
}

/**
 * Remove an id from the form mappings and updates the existing paths
 */
function removeFromMapping(selectorConfigs, removedId, formMappings) {
  let prevSelectorPath;
  selectorConfigs.forEach((selectorConfig) => {
    const id = selectorConfig.get('id');
    if(id === removedId || prevSelectorPath) {

      const p = ['designer-selector-' + id, 'selectorPath'];
      const selectorPath = formMappings.getIn(p);
      if(prevSelectorPath) {
        // update existing index
        formMappings = formMappings.setIn(p, prevSelectorPath);
      } else {
        // delete removed formMapping
        formMappings = formMappings.delete(p[0]);
      }

      prevSelectorPath = selectorPath;
    }
  });
  return formMappings;
}

export default function(state = Map(), action) {
  switch(action.type) {
    case actionTypes.DISPLAY_LIST_DESIGN: return displayListDesign(state, action);
    case actionTypes.RETRIEVE_DATA_DESIGN: return retrieveDataDesign(state, action);
    case actionTypes.GET_DESIGN_DATA: return beginFetchDesignData(state, action);
    case actionTypes.SAVE_DESIGN_RESULT: return saveDesignReult(state, action);
    case actionTypes.SET_DESIGN_DATASOURCE_CONFIG: return setDesignDatasourceConfig(state, action);
    case actionTypes.RECEIVE_AVAILABLE_DATASOURCES: return receiveAvailableDatasources(state, action);
    case actionTypes.SET_SELECTED_DATASOURCE: return setSelectedDatasource(state, action);
    case actionTypes.RECEIVE_DATASOURCE_DEFINITION: return receiveDatasourceDefinitionsBulk(state, action);
    case actionTypes.RECEIVE_DATASOURCE_RESULTS: return receiveDatasourceResultsBulk(state, action);
    case actionTypes.ADD_DATASOURCE_TO_DESIGN: return addDatasourceToDesign(state, action);
    case actionTypes.REMOVE_DATASOURCE_FROM_DESIGN: return removeDatasourceFromDesign(state, action);
    case actionTypes.RECEIVE_FIGURE_DESCRIPTORS: return receiveFigureDescriptors(state, action);
    case actionTypes.RECEIVE_FIGURES_MANIFEST: return receiveFiguresManifest(state, action);
  }
  return state;
}
