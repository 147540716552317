import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';

import RequestBuilder from '../../utils/RequestBuilder';
import { getRequestBuilderCreator, getRequestHandler } from './globals';
import { useCachedFetcher } from '../../utils/hooks/useCachedFetcher';

type OwnProps = {
    requestId: string,
    cacheKey?: string | string[],
    params?: unknown[],
    render: (data: unknown) => React.ReactElement
}

type StateProps = {
    requestBuilderCreator: () => RequestBuilder
}

/**
 * @deprecated removed in version 4.7.0
 * 
 * Wrapper around the "Fetcher" component, that invokes a registered "handler" function,
 * identified by a requestId. The params + requestId form the "cache-key" of the fetcher.
 */

function RequestIdFetcher(props: OwnProps & StateProps) {
    const { requestId, params, requestBuilderCreator } = props;

    const updater = useCallback(() => {
        const requestBuilder = requestBuilderCreator();
        const handler = getRequestHandler(requestId);
        if (handler == null) throw new Error('No request handler exists with id: ' + requestId);
        const safeParams = params ? params : [];
        return handler(requestBuilder, ...safeParams);
    }, [requestBuilderCreator, requestId, params]);

    const cachekey = props.cacheKey || props.requestId;
    const { data } = useCachedFetcher(updater, cachekey);
    return useMemo(() => props.render(data), [data, props.render]);
}

export default connect<StateProps, {}, OwnProps>(
    (state: any) => ({
        requestBuilderCreator: getRequestBuilderCreator()(state)
    })
)(RequestIdFetcher);
