import moment, { MomentInputObject } from 'moment';
import i18next from 'i18next';
import { javaToMomentFormatString } from './javaToMomentFormatStringConverter';

// translation keys
const KEY_DECIMAL_SEP = 'decimalSeparator';
const KEY_GROUPING_SEP = 'groupingSeparator'
const KEY_FORMAT_DATE = 'dateFormat';
const KEY_FORMAT_DATETIME = 'dateTimeFormat';
const KEY_FORMAT_DATETIME_SECONDS = 'dateTimeFormatWithSeconds';
const KEY_FORMAT_TIME = 'timeFormat';
const KEY_FORMAT_TIME_SECONDS = 'timeFormatWithSeconds';
const KEY_FORMAT_YEAR_MONTH = 'yearMonthFormat';

// default format properties
const DEFAULT_MIN_FRACTION_DIGITS: number = 0;
const DEFAULT_MAX_FRACTION_DIGITS: number = 2;

function toNumber(input: unknown): number {
    if (typeof input === 'number') {
        return input;
    } else {
        return +(input as any);
    }
}

export function formatPrice(input: unknown, opts?: unknown) {
    return formatDecimal(input, Object.assign({
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }, opts));
}

export function formatDecimal(input: unknown, opts?: unknown) {
    const value = toNumber(input);
    if (Number.isNaN(value)) {
        return '';
    }
    
    const formatter = new Intl.NumberFormat(undefined, Object.assign({
        minimumFractionDigits: DEFAULT_MIN_FRACTION_DIGITS,
        maximumFractionDigits: DEFAULT_MAX_FRACTION_DIGITS
    }, opts));

    const fmtParts = formatter.formatToParts(value);
    const decSep = i18next.t(KEY_DECIMAL_SEP);
    const groupSep = i18next.t(KEY_GROUPING_SEP);

    let acc = '';
    for (let part of fmtParts) {
        if (part.type === 'decimal' && decSep !== KEY_DECIMAL_SEP) {
            acc += decSep;
        } else if (part.type === 'group' && groupSep !== KEY_GROUPING_SEP) {
            acc += groupSep;
        } else {
            acc += part.value;
        }
    }
    return acc;
}

export function createDateTimeFormatter(key: string, fallback: string) {
    return (input: unknown): string => {
        if (input) {
            const fmt = i18next.t(key);
            const formatString = fmt === key ? fallback : javaToMomentFormatString(key, fmt);
            const m = moment(input as MomentInputObject);
            if (m.isValid()) {
                return m.format(formatString);
            }
        }
        return '';
    }
}

export const formatDate = createDateTimeFormatter(KEY_FORMAT_DATE, 'DD MM YYYY');

export const formatDateTime = createDateTimeFormatter(KEY_FORMAT_DATETIME, 'DD MM YYYY HH:mm');

export const formatDateTimeWithSeconds = createDateTimeFormatter(KEY_FORMAT_DATETIME_SECONDS, 'DD MM YYYY HH:mm:ss');

export const formatTime = createDateTimeFormatter(KEY_FORMAT_TIME, 'HH:mm');

export const formatTimeWithSeconds = createDateTimeFormatter(KEY_FORMAT_TIME_SECONDS, 'HH:mm:ss');

export const formatYearMonth = createDateTimeFormatter(KEY_FORMAT_YEAR_MONTH, 'MM YYYY');
