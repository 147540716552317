import { call } from "redux-saga/effects";

export function* callFetcher<P extends any[], T>(request: (...params: P) => Promise<T>, ...params: P): IterableIterator<any> {
    let result, err;
    try {
        result = yield call(request, ...params);
    } catch (e) {
        err = e;
    }
    return [result, err];
}
