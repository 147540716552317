import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React from 'react';

import { SingleDatePicker } from 'react-dates';
import moment, { Moment, isMoment } from 'moment';
import { WrappedFieldMetaProps } from 'redux-form';
import { javaToMomentFormatString } from '@pearlchain/component-lib-common';
import { useTranslation } from 'react-i18next';

interface OwnProps {
    input: InputProps,
    meta: WrappedFieldMetaProps,
    label: string,
    disabled?: boolean,
    displayFormat?: string,
    className?: string 
}

interface InputProps {
    value: Moment | string | null,
    onChange?: any,
    onFocus?: any,
    onBlur?: any
}

function getFormatString(t: (key: string) => string) {
    const format = t('dateFormat');
    return javaToMomentFormatString('dateFormat', format);
}

export default function DateBinder(props: OwnProps): JSX.Element {
    const {input : {value, onChange, onFocus, onBlur}, meta: { active=false }, disabled } = props;

    const onFocusFn = (arg: { focused: boolean|null }) => {
        if (arg.focused) {
            onFocus()
        } else {
            onBlur()
        }
    };

    const { t } = useTranslation();
    const displayFormat = props.displayFormat
        ? props.displayFormat
        : getFormatString(t);
    
    const onInternalDateChange = (value: Moment|null) => {
        onChange(value);
    };

    let dateValue;
    if (!isMoment(value)) {
        if (value) {
            dateValue = moment(value);
        } else {
            dateValue = null;
        }
    } else {
        dateValue = value;
    }

    const className = `binder-datepicker ${props.className ? props.className : ''}`
    return (
        <div className={className}>
            <SingleDatePicker id='date-input' placeholder={''} date={dateValue}
                        focused={active} numberOfMonths={1} disabled={disabled}
                        onDateChange={onInternalDateChange} onFocusChange={onFocusFn}
                        small={true} isOutsideRange={() => false}
                        firstDayOfWeek={1}
                        displayFormat={displayFormat}            
                    />
        </div>);
}
