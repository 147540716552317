import { Listeners, Listener } from "./Listeners";

export class Cache<T> {
    private readonly listeners: Listeners<T> = new Listeners();

    value?: T | undefined;
    hasValue: boolean = false;
    isLoading: boolean = false;

    onLoading(): void {
        this.isLoading = true;
    }

    onResult(result: T): void {
        this.value = result;
        this.hasValue = true;
        this.isLoading = false;
        this.listeners.trigger(result);
    }

    addListener(callback: Listener<T>): Listener<T> {
        return this.listeners.add(callback);
    }

    removeListener(callback: Listener<T>): void {
        this.listeners.remove(callback);
    }
}
