import { put, select, all, takeEvery } from 'redux-saga/effects';
import { fromJS } from 'immutable';

import ActionTypes, { receiveDesignList, receiveUpdateDesignProperty, removeDesign } from './actions';
import { runRemoteCall } from '../../../util/remoteSagaHelper';

const RemoteUrl = {
  LIST_DESIGNS: 'sec/api/webcomponent/list',
  SAVE_WEBCOMPONENT: 'sec/api/webcomponent/save',
  DELETE_WEBCOMPONENT: 'sec/api/webcomponent/delete' 
}

export default function() {
    return all([
        takeEvery(ActionTypes.FETCH_DESIGN_LIST, handleFetchDesignList),
        takeEvery(ActionTypes.REQUEST_UPDATE_DESIGN_PROPERTY, handleUpdateDesignProperty),
        takeEvery(ActionTypes.REQUEST_REMOVE_DESIGN, handleRemoveDesign)
    ]);
}

function *handleFetchDesignList() {
    const [ result ] = yield* runRemoteCall(RemoteUrl.LIST_DESIGNS, { type: 'design' }, 'designList');
    
    if(result) {
        const res = fromJS(result);
        yield put(receiveDesignList(res));
    } 
}

function *handleUpdateDesignProperty({ fieldKey, value }) {
    const { name, designId, propertyKey } = fieldKey;
    const design = yield select(getDesign(designId));
    
    // ignore unchanged value
    if(design.get(propertyKey) === value) {
        return;    
    }

    // do request
    const version = design.get('version');
    
    const [ result ] = yield* runRemoteCall(RemoteUrl.SAVE_WEBCOMPONENT, { 
        uniqueIdentifier: designId,
        [propertyKey]: value,
        version
    }, name);

    // handle result
    if(result) {
        const version = result.version;
        yield put(receiveUpdateDesignProperty(fieldKey, value, version));
    }
}

function *handleRemoveDesign({ designId }) {
    const design = yield select(getDesign(designId));
    const version = design.get('version');

    const [ result ] = yield* runRemoteCall(RemoteUrl.DELETE_WEBCOMPONENT, {
        uuid: designId,
        version
    }, designId);
    
    // design was removed successfully
    if(result) {
        yield put(removeDesign(designId));
    }
}

function getDesign(designId) {
    return (state) => {
        return state.designList
            .get('designs')
            .find((design) => design.get('uniqueIdentifier') === designId);
    };
}
