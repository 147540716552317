import { List, Map } from 'immutable';
import ActionTypes from '../actions/drilldownActions';
import { getCurrentDesignId, getCurrentDesignName, getCurrentDesignParams } from '../helper/selector/selectors';

export default function(state = Map(), action) {
    switch(action.type) {
        case ActionTypes.DRILLDOWN:
            return handlePushDrilldown(state, action);
        case ActionTypes.DRILLDOWN_FROM_BREADCRUMB:
            return handleDrilldownFromBreadcrumb(state, action);
        default:
            return state;
    }
}

function handlePushDrilldown(state) {
    const fullState = { designer: state };
    const currentDesignId = getCurrentDesignId(fullState);
    const currentDesignName = getCurrentDesignName(fullState);
    const currentDesignparams = getCurrentDesignParams(fullState);

    return state.update('drilldown', List(), (drilldown) => {
        return drilldown.push(Map({
            designId: currentDesignId,
            name: currentDesignName,
            params: currentDesignparams
        }))
    });
}

function handleDrilldownFromBreadcrumb(state, { idx }) {
    return state.update('drilldown', List(), (drilldown) => {
        return drilldown.setSize(idx);
    });
}
