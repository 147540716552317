/* global $ */
import ReactSVGFigure from './ReactSVGFigure';
import PdfFigureComponent from './PdfFigureComponent';

export default ReactSVGFigure.extend({
    NAME: 'custom.PDF',

    init(attr, setter, getter) {
        this._super(attr,
            $.extend({}, {
                url: this.setUrl,
                page: this.setPage
            }, setter),
            $.extend({}, {
                url: this.getUrl,
                page: this.getPage
            }, getter));
    },

    repaint(attributes) {
        attributes = Object.assign({}, {
            page: this.getPage(),
            url: this.getUrl()
        }, attributes);

        return this._super(attributes);
    },

    getReactComponent() {
        // the component that will manage updates to this figure
        return PdfFigureComponent;
    },

    setUrl(url) {
        this.url = url;
        this.repaint();
    },

    getUrl() {
        return this.url;
    },

    setPage(page) {
        this.page = page;
        this.repaint();
    },

    getPage() {
        return this.page;
    }
})
