import { List, Map } from 'immutable';
import { mapDeep } from '../../helper/treeHelper';

/**
 * Converts the figures from the manifest file to a list of figure descriptors 
 */
export function figureManifest2FigureDescriptors(manifestEntries) {
    return mapDeep(manifestEntries, (entry) => applySorting(entry.sub), (entry, children) => {
        const fileName = entry.fileName;
        const directory = entry.directory;
        const id = entry.id;
        if(directory) {
            return createFolder(fileName, id, List(children));

        } else {
            const path = id;
            return createFigureDescriptorImage(id, fileName, path);
        }
    });
}

function applySorting(sub) {
    return sub && sub.sort((a, b) => a.fileName > b.fileName ? 1 : -1);
}

export function createFolder(name, id, children) {
    return Map({
        name,
        id,
        children,
        folder: true
    });
}

function createFigureDescriptorImage(id, label, path) {
    return Map({
        id,
        label,
        'figure-props': Map({
            readOnly: true,
            mainConfig: Map({
                data: Map({
                    type: 'draw2d.shape.basic.Image',
                    path
                }),
                input: Map()
            })
        })
    });
}
