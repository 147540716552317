import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchServerData } from './server-data-actions'


class ServerData extends React.Component {
    componentDidMount(){
        this.props.fetchServerData(this.props.request)
    }
    render() {
        return this.props.children
    }
}

export default connect(
    (state, props) => props,
    {fetchServerData}
)(ServerData)
