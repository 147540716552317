const ActionTypes = {
    UPDATE_HISTORY: 'core.designer.undo.UPDATE_HISTORY',
    UNDO: 'core.designer.undo.UNDO',
    REDO: 'core.designer.undo.REDO'
}

export default ActionTypes;

export function updateUndoHistory(groupId) {
    return {
        type: ActionTypes.UPDATE_HISTORY,
        groupId
    }
}

export function undo(groupId) {
    return {
        type: ActionTypes.UNDO,
        groupId
    }
}

export function redo(groupId) {
    return {
        type: ActionTypes.REDO,
        groupId
    }
}
