(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("immutable"), require("react"), require("prop-types"), require("react-redux"), require("reselect"), require("redux-saga/effects"), require("redux-form"), require("react-immutable-proptypes"), require("react-fontawesome"), require("lodash"), require("react-notification-system-redux"), require("re-reselect"), require("redux"), require("redux-saga"), require("react-router-redux"), require("uuid"), require("@pearlchain/stackbase-common"), require("tee-cup"), require("mathjs"), require("colornames"), require("redux-thunk"), require("react-split-pane"), require("query-string"), require("react-dom"), require("pdfjs-dist"));
	else if(typeof define === 'function' && define.amd)
		define("designer-lib", ["immutable", "react", "prop-types", "react-redux", "reselect", "redux-saga/effects", "redux-form", "react-immutable-proptypes", "react-fontawesome", "lodash", "react-notification-system-redux", "re-reselect", "redux", "redux-saga", "react-router-redux", "uuid", "@pearlchain/stackbase-common", "tee-cup", "mathjs", "colornames", "redux-thunk", "react-split-pane", "query-string", "react-dom", "pdfjs-dist"], factory);
	else if(typeof exports === 'object')
		exports["designer-lib"] = factory(require("immutable"), require("react"), require("prop-types"), require("react-redux"), require("reselect"), require("redux-saga/effects"), require("redux-form"), require("react-immutable-proptypes"), require("react-fontawesome"), require("lodash"), require("react-notification-system-redux"), require("re-reselect"), require("redux"), require("redux-saga"), require("react-router-redux"), require("uuid"), require("@pearlchain/stackbase-common"), require("tee-cup"), require("mathjs"), require("colornames"), require("redux-thunk"), require("react-split-pane"), require("query-string"), require("react-dom"), require("pdfjs-dist"));
	else
		root["designer-lib"] = factory(root["immutable"], root["react"], root["prop-types"], root["react-redux"], root["reselect"], root["redux-saga/effects"], root["redux-form"], root["react-immutable-proptypes"], root["react-fontawesome"], root["lodash"], root["react-notification-system-redux"], root["re-reselect"], root["redux"], root["redux-saga"], root["react-router-redux"], root["uuid"], root["@pearlchain/stackbase-common"], root["tee-cup"], root["mathjs"], root["colornames"], root["redux-thunk"], root["react-split-pane"], root["query-string"], root["react-dom"], root["pdfjs-dist"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__30__, __WEBPACK_EXTERNAL_MODULE__33__, __WEBPACK_EXTERNAL_MODULE__41__, __WEBPACK_EXTERNAL_MODULE__42__, __WEBPACK_EXTERNAL_MODULE__45__, __WEBPACK_EXTERNAL_MODULE__47__, __WEBPACK_EXTERNAL_MODULE__959__, __WEBPACK_EXTERNAL_MODULE__962__, __WEBPACK_EXTERNAL_MODULE__964__, __WEBPACK_EXTERNAL_MODULE__967__, __WEBPACK_EXTERNAL_MODULE__998__, __WEBPACK_EXTERNAL_MODULE__1004__, __WEBPACK_EXTERNAL_MODULE__1016__, __WEBPACK_EXTERNAL_MODULE__1021__, __WEBPACK_EXTERNAL_MODULE__1023__) {
return 